import { Socket } from "phoenix"

const socket = new Socket("/socket")
const channels = {}

socket.connect()

const subscribe = (ch, actions, cb) => {
  let channel = channels[ch]

  if (!channel) {
    channel = socket.channel(ch)
    channel.join()
    channels[ch] = channel
  }

  return Object.fromEntries(actions.map(a => [a, channel.on(a, cb)]))
}

const unsubscribe = (ch, actions, refs) => {
  const channel = channels[ch]

  if (!channel) return

  actions.forEach(action => {
    const ref = refs[action]

    if (!ref) return 

    channel.off(action, ref)
  })
}

export default {
  subscribe,
  unsubscribe
}
