<script>
  import { setStore, mapStore } from "hui"
  import ProductTable from "./ProductTable.svelte"
  import SelectPage from "./SelectPage.svelte"

  const products = [
    {
      "data": {
        "prop.Y29yZT1Ub3RhbCtjYXJhdCt3ZWlnaHQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1SZWNpcGllbnQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Women",
        "shopify.ivy_and_rose.collection_ids": [],
        "shopify.ivy_and_rose.tags": [],
        "prop.Y29yZT1TZXR0aW5nK3N0eWxlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1EaWFtb25kK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "shopify.ilya_fine_jewelry.product_type": null,
        "prop.Y29yZT1DbGFyaXR5JmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1TaWRlK3N0b25lK3NoYXBlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "etsy.ivy_and_rose.who_made": "i_did",
        "prop.Y29yZT1TaXplJmViYXk9JmV0c3k9JnNob3BpZnk9": "4.75",
        "prop.Y29yZT1DZW50ZXIrbWluK3dpZHRoK2ZpdCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "etsy.ivy_and_rose.price": "50",
        "prop.Y29yZT1DZXJ0aWZpY2F0aW9uJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "etsy.ivy_and_rose.title": "Authentic Cartier Love Ring 18K White Gold 5.5Mm Wedding Band Stacking Designer",
        "ebay.ivy_and_rose.store_category_id": null,
        "product.main_style": null,
        "product.sku": "19204",
        "shopify.ivy_and_rose.title": null,
        "shopify.ivy_and_rose.price": null,
        "prop.Y29yZT1TaWRlK3N0b25lcytxdWFudGl0eSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "etsy.ivy_and_rose.is_supply": false,
        "prop.Y29yZT1HcmFtK3dlaWdodCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "6.8 grams",
        "prop.Y29yZT1DZW50ZXIrbWF4K2xlbmd0aCtmaXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "ebay.ivy_and_rose.condition": "NEW",
        "prop.Y29yZT1TZWNvbmRhcnkrc3RvbmUmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1TaWRlK3N0b25lK2NhcmF0K3dlaWdodCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1SaW5nK3NpemUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "4.75",
        "prop.Y29yZT1NZXRhbCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "18k White Gold",
        "prop.Y29yZT1UaGVtZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1BcHByb3hpbWF0ZSt0aW1lK3BlcmlvZCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "New",
        "prop.Y29yZT1NYWluK3N0b25lK3NoYXBlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1DZW50ZXIrbWluK2xlbmd0aCtmaXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "ebay.ivy_and_rose.secondary_category_id": null,
        "prop.Y29yZT1NYXgrc2l6ZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "10",
        "prop.Y29yZT1GYW5jeStkaWFtb25kK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "etsy.ivy_and_rose.shop_section_id": "17822028",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY2FyYXQrd2VpZ2h0JmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1DdXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1NYXRlcmlhbCttdWx0aSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "18k White Gold",
        "prop.Y29yZT1DZW50ZXIrbWF4K3dpZHRoK2ZpdCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "ebay.ivy_and_rose.sub_title": null,
        "product.additional_styles": [],
        "product.price": "50",
        "prop.Y29yZT1NZXRhbCtwdXJpdHkmZWJheT0mZXRzeT0mc2hvcGlmeT0": "18k",
        "product.title": "AUTHENTIC CARTIER LOVE RING 18K WHITE GOLD 5.5mm WEDDING BAND STACKING DESIGNER",
        "prop.Y29yZT1UcmVhdG1lbnQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1NYWluK3N0b25lJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1TdHlsZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1PY2Nhc2lvbiZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "shopify.ivy_and_rose.product_type": null,
        "ebay.ivy_and_rose.price": "50",
        "product.description": "Why we love it: \r\n\r\nThis authentic Cartier LOVE ring is high quality, and utterly classic! The substantial 18k white gold band bears the iconic Love symbol first produced by Cartier in the 1970s. The sleek and simple design has an air of grace, sophistication, and timeless elegance. \r\n\r\n\r\n\r\nHighlights:\r\n\r\n- Solid 18k white gold\r\n\r\n- 5.5mm version\r\n\r\n- The ring is in excellent condition, and the hallmarks are perfectly preserved.\r\n\r\n- Excellent value! This ring retails for $2080!\r\n\r\n\r\n\r\nDimensions:\r\n\r\nIt is 5.5mm across (north-south) and 1.6mm in height.\r\n\r\n\r\nSizing Note:\r\n\r\nThe ring is a French size 49, US size 4.75. Due to the eternity design, it can not be resized.",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "etsy.ivy_and_rose.category_id": "1232",
        "shopify.ivy_and_rose.vendor": null,
        "product.cost": "10",
        "prop.Y29yZT1TaXphYmxlJmViYXk9JmV0c3k9JnNob3BpZnk9": "No",
        "prop.Y29yZT1NaW4rc2l6ZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "4",
        "prop.Y29yZT1NYWluK3N0b25lK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1JdGVtK3R5cGUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Band - Other",
        "prop.Y29yZT1FcmEmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Modern",
        "product.internal_title": "ring - cocktail - modern",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY29sb3ImZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "shopify.ilya_fine_jewelry.price": null,
        "prop.Y29yZT1Db2xsZWN0aW9uJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "product.notes": "5.5 x 1.6 (French sz 49, retail 2080)",
        "shopify.ilya_fine_jewelry.title": null,
        "product.stock_number": null,
        "etsy.ivy_and_rose.when_made": "2010_2019",
        "prop.Y29yZT1DYW4rYmUrcGVyc29uYWxpemVkJmViYXk9JmV0c3k9JnNob3BpZnk9": "Yes",
        "product.location": "some",
        "shopify.ilya_fine_jewelry.seo_title": null,
        "prop.Y29yZT1OYXR1cmFsJTJGbGFiLWNyZWF0ZWQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "ebay.ivy_and_rose.store_category2_id": null,
        "shopify.ilya_fine_jewelry.vendor": null,
        "shopify.ivy_and_rose.seo_title": null,
        "prop.Y29yZT1HZW1zdG9uZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1TaWRlK3N0b25lJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "shopify.ilya_fine_jewelry.collection_ids": [],
        "shopify.ilya_fine_jewelry.tags": [],
        "prop.Y29yZT1TaWRlK3N0b25lK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "ebay.ivy_and_rose.category_id": "261994",
        "prop.Y29yZT1NYWRlK3RvK29yZGVyJmViYXk9JmV0c3k9JnNob3BpZnk9": "No",
        "product.availability": 1,
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrc2hhcGUmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY2xhcml0eSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1TaWRlK3N0b25lK2NsYXJpdHkmZWJheT0mZXRzeT0mc2hvcGlmeT0": null
      },
      "id": "a5b268f6-0068-4329-94da-5587f249d165",
      "sku": "19204"
    },
    {
      "data": {
        "prop.Y29yZT1Ub3RhbCtjYXJhdCt3ZWlnaHQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1SZWNpcGllbnQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Women",
        "shopify.ivy_and_rose.collection_ids": [],
        "shopify.ivy_and_rose.tags": [],
        "prop.Y29yZT1TZXR0aW5nK3N0eWxlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1EaWFtb25kK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "shopify.ilya_fine_jewelry.product_type": null,
        "prop.Y29yZT1DbGFyaXR5JmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1TaWRlK3N0b25lK3NoYXBlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "etsy.ivy_and_rose.who_made": "i_did",
        "prop.Y29yZT1TaXplJmViYXk9JmV0c3k9JnNob3BpZnk9": "11.0 (Resizable)",
        "prop.Y29yZT1DZW50ZXIrbWluK3dpZHRoK2ZpdCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "etsy.ivy_and_rose.price": "50",
        "prop.Y29yZT1DZXJ0aWZpY2F0aW9uJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "etsy.ivy_and_rose.title": "Solid 14K White Gold Wedding Band Stacking Ring Wide 5.8Mm Classic Milgrain Mens",
        "ebay.ivy_and_rose.store_category_id": "14843984",
        "product.main_style": null,
        "product.sku": "19202",
        "shopify.ivy_and_rose.title": null,
        "shopify.ivy_and_rose.price": null,
        "prop.Y29yZT1TaWRlK3N0b25lcytxdWFudGl0eSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "etsy.ivy_and_rose.is_supply": false,
        "prop.Y29yZT1HcmFtK3dlaWdodCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "9.0 grams",
        "prop.Y29yZT1DZW50ZXIrbWF4K2xlbmd0aCtmaXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "ebay.ivy_and_rose.condition": "NEW",
        "prop.Y29yZT1TZWNvbmRhcnkrc3RvbmUmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1TaWRlK3N0b25lK2NhcmF0K3dlaWdodCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1SaW5nK3NpemUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "11",
        "prop.Y29yZT1NZXRhbCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "14k White Gold",
        "prop.Y29yZT1UaGVtZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1BcHByb3hpbWF0ZSt0aW1lK3BlcmlvZCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "New",
        "prop.Y29yZT1NYWluK3N0b25lK3NoYXBlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1DZW50ZXIrbWluK2xlbmd0aCtmaXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "ebay.ivy_and_rose.secondary_category_id": "261994",
        "prop.Y29yZT1NYXgrc2l6ZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "10",
        "prop.Y29yZT1GYW5jeStkaWFtb25kK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "etsy.ivy_and_rose.shop_section_id": "17822028",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY2FyYXQrd2VpZ2h0JmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1DdXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1NYXRlcmlhbCttdWx0aSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "14k White Gold",
        "prop.Y29yZT1DZW50ZXIrbWF4K3dpZHRoK2ZpdCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "ebay.ivy_and_rose.sub_title": null,
        "product.additional_styles": [],
        "product.price": "50",
        "prop.Y29yZT1NZXRhbCtwdXJpdHkmZWJheT0mZXRzeT0mc2hvcGlmeT0": "14k",
        "product.title": "SOLID 14k WHITE GOLD WEDDING BAND STACKING RING WIDE 5.8mm CLASSIC MILGRAIN MENS",
        "prop.Y29yZT1UcmVhdG1lbnQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1NYWluK3N0b25lJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1TdHlsZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1PY2Nhc2lvbiZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "shopify.ivy_and_rose.product_type": null,
        "ebay.ivy_and_rose.price": "50",
        "product.description": "Why we love it: \r\n\r\nThis timeless band has a classic profile with milgrain accents for a sophisticated touch! It’s a great choice for a wedding band.\r\n\r\n\r\n\r\nHighlights:\r\n\r\n- Solid 14k white gold\r\n\r\n- Sleek and classic\r\n\r\n- Very substantial at 9.0 grams\r\n\r\n\r\n\r\nDimensions:\r\n\r\n5.8mm across (north-south) and 2.2mm in height.\r\n\r\n\r\n\r\nAdditional Notes:\r\n\r\nThe ring is currently a size 11. Due to the milgrain accents and thick shank, special resizing rates apply.",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "etsy.ivy_and_rose.category_id": "1247",
        "shopify.ivy_and_rose.vendor": null,
        "product.cost": "10",
        "prop.Y29yZT1TaXphYmxlJmViYXk9JmV0c3k9JnNob3BpZnk9": "yes",
        "prop.Y29yZT1NaW4rc2l6ZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "4",
        "prop.Y29yZT1NYWluK3N0b25lK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1JdGVtK3R5cGUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Band - Wedding",
        "prop.Y29yZT1FcmEmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Modern",
        "product.internal_title": "ring - wedding band - modern",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY29sb3ImZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "shopify.ilya_fine_jewelry.price": null,
        "prop.Y29yZT1Db2xsZWN0aW9uJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "product.notes": "5.8 x 2.2 (mens, special sizing rates apply)",
        "shopify.ilya_fine_jewelry.title": null,
        "product.stock_number": null,
        "etsy.ivy_and_rose.when_made": "2010_2019",
        "prop.Y29yZT1DYW4rYmUrcGVyc29uYWxpemVkJmViYXk9JmV0c3k9JnNob3BpZnk9": "Yes",
        "product.location": "some",
        "shopify.ilya_fine_jewelry.seo_title": null,
        "prop.Y29yZT1OYXR1cmFsJTJGbGFiLWNyZWF0ZWQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "ebay.ivy_and_rose.store_category2_id": null,
        "shopify.ilya_fine_jewelry.vendor": null,
        "shopify.ivy_and_rose.seo_title": null,
        "prop.Y29yZT1HZW1zdG9uZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1TaWRlK3N0b25lJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "shopify.ilya_fine_jewelry.collection_ids": [],
        "shopify.ilya_fine_jewelry.tags": [],
        "prop.Y29yZT1TaWRlK3N0b25lK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "ebay.ivy_and_rose.category_id": "261977",
        "prop.Y29yZT1NYWRlK3RvK29yZGVyJmViYXk9JmV0c3k9JnNob3BpZnk9": "No",
        "product.availability": 1,
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrc2hhcGUmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY2xhcml0eSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1TaWRlK3N0b25lK2NsYXJpdHkmZWJheT0mZXRzeT0mc2hvcGlmeT0": null
      },
      "id": "7f4301e5-fc07-47d4-9f31-612772b70545",
      "sku": "19202"
    },
    {
      "data": {
        "prop.Y29yZT1Ub3RhbCtjYXJhdCt3ZWlnaHQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1SZWNpcGllbnQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Women",
        "shopify.ivy_and_rose.collection_ids": [],
        "shopify.ivy_and_rose.tags": [],
        "prop.Y29yZT1TZXR0aW5nK3N0eWxlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1EaWFtb25kK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "shopify.ilya_fine_jewelry.product_type": null,
        "prop.Y29yZT1DbGFyaXR5JmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1TaWRlK3N0b25lK3NoYXBlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "etsy.ivy_and_rose.who_made": "someone_else",
        "prop.Y29yZT1TaXplJmViYXk9JmV0c3k9JnNob3BpZnk9": "9.0 (Resizable)",
        "prop.Y29yZT1DZW50ZXIrbWluK3dpZHRoK2ZpdCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "etsy.ivy_and_rose.price": "50",
        "prop.Y29yZT1DZXJ0aWZpY2F0aW9uJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "etsy.ivy_and_rose.title": "Solid 14K Yellow Gold Wedding Band Vintage Stacking Ring Faceted 4Mm Classic",
        "ebay.ivy_and_rose.store_category_id": null,
        "product.main_style": null,
        "product.sku": "19199",
        "shopify.ivy_and_rose.title": null,
        "shopify.ivy_and_rose.price": null,
        "prop.Y29yZT1TaWRlK3N0b25lcytxdWFudGl0eSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "etsy.ivy_and_rose.is_supply": false,
        "prop.Y29yZT1HcmFtK3dlaWdodCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "3.1 grams",
        "prop.Y29yZT1DZW50ZXIrbWF4K2xlbmd0aCtmaXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "ebay.ivy_and_rose.condition": "USED_EXCELLENT",
        "prop.Y29yZT1TZWNvbmRhcnkrc3RvbmUmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1TaWRlK3N0b25lK2NhcmF0K3dlaWdodCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1SaW5nK3NpemUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "9",
        "prop.Y29yZT1NZXRhbCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "14k Yellow Gold",
        "prop.Y29yZT1UaGVtZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1BcHByb3hpbWF0ZSt0aW1lK3BlcmlvZCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "Retro",
        "prop.Y29yZT1NYWluK3N0b25lK3NoYXBlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1DZW50ZXIrbWluK2xlbmd0aCtmaXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "ebay.ivy_and_rose.secondary_category_id": null,
        "prop.Y29yZT1NYXgrc2l6ZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "10",
        "prop.Y29yZT1GYW5jeStkaWFtb25kK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "etsy.ivy_and_rose.shop_section_id": "17822028",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY2FyYXQrd2VpZ2h0JmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1DdXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1NYXRlcmlhbCttdWx0aSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "14k Yellow Gold",
        "prop.Y29yZT1DZW50ZXIrbWF4K3dpZHRoK2ZpdCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "ebay.ivy_and_rose.sub_title": null,
        "product.additional_styles": [],
        "product.price": "50",
        "prop.Y29yZT1NZXRhbCtwdXJpdHkmZWJheT0mZXRzeT0mc2hvcGlmeT0": "14k",
        "product.title": "SOLID 14k YELLOW GOLD WEDDING BAND VINTAGE STACKING RING FACETED 4mm CLASSIC",
        "prop.Y29yZT1UcmVhdG1lbnQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1NYWluK3N0b25lJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1TdHlsZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1PY2Nhc2lvbiZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "shopify.ivy_and_rose.product_type": null,
        "ebay.ivy_and_rose.price": "50",
        "product.description": "Why we love it: \n\r\nThis vintage band has a classic profile with a faceted finish that catches the light beautifully and adds a unique touch!\n\r\n\n\r\nHighlights:\n\r\n- Solid 14k yellow gold\n\r\n\n\r\nDimensions:\n\r\n3.8mm across (north-south) and 1.4mm in height.",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "etsy.ivy_and_rose.category_id": "1232",
        "shopify.ivy_and_rose.vendor": null,
        "product.cost": "10",
        "prop.Y29yZT1TaXphYmxlJmViYXk9JmV0c3k9JnNob3BpZnk9": "yes",
        "prop.Y29yZT1NaW4rc2l6ZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "4",
        "prop.Y29yZT1NYWluK3N0b25lK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1JdGVtK3R5cGUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Band - Other",
        "prop.Y29yZT1FcmEmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Original Vintage",
        "product.internal_title": "ring - cocktail - vintage",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY29sb3ImZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "shopify.ilya_fine_jewelry.price": null,
        "prop.Y29yZT1Db2xsZWN0aW9uJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "product.notes": "3.8 x 1.4",
        "shopify.ilya_fine_jewelry.title": null,
        "product.stock_number": null,
        "etsy.ivy_and_rose.when_made": "1950s",
        "prop.Y29yZT1DYW4rYmUrcGVyc29uYWxpemVkJmViYXk9JmV0c3k9JnNob3BpZnk9": "Yes",
        "product.location": "some",
        "shopify.ilya_fine_jewelry.seo_title": null,
        "prop.Y29yZT1OYXR1cmFsJTJGbGFiLWNyZWF0ZWQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "ebay.ivy_and_rose.store_category2_id": null,
        "shopify.ilya_fine_jewelry.vendor": null,
        "shopify.ivy_and_rose.seo_title": null,
        "prop.Y29yZT1HZW1zdG9uZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1TaWRlK3N0b25lJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "shopify.ilya_fine_jewelry.collection_ids": [],
        "shopify.ilya_fine_jewelry.tags": [],
        "prop.Y29yZT1TaWRlK3N0b25lK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "ebay.ivy_and_rose.category_id": "261994",
        "prop.Y29yZT1NYWRlK3RvK29yZGVyJmViYXk9JmV0c3k9JnNob3BpZnk9": "No",
        "product.availability": 0,
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrc2hhcGUmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY2xhcml0eSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1TaWRlK3N0b25lK2NsYXJpdHkmZWJheT0mZXRzeT0mc2hvcGlmeT0": null
      },
      "id": "9c8aa6f9-88d8-4f10-977e-7432d058f23b",
      "sku": "19199"
    },
    {
      "data": {
        "prop.Y29yZT1Ub3RhbCtjYXJhdCt3ZWlnaHQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "0.50ct",
        "prop.Y29yZT1SZWNpcGllbnQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Women",
        "shopify.ivy_and_rose.collection_ids": [],
        "shopify.ivy_and_rose.tags": [],
        "prop.Y29yZT1TZXR0aW5nK3N0eWxlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1EaWFtb25kK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "shopify.ilya_fine_jewelry.product_type": null,
        "prop.Y29yZT1DbGFyaXR5JmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1TaWRlK3N0b25lK3NoYXBlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "etsy.ivy_and_rose.who_made": "someone_else",
        "prop.Y29yZT1TaXplJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1DZW50ZXIrbWluK3dpZHRoK2ZpdCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "etsy.ivy_and_rose.price": "50",
        "prop.Y29yZT1DZXJ0aWZpY2F0aW9uJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "etsy.ivy_and_rose.title": "Lab Created Diamond Matching Pair 1/2 Carat Kite Shape Cut Loose Accent Stones",
        "ebay.ivy_and_rose.store_category_id": "14736769",
        "product.main_style": null,
        "product.sku": "LG-19522-KITE-.50",
        "shopify.ivy_and_rose.title": null,
        "shopify.ivy_and_rose.price": null,
        "prop.Y29yZT1TaWRlK3N0b25lcytxdWFudGl0eSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "etsy.ivy_and_rose.is_supply": true,
        "prop.Y29yZT1HcmFtK3dlaWdodCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1DZW50ZXIrbWF4K2xlbmd0aCtmaXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "ebay.ivy_and_rose.condition": "NEW",
        "prop.Y29yZT1TZWNvbmRhcnkrc3RvbmUmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1TaWRlK3N0b25lK2NhcmF0K3dlaWdodCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1SaW5nK3NpemUmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1NZXRhbCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1UaGVtZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1BcHByb3hpbWF0ZSt0aW1lK3BlcmlvZCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "New",
        "prop.Y29yZT1NYWluK3N0b25lK3NoYXBlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1DZW50ZXIrbWluK2xlbmd0aCtmaXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "ebay.ivy_and_rose.secondary_category_id": null,
        "prop.Y29yZT1NYXgrc2l6ZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "10",
        "prop.Y29yZT1GYW5jeStkaWFtb25kK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "etsy.ivy_and_rose.shop_section_id": "18036037",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY2FyYXQrd2VpZ2h0JmViYXk9JmV0c3k9JnNob3BpZnk9": "0.50ctw (7.80mm x 3.40mm) Avg.",
        "prop.Y29yZT1DdXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1NYXRlcmlhbCttdWx0aSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1DZW50ZXIrbWF4K3dpZHRoK2ZpdCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "ebay.ivy_and_rose.sub_title": null,
        "product.additional_styles": [],
        "product.price": "50",
        "prop.Y29yZT1NZXRhbCtwdXJpdHkmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "product.title": "LAB CREATED DIAMOND MATCHING PAIR 1/2 CARAT KITE SHAPE CUT LOOSE ACCENT STONES",
        "prop.Y29yZT1UcmVhdG1lbnQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1NYWluK3N0b25lJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1TdHlsZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1PY2Nhc2lvbiZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "shopify.ivy_and_rose.product_type": null,
        "ebay.ivy_and_rose.price": "50",
        "product.description": "Highlights:\n\r\n- ½ carat matched pair of kite shape lab diamonds\n\r\n- Bright white with E-F color\n\r\n- Very clean with VVS-VS clarity\n\r\n- The diamonds are lab grown. They offer all the beauty and durability of earth mined diamonds at an excellent value.\n\r\n\n\r\nAdditional Notes:\n\r\nThe diamonds are being sold loose, and would make perfect sides on a 3 stone ring. We are a full service jeweler, and would be happy to create a finished piece for you using these stones.",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Lab Grown Diamondx2",
        "etsy.ivy_and_rose.category_id": "6648",
        "shopify.ivy_and_rose.vendor": null,
        "product.cost": "10",
        "prop.Y29yZT1TaXphYmxlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1NaW4rc2l6ZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "4",
        "prop.Y29yZT1NYWluK3N0b25lK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1JdGVtK3R5cGUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Loose Diamond",
        "prop.Y29yZT1FcmEmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Modern",
        "product.internal_title": null,
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY29sb3ImZWJheT0mZXRzeT0mc2hvcGlmeT0": "E-F",
        "shopify.ilya_fine_jewelry.price": null,
        "prop.Y29yZT1Db2xsZWN0aW9uJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "product.notes": null,
        "shopify.ilya_fine_jewelry.title": null,
        "product.stock_number": null,
        "etsy.ivy_and_rose.when_made": "made_to_order",
        "prop.Y29yZT1DYW4rYmUrcGVyc29uYWxpemVkJmViYXk9JmV0c3k9JnNob3BpZnk9": "Yes",
        "product.location": "some",
        "shopify.ilya_fine_jewelry.seo_title": null,
        "prop.Y29yZT1OYXR1cmFsJTJGbGFiLWNyZWF0ZWQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Lab Grown",
        "ebay.ivy_and_rose.store_category2_id": "14736889",
        "shopify.ilya_fine_jewelry.vendor": null,
        "shopify.ivy_and_rose.seo_title": null,
        "prop.Y29yZT1HZW1zdG9uZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "Lab Grown Diamondx2",
        "prop.Y29yZT1TaWRlK3N0b25lJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "shopify.ilya_fine_jewelry.collection_ids": [],
        "shopify.ilya_fine_jewelry.tags": [],
        "prop.Y29yZT1TaWRlK3N0b25lK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "ebay.ivy_and_rose.category_id": "262026",
        "prop.Y29yZT1NYWRlK3RvK29yZGVyJmViYXk9JmV0c3k9JnNob3BpZnk9": "Yes",
        "product.availability": 4,
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrc2hhcGUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Kite",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY2xhcml0eSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "VVS-VS",
        "prop.Y29yZT1TaWRlK3N0b25lK2NsYXJpdHkmZWJheT0mZXRzeT0mc2hvcGlmeT0": null
      },
      "id": "55b58025-40e5-497e-85fd-e89155adb948",
      "sku": "LG-19522-KITE-.50"
    },
    {
      "data": {
        "prop.Y29yZT1Ub3RhbCtjYXJhdCt3ZWlnaHQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1SZWNpcGllbnQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Women",
        "shopify.ivy_and_rose.collection_ids": [],
        "shopify.ivy_and_rose.tags": [],
        "prop.Y29yZT1TZXR0aW5nK3N0eWxlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1EaWFtb25kK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "shopify.ilya_fine_jewelry.product_type": null,
        "prop.Y29yZT1DbGFyaXR5JmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1TaWRlK3N0b25lK3NoYXBlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "etsy.ivy_and_rose.who_made": "i_did",
        "prop.Y29yZT1TaXplJmViYXk9JmV0c3k9JnNob3BpZnk9": "5.0 (Resizable)",
        "prop.Y29yZT1DZW50ZXIrbWluK3dpZHRoK2ZpdCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "etsy.ivy_and_rose.price": "50",
        "prop.Y29yZT1DZXJ0aWZpY2F0aW9uJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "etsy.ivy_and_rose.title": "Solid 14K Two Tone Gold Wedding Band Stacking Ring White Yellow Classic Mens",
        "ebay.ivy_and_rose.store_category_id": "14843984",
        "product.main_style": null,
        "product.sku": "19123",
        "shopify.ivy_and_rose.title": null,
        "shopify.ivy_and_rose.price": null,
        "prop.Y29yZT1TaWRlK3N0b25lcytxdWFudGl0eSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "etsy.ivy_and_rose.is_supply": false,
        "prop.Y29yZT1HcmFtK3dlaWdodCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "5.2 grams",
        "prop.Y29yZT1DZW50ZXIrbWF4K2xlbmd0aCtmaXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "ebay.ivy_and_rose.condition": "NEW",
        "prop.Y29yZT1TZWNvbmRhcnkrc3RvbmUmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1TaWRlK3N0b25lK2NhcmF0K3dlaWdodCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1SaW5nK3NpemUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "5",
        "prop.Y29yZT1NZXRhbCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "14k Yellow &amp; White Gold",
        "prop.Y29yZT1UaGVtZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1BcHByb3hpbWF0ZSt0aW1lK3BlcmlvZCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "New",
        "prop.Y29yZT1NYWluK3N0b25lK3NoYXBlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1DZW50ZXIrbWluK2xlbmd0aCtmaXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "ebay.ivy_and_rose.secondary_category_id": "261994",
        "prop.Y29yZT1NYXgrc2l6ZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "10",
        "prop.Y29yZT1GYW5jeStkaWFtb25kK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "etsy.ivy_and_rose.shop_section_id": "17822028",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY2FyYXQrd2VpZ2h0JmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1DdXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1NYXRlcmlhbCttdWx0aSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "14k Yellow &amp; White Gold",
        "prop.Y29yZT1DZW50ZXIrbWF4K3dpZHRoK2ZpdCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "ebay.ivy_and_rose.sub_title": null,
        "product.additional_styles": [],
        "product.price": "50",
        "prop.Y29yZT1NZXRhbCtwdXJpdHkmZWJheT0mZXRzeT0mc2hvcGlmeT0": "14k",
        "product.title": "SOLID 14k TWO TONE GOLD WEDDING BAND STACKING RING WHITE YELLOW CLASSIC MENS",
        "prop.Y29yZT1UcmVhdG1lbnQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1NYWluK3N0b25lJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1TdHlsZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1PY2Nhc2lvbiZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "shopify.ivy_and_rose.product_type": null,
        "ebay.ivy_and_rose.price": "50",
        "product.description": "Why we love it: \r\n\r\nThis timeless band has a classic profile with a two tone gold detail for a unique touch!\r\n\r\n\r\n\r\nHighlights:\r\n\r\n- Sleek and classic comfort fit band\r\n\r\n- Very substantial at 5.2 grams\r\n\r\n- 14k yellow gold with white gold edges\r\n\r\n\r\n\r\nDimensions:\r\n\r\n3.7mm across (north-south) and 2.1mm in height.\r\n\r\n\r\n\r\nAdditional Notes:\r\n\r\nThe ring is currently a size 5. Due to the two tone design, special resizing rates apply.",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "etsy.ivy_and_rose.category_id": "1247",
        "shopify.ivy_and_rose.vendor": null,
        "product.cost": "10",
        "prop.Y29yZT1TaXphYmxlJmViYXk9JmV0c3k9JnNob3BpZnk9": "no",
        "prop.Y29yZT1NaW4rc2l6ZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "4",
        "prop.Y29yZT1NYWluK3N0b25lK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1JdGVtK3R5cGUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Band - Wedding",
        "prop.Y29yZT1FcmEmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Modern",
        "product.internal_title": "ring - wedding band - modern",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY29sb3ImZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "shopify.ilya_fine_jewelry.price": null,
        "prop.Y29yZT1Db2xsZWN0aW9uJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "product.notes": "3.7 x 2.1 (special sizing rates apply)",
        "shopify.ilya_fine_jewelry.title": null,
        "product.stock_number": null,
        "etsy.ivy_and_rose.when_made": "2010_2019",
        "prop.Y29yZT1DYW4rYmUrcGVyc29uYWxpemVkJmViYXk9JmV0c3k9JnNob3BpZnk9": "Yes",
        "product.location": "some",
        "shopify.ilya_fine_jewelry.seo_title": null,
        "prop.Y29yZT1OYXR1cmFsJTJGbGFiLWNyZWF0ZWQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "ebay.ivy_and_rose.store_category2_id": null,
        "shopify.ilya_fine_jewelry.vendor": null,
        "shopify.ivy_and_rose.seo_title": null,
        "prop.Y29yZT1HZW1zdG9uZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1TaWRlK3N0b25lJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "shopify.ilya_fine_jewelry.collection_ids": [],
        "shopify.ilya_fine_jewelry.tags": [],
        "prop.Y29yZT1TaWRlK3N0b25lK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "ebay.ivy_and_rose.category_id": "261977",
        "prop.Y29yZT1NYWRlK3RvK29yZGVyJmViYXk9JmV0c3k9JnNob3BpZnk9": "No",
        "product.availability": 1,
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrc2hhcGUmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY2xhcml0eSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1TaWRlK3N0b25lK2NsYXJpdHkmZWJheT0mZXRzeT0mc2hvcGlmeT0": null
      },
      "id": "dfd0db05-0974-45fd-8438-555f70249e25",
      "sku": "19123"
    },
    {
      "data": {
        "prop.Y29yZT1Ub3RhbCtjYXJhdCt3ZWlnaHQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1SZWNpcGllbnQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Women",
        "shopify.ivy_and_rose.collection_ids": [],
        "shopify.ivy_and_rose.tags": [],
        "prop.Y29yZT1TZXR0aW5nK3N0eWxlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1EaWFtb25kK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "shopify.ilya_fine_jewelry.product_type": null,
        "prop.Y29yZT1DbGFyaXR5JmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1TaWRlK3N0b25lK3NoYXBlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "etsy.ivy_and_rose.who_made": "i_did",
        "prop.Y29yZT1TaXplJmViYXk9JmV0c3k9JnNob3BpZnk9": "9.5 (Resizable)",
        "prop.Y29yZT1DZW50ZXIrbWluK3dpZHRoK2ZpdCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "etsy.ivy_and_rose.price": "50",
        "prop.Y29yZT1DZXJ0aWZpY2F0aW9uJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "etsy.ivy_and_rose.title": "Classic 14K Yellow Gold Wedding Band Ring Simple Stacking Thin Dainty Plain",
        "ebay.ivy_and_rose.store_category_id": "14843984",
        "product.main_style": null,
        "product.sku": "19122",
        "shopify.ivy_and_rose.title": null,
        "shopify.ivy_and_rose.price": null,
        "prop.Y29yZT1TaWRlK3N0b25lcytxdWFudGl0eSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "etsy.ivy_and_rose.is_supply": false,
        "prop.Y29yZT1HcmFtK3dlaWdodCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "1.4 grams",
        "prop.Y29yZT1DZW50ZXIrbWF4K2xlbmd0aCtmaXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "ebay.ivy_and_rose.condition": "NEW",
        "prop.Y29yZT1TZWNvbmRhcnkrc3RvbmUmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1TaWRlK3N0b25lK2NhcmF0K3dlaWdodCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1SaW5nK3NpemUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "9.5",
        "prop.Y29yZT1NZXRhbCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "14k Yellow Gold",
        "prop.Y29yZT1UaGVtZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1BcHByb3hpbWF0ZSt0aW1lK3BlcmlvZCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "New",
        "prop.Y29yZT1NYWluK3N0b25lK3NoYXBlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1DZW50ZXIrbWluK2xlbmd0aCtmaXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "ebay.ivy_and_rose.secondary_category_id": "261994",
        "prop.Y29yZT1NYXgrc2l6ZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "10",
        "prop.Y29yZT1GYW5jeStkaWFtb25kK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "etsy.ivy_and_rose.shop_section_id": "17822028",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY2FyYXQrd2VpZ2h0JmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1DdXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1NYXRlcmlhbCttdWx0aSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "14k Yellow Gold",
        "prop.Y29yZT1DZW50ZXIrbWF4K3dpZHRoK2ZpdCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "ebay.ivy_and_rose.sub_title": null,
        "product.additional_styles": [],
        "product.price": "50",
        "prop.Y29yZT1NZXRhbCtwdXJpdHkmZWJheT0mZXRzeT0mc2hvcGlmeT0": "14k",
        "product.title": "CLASSIC 14k YELLOW GOLD WEDDING BAND RING SIMPLE STACKING THIN DAINTY PLAIN",
        "prop.Y29yZT1UcmVhdG1lbnQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1NYWluK3N0b25lJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1TdHlsZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1PY2Nhc2lvbiZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "shopify.ivy_and_rose.product_type": null,
        "ebay.ivy_and_rose.price": "50",
        "product.description": "Why we love it: \n\r\nThis solid 14k yellow gold ring is high quality, and utterly classic! Its sleek and simple design has an air of grace, sophistication, and timeless elegance.\n\r\n\n\r\nDimensions:\n\r\nThe ring measures 2.5mm across (north south) and 0.8mm from finger to top.",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "etsy.ivy_and_rose.category_id": "1247",
        "shopify.ivy_and_rose.vendor": null,
        "product.cost": "10",
        "prop.Y29yZT1TaXphYmxlJmViYXk9JmV0c3k9JnNob3BpZnk9": "yes",
        "prop.Y29yZT1NaW4rc2l6ZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "4",
        "prop.Y29yZT1NYWluK3N0b25lK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1JdGVtK3R5cGUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Band - Wedding",
        "prop.Y29yZT1FcmEmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Modern",
        "product.internal_title": "ring - wedding band - modern",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY29sb3ImZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "shopify.ilya_fine_jewelry.price": null,
        "prop.Y29yZT1Db2xsZWN0aW9uJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "product.notes": "2.5 x 0.8",
        "shopify.ilya_fine_jewelry.title": null,
        "product.stock_number": null,
        "etsy.ivy_and_rose.when_made": "2010_2019",
        "prop.Y29yZT1DYW4rYmUrcGVyc29uYWxpemVkJmViYXk9JmV0c3k9JnNob3BpZnk9": "Yes",
        "product.location": "some",
        "shopify.ilya_fine_jewelry.seo_title": null,
        "prop.Y29yZT1OYXR1cmFsJTJGbGFiLWNyZWF0ZWQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "ebay.ivy_and_rose.store_category2_id": null,
        "shopify.ilya_fine_jewelry.vendor": null,
        "shopify.ivy_and_rose.seo_title": null,
        "prop.Y29yZT1HZW1zdG9uZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1TaWRlK3N0b25lJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "shopify.ilya_fine_jewelry.collection_ids": [],
        "shopify.ilya_fine_jewelry.tags": [],
        "prop.Y29yZT1TaWRlK3N0b25lK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "ebay.ivy_and_rose.category_id": "261977",
        "prop.Y29yZT1NYWRlK3RvK29yZGVyJmViYXk9JmV0c3k9JnNob3BpZnk9": "No",
        "product.availability": 1,
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrc2hhcGUmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY2xhcml0eSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1TaWRlK3N0b25lK2NsYXJpdHkmZWJheT0mZXRzeT0mc2hvcGlmeT0": null
      },
      "id": "c53e2be9-39cb-446b-90e1-ade668351a8d",
      "sku": "19122"
    },
    {
      "data": {
        "prop.Y29yZT1Ub3RhbCtjYXJhdCt3ZWlnaHQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "0.10ct",
        "prop.Y29yZT1SZWNpcGllbnQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Women",
        "shopify.ivy_and_rose.collection_ids": [],
        "shopify.ivy_and_rose.tags": [],
        "prop.Y29yZT1TZXR0aW5nK3N0eWxlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1EaWFtb25kK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "shopify.ilya_fine_jewelry.product_type": null,
        "prop.Y29yZT1DbGFyaXR5JmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1TaWRlK3N0b25lK3NoYXBlJmViYXk9JmV0c3k9JnNob3BpZnk9": "Vintage Single Cut",
        "etsy.ivy_and_rose.who_made": "someone_else",
        "prop.Y29yZT1TaXplJmViYXk9JmV0c3k9JnNob3BpZnk9": "5.25 (Resizable)",
        "prop.Y29yZT1DZW50ZXIrbWluK3dpZHRoK2ZpdCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "etsy.ivy_and_rose.price": "50",
        "prop.Y29yZT1DZXJ0aWZpY2F0aW9uJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "etsy.ivy_and_rose.title": "Vintage Diamond Wedding Ring Stacking Band 14K White Gold Art Deco Graduated",
        "ebay.ivy_and_rose.store_category_id": "14843984",
        "product.main_style": "Channel",
        "product.sku": "17955",
        "shopify.ivy_and_rose.title": null,
        "shopify.ivy_and_rose.price": null,
        "prop.Y29yZT1TaWRlK3N0b25lcytxdWFudGl0eSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "etsy.ivy_and_rose.is_supply": false,
        "prop.Y29yZT1HcmFtK3dlaWdodCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "1.8 grams",
        "prop.Y29yZT1DZW50ZXIrbWF4K2xlbmd0aCtmaXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "ebay.ivy_and_rose.condition": "USED_EXCELLENT",
        "prop.Y29yZT1TZWNvbmRhcnkrc3RvbmUmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1TaWRlK3N0b25lK2NhcmF0K3dlaWdodCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "0.10ctw",
        "prop.Y29yZT1SaW5nK3NpemUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "5.25",
        "prop.Y29yZT1NZXRhbCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "14k White Gold",
        "prop.Y29yZT1UaGVtZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1BcHByb3hpbWF0ZSt0aW1lK3BlcmlvZCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "Art Deco/Art Nouveau",
        "prop.Y29yZT1NYWluK3N0b25lK3NoYXBlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1DZW50ZXIrbWluK2xlbmd0aCtmaXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "ebay.ivy_and_rose.secondary_category_id": "262014",
        "prop.Y29yZT1NYXgrc2l6ZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "10",
        "prop.Y29yZT1GYW5jeStkaWFtb25kK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "etsy.ivy_and_rose.shop_section_id": "17822028",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY2FyYXQrd2VpZ2h0JmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1DdXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1NYXRlcmlhbCttdWx0aSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "14k White Gold",
        "prop.Y29yZT1DZW50ZXIrbWF4K3dpZHRoK2ZpdCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "ebay.ivy_and_rose.sub_title": null,
        "product.additional_styles": [],
        "product.price": "50",
        "prop.Y29yZT1NZXRhbCtwdXJpdHkmZWJheT0mZXRzeT0mc2hvcGlmeT0": "14k",
        "product.title": "VINTAGE DIAMOND WEDDING RING STACKING BAND 14k WHITE GOLD ART DECO GRADUATED",
        "prop.Y29yZT1UcmVhdG1lbnQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Not Treated, Not Enhanced",
        "prop.Y29yZT1NYWluK3N0b25lJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1TdHlsZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1PY2Nhc2lvbiZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "shopify.ivy_and_rose.product_type": null,
        "ebay.ivy_and_rose.price": "50",
        "product.description": "Why we love it: \n\r\nThis original vintage diamond and 14k white gold band was crafted in the Art Deco era and has a unique graduated design!\n\r\n\n\r\nHighlights:\n\r\n- Original vintage from the late Art Deco era\n\r\n- 0.10ct of nicely white and eye clean diamonds\n\r\n- Sleek 14k white gold graduated channel setting\n\r\n\n\r\nDimensions:\n\r\nThe ring measures 3.3mm across (north to south) and 3.3mm from finger to top.",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "etsy.ivy_and_rose.category_id": "1247",
        "shopify.ivy_and_rose.vendor": null,
        "product.cost": "10",
        "prop.Y29yZT1TaXphYmxlJmViYXk9JmV0c3k9JnNob3BpZnk9": "yes",
        "prop.Y29yZT1NaW4rc2l6ZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "4",
        "prop.Y29yZT1NYWluK3N0b25lK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1JdGVtK3R5cGUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Band - Wedding",
        "prop.Y29yZT1FcmEmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Original Vintage",
        "product.internal_title": "ring - wedding band - vintage",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY29sb3ImZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "shopify.ilya_fine_jewelry.price": null,
        "prop.Y29yZT1Db2xsZWN0aW9uJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "product.notes": "3.3 x 3.3 (really cool design!, org late deco)",
        "shopify.ilya_fine_jewelry.title": null,
        "product.stock_number": null,
        "etsy.ivy_and_rose.when_made": "1920s",
        "prop.Y29yZT1DYW4rYmUrcGVyc29uYWxpemVkJmViYXk9JmV0c3k9JnNob3BpZnk9": "Yes",
        "product.location": "some",
        "shopify.ilya_fine_jewelry.seo_title": null,
        "prop.Y29yZT1OYXR1cmFsJTJGbGFiLWNyZWF0ZWQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Natural",
        "ebay.ivy_and_rose.store_category2_id": "14736886",
        "shopify.ilya_fine_jewelry.vendor": null,
        "shopify.ivy_and_rose.seo_title": null,
        "prop.Y29yZT1HZW1zdG9uZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "Diamond",
        "prop.Y29yZT1TaWRlK3N0b25lJmViYXk9JmV0c3k9JnNob3BpZnk9": "Diamond",
        "shopify.ilya_fine_jewelry.collection_ids": [],
        "shopify.ilya_fine_jewelry.tags": [],
        "prop.Y29yZT1TaWRlK3N0b25lK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": "G-H",
        "ebay.ivy_and_rose.category_id": "261977",
        "prop.Y29yZT1NYWRlK3RvK29yZGVyJmViYXk9JmV0c3k9JnNob3BpZnk9": "No",
        "product.availability": 0,
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrc2hhcGUmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY2xhcml0eSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1TaWRlK3N0b25lK2NsYXJpdHkmZWJheT0mZXRzeT0mc2hvcGlmeT0": "VS"
      },
      "id": "8b660c0b-64f5-4fd8-91f5-1895325c723e",
      "sku": "17955"
    },
    {
      "data": {
        "prop.Y29yZT1Ub3RhbCtjYXJhdCt3ZWlnaHQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1SZWNpcGllbnQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Women",
        "shopify.ivy_and_rose.collection_ids": [],
        "shopify.ivy_and_rose.tags": [],
        "prop.Y29yZT1TZXR0aW5nK3N0eWxlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1EaWFtb25kK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "shopify.ilya_fine_jewelry.product_type": null,
        "prop.Y29yZT1DbGFyaXR5JmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1TaWRlK3N0b25lK3NoYXBlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "etsy.ivy_and_rose.who_made": "i_did",
        "prop.Y29yZT1TaXplJmViYXk9JmV0c3k9JnNob3BpZnk9": "10.75 (Resizable)",
        "prop.Y29yZT1DZW50ZXIrbWluK3dpZHRoK2ZpdCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "etsy.ivy_and_rose.price": "50",
        "prop.Y29yZT1DZXJ0aWZpY2F0aW9uJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "etsy.ivy_and_rose.title": "Solid 14K White Gold Mens Wedding Band Stacking Ring Wide Brush Finish 5.7Mm",
        "ebay.ivy_and_rose.store_category_id": "14843984",
        "product.main_style": null,
        "product.sku": "16682",
        "shopify.ivy_and_rose.title": null,
        "shopify.ivy_and_rose.price": null,
        "prop.Y29yZT1TaWRlK3N0b25lcytxdWFudGl0eSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "etsy.ivy_and_rose.is_supply": false,
        "prop.Y29yZT1HcmFtK3dlaWdodCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "7.2 grams",
        "prop.Y29yZT1DZW50ZXIrbWF4K2xlbmd0aCtmaXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "ebay.ivy_and_rose.condition": "NEW",
        "prop.Y29yZT1TZWNvbmRhcnkrc3RvbmUmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1TaWRlK3N0b25lK2NhcmF0K3dlaWdodCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1SaW5nK3NpemUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "10.75",
        "prop.Y29yZT1NZXRhbCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "14k White Gold",
        "prop.Y29yZT1UaGVtZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1BcHByb3hpbWF0ZSt0aW1lK3BlcmlvZCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "New",
        "prop.Y29yZT1NYWluK3N0b25lK3NoYXBlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1DZW50ZXIrbWluK2xlbmd0aCtmaXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "ebay.ivy_and_rose.secondary_category_id": "261994",
        "prop.Y29yZT1NYXgrc2l6ZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "10",
        "prop.Y29yZT1GYW5jeStkaWFtb25kK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "etsy.ivy_and_rose.shop_section_id": "17822028",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY2FyYXQrd2VpZ2h0JmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1DdXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1NYXRlcmlhbCttdWx0aSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "14k White Gold",
        "prop.Y29yZT1DZW50ZXIrbWF4K3dpZHRoK2ZpdCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "ebay.ivy_and_rose.sub_title": null,
        "product.additional_styles": [],
        "product.price": "50",
        "prop.Y29yZT1NZXRhbCtwdXJpdHkmZWJheT0mZXRzeT0mc2hvcGlmeT0": "14k",
        "product.title": "SOLID 14k WHITE GOLD MENS WEDDING BAND STACKING RING WIDE BRUSH FINISH 5.7mm",
        "prop.Y29yZT1UcmVhdG1lbnQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1NYWluK3N0b25lJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1TdHlsZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1PY2Nhc2lvbiZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "shopify.ivy_and_rose.product_type": null,
        "ebay.ivy_and_rose.price": "50",
        "product.description": "Why we love it: \r\n\r\nThis timeless 14k white gold band has a classic profile with a brush finish detail for a unique touch!\r\n\r\n\r\n\r\nHighlights:\r\n\r\n- Solid 14k white gold\r\n\r\n- Sleek and classic stepped band\r\n\r\n- Very substantial at 7.2 grams\r\n\r\n\r\n\r\nDimensions:\r\n\r\n5.7mm across (north-south) and 1.7mm in height.\r\n\r\n\r\n\r\nAdditional Notes:\r\n\r\nThe ring is currently a size 10.75. Due to the two tone design, special resizing rates apply.",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "etsy.ivy_and_rose.category_id": "1247",
        "shopify.ivy_and_rose.vendor": null,
        "product.cost": "10",
        "prop.Y29yZT1TaXphYmxlJmViYXk9JmV0c3k9JnNob3BpZnk9": "no",
        "prop.Y29yZT1NaW4rc2l6ZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "4",
        "prop.Y29yZT1NYWluK3N0b25lK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1JdGVtK3R5cGUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Band - Wedding",
        "prop.Y29yZT1FcmEmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Modern",
        "product.internal_title": "ring - wedding band - modern",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY29sb3ImZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "shopify.ilya_fine_jewelry.price": null,
        "prop.Y29yZT1Db2xsZWN0aW9uJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "product.notes": "5.7 x 1.7 (Special resizing notes)",
        "shopify.ilya_fine_jewelry.title": null,
        "product.stock_number": null,
        "etsy.ivy_and_rose.when_made": "2010_2019",
        "prop.Y29yZT1DYW4rYmUrcGVyc29uYWxpemVkJmViYXk9JmV0c3k9JnNob3BpZnk9": "Yes",
        "product.location": "some",
        "shopify.ilya_fine_jewelry.seo_title": null,
        "prop.Y29yZT1OYXR1cmFsJTJGbGFiLWNyZWF0ZWQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "ebay.ivy_and_rose.store_category2_id": null,
        "shopify.ilya_fine_jewelry.vendor": null,
        "shopify.ivy_and_rose.seo_title": null,
        "prop.Y29yZT1HZW1zdG9uZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1TaWRlK3N0b25lJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "shopify.ilya_fine_jewelry.collection_ids": [],
        "shopify.ilya_fine_jewelry.tags": [],
        "prop.Y29yZT1TaWRlK3N0b25lK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "ebay.ivy_and_rose.category_id": "261977",
        "prop.Y29yZT1NYWRlK3RvK29yZGVyJmViYXk9JmV0c3k9JnNob3BpZnk9": "No",
        "product.availability": 1,
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrc2hhcGUmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY2xhcml0eSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1TaWRlK3N0b25lK2NsYXJpdHkmZWJheT0mZXRzeT0mc2hvcGlmeT0": null
      },
      "id": "677f812b-de21-4e44-a521-a30d0f7c3eb9",
      "sku": "16682"
    },
    {
      "data": {
        "prop.Y29yZT1Ub3RhbCtjYXJhdCt3ZWlnaHQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "0.35ctw",
        "prop.Y29yZT1SZWNpcGllbnQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Women",
        "shopify.ivy_and_rose.collection_ids": [],
        "shopify.ivy_and_rose.tags": [],
        "prop.Y29yZT1TZXR0aW5nK3N0eWxlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1EaWFtb25kK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "shopify.ilya_fine_jewelry.product_type": null,
        "prop.Y29yZT1DbGFyaXR5JmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1TaWRlK3N0b25lK3NoYXBlJmViYXk9JmV0c3k9JnNob3BpZnk9": "Vintage Single Cut",
        "etsy.ivy_and_rose.who_made": "someone_else",
        "prop.Y29yZT1TaXplJmViYXk9JmV0c3k9JnNob3BpZnk9": "5.0 (Resizable)",
        "prop.Y29yZT1DZW50ZXIrbWluK3dpZHRoK2ZpdCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "etsy.ivy_and_rose.price": "50",
        "prop.Y29yZT1DZXJ0aWZpY2F0aW9uJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "etsy.ivy_and_rose.title": "Vintage Diamond Platinum Wedding Band Stacking Ring Art Deco Channel Setting",
        "ebay.ivy_and_rose.store_category_id": "14843984",
        "product.main_style": "Channel",
        "product.sku": "16463",
        "shopify.ivy_and_rose.title": null,
        "shopify.ivy_and_rose.price": null,
        "prop.Y29yZT1TaWRlK3N0b25lcytxdWFudGl0eSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "etsy.ivy_and_rose.is_supply": false,
        "prop.Y29yZT1HcmFtK3dlaWdodCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "2.5 grams",
        "prop.Y29yZT1DZW50ZXIrbWF4K2xlbmd0aCtmaXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "ebay.ivy_and_rose.condition": "USED_EXCELLENT",
        "prop.Y29yZT1TZWNvbmRhcnkrc3RvbmUmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1TaWRlK3N0b25lK2NhcmF0K3dlaWdodCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "0.35ct",
        "prop.Y29yZT1SaW5nK3NpemUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "5",
        "prop.Y29yZT1NZXRhbCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "Platinum",
        "prop.Y29yZT1UaGVtZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1BcHByb3hpbWF0ZSt0aW1lK3BlcmlvZCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "Art Deco/Art Nouveau",
        "prop.Y29yZT1NYWluK3N0b25lK3NoYXBlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1DZW50ZXIrbWluK2xlbmd0aCtmaXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "ebay.ivy_and_rose.secondary_category_id": "262014",
        "prop.Y29yZT1NYXgrc2l6ZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "10",
        "prop.Y29yZT1GYW5jeStkaWFtb25kK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "etsy.ivy_and_rose.shop_section_id": "17822028",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY2FyYXQrd2VpZ2h0JmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1DdXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1NYXRlcmlhbCttdWx0aSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "Platinum",
        "prop.Y29yZT1DZW50ZXIrbWF4K3dpZHRoK2ZpdCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "ebay.ivy_and_rose.sub_title": null,
        "product.additional_styles": [],
        "product.price": "50",
        "prop.Y29yZT1NZXRhbCtwdXJpdHkmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Plat",
        "product.title": "VINTAGE DIAMOND PLATINUM WEDDING BAND STACKING RING ART DECO CHANNEL SETTING",
        "prop.Y29yZT1UcmVhdG1lbnQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Not Treated, Not Enhanced",
        "prop.Y29yZT1NYWluK3N0b25lJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1TdHlsZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1PY2Nhc2lvbiZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "shopify.ivy_and_rose.product_type": null,
        "ebay.ivy_and_rose.price": "50",
        "product.description": "Why we love it: \n\r\nThis original vintage diamond and platinum band has a sleek and timeless design that will remain forever in style! The inside of the shank is engraved with the original wedding date! \n\r\n\n\r\nHighlights:\n\r\n- Original vintage from the Art Deco era\n\r\n- Bright white and completely eye clean F-H VS1-SI1 diamonds\n\r\n- Sleek platinum channel setting\n\r\n\n\r\nDimensions:\n\r\nThe ring measures 2.1mm across (north to south) and 1.7mm from finger to top.",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "etsy.ivy_and_rose.category_id": "1247",
        "shopify.ivy_and_rose.vendor": null,
        "product.cost": "10",
        "prop.Y29yZT1TaXphYmxlJmViYXk9JmV0c3k9JnNob3BpZnk9": "yes",
        "prop.Y29yZT1NaW4rc2l6ZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "4",
        "prop.Y29yZT1NYWluK3N0b25lK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1JdGVtK3R5cGUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Band - Wedding",
        "prop.Y29yZT1FcmEmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Original Vintage",
        "product.internal_title": "ring - wedding band - vintage",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY29sb3ImZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "shopify.ilya_fine_jewelry.price": null,
        "prop.Y29yZT1Db2xsZWN0aW9uJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "product.notes": "2.1 x 1.7 (original art deco, hand engraving inside shank)",
        "shopify.ilya_fine_jewelry.title": null,
        "product.stock_number": null,
        "etsy.ivy_and_rose.when_made": "1920s",
        "prop.Y29yZT1DYW4rYmUrcGVyc29uYWxpemVkJmViYXk9JmV0c3k9JnNob3BpZnk9": "Yes",
        "product.location": "some",
        "shopify.ilya_fine_jewelry.seo_title": null,
        "prop.Y29yZT1OYXR1cmFsJTJGbGFiLWNyZWF0ZWQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Natural",
        "ebay.ivy_and_rose.store_category2_id": "14736886",
        "shopify.ilya_fine_jewelry.vendor": null,
        "shopify.ivy_and_rose.seo_title": null,
        "prop.Y29yZT1HZW1zdG9uZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "Diamond",
        "prop.Y29yZT1TaWRlK3N0b25lJmViYXk9JmV0c3k9JnNob3BpZnk9": "Diamond",
        "shopify.ilya_fine_jewelry.collection_ids": [],
        "shopify.ilya_fine_jewelry.tags": [],
        "prop.Y29yZT1TaWRlK3N0b25lK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": "F-H",
        "ebay.ivy_and_rose.category_id": "261977",
        "prop.Y29yZT1NYWRlK3RvK29yZGVyJmViYXk9JmV0c3k9JnNob3BpZnk9": "No",
        "product.availability": 0,
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrc2hhcGUmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY2xhcml0eSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1TaWRlK3N0b25lK2NsYXJpdHkmZWJheT0mZXRzeT0mc2hvcGlmeT0": "VS1-SI1"
      },
      "id": "221bc65c-a20e-465d-9228-685cda151e4b",
      "sku": "16463"
    },
    {
      "data": {
        "prop.Y29yZT1Ub3RhbCtjYXJhdCt3ZWlnaHQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "3.76ct",
        "prop.Y29yZT1SZWNpcGllbnQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Women",
        "shopify.ivy_and_rose.collection_ids": [],
        "shopify.ivy_and_rose.tags": [],
        "prop.Y29yZT1TZXR0aW5nK3N0eWxlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1EaWFtb25kK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "shopify.ilya_fine_jewelry.product_type": null,
        "prop.Y29yZT1DbGFyaXR5JmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1TaWRlK3N0b25lK3NoYXBlJmViYXk9JmV0c3k9JnNob3BpZnk9": "Round",
        "etsy.ivy_and_rose.who_made": "i_did",
        "prop.Y29yZT1TaXplJmViYXk9JmV0c3k9JnNob3BpZnk9": "5.75 (Resizable)",
        "prop.Y29yZT1DZW50ZXIrbWluK3dpZHRoK2ZpdCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "etsy.ivy_and_rose.price": "50",
        "prop.Y29yZT1DZXJ0aWZpY2F0aW9uJmViYXk9JmV0c3k9JnNob3BpZnk9": "GIA",
        "etsy.ivy_and_rose.title": "3.37Ct Gia Certified Sapphire Diamond Engagment Ring Platinum Oval Shape Natural",
        "ebay.ivy_and_rose.store_category_id": "6106058",
        "product.main_style": "Accent Stones",
        "product.sku": "15856",
        "shopify.ivy_and_rose.title": null,
        "shopify.ivy_and_rose.price": null,
        "prop.Y29yZT1TaWRlK3N0b25lcytxdWFudGl0eSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "6",
        "etsy.ivy_and_rose.is_supply": false,
        "prop.Y29yZT1HcmFtK3dlaWdodCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "3.7 grams",
        "prop.Y29yZT1DZW50ZXIrbWF4K2xlbmd0aCtmaXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "ebay.ivy_and_rose.condition": "NEW",
        "prop.Y29yZT1TZWNvbmRhcnkrc3RvbmUmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1TaWRlK3N0b25lK2NhcmF0K3dlaWdodCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "0.39ctw",
        "prop.Y29yZT1SaW5nK3NpemUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "5.75",
        "prop.Y29yZT1NZXRhbCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "Platinum",
        "prop.Y29yZT1UaGVtZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1BcHByb3hpbWF0ZSt0aW1lK3BlcmlvZCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "New",
        "prop.Y29yZT1NYWluK3N0b25lK3NoYXBlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1DZW50ZXIrbWluK2xlbmd0aCtmaXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "ebay.ivy_and_rose.secondary_category_id": null,
        "prop.Y29yZT1NYXgrc2l6ZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "10",
        "prop.Y29yZT1GYW5jeStkaWFtb25kK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "etsy.ivy_and_rose.shop_section_id": "17500487",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY2FyYXQrd2VpZ2h0JmViYXk9JmV0c3k9JnNob3BpZnk9": "3.37ct",
        "prop.Y29yZT1DdXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1NYXRlcmlhbCttdWx0aSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "Platinum",
        "prop.Y29yZT1DZW50ZXIrbWF4K3dpZHRoK2ZpdCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "ebay.ivy_and_rose.sub_title": null,
        "product.additional_styles": [
          "Prong"
        ],
        "product.price": "50",
        "prop.Y29yZT1NZXRhbCtwdXJpdHkmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Plat",
        "product.title": "3.37ct GIA CERTIFIED SAPPHIRE DIAMOND ENGAGMENT RING PLATINUM OVAL SHAPE NATURAL",
        "prop.Y29yZT1UcmVhdG1lbnQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1NYWluK3N0b25lJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1TdHlsZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1PY2Nhc2lvbiZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "shopify.ivy_and_rose.product_type": null,
        "ebay.ivy_and_rose.price": "50",
        "product.description": "Why we love it: \n\r\nThis high quality sapphire and diamond ring features a rich blue sapphire flanked by clusters of bezel set diamonds for an elegant finish!\n\r\n\n\r\nHighlights:\n\r\n- GIA certified\n\r\n- 3.37ct oval cut natural sapphire center with a gorgeous rich blue color\n\r\n- 0.39ct of high quality F VS diamond accents\n\r\n- High quality platinum setting\n\r\n- Overall, this is a fantastic quality piece, worthy of an engagement ring!\n\r\n\n\r\nDimensions:\n\r\n9.8mm across (north-south) and 6.8mm from the finger to the top of the ring.",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Sapphire (GIA Certified)",
        "etsy.ivy_and_rose.category_id": "1240",
        "shopify.ivy_and_rose.vendor": null,
        "product.cost": "10",
        "prop.Y29yZT1TaXphYmxlJmViYXk9JmV0c3k9JnNob3BpZnk9": "yes",
        "prop.Y29yZT1NaW4rc2l6ZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "4",
        "prop.Y29yZT1NYWluK3N0b25lK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1JdGVtK3R5cGUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Cocktail Ring- Statement",
        "prop.Y29yZT1FcmEmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Modern",
        "product.internal_title": "ring - cocktail - modern",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY29sb3ImZWJheT0mZXRzeT0mc2hvcGlmeT0": "Blue",
        "shopify.ilya_fine_jewelry.price": null,
        "prop.Y29yZT1Db2xsZWN0aW9uJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "product.notes": null,
        "shopify.ilya_fine_jewelry.title": null,
        "product.stock_number": null,
        "etsy.ivy_and_rose.when_made": "2010_2019",
        "prop.Y29yZT1DYW4rYmUrcGVyc29uYWxpemVkJmViYXk9JmV0c3k9JnNob3BpZnk9": "Yes",
        "product.location": "some",
        "shopify.ilya_fine_jewelry.seo_title": null,
        "prop.Y29yZT1OYXR1cmFsJTJGbGFiLWNyZWF0ZWQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Natural",
        "ebay.ivy_and_rose.store_category2_id": null,
        "shopify.ilya_fine_jewelry.vendor": null,
        "shopify.ivy_and_rose.seo_title": null,
        "prop.Y29yZT1HZW1zdG9uZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "Sapphire (GIA Certified)",
        "prop.Y29yZT1TaWRlK3N0b25lJmViYXk9JmV0c3k9JnNob3BpZnk9": "Diamond",
        "shopify.ilya_fine_jewelry.collection_ids": [],
        "shopify.ilya_fine_jewelry.tags": [],
        "prop.Y29yZT1TaWRlK3N0b25lK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": "F",
        "ebay.ivy_and_rose.category_id": "261994",
        "prop.Y29yZT1NYWRlK3RvK29yZGVyJmViYXk9JmV0c3k9JnNob3BpZnk9": "No",
        "product.availability": 0,
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrc2hhcGUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Oval",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY2xhcml0eSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1TaWRlK3N0b25lK2NsYXJpdHkmZWJheT0mZXRzeT0mc2hvcGlmeT0": "VS"
      },
      "id": "005f70ab-c6de-451e-98b7-2077ace3ce01",
      "sku": "15856"
    },
    {
      "data": {
        "prop.Y29yZT1Ub3RhbCtjYXJhdCt3ZWlnaHQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "1.01ct",
        "prop.Y29yZT1SZWNpcGllbnQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Women",
        "shopify.ivy_and_rose.collection_ids": [],
        "shopify.ivy_and_rose.tags": [],
        "prop.Y29yZT1TZXR0aW5nK3N0eWxlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1EaWFtb25kK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "shopify.ilya_fine_jewelry.product_type": null,
        "prop.Y29yZT1DbGFyaXR5JmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1TaWRlK3N0b25lK3NoYXBlJmViYXk9JmV0c3k9JnNob3BpZnk9": "Vintage Single Cut",
        "etsy.ivy_and_rose.who_made": "someone_else",
        "prop.Y29yZT1TaXplJmViYXk9JmV0c3k9JnNob3BpZnk9": "9.0 (Resizable)",
        "prop.Y29yZT1DZW50ZXIrbWluK3dpZHRoK2ZpdCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "etsy.ivy_and_rose.price": "50",
        "prop.Y29yZT1DZXJ0aWZpY2F0aW9uJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "etsy.ivy_and_rose.title": "Vintage Diamond Engagement Ring 0.89Ct G Vs1 Old European Cut Platinum Filigree",
        "ebay.ivy_and_rose.store_category_id": "14736792",
        "product.main_style": "Accent Stones",
        "product.sku": "19866",
        "shopify.ivy_and_rose.title": null,
        "shopify.ivy_and_rose.price": null,
        "prop.Y29yZT1TaWRlK3N0b25lcytxdWFudGl0eSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "etsy.ivy_and_rose.is_supply": false,
        "prop.Y29yZT1HcmFtK3dlaWdodCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "2.6 grams",
        "prop.Y29yZT1DZW50ZXIrbWF4K2xlbmd0aCtmaXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "ebay.ivy_and_rose.condition": "USED_EXCELLENT",
        "prop.Y29yZT1TZWNvbmRhcnkrc3RvbmUmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1TaWRlK3N0b25lK2NhcmF0K3dlaWdodCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "0.12ctw",
        "prop.Y29yZT1SaW5nK3NpemUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "9",
        "prop.Y29yZT1NZXRhbCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "Platinum",
        "prop.Y29yZT1UaGVtZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1BcHByb3hpbWF0ZSt0aW1lK3BlcmlvZCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "Art Deco/Art Nouveau",
        "prop.Y29yZT1NYWluK3N0b25lK3NoYXBlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1DZW50ZXIrbWluK2xlbmd0aCtmaXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "ebay.ivy_and_rose.secondary_category_id": "262014",
        "prop.Y29yZT1NYXgrc2l6ZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "10",
        "prop.Y29yZT1GYW5jeStkaWFtb25kK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "etsy.ivy_and_rose.shop_section_id": "17500487",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY2FyYXQrd2VpZ2h0JmViYXk9JmV0c3k9JnNob3BpZnk9": "0.89ct",
        "prop.Y29yZT1DdXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1NYXRlcmlhbCttdWx0aSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "Platinum",
        "prop.Y29yZT1DZW50ZXIrbWF4K3dpZHRoK2ZpdCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "ebay.ivy_and_rose.sub_title": null,
        "product.additional_styles": [],
        "product.price": "50",
        "prop.Y29yZT1NZXRhbCtwdXJpdHkmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Plat",
        "product.title": "VINTAGE DIAMOND ENGAGEMENT RING 0.89ct G VS1 OLD EUROPEAN CUT PLATINUM FILIGREE",
        "prop.Y29yZT1UcmVhdG1lbnQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Not Treated, Not Enhanced",
        "prop.Y29yZT1NYWluK3N0b25lJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1TdHlsZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1PY2Nhc2lvbiZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "shopify.ivy_and_rose.product_type": null,
        "ebay.ivy_and_rose.price": "50",
        "product.description": "Why we love it: \n\r\nThis charming vintage diamond ring features a glittering old European cut diamond paired with a romantic filigree setting with diamond accented shoulders! \n\r\n\n\r\nHighlights:\n\r\n- Original vintage from the Art Deco era\n\r\n- 0.89ct old European cut diamond\n\r\n- High quality, bright white, and exceptionally clean with grades of F-G VS\n\r\n- High quality platinum setting with fine filigree details\n\r\n\n\r\nDimensions:\n\r\n6.4mm across (north-south) and 6.5mm from the finger to the top of the setting.",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Diamond",
        "etsy.ivy_and_rose.category_id": "1245",
        "shopify.ivy_and_rose.vendor": null,
        "product.cost": "10",
        "prop.Y29yZT1TaXphYmxlJmViYXk9JmV0c3k9JnNob3BpZnk9": "yes",
        "prop.Y29yZT1NaW4rc2l6ZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "4",
        "prop.Y29yZT1NYWluK3N0b25lK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1JdGVtK3R5cGUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Engagement Ring",
        "prop.Y29yZT1FcmEmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Original Vintage",
        "product.internal_title": "ring - engagement - vintage",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY29sb3ImZWJheT0mZXRzeT0mc2hvcGlmeT0": "G",
        "shopify.ilya_fine_jewelry.price": null,
        "prop.Y29yZT1Db2xsZWN0aW9uJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "product.notes": "6.4 x 6.5 (original antique ring)",
        "shopify.ilya_fine_jewelry.title": null,
        "product.stock_number": null,
        "etsy.ivy_and_rose.when_made": "1920s",
        "prop.Y29yZT1DYW4rYmUrcGVyc29uYWxpemVkJmViYXk9JmV0c3k9JnNob3BpZnk9": "Yes",
        "product.location": "some",
        "shopify.ilya_fine_jewelry.seo_title": null,
        "prop.Y29yZT1OYXR1cmFsJTJGbGFiLWNyZWF0ZWQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Natural",
        "ebay.ivy_and_rose.store_category2_id": "14736886",
        "shopify.ilya_fine_jewelry.vendor": null,
        "shopify.ivy_and_rose.seo_title": null,
        "prop.Y29yZT1HZW1zdG9uZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "Diamond",
        "prop.Y29yZT1TaWRlK3N0b25lJmViYXk9JmV0c3k9JnNob3BpZnk9": "Diamond",
        "shopify.ilya_fine_jewelry.collection_ids": [],
        "shopify.ilya_fine_jewelry.tags": [],
        "prop.Y29yZT1TaWRlK3N0b25lK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": "F-G",
        "ebay.ivy_and_rose.category_id": "261975",
        "prop.Y29yZT1NYWRlK3RvK29yZGVyJmViYXk9JmV0c3k9JnNob3BpZnk9": "No",
        "product.availability": 0,
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrc2hhcGUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Old European Cut",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY2xhcml0eSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "VS1",
        "prop.Y29yZT1TaWRlK3N0b25lK2NsYXJpdHkmZWJheT0mZXRzeT0mc2hvcGlmeT0": "VS"
      },
      "id": "2f3f5a82-a547-4986-bb79-0a27814a579f",
      "sku": "19866"
    },
    {
      "data": {
        "prop.Y29yZT1Ub3RhbCtjYXJhdCt3ZWlnaHQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "0.17ct",
        "prop.Y29yZT1SZWNpcGllbnQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Women",
        "shopify.ivy_and_rose.collection_ids": [],
        "shopify.ivy_and_rose.tags": [],
        "prop.Y29yZT1TZXR0aW5nK3N0eWxlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1EaWFtb25kK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "shopify.ilya_fine_jewelry.product_type": null,
        "prop.Y29yZT1DbGFyaXR5JmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1TaWRlK3N0b25lK3NoYXBlJmViYXk9JmV0c3k9JnNob3BpZnk9": "Vintage Single Cut",
        "etsy.ivy_and_rose.who_made": "someone_else",
        "prop.Y29yZT1TaXplJmViYXk9JmV0c3k9JnNob3BpZnk9": "4.5 (Resizable)",
        "prop.Y29yZT1DZW50ZXIrbWluK3dpZHRoK2ZpdCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "etsy.ivy_and_rose.price": "50",
        "prop.Y29yZT1DZXJ0aWZpY2F0aW9uJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "etsy.ivy_and_rose.title": "Vintage Diamond Engagement Ring Transitional Cut 14K White Gold Illusion Setting",
        "ebay.ivy_and_rose.store_category_id": "14736792",
        "product.main_style": "Accent Stones",
        "product.sku": "19213",
        "shopify.ivy_and_rose.title": null,
        "shopify.ivy_and_rose.price": null,
        "prop.Y29yZT1TaWRlK3N0b25lcytxdWFudGl0eSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "etsy.ivy_and_rose.is_supply": false,
        "prop.Y29yZT1HcmFtK3dlaWdodCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "2.2 grams",
        "prop.Y29yZT1DZW50ZXIrbWF4K2xlbmd0aCtmaXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "ebay.ivy_and_rose.condition": "USED_EXCELLENT",
        "prop.Y29yZT1TZWNvbmRhcnkrc3RvbmUmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1TaWRlK3N0b25lK2NhcmF0K3dlaWdodCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "0.02ct",
        "prop.Y29yZT1SaW5nK3NpemUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "4.5",
        "prop.Y29yZT1NZXRhbCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "14k White Gold",
        "prop.Y29yZT1UaGVtZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1BcHByb3hpbWF0ZSt0aW1lK3BlcmlvZCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "Retro",
        "prop.Y29yZT1NYWluK3N0b25lK3NoYXBlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1DZW50ZXIrbWluK2xlbmd0aCtmaXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "ebay.ivy_and_rose.secondary_category_id": "262014",
        "prop.Y29yZT1NYXgrc2l6ZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "10",
        "prop.Y29yZT1GYW5jeStkaWFtb25kK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "etsy.ivy_and_rose.shop_section_id": "17500487",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY2FyYXQrd2VpZ2h0JmViYXk9JmV0c3k9JnNob3BpZnk9": "0.15ct",
        "prop.Y29yZT1DdXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1NYXRlcmlhbCttdWx0aSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "14k White Gold",
        "prop.Y29yZT1DZW50ZXIrbWF4K3dpZHRoK2ZpdCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "ebay.ivy_and_rose.sub_title": null,
        "product.additional_styles": [],
        "product.price": "50",
        "prop.Y29yZT1NZXRhbCtwdXJpdHkmZWJheT0mZXRzeT0mc2hvcGlmeT0": "14k",
        "product.title": "VINTAGE DIAMOND ENGAGEMENT RING TRANSITIONAL CUT 14k WHITE GOLD ILLUSION SETTING",
        "prop.Y29yZT1UcmVhdG1lbnQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Not Treated, Not Enhanced",
        "prop.Y29yZT1NYWluK3N0b25lJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1TdHlsZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1PY2Nhc2lvbiZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "shopify.ivy_and_rose.product_type": null,
        "ebay.ivy_and_rose.price": "50",
        "product.description": "Why we love it: \n\r\nThis vintage engagement ring showcases a high quality center diamond in a classic setting with vintage details that add charm without clutter. The illusion setting makes the center stone appear larger, and the shoulders glitter with vibrant accent diamonds.\n\r\n\n\r\nHighlights:\n\r\n- 0.15ct center diamond with bright white G-H color and fantastic VS2 clarity\n\r\n- Bright white and exceptionally clean side diamonds\n\r\n- Classic 14k white gold setting\n\r\n\n\r\nDimensions:\n\r\n5.9mm across (north-south) and 6.3mm from the finger to the top of the setting.",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Diamond",
        "etsy.ivy_and_rose.category_id": "1245",
        "shopify.ivy_and_rose.vendor": null,
        "product.cost": "10",
        "prop.Y29yZT1TaXphYmxlJmViYXk9JmV0c3k9JnNob3BpZnk9": "yes",
        "prop.Y29yZT1NaW4rc2l6ZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "4",
        "prop.Y29yZT1NYWluK3N0b25lK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1JdGVtK3R5cGUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Engagement Ring",
        "prop.Y29yZT1FcmEmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Original Vintage",
        "product.internal_title": "ring - engagement - vintage",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY29sb3ImZWJheT0mZXRzeT0mc2hvcGlmeT0": "G-H",
        "shopify.ilya_fine_jewelry.price": null,
        "prop.Y29yZT1Db2xsZWN0aW9uJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "product.notes": "5.9 x 6.3",
        "shopify.ilya_fine_jewelry.title": null,
        "product.stock_number": null,
        "etsy.ivy_and_rose.when_made": "1950s",
        "prop.Y29yZT1DYW4rYmUrcGVyc29uYWxpemVkJmViYXk9JmV0c3k9JnNob3BpZnk9": "Yes",
        "product.location": "some",
        "shopify.ilya_fine_jewelry.seo_title": null,
        "prop.Y29yZT1OYXR1cmFsJTJGbGFiLWNyZWF0ZWQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Natural",
        "ebay.ivy_and_rose.store_category2_id": "14736886",
        "shopify.ilya_fine_jewelry.vendor": null,
        "shopify.ivy_and_rose.seo_title": null,
        "prop.Y29yZT1HZW1zdG9uZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "Diamond",
        "prop.Y29yZT1TaWRlK3N0b25lJmViYXk9JmV0c3k9JnNob3BpZnk9": "Diamond",
        "shopify.ilya_fine_jewelry.collection_ids": [],
        "shopify.ilya_fine_jewelry.tags": [],
        "prop.Y29yZT1TaWRlK3N0b25lK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": "G-H",
        "ebay.ivy_and_rose.category_id": "261975",
        "prop.Y29yZT1NYWRlK3RvK29yZGVyJmViYXk9JmV0c3k9JnNob3BpZnk9": "No",
        "product.availability": 1,
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrc2hhcGUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Old Transitional Cut",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY2xhcml0eSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "VS2",
        "prop.Y29yZT1TaWRlK3N0b25lK2NsYXJpdHkmZWJheT0mZXRzeT0mc2hvcGlmeT0": "VS"
      },
      "id": "5119b711-2e84-419d-a8a0-44459b7ed832",
      "sku": "19213"
    },
    {
      "data": {
        "prop.Y29yZT1Ub3RhbCtjYXJhdCt3ZWlnaHQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "0.20ct",
        "prop.Y29yZT1SZWNpcGllbnQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Women",
        "shopify.ivy_and_rose.collection_ids": [],
        "shopify.ivy_and_rose.tags": [],
        "prop.Y29yZT1TZXR0aW5nK3N0eWxlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1EaWFtb25kK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "shopify.ilya_fine_jewelry.product_type": null,
        "prop.Y29yZT1DbGFyaXR5JmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1TaWRlK3N0b25lK3NoYXBlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "etsy.ivy_and_rose.who_made": "someone_else",
        "prop.Y29yZT1TaXplJmViYXk9JmV0c3k9JnNob3BpZnk9": "8.0 (Resizable)",
        "prop.Y29yZT1DZW50ZXIrbWluK3dpZHRoK2ZpdCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "etsy.ivy_and_rose.price": "50",
        "prop.Y29yZT1DZXJ0aWZpY2F0aW9uJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "etsy.ivy_and_rose.title": "Gold Nugget Diamond Ring 1970S Vintage 10.6Gm 14K Yellow Chunky Estate Mens",
        "ebay.ivy_and_rose.store_category_id": "14736886",
        "product.main_style": "Cocktail",
        "product.sku": "19207",
        "shopify.ivy_and_rose.title": null,
        "shopify.ivy_and_rose.price": null,
        "prop.Y29yZT1TaWRlK3N0b25lcytxdWFudGl0eSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "etsy.ivy_and_rose.is_supply": false,
        "prop.Y29yZT1HcmFtK3dlaWdodCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "10.6 grams",
        "prop.Y29yZT1DZW50ZXIrbWF4K2xlbmd0aCtmaXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "ebay.ivy_and_rose.condition": "USED_EXCELLENT",
        "prop.Y29yZT1TZWNvbmRhcnkrc3RvbmUmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1TaWRlK3N0b25lK2NhcmF0K3dlaWdodCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1SaW5nK3NpemUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "8",
        "prop.Y29yZT1NZXRhbCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "14k Yellow Gold",
        "prop.Y29yZT1UaGVtZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1BcHByb3hpbWF0ZSt0aW1lK3BlcmlvZCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "Retro",
        "prop.Y29yZT1NYWluK3N0b25lK3NoYXBlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1DZW50ZXIrbWluK2xlbmd0aCtmaXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "ebay.ivy_and_rose.secondary_category_id": "262014",
        "prop.Y29yZT1NYXgrc2l6ZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "10",
        "prop.Y29yZT1GYW5jeStkaWFtb25kK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "etsy.ivy_and_rose.shop_section_id": "21991708",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY2FyYXQrd2VpZ2h0JmViYXk9JmV0c3k9JnNob3BpZnk9": "0.20ct",
        "prop.Y29yZT1DdXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1NYXRlcmlhbCttdWx0aSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "14k Yellow Gold",
        "prop.Y29yZT1DZW50ZXIrbWF4K3dpZHRoK2ZpdCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "ebay.ivy_and_rose.sub_title": null,
        "product.additional_styles": [],
        "product.price": "50",
        "prop.Y29yZT1NZXRhbCtwdXJpdHkmZWJheT0mZXRzeT0mc2hvcGlmeT0": "14k",
        "product.title": "GOLD NUGGET DIAMOND RING 1970s VINTAGE 10.6gm 14k YELLOW CHUNKY ESTATE MENS",
        "prop.Y29yZT1UcmVhdG1lbnQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Not Treated, Not Enhanced",
        "prop.Y29yZT1NYWluK3N0b25lJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1TdHlsZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1PY2Nhc2lvbiZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "shopify.ivy_and_rose.product_type": null,
        "ebay.ivy_and_rose.price": "50",
        "product.description": "Why we love it: \n\r\nThis vintage diamond ring has a chunky shape with a charming nugget texture punctuated by a bright bezel set diamond. \n\r\n\n\r\nHighlights:\n\r\n- 1970s vintage\n\r\n- 0.20ct round brilliant cut diamond\n\r\n- High quality, bright white and exceptionally clean with grades of G-H VS1\n\r\n- Chunky gold nugget design\n\r\n- Substantial 14k yellow gold, weighing 10.6gm\n\r\n- Versatile design is suited to any gender\n\r\n\n\r\nDimensions:\n\r\n13.1mm across (north-south) and 4.5mm from the finger to the top of the setting.",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Diamond",
        "etsy.ivy_and_rose.category_id": "1231",
        "shopify.ivy_and_rose.vendor": null,
        "product.cost": "10",
        "prop.Y29yZT1TaXphYmxlJmViYXk9JmV0c3k9JnNob3BpZnk9": "yes",
        "prop.Y29yZT1NaW4rc2l6ZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "4",
        "prop.Y29yZT1NYWluK3N0b25lK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1JdGVtK3R5cGUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Cocktail Ring - Other",
        "prop.Y29yZT1FcmEmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Original Vintage",
        "product.internal_title": "ring - cocktail - vintage",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY29sb3ImZWJheT0mZXRzeT0mc2hvcGlmeT0": "G-H",
        "shopify.ilya_fine_jewelry.price": null,
        "prop.Y29yZT1Db2xsZWN0aW9uJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "product.notes": "13.1 x 4.5 (vintage nugget ring mens or ladys?)",
        "shopify.ilya_fine_jewelry.title": null,
        "product.stock_number": null,
        "etsy.ivy_and_rose.when_made": "1950s",
        "prop.Y29yZT1DYW4rYmUrcGVyc29uYWxpemVkJmViYXk9JmV0c3k9JnNob3BpZnk9": "Yes",
        "product.location": "some",
        "shopify.ilya_fine_jewelry.seo_title": null,
        "prop.Y29yZT1OYXR1cmFsJTJGbGFiLWNyZWF0ZWQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Natural",
        "ebay.ivy_and_rose.store_category2_id": "14736870",
        "shopify.ilya_fine_jewelry.vendor": null,
        "shopify.ivy_and_rose.seo_title": null,
        "prop.Y29yZT1HZW1zdG9uZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "Diamond",
        "prop.Y29yZT1TaWRlK3N0b25lJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "shopify.ilya_fine_jewelry.collection_ids": [],
        "shopify.ilya_fine_jewelry.tags": [],
        "prop.Y29yZT1TaWRlK3N0b25lK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "ebay.ivy_and_rose.category_id": "261994",
        "prop.Y29yZT1NYWRlK3RvK29yZGVyJmViYXk9JmV0c3k9JnNob3BpZnk9": "No",
        "product.availability": 0,
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrc2hhcGUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Round",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY2xhcml0eSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "VS1",
        "prop.Y29yZT1TaWRlK3N0b25lK2NsYXJpdHkmZWJheT0mZXRzeT0mc2hvcGlmeT0": null
      },
      "id": "99c4cde7-8d76-40d6-a52e-823f96fd6c73",
      "sku": "19207"
    },
    {
      "data": {
        "prop.Y29yZT1Ub3RhbCtjYXJhdCt3ZWlnaHQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1SZWNpcGllbnQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Women",
        "shopify.ivy_and_rose.collection_ids": [],
        "shopify.ivy_and_rose.tags": [],
        "prop.Y29yZT1TZXR0aW5nK3N0eWxlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1EaWFtb25kK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "shopify.ilya_fine_jewelry.product_type": null,
        "prop.Y29yZT1DbGFyaXR5JmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1TaWRlK3N0b25lK3NoYXBlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "etsy.ivy_and_rose.who_made": "i_did",
        "prop.Y29yZT1TaXplJmViYXk9JmV0c3k9JnNob3BpZnk9": "6.75 (Resizable)",
        "prop.Y29yZT1DZW50ZXIrbWluK3dpZHRoK2ZpdCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "etsy.ivy_and_rose.price": "50",
        "prop.Y29yZT1DZXJ0aWZpY2F0aW9uJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "etsy.ivy_and_rose.title": "Classic 14K Yellow Gold Wedding Band 3M Ring Simple Stacking Comfort Mens Ladies",
        "ebay.ivy_and_rose.store_category_id": "14843984",
        "product.main_style": null,
        "product.sku": "19120",
        "shopify.ivy_and_rose.title": null,
        "shopify.ivy_and_rose.price": null,
        "prop.Y29yZT1TaWRlK3N0b25lcytxdWFudGl0eSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "etsy.ivy_and_rose.is_supply": false,
        "prop.Y29yZT1HcmFtK3dlaWdodCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "2.9 grams",
        "prop.Y29yZT1DZW50ZXIrbWF4K2xlbmd0aCtmaXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "ebay.ivy_and_rose.condition": "NEW",
        "prop.Y29yZT1TZWNvbmRhcnkrc3RvbmUmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1TaWRlK3N0b25lK2NhcmF0K3dlaWdodCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1SaW5nK3NpemUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "6.75",
        "prop.Y29yZT1NZXRhbCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "14k Yellow Gold",
        "prop.Y29yZT1UaGVtZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1BcHByb3hpbWF0ZSt0aW1lK3BlcmlvZCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "New",
        "prop.Y29yZT1NYWluK3N0b25lK3NoYXBlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1DZW50ZXIrbWluK2xlbmd0aCtmaXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "ebay.ivy_and_rose.secondary_category_id": "261994",
        "prop.Y29yZT1NYXgrc2l6ZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "10",
        "prop.Y29yZT1GYW5jeStkaWFtb25kK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "etsy.ivy_and_rose.shop_section_id": "17822028",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY2FyYXQrd2VpZ2h0JmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1DdXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1NYXRlcmlhbCttdWx0aSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "14k Yellow Gold",
        "prop.Y29yZT1DZW50ZXIrbWF4K3dpZHRoK2ZpdCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "ebay.ivy_and_rose.sub_title": null,
        "product.additional_styles": [],
        "product.price": "50",
        "prop.Y29yZT1NZXRhbCtwdXJpdHkmZWJheT0mZXRzeT0mc2hvcGlmeT0": "14k",
        "product.title": "CLASSIC 14k YELLOW GOLD WEDDING BAND 3m RING SIMPLE STACKING COMFORT MENS LADIES",
        "prop.Y29yZT1UcmVhdG1lbnQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1NYWluK3N0b25lJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1TdHlsZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1PY2Nhc2lvbiZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "shopify.ivy_and_rose.product_type": null,
        "ebay.ivy_and_rose.price": "50",
        "product.description": "Why we love it: \n\r\nThis solid 14k yellow gold ring is high quality, and utterly classic! Its sleek and simple design has an air of grace, sophistication, and timeless elegance.\n\r\n\n\r\nDimensions:\n\r\nThe ring measures 3.0mm across (north south) and 1.6mm from finger to top.",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "etsy.ivy_and_rose.category_id": "1247",
        "shopify.ivy_and_rose.vendor": null,
        "product.cost": "10",
        "prop.Y29yZT1TaXphYmxlJmViYXk9JmV0c3k9JnNob3BpZnk9": "yes",
        "prop.Y29yZT1NaW4rc2l6ZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "4",
        "prop.Y29yZT1NYWluK3N0b25lK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1JdGVtK3R5cGUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Band - Wedding",
        "prop.Y29yZT1FcmEmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Modern",
        "product.internal_title": "ring - wedding band - modern",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY29sb3ImZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "shopify.ilya_fine_jewelry.price": null,
        "prop.Y29yZT1Db2xsZWN0aW9uJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "product.notes": "3.0 x 1.6",
        "shopify.ilya_fine_jewelry.title": null,
        "product.stock_number": null,
        "etsy.ivy_and_rose.when_made": "2010_2019",
        "prop.Y29yZT1DYW4rYmUrcGVyc29uYWxpemVkJmViYXk9JmV0c3k9JnNob3BpZnk9": "Yes",
        "product.location": "some",
        "shopify.ilya_fine_jewelry.seo_title": null,
        "prop.Y29yZT1OYXR1cmFsJTJGbGFiLWNyZWF0ZWQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "ebay.ivy_and_rose.store_category2_id": null,
        "shopify.ilya_fine_jewelry.vendor": null,
        "shopify.ivy_and_rose.seo_title": null,
        "prop.Y29yZT1HZW1zdG9uZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1TaWRlK3N0b25lJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "shopify.ilya_fine_jewelry.collection_ids": [],
        "shopify.ilya_fine_jewelry.tags": [],
        "prop.Y29yZT1TaWRlK3N0b25lK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "ebay.ivy_and_rose.category_id": "261977",
        "prop.Y29yZT1NYWRlK3RvK29yZGVyJmViYXk9JmV0c3k9JnNob3BpZnk9": "No",
        "product.availability": 0,
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrc2hhcGUmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY2xhcml0eSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1TaWRlK3N0b25lK2NsYXJpdHkmZWJheT0mZXRzeT0mc2hvcGlmeT0": null
      },
      "id": "eb138ab6-4543-43b3-9d20-7b9e7c405214",
      "sku": "19120"
    },
    {
      "data": {
        "prop.Y29yZT1Ub3RhbCtjYXJhdCt3ZWlnaHQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "1.46ct",
        "prop.Y29yZT1SZWNpcGllbnQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Women",
        "shopify.ivy_and_rose.collection_ids": [],
        "shopify.ivy_and_rose.tags": [],
        "prop.Y29yZT1TZXR0aW5nK3N0eWxlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1EaWFtb25kK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "shopify.ilya_fine_jewelry.product_type": null,
        "prop.Y29yZT1DbGFyaXR5JmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1TaWRlK3N0b25lK3NoYXBlJmViYXk9JmV0c3k9JnNob3BpZnk9": "Sapphires: Custom Cut, Diamonds: Round",
        "etsy.ivy_and_rose.who_made": "i_did",
        "prop.Y29yZT1TaXplJmViYXk9JmV0c3k9JnNob3BpZnk9": "6.5 (Resizable)",
        "prop.Y29yZT1DZW50ZXIrbWluK3dpZHRoK2ZpdCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "etsy.ivy_and_rose.price": "50",
        "prop.Y29yZT1DZXJ0aWZpY2F0aW9uJmViYXk9JmV0c3k9JnNob3BpZnk9": "GIA",
        "etsy.ivy_and_rose.title": "0.90Ct Gia Certified Diamond Sapphire Cocktail Ring Vintage Style 14K White Gold",
        "ebay.ivy_and_rose.store_category_id": "14736792",
        "product.main_style": "Halo",
        "product.sku": "MTO-17021-12568-BS",
        "shopify.ivy_and_rose.title": null,
        "shopify.ivy_and_rose.price": null,
        "prop.Y29yZT1TaWRlK3N0b25lcytxdWFudGl0eSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "etsy.ivy_and_rose.is_supply": false,
        "prop.Y29yZT1HcmFtK3dlaWdodCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1DZW50ZXIrbWF4K2xlbmd0aCtmaXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "ebay.ivy_and_rose.condition": "NEW",
        "prop.Y29yZT1TZWNvbmRhcnkrc3RvbmUmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1TaWRlK3N0b25lK2NhcmF0K3dlaWdodCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "Sapphires: 0.44ct; Diamonds: 0.12ct",
        "prop.Y29yZT1SaW5nK3NpemUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "6.5",
        "prop.Y29yZT1NZXRhbCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "14k White Gold",
        "prop.Y29yZT1UaGVtZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1BcHByb3hpbWF0ZSt0aW1lK3BlcmlvZCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "New - Vintage Reproduction",
        "prop.Y29yZT1NYWluK3N0b25lK3NoYXBlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1DZW50ZXIrbWluK2xlbmd0aCtmaXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "ebay.ivy_and_rose.secondary_category_id": null,
        "prop.Y29yZT1NYXgrc2l6ZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "10",
        "prop.Y29yZT1GYW5jeStkaWFtb25kK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "etsy.ivy_and_rose.shop_section_id": "20281911",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY2FyYXQrd2VpZ2h0JmViYXk9JmV0c3k9JnNob3BpZnk9": "0.90ct",
        "prop.Y29yZT1DdXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1NYXRlcmlhbCttdWx0aSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "14k White Gold",
        "prop.Y29yZT1DZW50ZXIrbWF4K3dpZHRoK2ZpdCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "ebay.ivy_and_rose.sub_title": null,
        "product.additional_styles": [
          "Dome"
        ],
        "product.price": "50",
        "prop.Y29yZT1NZXRhbCtwdXJpdHkmZWJheT0mZXRzeT0mc2hvcGlmeT0": "14k",
        "product.title": "0.90ct GIA CERTIFIED DIAMOND SAPPHIRE COCKTAIL RING VINTAGE STYLE 14k WHITE GOLD",
        "prop.Y29yZT1UcmVhdG1lbnQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1NYWluK3N0b25lJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1TdHlsZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1PY2Nhc2lvbiZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "shopify.ivy_and_rose.product_type": null,
        "ebay.ivy_and_rose.price": "50",
        "product.description": "Why we love it: \n\r\nThis diamond and sapphire ring offers a stunning Art Deco style design, rich color, and gorgeous, substantial sparkle! Featuring a high quality round cut diamond, the ring has a beautifully crafted oval design set with brilliantly white and vibrant natural diamonds as well as rich blue custom cut natural sapphires.\n\r\n\n\r\nHighlights:\n\r\n- 0.90ct round cut diamond center\n\r\n- GIA certified\n\r\n- Excellent cut for fantastic sparkle\n\r\n- Bright white and completely eye clean with grades of G SI1\n\r\n- Calibre cut natural sapphires\n\r\n- Natural diamond accent stones\n\r\n- Unique and elegant vintage style design\n\r\n- 14k white gold setting\n\r\n\n\r\nDimensions:\n\r\nThe ring measures 13.2mm across (north south) and 4.0mm from the finger to the top of the setting.",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Diamond (GIA Certified)",
        "etsy.ivy_and_rose.category_id": "1245",
        "shopify.ivy_and_rose.vendor": null,
        "product.cost": "10",
        "prop.Y29yZT1TaXphYmxlJmViYXk9JmV0c3k9JnNob3BpZnk9": "yes",
        "prop.Y29yZT1NaW4rc2l6ZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "4",
        "prop.Y29yZT1NYWluK3N0b25lK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1JdGVtK3R5cGUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Engagement Ring",
        "prop.Y29yZT1FcmEmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Vintage Inspired",
        "product.internal_title": null,
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY29sb3ImZWJheT0mZXRzeT0mc2hvcGlmeT0": "G",
        "shopify.ilya_fine_jewelry.price": null,
        "prop.Y29yZT1Db2xsZWN0aW9uJmViYXk9JmV0c3k9JnNob3BpZnk9": "Harmony",
        "product.notes": null,
        "shopify.ilya_fine_jewelry.title": null,
        "product.stock_number": null,
        "etsy.ivy_and_rose.when_made": "made_to_order",
        "prop.Y29yZT1DYW4rYmUrcGVyc29uYWxpemVkJmViYXk9JmV0c3k9JnNob3BpZnk9": "Yes",
        "product.location": "some",
        "shopify.ilya_fine_jewelry.seo_title": null,
        "prop.Y29yZT1OYXR1cmFsJTJGbGFiLWNyZWF0ZWQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Natural",
        "ebay.ivy_and_rose.store_category2_id": "14736886",
        "shopify.ilya_fine_jewelry.vendor": null,
        "shopify.ivy_and_rose.seo_title": null,
        "prop.Y29yZT1HZW1zdG9uZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "Diamond (GIA Certified)",
        "prop.Y29yZT1TaWRlK3N0b25lJmViYXk9JmV0c3k9JnNob3BpZnk9": "Sapphires, Diamonds",
        "shopify.ilya_fine_jewelry.collection_ids": [],
        "shopify.ilya_fine_jewelry.tags": [],
        "prop.Y29yZT1TaWRlK3N0b25lK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": "Blue, G-H",
        "ebay.ivy_and_rose.category_id": "261975",
        "prop.Y29yZT1NYWRlK3RvK29yZGVyJmViYXk9JmV0c3k9JnNob3BpZnk9": "Yes",
        "product.availability": 1,
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrc2hhcGUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Round (Excellent Cut)",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY2xhcml0eSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "SI1 (100% Eye Clean)",
        "prop.Y29yZT1TaWRlK3N0b25lK2NsYXJpdHkmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Diamonds: VS1-SI1"
      },
      "id": "8bafc896-807b-4b4c-9dfe-72812f133b4c",
      "sku": "MTO-17021-12568-BS"
    },
    {
      "data": {
        "prop.Y29yZT1Ub3RhbCtjYXJhdCt3ZWlnaHQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "1.58ct",
        "prop.Y29yZT1SZWNpcGllbnQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Women",
        "shopify.ivy_and_rose.collection_ids": [],
        "shopify.ivy_and_rose.tags": [],
        "prop.Y29yZT1TZXR0aW5nK3N0eWxlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1EaWFtb25kK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "shopify.ilya_fine_jewelry.product_type": null,
        "prop.Y29yZT1DbGFyaXR5JmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1TaWRlK3N0b25lK3NoYXBlJmViYXk9JmV0c3k9JnNob3BpZnk9": "Sapphires: Custom Cut, Diamonds: Round",
        "etsy.ivy_and_rose.who_made": "i_did",
        "prop.Y29yZT1TaXplJmViYXk9JmV0c3k9JnNob3BpZnk9": "6.5 (Resizable)",
        "prop.Y29yZT1DZW50ZXIrbWluK3dpZHRoK2ZpdCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "etsy.ivy_and_rose.price": "50",
        "prop.Y29yZT1DZXJ0aWZpY2F0aW9uJmViYXk9JmV0c3k9JnNob3BpZnk9": "EGL-USA",
        "etsy.ivy_and_rose.title": "Certified Diamond Sapphire Engagement Ring Vintage Style Art Deco 14K White Gold",
        "ebay.ivy_and_rose.store_category_id": "14736792",
        "product.main_style": "Halo",
        "product.sku": "MTO-16628-12564-BS",
        "shopify.ivy_and_rose.title": null,
        "shopify.ivy_and_rose.price": null,
        "prop.Y29yZT1TaWRlK3N0b25lcytxdWFudGl0eSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "etsy.ivy_and_rose.is_supply": false,
        "prop.Y29yZT1HcmFtK3dlaWdodCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1DZW50ZXIrbWF4K2xlbmd0aCtmaXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "ebay.ivy_and_rose.condition": "NEW",
        "prop.Y29yZT1TZWNvbmRhcnkrc3RvbmUmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1TaWRlK3N0b25lK2NhcmF0K3dlaWdodCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "Sapphires: 0.57ct, Diamonds: 0.36ct",
        "prop.Y29yZT1SaW5nK3NpemUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "6.5",
        "prop.Y29yZT1NZXRhbCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "14k White Gold",
        "prop.Y29yZT1UaGVtZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1BcHByb3hpbWF0ZSt0aW1lK3BlcmlvZCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "New - Vintage Reproduction",
        "prop.Y29yZT1NYWluK3N0b25lK3NoYXBlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1DZW50ZXIrbWluK2xlbmd0aCtmaXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "ebay.ivy_and_rose.secondary_category_id": null,
        "prop.Y29yZT1NYXgrc2l6ZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "10",
        "prop.Y29yZT1GYW5jeStkaWFtb25kK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "etsy.ivy_and_rose.shop_section_id": "20281911",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY2FyYXQrd2VpZ2h0JmViYXk9JmV0c3k9JnNob3BpZnk9": "0.65ct",
        "prop.Y29yZT1DdXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1NYXRlcmlhbCttdWx0aSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "14k White Gold",
        "prop.Y29yZT1DZW50ZXIrbWF4K3dpZHRoK2ZpdCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "ebay.ivy_and_rose.sub_title": null,
        "product.additional_styles": [
          "Dome"
        ],
        "product.price": "50",
        "prop.Y29yZT1NZXRhbCtwdXJpdHkmZWJheT0mZXRzeT0mc2hvcGlmeT0": "14k",
        "product.title": "CERTIFIED DIAMOND SAPPHIRE ENGAGEMENT RING VINTAGE STYLE ART DECO 14k WHITE GOLD",
        "prop.Y29yZT1UcmVhdG1lbnQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1NYWluK3N0b25lJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1TdHlsZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1PY2Nhc2lvbiZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "shopify.ivy_and_rose.product_type": null,
        "ebay.ivy_and_rose.price": "50",
        "product.description": "Why we love it: \n\r\nThis gorgeous diamond and sapphire ring features a vibrant 0.65ct round brilliant cut diamond set at the center of an elegant Art Deco style design. The bright white and completely eye clean diamond is accented by a rich blue border of calibre cut sapphires and vibrant diamonds.\n\r\n\n\r\nHighlights:\n\r\n- 0.65 carat round cut diamond center\n\r\n- EGL-USA certified\n\r\n- Very bright white and completely eye clean with grades of F SI1\n\r\n- Calibre cut natural sapphires\n\r\n- Natural diamond accent stones\n\r\n- Unique and elegant vintage style design\n\r\n- 14k white gold setting\n\r\n\n\r\nDimensions:\n\r\nThe ring measures 9.2mm across (north south) and 4.1mm from finger to top",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Diamond (EGL-USA Certified)",
        "etsy.ivy_and_rose.category_id": "1245",
        "shopify.ivy_and_rose.vendor": null,
        "product.cost": "10",
        "prop.Y29yZT1TaXphYmxlJmViYXk9JmV0c3k9JnNob3BpZnk9": "yes",
        "prop.Y29yZT1NaW4rc2l6ZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "4",
        "prop.Y29yZT1NYWluK3N0b25lK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1JdGVtK3R5cGUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Engagement Ring",
        "prop.Y29yZT1FcmEmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Vintage Inspired",
        "product.internal_title": null,
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY29sb3ImZWJheT0mZXRzeT0mc2hvcGlmeT0": "F",
        "shopify.ilya_fine_jewelry.price": null,
        "prop.Y29yZT1Db2xsZWN0aW9uJmViYXk9JmV0c3k9JnNob3BpZnk9": "Harmony",
        "product.notes": null,
        "shopify.ilya_fine_jewelry.title": null,
        "product.stock_number": null,
        "etsy.ivy_and_rose.when_made": "made_to_order",
        "prop.Y29yZT1DYW4rYmUrcGVyc29uYWxpemVkJmViYXk9JmV0c3k9JnNob3BpZnk9": "Yes",
        "product.location": "some",
        "shopify.ilya_fine_jewelry.seo_title": null,
        "prop.Y29yZT1OYXR1cmFsJTJGbGFiLWNyZWF0ZWQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Natural",
        "ebay.ivy_and_rose.store_category2_id": "14736886",
        "shopify.ilya_fine_jewelry.vendor": null,
        "shopify.ivy_and_rose.seo_title": null,
        "prop.Y29yZT1HZW1zdG9uZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "Diamond (EGL-USA Certified)",
        "prop.Y29yZT1TaWRlK3N0b25lJmViYXk9JmV0c3k9JnNob3BpZnk9": "Sapphires, Diamonds",
        "shopify.ilya_fine_jewelry.collection_ids": [],
        "shopify.ilya_fine_jewelry.tags": [],
        "prop.Y29yZT1TaWRlK3N0b25lK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": "Blue, G-H",
        "ebay.ivy_and_rose.category_id": "261975",
        "prop.Y29yZT1NYWRlK3RvK29yZGVyJmViYXk9JmV0c3k9JnNob3BpZnk9": "Yes",
        "product.availability": 1,
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrc2hhcGUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Round",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY2xhcml0eSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "SI1",
        "prop.Y29yZT1TaWRlK3N0b25lK2NsYXJpdHkmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Diamonds: VS1-SI1"
      },
      "id": "cc8e7751-39e4-441a-83a7-d573fec47a5d",
      "sku": "MTO-16628-12564-BS"
    },
    {
      "data": {
        "prop.Y29yZT1Ub3RhbCtjYXJhdCt3ZWlnaHQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "0.50ct",
        "prop.Y29yZT1SZWNpcGllbnQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Women",
        "shopify.ivy_and_rose.collection_ids": [],
        "shopify.ivy_and_rose.tags": [],
        "prop.Y29yZT1TZXR0aW5nK3N0eWxlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1EaWFtb25kK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "shopify.ilya_fine_jewelry.product_type": null,
        "prop.Y29yZT1DbGFyaXR5JmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1TaWRlK3N0b25lK3NoYXBlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "etsy.ivy_and_rose.who_made": "someone_else",
        "prop.Y29yZT1TaXplJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1DZW50ZXIrbWluK3dpZHRoK2ZpdCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "etsy.ivy_and_rose.price": "50",
        "prop.Y29yZT1DZXJ0aWZpY2F0aW9uJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "etsy.ivy_and_rose.title": "Lab Created Diamond Matching Pair 1/2Ct Trapezoid Shape Cut Loose Accent Stones",
        "ebay.ivy_and_rose.store_category_id": "14736769",
        "product.main_style": null,
        "product.sku": "LG-19522-TRAP-.50",
        "shopify.ivy_and_rose.title": null,
        "shopify.ivy_and_rose.price": null,
        "prop.Y29yZT1TaWRlK3N0b25lcytxdWFudGl0eSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "etsy.ivy_and_rose.is_supply": true,
        "prop.Y29yZT1HcmFtK3dlaWdodCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1DZW50ZXIrbWF4K2xlbmd0aCtmaXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "ebay.ivy_and_rose.condition": "NEW",
        "prop.Y29yZT1TZWNvbmRhcnkrc3RvbmUmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1TaWRlK3N0b25lK2NhcmF0K3dlaWdodCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1SaW5nK3NpemUmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1NZXRhbCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1UaGVtZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1BcHByb3hpbWF0ZSt0aW1lK3BlcmlvZCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "New",
        "prop.Y29yZT1NYWluK3N0b25lK3NoYXBlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1DZW50ZXIrbWluK2xlbmd0aCtmaXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "ebay.ivy_and_rose.secondary_category_id": null,
        "prop.Y29yZT1NYXgrc2l6ZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "10",
        "prop.Y29yZT1GYW5jeStkaWFtb25kK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "etsy.ivy_and_rose.shop_section_id": "18036037",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY2FyYXQrd2VpZ2h0JmViYXk9JmV0c3k9JnNob3BpZnk9": "0.50ctw (6.00mm x 2.75mm Avg.)",
        "prop.Y29yZT1DdXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1NYXRlcmlhbCttdWx0aSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1DZW50ZXIrbWF4K3dpZHRoK2ZpdCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "ebay.ivy_and_rose.sub_title": null,
        "product.additional_styles": [],
        "product.price": "50",
        "prop.Y29yZT1NZXRhbCtwdXJpdHkmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "product.title": "LAB CREATED DIAMOND MATCHING PAIR 1/2ct TRAPEZOID SHAPE CUT LOOSE ACCENT STONES",
        "prop.Y29yZT1UcmVhdG1lbnQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1NYWluK3N0b25lJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1TdHlsZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1PY2Nhc2lvbiZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "shopify.ivy_and_rose.product_type": null,
        "ebay.ivy_and_rose.price": "50",
        "product.description": "Highlights:\n\r\n- ½ carat matched pair of trapezoid cut lab diamonds\n\r\n- Bright white with E-F color\n\r\n- Very clean with VVS-VS clarity\n\r\n- The diamonds are lab grown. They offer all the beauty and durability of earth mined diamonds at an excellent value.\n\r\n\n\r\nAdditional Notes:\n\r\nThe diamonds are being sold loose, and would make perfect sides on a 3 stone ring. We are a full service jeweler, and would be happy to create a finished piece for you using these stones.",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Lab Grown Diamondx2",
        "etsy.ivy_and_rose.category_id": "6648",
        "shopify.ivy_and_rose.vendor": null,
        "product.cost": "10",
        "prop.Y29yZT1TaXphYmxlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1NaW4rc2l6ZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "4",
        "prop.Y29yZT1NYWluK3N0b25lK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1JdGVtK3R5cGUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Loose Diamond",
        "prop.Y29yZT1FcmEmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Modern",
        "product.internal_title": null,
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY29sb3ImZWJheT0mZXRzeT0mc2hvcGlmeT0": "E-F",
        "shopify.ilya_fine_jewelry.price": null,
        "prop.Y29yZT1Db2xsZWN0aW9uJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "product.notes": null,
        "shopify.ilya_fine_jewelry.title": null,
        "product.stock_number": null,
        "etsy.ivy_and_rose.when_made": "made_to_order",
        "prop.Y29yZT1DYW4rYmUrcGVyc29uYWxpemVkJmViYXk9JmV0c3k9JnNob3BpZnk9": "Yes",
        "product.location": "some",
        "shopify.ilya_fine_jewelry.seo_title": null,
        "prop.Y29yZT1OYXR1cmFsJTJGbGFiLWNyZWF0ZWQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Lab Grown",
        "ebay.ivy_and_rose.store_category2_id": "14736889",
        "shopify.ilya_fine_jewelry.vendor": null,
        "shopify.ivy_and_rose.seo_title": null,
        "prop.Y29yZT1HZW1zdG9uZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "Lab Grown Diamondx2",
        "prop.Y29yZT1TaWRlK3N0b25lJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "shopify.ilya_fine_jewelry.collection_ids": [],
        "shopify.ilya_fine_jewelry.tags": [],
        "prop.Y29yZT1TaWRlK3N0b25lK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "ebay.ivy_and_rose.category_id": "262026",
        "prop.Y29yZT1NYWRlK3RvK29yZGVyJmViYXk9JmV0c3k9JnNob3BpZnk9": "Yes",
        "product.availability": 4,
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrc2hhcGUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Trapezoid",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY2xhcml0eSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "VVS-VS",
        "prop.Y29yZT1TaWRlK3N0b25lK2NsYXJpdHkmZWJheT0mZXRzeT0mc2hvcGlmeT0": null
      },
      "id": "3f131853-6c56-462c-b8c7-d548d6e49715",
      "sku": "LG-19522-TRAP-.50"
    },
    {
      "data": {
        "prop.Y29yZT1Ub3RhbCtjYXJhdCt3ZWlnaHQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "4.49ct",
        "prop.Y29yZT1SZWNpcGllbnQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Women",
        "shopify.ivy_and_rose.collection_ids": [],
        "shopify.ivy_and_rose.tags": [],
        "prop.Y29yZT1TZXR0aW5nK3N0eWxlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1EaWFtb25kK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "shopify.ilya_fine_jewelry.product_type": null,
        "prop.Y29yZT1DbGFyaXR5JmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1TaWRlK3N0b25lK3NoYXBlJmViYXk9JmV0c3k9JnNob3BpZnk9": "Round (Very Good-Excellent Cut), Marquise",
        "etsy.ivy_and_rose.who_made": "i_did",
        "prop.Y29yZT1TaXplJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1DZW50ZXIrbWluK3dpZHRoK2ZpdCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "etsy.ivy_and_rose.price": "50",
        "prop.Y29yZT1DZXJ0aWZpY2F0aW9uJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "etsy.ivy_and_rose.title": "Tiffany &amp; Co Diamond Tennis Bracelet Victoria 4.5Ct F-G Vs Platinum Marquise",
        "ebay.ivy_and_rose.store_category_id": "5979473",
        "product.main_style": "Tennis",
        "product.sku": "20956",
        "shopify.ivy_and_rose.title": null,
        "shopify.ivy_and_rose.price": null,
        "prop.Y29yZT1TaWRlK3N0b25lcytxdWFudGl0eSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "etsy.ivy_and_rose.is_supply": false,
        "prop.Y29yZT1HcmFtK3dlaWdodCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "13.5 grams",
        "prop.Y29yZT1DZW50ZXIrbWF4K2xlbmd0aCtmaXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "ebay.ivy_and_rose.condition": "NEW",
        "prop.Y29yZT1TZWNvbmRhcnkrc3RvbmUmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1TaWRlK3N0b25lK2NhcmF0K3dlaWdodCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "4.49ct",
        "prop.Y29yZT1SaW5nK3NpemUmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1NZXRhbCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "Platinum",
        "prop.Y29yZT1UaGVtZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1BcHByb3hpbWF0ZSt0aW1lK3BlcmlvZCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "New",
        "prop.Y29yZT1NYWluK3N0b25lK3NoYXBlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1DZW50ZXIrbWluK2xlbmd0aCtmaXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "ebay.ivy_and_rose.secondary_category_id": null,
        "prop.Y29yZT1NYXgrc2l6ZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "10",
        "prop.Y29yZT1GYW5jeStkaWFtb25kK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "etsy.ivy_and_rose.shop_section_id": "17865866",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY2FyYXQrd2VpZ2h0JmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1DdXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1NYXRlcmlhbCttdWx0aSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "Platinum",
        "prop.Y29yZT1DZW50ZXIrbWF4K3dpZHRoK2ZpdCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "ebay.ivy_and_rose.sub_title": null,
        "product.additional_styles": [
          "Prong"
        ],
        "product.price": "50",
        "prop.Y29yZT1NZXRhbCtwdXJpdHkmZWJheT0mZXRzeT0mc2hvcGlmeT0": "PT",
        "product.title": "TIFFANY &amp; CO DIAMOND TENNIS BRACELET VICTORIA 4.5ct F-G VS PLATINUM MARQUISE",
        "prop.Y29yZT1UcmVhdG1lbnQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Not Treated, Not Enhanced",
        "prop.Y29yZT1NYWluK3N0b25lJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1TdHlsZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1PY2Nhc2lvbiZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "shopify.ivy_and_rose.product_type": null,
        "ebay.ivy_and_rose.price": "50",
        "product.description": "Why we love it: \n\r\nThis authentic Tiffany diamond bracelet is substantial and absolutely fantastic quality! A diamond tennis bracelet is a time tested look that will always be in style! The bracelet is part of the Tiffany Victoria line and retails for $46,000!\n\r\n\n\r\nHighlights:\n\r\n- 4.49 carats of diamonds for fantastic, substantial sparkle!\n\r\n- The diamonds are very high quality, bright white and exceptionally clean, graded F-G VS\n\r\n- Excellent cut round brilliants for fantastic brilliance and sparkle\n\r\n- Marquise diamonds on the clasp add an elegant touch\n\r\n- Sturdy and solid construction in platinum\n\r\n- Authentic Tiffany &amp; Co\n\r\n- Overall very high quality!\n\r\n\n\r\nDimensions:\n\r\n7 inches in length.",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "etsy.ivy_and_rose.category_id": "1193",
        "shopify.ivy_and_rose.vendor": null,
        "product.cost": "10",
        "prop.Y29yZT1TaXphYmxlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1NaW4rc2l6ZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "4",
        "prop.Y29yZT1NYWluK3N0b25lK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1JdGVtK3R5cGUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Bracelet (All Other)",
        "prop.Y29yZT1FcmEmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Modern",
        "product.internal_title": null,
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY29sb3ImZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "shopify.ilya_fine_jewelry.price": null,
        "prop.Y29yZT1Db2xsZWN0aW9uJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "product.notes": "7\" (\"Victoria\" bracelet by Tiffany &amp; Co)",
        "shopify.ilya_fine_jewelry.title": null,
        "product.stock_number": null,
        "etsy.ivy_and_rose.when_made": "2010_2019",
        "prop.Y29yZT1DYW4rYmUrcGVyc29uYWxpemVkJmViYXk9JmV0c3k9JnNob3BpZnk9": "Yes",
        "product.location": "some",
        "shopify.ilya_fine_jewelry.seo_title": null,
        "prop.Y29yZT1OYXR1cmFsJTJGbGFiLWNyZWF0ZWQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Natural",
        "ebay.ivy_and_rose.store_category2_id": null,
        "shopify.ilya_fine_jewelry.vendor": null,
        "shopify.ivy_and_rose.seo_title": null,
        "prop.Y29yZT1HZW1zdG9uZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "Diamonds",
        "prop.Y29yZT1TaWRlK3N0b25lJmViYXk9JmV0c3k9JnNob3BpZnk9": "Diamonds",
        "shopify.ilya_fine_jewelry.collection_ids": [],
        "shopify.ilya_fine_jewelry.tags": [],
        "prop.Y29yZT1TaWRlK3N0b25lK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": "F-G",
        "ebay.ivy_and_rose.category_id": "261988",
        "prop.Y29yZT1NYWRlK3RvK29yZGVyJmViYXk9JmV0c3k9JnNob3BpZnk9": "No",
        "product.availability": 1,
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrc2hhcGUmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY2xhcml0eSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1TaWRlK3N0b25lK2NsYXJpdHkmZWJheT0mZXRzeT0mc2hvcGlmeT0": "VS"
      },
      "id": "135082d0-67f8-4866-9f93-a8884acbfc49",
      "sku": "20956"
    },
    {
      "data": {
        "prop.Y29yZT1Ub3RhbCtjYXJhdCt3ZWlnaHQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "0.50ct",
        "prop.Y29yZT1SZWNpcGllbnQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Women",
        "shopify.ivy_and_rose.collection_ids": [],
        "shopify.ivy_and_rose.tags": [],
        "prop.Y29yZT1TZXR0aW5nK3N0eWxlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1EaWFtb25kK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "shopify.ilya_fine_jewelry.product_type": null,
        "prop.Y29yZT1DbGFyaXR5JmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1TaWRlK3N0b25lK3NoYXBlJmViYXk9JmV0c3k9JnNob3BpZnk9": "Round",
        "etsy.ivy_and_rose.who_made": "someone_else",
        "prop.Y29yZT1TaXplJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1DZW50ZXIrbWluK3dpZHRoK2ZpdCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "etsy.ivy_and_rose.price": "50",
        "prop.Y29yZT1DZXJ0aWZpY2F0aW9uJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "etsy.ivy_and_rose.title": "Mid Century Vintage Diamond Earrings Heart Shape 14K Rose Gold Soviet Estate",
        "ebay.ivy_and_rose.store_category_id": "14737505",
        "product.main_style": "Drop/Dangle",
        "product.sku": "20693",
        "shopify.ivy_and_rose.title": null,
        "shopify.ivy_and_rose.price": null,
        "prop.Y29yZT1TaWRlK3N0b25lcytxdWFudGl0eSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "8",
        "etsy.ivy_and_rose.is_supply": false,
        "prop.Y29yZT1HcmFtK3dlaWdodCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "4.8 grams",
        "prop.Y29yZT1DZW50ZXIrbWF4K2xlbmd0aCtmaXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "ebay.ivy_and_rose.condition": "USED_EXCELLENT",
        "prop.Y29yZT1TZWNvbmRhcnkrc3RvbmUmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1TaWRlK3N0b25lK2NhcmF0K3dlaWdodCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "0.50ctw",
        "prop.Y29yZT1SaW5nK3NpemUmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1NZXRhbCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "14k Rose Gold",
        "prop.Y29yZT1UaGVtZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1BcHByb3hpbWF0ZSt0aW1lK3BlcmlvZCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "Retro",
        "prop.Y29yZT1NYWluK3N0b25lK3NoYXBlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1DZW50ZXIrbWluK2xlbmd0aCtmaXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "ebay.ivy_and_rose.secondary_category_id": "262008",
        "prop.Y29yZT1NYXgrc2l6ZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "10",
        "prop.Y29yZT1GYW5jeStkaWFtb25kK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "etsy.ivy_and_rose.shop_section_id": "17821498",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY2FyYXQrd2VpZ2h0JmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1DdXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1NYXRlcmlhbCttdWx0aSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "14k Rose Gold",
        "prop.Y29yZT1DZW50ZXIrbWF4K3dpZHRoK2ZpdCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "ebay.ivy_and_rose.sub_title": null,
        "product.additional_styles": [],
        "product.price": "50",
        "prop.Y29yZT1NZXRhbCtwdXJpdHkmZWJheT0mZXRzeT0mc2hvcGlmeT0": "14k",
        "product.title": "MID CENTURY VINTAGE DIAMOND EARRINGS HEART SHAPE 14k ROSE GOLD SOVIET ESTATE",
        "prop.Y29yZT1UcmVhdG1lbnQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Not Treated, Not Enhanced",
        "prop.Y29yZT1NYWluK3N0b25lJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1TdHlsZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1PY2Nhc2lvbiZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "shopify.ivy_and_rose.product_type": null,
        "ebay.ivy_and_rose.price": "50",
        "product.description": "Why we love it: \n\r\nThese vintage diamond earrings has a romantic design with a sweet heart shape motif beautifully crafted in two tone 14k white and rose gold.\n\r\n\n\r\nHighlights:\n\r\n- Original vintage from the mid century\n\r\n- Rare vintage soviet jewelry!\n\r\n- 0.50ct of bright white and eye clean F-G VS2-SI2 diamonds\n\r\n- Hand crafted 14k rose and white gold settings\n\r\n\n\r\nDimensions:\n\r\n18.8 x 13.5mm",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "etsy.ivy_and_rose.category_id": "1208",
        "shopify.ivy_and_rose.vendor": null,
        "product.cost": "10",
        "prop.Y29yZT1TaXphYmxlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1NaW4rc2l6ZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "4",
        "prop.Y29yZT1NYWluK3N0b25lK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1JdGVtK3R5cGUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Drop Earrings - Dangle",
        "prop.Y29yZT1FcmEmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Original Vintage",
        "product.internal_title": null,
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY29sb3ImZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "shopify.ilya_fine_jewelry.price": null,
        "prop.Y29yZT1Db2xsZWN0aW9uJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "product.notes": "18.8 x 13.5 x 4.4 (Vintage Soviet w/hallmarks)",
        "shopify.ilya_fine_jewelry.title": null,
        "product.stock_number": null,
        "etsy.ivy_and_rose.when_made": "1950s",
        "prop.Y29yZT1DYW4rYmUrcGVyc29uYWxpemVkJmViYXk9JmV0c3k9JnNob3BpZnk9": "Yes",
        "product.location": "some",
        "shopify.ilya_fine_jewelry.seo_title": null,
        "prop.Y29yZT1OYXR1cmFsJTJGbGFiLWNyZWF0ZWQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Natural",
        "ebay.ivy_and_rose.store_category2_id": "14736887",
        "shopify.ilya_fine_jewelry.vendor": null,
        "shopify.ivy_and_rose.seo_title": null,
        "prop.Y29yZT1HZW1zdG9uZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "Diamond",
        "prop.Y29yZT1TaWRlK3N0b25lJmViYXk9JmV0c3k9JnNob3BpZnk9": "Diamond",
        "shopify.ilya_fine_jewelry.collection_ids": [],
        "shopify.ilya_fine_jewelry.tags": [],
        "prop.Y29yZT1TaWRlK3N0b25lK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": "F-G",
        "ebay.ivy_and_rose.category_id": "261990",
        "prop.Y29yZT1NYWRlK3RvK29yZGVyJmViYXk9JmV0c3k9JnNob3BpZnk9": "No",
        "product.availability": 1,
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrc2hhcGUmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY2xhcml0eSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1TaWRlK3N0b25lK2NsYXJpdHkmZWJheT0mZXRzeT0mc2hvcGlmeT0": "VS2-SI2"
      },
      "id": "d89e6c84-7877-472f-ac18-244b95acdd6d",
      "sku": "20693"
    },
    {
      "data": {
        "prop.Y29yZT1Ub3RhbCtjYXJhdCt3ZWlnaHQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "0.99ct",
        "prop.Y29yZT1SZWNpcGllbnQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Women",
        "shopify.ivy_and_rose.collection_ids": [],
        "shopify.ivy_and_rose.tags": [],
        "prop.Y29yZT1TZXR0aW5nK3N0eWxlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1EaWFtb25kK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "shopify.ilya_fine_jewelry.product_type": null,
        "prop.Y29yZT1DbGFyaXR5JmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1TaWRlK3N0b25lK3NoYXBlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "etsy.ivy_and_rose.who_made": "someone_else",
        "prop.Y29yZT1TaXplJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1DZW50ZXIrbWluK3dpZHRoK2ZpdCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "etsy.ivy_and_rose.price": "50",
        "prop.Y29yZT1DZXJ0aWZpY2F0aW9uJmViYXk9JmV0c3k9JnNob3BpZnk9": "GIA",
        "etsy.ivy_and_rose.title": "1 Carat Old European Cut Diamond Gia Certified H Si1 6.5Mm Natural Loose Vintage",
        "ebay.ivy_and_rose.store_category_id": "14736769",
        "product.main_style": null,
        "product.sku": "20466",
        "shopify.ivy_and_rose.title": null,
        "shopify.ivy_and_rose.price": null,
        "prop.Y29yZT1TaWRlK3N0b25lcytxdWFudGl0eSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "etsy.ivy_and_rose.is_supply": true,
        "prop.Y29yZT1HcmFtK3dlaWdodCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1DZW50ZXIrbWF4K2xlbmd0aCtmaXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "ebay.ivy_and_rose.condition": "USED_EXCELLENT",
        "prop.Y29yZT1TZWNvbmRhcnkrc3RvbmUmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1TaWRlK3N0b25lK2NhcmF0K3dlaWdodCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1SaW5nK3NpemUmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1NZXRhbCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1UaGVtZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1BcHByb3hpbWF0ZSt0aW1lK3BlcmlvZCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "Art Deco/Art Nouveau",
        "prop.Y29yZT1NYWluK3N0b25lK3NoYXBlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1DZW50ZXIrbWluK2xlbmd0aCtmaXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "ebay.ivy_and_rose.secondary_category_id": null,
        "prop.Y29yZT1NYXgrc2l6ZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "10",
        "prop.Y29yZT1GYW5jeStkaWFtb25kK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "etsy.ivy_and_rose.shop_section_id": "18036037",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY2FyYXQrd2VpZ2h0JmViYXk9JmV0c3k9JnNob3BpZnk9": "0.99ct (6.48mm - 6.53mm)",
        "prop.Y29yZT1DdXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1NYXRlcmlhbCttdWx0aSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1DZW50ZXIrbWF4K3dpZHRoK2ZpdCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "ebay.ivy_and_rose.sub_title": null,
        "product.additional_styles": [],
        "product.price": "50",
        "prop.Y29yZT1NZXRhbCtwdXJpdHkmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "product.title": "1 CARAT OLD EUROPEAN CUT DIAMOND GIA CERTIFIED H SI1 6.5mm NATURAL LOOSE VINTAGE",
        "prop.Y29yZT1UcmVhdG1lbnQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Not Treated, Not Enhanced",
        "prop.Y29yZT1NYWluK3N0b25lJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1TdHlsZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1PY2Nhc2lvbiZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "shopify.ivy_and_rose.product_type": null,
        "ebay.ivy_and_rose.price": "50",
        "product.description": "Highlights:\n\r\n- 0.99ct natural diamond\n\r\n- Original vintage diamond\n\r\n- Beautiful old European cut.\n\r\n- GIA certified\n\r\n- Faces up bright white with H color\n\r\n- Completely eye clean with a clarity grade of SI1\n\r\n- Great spread at 6.5mm in diameter!\n\r\n- Fantastic sparkle and brilliance!\n\r\n\n\r\nAdditional Notes:\n\r\nThe diamond is being sold loose, but it can be mounted upon request.",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Diamond (GIA Certified)",
        "etsy.ivy_and_rose.category_id": "6648",
        "shopify.ivy_and_rose.vendor": null,
        "product.cost": "10",
        "prop.Y29yZT1TaXphYmxlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1NaW4rc2l6ZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "4",
        "prop.Y29yZT1NYWluK3N0b25lK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1JdGVtK3R5cGUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Loose Diamond",
        "prop.Y29yZT1FcmEmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Original Vintage",
        "product.internal_title": null,
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY29sb3ImZWJheT0mZXRzeT0mc2hvcGlmeT0": "H",
        "shopify.ilya_fine_jewelry.price": null,
        "prop.Y29yZT1Db2xsZWN0aW9uJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "product.notes": "Big Spread!",
        "shopify.ilya_fine_jewelry.title": null,
        "product.stock_number": null,
        "etsy.ivy_and_rose.when_made": "1920s",
        "prop.Y29yZT1DYW4rYmUrcGVyc29uYWxpemVkJmViYXk9JmV0c3k9JnNob3BpZnk9": "Yes",
        "product.location": "some",
        "shopify.ilya_fine_jewelry.seo_title": null,
        "prop.Y29yZT1OYXR1cmFsJTJGbGFiLWNyZWF0ZWQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Natural",
        "ebay.ivy_and_rose.store_category2_id": "14736889",
        "shopify.ilya_fine_jewelry.vendor": null,
        "shopify.ivy_and_rose.seo_title": null,
        "prop.Y29yZT1HZW1zdG9uZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "Diamond (GIA Certified)",
        "prop.Y29yZT1TaWRlK3N0b25lJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "shopify.ilya_fine_jewelry.collection_ids": [],
        "shopify.ilya_fine_jewelry.tags": [],
        "prop.Y29yZT1TaWRlK3N0b25lK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "ebay.ivy_and_rose.category_id": "262026",
        "prop.Y29yZT1NYWRlK3RvK29yZGVyJmViYXk9JmV0c3k9JnNob3BpZnk9": "No",
        "product.availability": 1,
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrc2hhcGUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Old European Cut",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY2xhcml0eSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "SI1",
        "prop.Y29yZT1TaWRlK3N0b25lK2NsYXJpdHkmZWJheT0mZXRzeT0mc2hvcGlmeT0": null
      },
      "id": "26925431-9574-4715-912e-7e02fc58d53a",
      "sku": "20466"
    },
    {
      "data": {
        "prop.Y29yZT1Ub3RhbCtjYXJhdCt3ZWlnaHQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "0.08ct",
        "prop.Y29yZT1SZWNpcGllbnQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Women",
        "shopify.ivy_and_rose.collection_ids": [],
        "shopify.ivy_and_rose.tags": [],
        "prop.Y29yZT1TZXR0aW5nK3N0eWxlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1EaWFtb25kK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "shopify.ilya_fine_jewelry.product_type": null,
        "prop.Y29yZT1DbGFyaXR5JmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1TaWRlK3N0b25lK3NoYXBlJmViYXk9JmV0c3k9JnNob3BpZnk9": "Round",
        "etsy.ivy_and_rose.who_made": "i_did",
        "prop.Y29yZT1TaXplJmViYXk9JmV0c3k9JnNob3BpZnk9": "7.5 (Resizable)",
        "prop.Y29yZT1DZW50ZXIrbWluK3dpZHRoK2ZpdCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "etsy.ivy_and_rose.price": "50",
        "prop.Y29yZT1DZXJ0aWZpY2F0aW9uJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "etsy.ivy_and_rose.title": "Pave Diamond Claddagh Ring Platinum Yellow Gold Traditional Irish Heart Crown",
        "ebay.ivy_and_rose.store_category_id": "14736870",
        "product.main_style": "Claddagh",
        "product.sku": "19890",
        "shopify.ivy_and_rose.title": null,
        "shopify.ivy_and_rose.price": null,
        "prop.Y29yZT1TaWRlK3N0b25lcytxdWFudGl0eSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "etsy.ivy_and_rose.is_supply": false,
        "prop.Y29yZT1HcmFtK3dlaWdodCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "5.0 grams",
        "prop.Y29yZT1DZW50ZXIrbWF4K2xlbmd0aCtmaXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "ebay.ivy_and_rose.condition": "NEW",
        "prop.Y29yZT1TZWNvbmRhcnkrc3RvbmUmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1TaWRlK3N0b25lK2NhcmF0K3dlaWdodCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "0.08ctw",
        "prop.Y29yZT1SaW5nK3NpemUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "7.5",
        "prop.Y29yZT1NZXRhbCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "14k Yellow Gold, Platinum",
        "prop.Y29yZT1UaGVtZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1BcHByb3hpbWF0ZSt0aW1lK3BlcmlvZCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "New",
        "prop.Y29yZT1NYWluK3N0b25lK3NoYXBlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1DZW50ZXIrbWluK2xlbmd0aCtmaXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "ebay.ivy_and_rose.secondary_category_id": null,
        "prop.Y29yZT1NYXgrc2l6ZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "10",
        "prop.Y29yZT1GYW5jeStkaWFtb25kK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "etsy.ivy_and_rose.shop_section_id": "21991708",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY2FyYXQrd2VpZ2h0JmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1DdXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1NYXRlcmlhbCttdWx0aSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "14k Yellow Gold, Platinum",
        "prop.Y29yZT1DZW50ZXIrbWF4K3dpZHRoK2ZpdCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "ebay.ivy_and_rose.sub_title": null,
        "product.additional_styles": [
          "Pave"
        ],
        "product.price": "50",
        "prop.Y29yZT1NZXRhbCtwdXJpdHkmZWJheT0mZXRzeT0mc2hvcGlmeT0": "14k, Plat",
        "product.title": "PAVE DIAMOND CLADDAGH RING PLATINUM YELLOW GOLD TRADITIONAL IRISH HEART CROWN",
        "prop.Y29yZT1UcmVhdG1lbnQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Not Treated, Not Enhanced",
        "prop.Y29yZT1NYWluK3N0b25lJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1TdHlsZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1PY2Nhc2lvbiZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "shopify.ivy_and_rose.product_type": null,
        "ebay.ivy_and_rose.price": "50",
        "product.description": "Why we love it: \n\r\nThis diamond claddagh ring has a classic design connected to a rich history. Claddagh rings are an Irish tradition dating back to the 17th century. The hands represent friendship, the heart represents love and the crown represents loyalty. It’s a traditional wedding ring.\n\r\n\n\r\nHighlights:\n\r\n- 0.08ct bright white natural diamonds\n\r\n- Platinum ring with a 14k yellow gold head\n\r\n\n\r\nDimensions:\n\r\nThe ring measures 9.6mm across (north south) and 1.9mm from the finger to the top of the setting.",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "etsy.ivy_and_rose.category_id": "1231",
        "shopify.ivy_and_rose.vendor": null,
        "product.cost": "10",
        "prop.Y29yZT1TaXphYmxlJmViYXk9JmV0c3k9JnNob3BpZnk9": "yes",
        "prop.Y29yZT1NaW4rc2l6ZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "4",
        "prop.Y29yZT1NYWluK3N0b25lK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1JdGVtK3R5cGUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Cocktail Ring - Other",
        "prop.Y29yZT1FcmEmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Modern",
        "product.internal_title": "ring - cocktail - modern",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY29sb3ImZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "shopify.ilya_fine_jewelry.price": null,
        "prop.Y29yZT1Db2xsZWN0aW9uJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "product.notes": "9.6 x 1.9",
        "shopify.ilya_fine_jewelry.title": null,
        "product.stock_number": null,
        "etsy.ivy_and_rose.when_made": "2010_2019",
        "prop.Y29yZT1DYW4rYmUrcGVyc29uYWxpemVkJmViYXk9JmV0c3k9JnNob3BpZnk9": "Yes",
        "product.location": "some",
        "shopify.ilya_fine_jewelry.seo_title": null,
        "prop.Y29yZT1OYXR1cmFsJTJGbGFiLWNyZWF0ZWQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Natural",
        "ebay.ivy_and_rose.store_category2_id": null,
        "shopify.ilya_fine_jewelry.vendor": null,
        "shopify.ivy_and_rose.seo_title": null,
        "prop.Y29yZT1HZW1zdG9uZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "Diamond",
        "prop.Y29yZT1TaWRlK3N0b25lJmViYXk9JmV0c3k9JnNob3BpZnk9": "Diamond",
        "shopify.ilya_fine_jewelry.collection_ids": [],
        "shopify.ilya_fine_jewelry.tags": [],
        "prop.Y29yZT1TaWRlK3N0b25lK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": "G-H",
        "ebay.ivy_and_rose.category_id": "261994",
        "prop.Y29yZT1NYWRlK3RvK29yZGVyJmViYXk9JmV0c3k9JnNob3BpZnk9": "No",
        "product.availability": 1,
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrc2hhcGUmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY2xhcml0eSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1TaWRlK3N0b25lK2NsYXJpdHkmZWJheT0mZXRzeT0mc2hvcGlmeT0": "SI1-SI2"
      },
      "id": "72b05eff-0495-4671-9e90-6054ca4a9c3f",
      "sku": "19890"
    },
    {
      "data": {
        "prop.Y29yZT1Ub3RhbCtjYXJhdCt3ZWlnaHQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "0.17ct",
        "prop.Y29yZT1SZWNpcGllbnQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Women",
        "shopify.ivy_and_rose.collection_ids": [],
        "shopify.ivy_and_rose.tags": [],
        "prop.Y29yZT1TZXR0aW5nK3N0eWxlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1EaWFtb25kK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "shopify.ilya_fine_jewelry.product_type": null,
        "prop.Y29yZT1DbGFyaXR5JmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1TaWRlK3N0b25lK3NoYXBlJmViYXk9JmV0c3k9JnNob3BpZnk9": "Vintage Single Cut",
        "etsy.ivy_and_rose.who_made": "someone_else",
        "prop.Y29yZT1TaXplJmViYXk9JmV0c3k9JnNob3BpZnk9": "7.5 (Resizable)",
        "prop.Y29yZT1DZW50ZXIrbWluK3dpZHRoK2ZpdCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "etsy.ivy_and_rose.price": "50",
        "prop.Y29yZT1DZXJ0aWZpY2F0aW9uJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "etsy.ivy_and_rose.title": "Vintage Diamond Engagement Ring Round Cut 14K Two Tone Gold Illusion Setting",
        "ebay.ivy_and_rose.store_category_id": "14736792",
        "product.main_style": "Accent Stones",
        "product.sku": "19662",
        "shopify.ivy_and_rose.title": null,
        "shopify.ivy_and_rose.price": null,
        "prop.Y29yZT1TaWRlK3N0b25lcytxdWFudGl0eSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "2",
        "etsy.ivy_and_rose.is_supply": false,
        "prop.Y29yZT1HcmFtK3dlaWdodCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "3.3 grams",
        "prop.Y29yZT1DZW50ZXIrbWF4K2xlbmd0aCtmaXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "ebay.ivy_and_rose.condition": "USED_EXCELLENT",
        "prop.Y29yZT1TZWNvbmRhcnkrc3RvbmUmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1TaWRlK3N0b25lK2NhcmF0K3dlaWdodCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "0.03ctw",
        "prop.Y29yZT1SaW5nK3NpemUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "7.5",
        "prop.Y29yZT1NZXRhbCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "14k Yellow &amp; White Gold",
        "prop.Y29yZT1UaGVtZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1BcHByb3hpbWF0ZSt0aW1lK3BlcmlvZCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "Retro",
        "prop.Y29yZT1NYWluK3N0b25lK3NoYXBlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1DZW50ZXIrbWluK2xlbmd0aCtmaXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "ebay.ivy_and_rose.secondary_category_id": "262014",
        "prop.Y29yZT1NYXgrc2l6ZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "10",
        "prop.Y29yZT1GYW5jeStkaWFtb25kK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "etsy.ivy_and_rose.shop_section_id": "17500487",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY2FyYXQrd2VpZ2h0JmViYXk9JmV0c3k9JnNob3BpZnk9": "0.15ct",
        "prop.Y29yZT1DdXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1NYXRlcmlhbCttdWx0aSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "14k Yellow &amp; White Gold",
        "prop.Y29yZT1DZW50ZXIrbWF4K3dpZHRoK2ZpdCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "ebay.ivy_and_rose.sub_title": null,
        "product.additional_styles": [],
        "product.price": "50",
        "prop.Y29yZT1NZXRhbCtwdXJpdHkmZWJheT0mZXRzeT0mc2hvcGlmeT0": "14k",
        "product.title": "VINTAGE DIAMOND ENGAGEMENT RING ROUND CUT 14k TWO TONE GOLD ILLUSION SETTING",
        "prop.Y29yZT1UcmVhdG1lbnQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Not Treated, Not Enhanced",
        "prop.Y29yZT1NYWluK3N0b25lJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1TdHlsZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1PY2Nhc2lvbiZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "shopify.ivy_and_rose.product_type": null,
        "ebay.ivy_and_rose.price": "50",
        "product.description": "Why we love it: \n\r\nThis vintage diamond ring showcases a bright and lively center diamond in a classic setting with vintage details that add charm without clutter. The illusion setting makes the center stone appear larger, and the shoulders glitter with vibrant accent diamonds.\n\r\n\n\r\nHighlights:\n\r\n- Original vintage from the Mid Century\n\r\n- 0.15ct bright white and completely eye clean center diamond with F-G SI1 clarity\n\r\n- Bright white and eye clean side diamonds\n\r\n- Illusion settings make the diamonds appear larger\n\r\n- Two tone 14k white and yellow gold setting\n\r\n\n\r\nDimensions:\n\r\n6.3mm across (north-south) and 6.4mm from the finger to the top of the setting.",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Diamond",
        "etsy.ivy_and_rose.category_id": "1245",
        "shopify.ivy_and_rose.vendor": null,
        "product.cost": "10",
        "prop.Y29yZT1TaXphYmxlJmViYXk9JmV0c3k9JnNob3BpZnk9": "yes",
        "prop.Y29yZT1NaW4rc2l6ZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "4",
        "prop.Y29yZT1NYWluK3N0b25lK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1JdGVtK3R5cGUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Engagement Ring",
        "prop.Y29yZT1FcmEmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Original Vintage",
        "product.internal_title": "ring - engagement - vintage",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY29sb3ImZWJheT0mZXRzeT0mc2hvcGlmeT0": "F-G",
        "shopify.ilya_fine_jewelry.price": null,
        "prop.Y29yZT1Db2xsZWN0aW9uJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "product.notes": "6.3 x 6.4",
        "shopify.ilya_fine_jewelry.title": null,
        "product.stock_number": null,
        "etsy.ivy_and_rose.when_made": "1950s",
        "prop.Y29yZT1DYW4rYmUrcGVyc29uYWxpemVkJmViYXk9JmV0c3k9JnNob3BpZnk9": "Yes",
        "product.location": "some",
        "shopify.ilya_fine_jewelry.seo_title": null,
        "prop.Y29yZT1OYXR1cmFsJTJGbGFiLWNyZWF0ZWQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Natural",
        "ebay.ivy_and_rose.store_category2_id": "14736886",
        "shopify.ilya_fine_jewelry.vendor": null,
        "shopify.ivy_and_rose.seo_title": null,
        "prop.Y29yZT1HZW1zdG9uZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "Diamond",
        "prop.Y29yZT1TaWRlK3N0b25lJmViYXk9JmV0c3k9JnNob3BpZnk9": "Diamond",
        "shopify.ilya_fine_jewelry.collection_ids": [],
        "shopify.ilya_fine_jewelry.tags": [],
        "prop.Y29yZT1TaWRlK3N0b25lK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": "F-G",
        "ebay.ivy_and_rose.category_id": "261975",
        "prop.Y29yZT1NYWRlK3RvK29yZGVyJmViYXk9JmV0c3k9JnNob3BpZnk9": "No",
        "product.availability": 1,
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrc2hhcGUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Round",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY2xhcml0eSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "SI1",
        "prop.Y29yZT1TaWRlK3N0b25lK2NsYXJpdHkmZWJheT0mZXRzeT0mc2hvcGlmeT0": "SI2"
      },
      "id": "0ad83846-48dd-41b3-ba0e-77a543bad092",
      "sku": "19662"
    },
    {
      "data": {
        "prop.Y29yZT1Ub3RhbCtjYXJhdCt3ZWlnaHQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "1.50ct",
        "prop.Y29yZT1SZWNpcGllbnQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Women",
        "shopify.ivy_and_rose.collection_ids": [],
        "shopify.ivy_and_rose.tags": [],
        "prop.Y29yZT1TZXR0aW5nK3N0eWxlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1EaWFtb25kK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "shopify.ilya_fine_jewelry.product_type": null,
        "prop.Y29yZT1DbGFyaXR5JmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1TaWRlK3N0b25lK3NoYXBlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "etsy.ivy_and_rose.who_made": "someone_else",
        "prop.Y29yZT1TaXplJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1DZW50ZXIrbWluK3dpZHRoK2ZpdCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "etsy.ivy_and_rose.price": "50",
        "prop.Y29yZT1DZXJ0aWZpY2F0aW9uJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "etsy.ivy_and_rose.title": "1.5 Carat Victorian Diamond Drop Earrings Rose Cut Buttercup Setting Antique",
        "ebay.ivy_and_rose.store_category_id": "14737505",
        "product.main_style": null,
        "product.sku": "19549",
        "shopify.ivy_and_rose.title": null,
        "shopify.ivy_and_rose.price": null,
        "prop.Y29yZT1TaWRlK3N0b25lcytxdWFudGl0eSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "etsy.ivy_and_rose.is_supply": false,
        "prop.Y29yZT1HcmFtK3dlaWdodCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "3.6 grams",
        "prop.Y29yZT1DZW50ZXIrbWF4K2xlbmd0aCtmaXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "ebay.ivy_and_rose.condition": "USED_EXCELLENT",
        "prop.Y29yZT1TZWNvbmRhcnkrc3RvbmUmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1TaWRlK3N0b25lK2NhcmF0K3dlaWdodCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1SaW5nK3NpemUmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1NZXRhbCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "14k Yellow Gold",
        "prop.Y29yZT1UaGVtZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1BcHByb3hpbWF0ZSt0aW1lK3BlcmlvZCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "Victorian/Edwardian",
        "prop.Y29yZT1NYWluK3N0b25lK3NoYXBlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1DZW50ZXIrbWluK2xlbmd0aCtmaXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "ebay.ivy_and_rose.secondary_category_id": "262008",
        "prop.Y29yZT1NYXgrc2l6ZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "10",
        "prop.Y29yZT1GYW5jeStkaWFtb25kK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "etsy.ivy_and_rose.shop_section_id": "17821498",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY2FyYXQrd2VpZ2h0JmViYXk9JmV0c3k9JnNob3BpZnk9": "Approx. 1.50ct",
        "prop.Y29yZT1DdXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1NYXRlcmlhbCttdWx0aSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "14k Yellow Gold",
        "prop.Y29yZT1DZW50ZXIrbWF4K3dpZHRoK2ZpdCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "ebay.ivy_and_rose.sub_title": null,
        "product.additional_styles": [],
        "product.price": "50",
        "prop.Y29yZT1NZXRhbCtwdXJpdHkmZWJheT0mZXRzeT0mc2hvcGlmeT0": "14k",
        "product.title": "1.5 CARAT VICTORIAN DIAMOND DROP EARRINGS ROSE CUT BUTTERCUP SETTING ANTIQUE",
        "prop.Y29yZT1UcmVhdG1lbnQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Not Treated, Not Enhanced",
        "prop.Y29yZT1NYWluK3N0b25lJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1TdHlsZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "Dangle/Drop",
        "prop.Y29yZT1PY2Nhc2lvbiZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "shopify.ivy_and_rose.product_type": null,
        "ebay.ivy_and_rose.price": "50",
        "product.description": "Why we love it: \r\n\r\nCrafted during the Victorian era, these vintage earrings are a fantastic find featuring a substantial 1.5ct pair of rose cut diamonds set in classic and beautifully hand crafted buttercup settings.\r\n\r\n\r\n\r\nHighlights:\r\n\r\n- Original Victorian antique with French hallmarks\r\n\r\n- 1.5 carat pair of rose cut natural diamonds\r\n\r\n- Rose cuts give the diamonds a large spread, and the facets catch the light beautifully\r\n\r\n- Solid 14k yellow gold settings with classic buttercup settings and a beautiful patina\r\n\r\n- Secure ear hooks with leverbacks\r\n\r\n\r\n\r\nDimensions:\r\n\r\n18.0 x 11.8mm",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Diamondx2",
        "etsy.ivy_and_rose.category_id": "1208",
        "shopify.ivy_and_rose.vendor": null,
        "product.cost": "10",
        "prop.Y29yZT1TaXphYmxlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1NaW4rc2l6ZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "4",
        "prop.Y29yZT1NYWluK3N0b25lK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1JdGVtK3R5cGUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Drop Earrings - Dangle",
        "prop.Y29yZT1FcmEmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Original Vintage",
        "product.internal_title": null,
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY29sb3ImZWJheT0mZXRzeT0mc2hvcGlmeT0": "Salt and Pepper",
        "shopify.ilya_fine_jewelry.price": null,
        "prop.Y29yZT1Db2xsZWN0aW9uJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "product.notes": "18.0 x 11.8 (org French Hallmarks, near colorless, 1 stone has triangle naturals)",
        "shopify.ilya_fine_jewelry.title": null,
        "product.stock_number": null,
        "etsy.ivy_and_rose.when_made": "1800s",
        "prop.Y29yZT1DYW4rYmUrcGVyc29uYWxpemVkJmViYXk9JmV0c3k9JnNob3BpZnk9": "Yes",
        "product.location": "some",
        "shopify.ilya_fine_jewelry.seo_title": null,
        "prop.Y29yZT1OYXR1cmFsJTJGbGFiLWNyZWF0ZWQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Natural",
        "ebay.ivy_and_rose.store_category2_id": "14736887",
        "shopify.ilya_fine_jewelry.vendor": null,
        "shopify.ivy_and_rose.seo_title": null,
        "prop.Y29yZT1HZW1zdG9uZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "Diamondx2",
        "prop.Y29yZT1TaWRlK3N0b25lJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "shopify.ilya_fine_jewelry.collection_ids": [],
        "shopify.ilya_fine_jewelry.tags": [],
        "prop.Y29yZT1TaWRlK3N0b25lK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "ebay.ivy_and_rose.category_id": "261990",
        "prop.Y29yZT1NYWRlK3RvK29yZGVyJmViYXk9JmV0c3k9JnNob3BpZnk9": "No",
        "product.availability": 0,
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrc2hhcGUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Rose Cut",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY2xhcml0eSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "SI",
        "prop.Y29yZT1TaWRlK3N0b25lK2NsYXJpdHkmZWJheT0mZXRzeT0mc2hvcGlmeT0": null
      },
      "id": "2320c2eb-677d-4c33-b50b-5dbcdfb42795",
      "sku": "19549"
    },
    {
      "data": {
        "prop.Y29yZT1Ub3RhbCtjYXJhdCt3ZWlnaHQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "2.56ct",
        "prop.Y29yZT1SZWNpcGllbnQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Women",
        "shopify.ivy_and_rose.collection_ids": [],
        "shopify.ivy_and_rose.tags": [],
        "prop.Y29yZT1TZXR0aW5nK3N0eWxlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1EaWFtb25kK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "shopify.ilya_fine_jewelry.product_type": null,
        "prop.Y29yZT1DbGFyaXR5JmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1TaWRlK3N0b25lK3NoYXBlJmViYXk9JmV0c3k9JnNob3BpZnk9": "Round",
        "etsy.ivy_and_rose.who_made": "i_did",
        "prop.Y29yZT1TaXplJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1DZW50ZXIrbWluK3dpZHRoK2ZpdCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "etsy.ivy_and_rose.price": "50",
        "prop.Y29yZT1DZXJ0aWZpY2F0aW9uJmViYXk9JmV0c3k9JnNob3BpZnk9": "IGI",
        "etsy.ivy_and_rose.title": "Lab Created Diamond Pendant 1Ct Emerald Cut Scalloped Double Halo Necklace 14K",
        "ebay.ivy_and_rose.store_category_id": "5809673",
        "product.main_style": "Pendant",
        "product.sku": "MTO-LG-DIA-19847",
        "shopify.ivy_and_rose.title": null,
        "shopify.ivy_and_rose.price": null,
        "prop.Y29yZT1TaWRlK3N0b25lcytxdWFudGl0eSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "etsy.ivy_and_rose.is_supply": false,
        "prop.Y29yZT1HcmFtK3dlaWdodCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1DZW50ZXIrbWF4K2xlbmd0aCtmaXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "ebay.ivy_and_rose.condition": "NEW",
        "prop.Y29yZT1TZWNvbmRhcnkrc3RvbmUmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1TaWRlK3N0b25lK2NhcmF0K3dlaWdodCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "0.56ct",
        "prop.Y29yZT1SaW5nK3NpemUmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1NZXRhbCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "14k White Gold",
        "prop.Y29yZT1UaGVtZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1BcHByb3hpbWF0ZSt0aW1lK3BlcmlvZCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "New",
        "prop.Y29yZT1NYWluK3N0b25lK3NoYXBlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1DZW50ZXIrbWluK2xlbmd0aCtmaXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "ebay.ivy_and_rose.secondary_category_id": null,
        "prop.Y29yZT1NYXgrc2l6ZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "10",
        "prop.Y29yZT1GYW5jeStkaWFtb25kK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "etsy.ivy_and_rose.shop_section_id": "17869125",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY2FyYXQrd2VpZ2h0JmViYXk9JmV0c3k9JnNob3BpZnk9": "1ct (approx)",
        "prop.Y29yZT1DdXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1NYXRlcmlhbCttdWx0aSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "14k White Gold",
        "prop.Y29yZT1DZW50ZXIrbWF4K3dpZHRoK2ZpdCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "ebay.ivy_and_rose.sub_title": null,
        "product.additional_styles": [
          "Halo"
        ],
        "product.price": "50",
        "prop.Y29yZT1NZXRhbCtwdXJpdHkmZWJheT0mZXRzeT0mc2hvcGlmeT0": "14k",
        "product.title": "LAB CREATED DIAMOND PENDANT 1ct EMERALD CUT SCALLOPED DOUBLE HALO NECKLACE 14k",
        "prop.Y29yZT1UcmVhdG1lbnQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1NYWluK3N0b25lJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1TdHlsZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1PY2Nhc2lvbiZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "shopify.ivy_and_rose.product_type": null,
        "ebay.ivy_and_rose.price": "50",
        "product.description": "Why we love it: \n\r\nThis lab diamond pendant offers brilliant sparkle with an eye catching scalloped double halo design.\n\r\n\n\r\nHighlights:\n\r\n- 1 carat lab diamond center with a sleek emerald cut\n\r\n- IGI Certified\n\r\n- Bright white color and excellent clarity with grades of F VS1\n\r\n- 14k white gold halo setting\n\r\n\n\r\nDimensions:\n\r\n20.8 x 12.3mm\n\r\n\n\r\nAdditional Notes:\n\r\n- This listing is for the pendant only; a chain can be added upon request in any popular style and any length.\n\r\n- This listing reflects minimum guaranteed quality for the center diamond. The stone you receive will be the listed quality or better on all stats (carat weight, color, clarity, cut). Please contact us if you would like to see a certification for our current selection.",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Lab Grown Diamond (IGI Certified)",
        "etsy.ivy_and_rose.category_id": "1229",
        "shopify.ivy_and_rose.vendor": null,
        "product.cost": "10",
        "prop.Y29yZT1TaXphYmxlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1NaW4rc2l6ZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "4",
        "prop.Y29yZT1NYWluK3N0b25lK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1JdGVtK3R5cGUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Necklace - Pendant",
        "prop.Y29yZT1FcmEmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Modern",
        "product.internal_title": null,
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY29sb3ImZWJheT0mZXRzeT0mc2hvcGlmeT0": "F",
        "shopify.ilya_fine_jewelry.price": null,
        "prop.Y29yZT1Db2xsZWN0aW9uJmViYXk9JmV0c3k9JnNob3BpZnk9": "Allure",
        "product.notes": null,
        "shopify.ilya_fine_jewelry.title": null,
        "product.stock_number": "5x7 center",
        "etsy.ivy_and_rose.when_made": "made_to_order",
        "prop.Y29yZT1DYW4rYmUrcGVyc29uYWxpemVkJmViYXk9JmV0c3k9JnNob3BpZnk9": "Yes",
        "product.location": "some",
        "shopify.ilya_fine_jewelry.seo_title": null,
        "prop.Y29yZT1OYXR1cmFsJTJGbGFiLWNyZWF0ZWQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Lab Grown",
        "ebay.ivy_and_rose.store_category2_id": null,
        "shopify.ilya_fine_jewelry.vendor": null,
        "shopify.ivy_and_rose.seo_title": null,
        "prop.Y29yZT1HZW1zdG9uZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "Lab Grown Diamond (IGI Certified)",
        "prop.Y29yZT1TaWRlK3N0b25lJmViYXk9JmV0c3k9JnNob3BpZnk9": "Lab Grown Diamonds",
        "shopify.ilya_fine_jewelry.collection_ids": [],
        "shopify.ilya_fine_jewelry.tags": [],
        "prop.Y29yZT1TaWRlK3N0b25lK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": "E-F",
        "ebay.ivy_and_rose.category_id": "261993",
        "prop.Y29yZT1NYWRlK3RvK29yZGVyJmViYXk9JmV0c3k9JnNob3BpZnk9": "Yes",
        "product.availability": 999,
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrc2hhcGUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Emerald Cut",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY2xhcml0eSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "VS1",
        "prop.Y29yZT1TaWRlK3N0b25lK2NsYXJpdHkmZWJheT0mZXRzeT0mc2hvcGlmeT0": "VVS-VS"
      },
      "id": "78b9b14a-733c-469b-8b30-40d72de1b04d",
      "sku": "MTO-LG-DIA-19847"
    },
    {
      "data": {
        "prop.Y29yZT1Ub3RhbCtjYXJhdCt3ZWlnaHQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "2.40ct",
        "prop.Y29yZT1SZWNpcGllbnQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Women",
        "shopify.ivy_and_rose.collection_ids": [],
        "shopify.ivy_and_rose.tags": [],
        "prop.Y29yZT1TZXR0aW5nK3N0eWxlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1EaWFtb25kK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "shopify.ilya_fine_jewelry.product_type": null,
        "prop.Y29yZT1DbGFyaXR5JmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1TaWRlK3N0b25lK3NoYXBlJmViYXk9JmV0c3k9JnNob3BpZnk9": "Round",
        "etsy.ivy_and_rose.who_made": "i_did",
        "prop.Y29yZT1TaXplJmViYXk9JmV0c3k9JnNob3BpZnk9": "6.5 (Resizable)",
        "prop.Y29yZT1DZW50ZXIrbWluK3dpZHRoK2ZpdCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "etsy.ivy_and_rose.price": "50",
        "prop.Y29yZT1DZXJ0aWZpY2F0aW9uJmViYXk9JmV0c3k9JnNob3BpZnk9": "IGI",
        "etsy.ivy_and_rose.title": "2Ct Lab Created Diamond Engagement Ring Vintage Style Art Deco Oval Shape Halo",
        "ebay.ivy_and_rose.store_category_id": "14736792",
        "product.main_style": "Halo",
        "product.sku": "MTO-LG-DIA-14558",
        "shopify.ivy_and_rose.title": null,
        "shopify.ivy_and_rose.price": null,
        "prop.Y29yZT1TaWRlK3N0b25lcytxdWFudGl0eSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "etsy.ivy_and_rose.is_supply": false,
        "prop.Y29yZT1HcmFtK3dlaWdodCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1DZW50ZXIrbWF4K2xlbmd0aCtmaXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "ebay.ivy_and_rose.condition": "NEW",
        "prop.Y29yZT1TZWNvbmRhcnkrc3RvbmUmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1TaWRlK3N0b25lK2NhcmF0K3dlaWdodCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "0.40ct",
        "prop.Y29yZT1SaW5nK3NpemUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "6.5",
        "prop.Y29yZT1NZXRhbCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "14k White Gold",
        "prop.Y29yZT1UaGVtZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1BcHByb3hpbWF0ZSt0aW1lK3BlcmlvZCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "New - Vintage Reproduction",
        "prop.Y29yZT1NYWluK3N0b25lK3NoYXBlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1DZW50ZXIrbWluK2xlbmd0aCtmaXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "ebay.ivy_and_rose.secondary_category_id": null,
        "prop.Y29yZT1NYXgrc2l6ZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "10",
        "prop.Y29yZT1GYW5jeStkaWFtb25kK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "etsy.ivy_and_rose.shop_section_id": "17500487",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY2FyYXQrd2VpZ2h0JmViYXk9JmV0c3k9JnNob3BpZnk9": "2ct (approx)",
        "prop.Y29yZT1DdXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1NYXRlcmlhbCttdWx0aSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "14k White Gold",
        "prop.Y29yZT1DZW50ZXIrbWF4K3dpZHRoK2ZpdCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "ebay.ivy_and_rose.sub_title": null,
        "product.additional_styles": [],
        "product.price": "50",
        "prop.Y29yZT1NZXRhbCtwdXJpdHkmZWJheT0mZXRzeT0mc2hvcGlmeT0": "14k",
        "product.title": "2ct LAB CREATED DIAMOND ENGAGEMENT RING VINTAGE STYLE ART DECO OVAL SHAPE HALO",
        "prop.Y29yZT1UcmVhdG1lbnQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1NYWluK3N0b25lJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1TdHlsZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1PY2Nhc2lvbiZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "shopify.ivy_and_rose.product_type": null,
        "ebay.ivy_and_rose.price": "50",
        "product.description": "Why we love it: \n\r\nThis vintage inspired lab grown diamond ring features a substantial 2 carat lab grown diamond surrounded by an octagonal diamond halo! The geometric elements add a touch of Art Deco flair to this classic design.\n\r\n\n\r\nHighlights:\n\r\n- Substantial 2ct oval cut lab diamond center\n\r\n- IGI Certified\n\r\n- Colorless and very clean with grades of F VS2\n\r\n- 0.40ct of lab diamond accents\n\r\n- Chic Art Deco style design\n\r\n- Classic 14k white gold setting\n\r\n\n\r\nDimensions:\n\r\nThe ring measures 14.3mm across (north south) and 5mm from finger to top.\n\r\n\n\r\nAdditional notes:\n\r\nThis listing reflects minimum guaranteed quality for the center diamond. The stone you receive will be the listed quality or better on all stats (carat weight, color, clarity, cut). Please contact us if you would like to see a certification for our current selection.",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Lab Grown Diamond (IGI Certified)",
        "etsy.ivy_and_rose.category_id": "1245",
        "shopify.ivy_and_rose.vendor": null,
        "product.cost": "10",
        "prop.Y29yZT1TaXphYmxlJmViYXk9JmV0c3k9JnNob3BpZnk9": "yes",
        "prop.Y29yZT1NaW4rc2l6ZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "4",
        "prop.Y29yZT1NYWluK3N0b25lK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1JdGVtK3R5cGUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Engagement Ring",
        "prop.Y29yZT1FcmEmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Vintage Inspired",
        "product.internal_title": null,
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY29sb3ImZWJheT0mZXRzeT0mc2hvcGlmeT0": "F",
        "shopify.ilya_fine_jewelry.price": null,
        "prop.Y29yZT1Db2xsZWN0aW9uJmViYXk9JmV0c3k9JnNob3BpZnk9": "Harmony",
        "product.notes": null,
        "shopify.ilya_fine_jewelry.title": null,
        "product.stock_number": null,
        "etsy.ivy_and_rose.when_made": "made_to_order",
        "prop.Y29yZT1DYW4rYmUrcGVyc29uYWxpemVkJmViYXk9JmV0c3k9JnNob3BpZnk9": "Yes",
        "product.location": "some",
        "shopify.ilya_fine_jewelry.seo_title": null,
        "prop.Y29yZT1OYXR1cmFsJTJGbGFiLWNyZWF0ZWQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Lab Grown",
        "ebay.ivy_and_rose.store_category2_id": "14736886",
        "shopify.ilya_fine_jewelry.vendor": null,
        "shopify.ivy_and_rose.seo_title": null,
        "prop.Y29yZT1HZW1zdG9uZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "Lab Grown Diamond (IGI Certified)",
        "prop.Y29yZT1TaWRlK3N0b25lJmViYXk9JmV0c3k9JnNob3BpZnk9": "Lab Grown Diamonds",
        "shopify.ilya_fine_jewelry.collection_ids": [],
        "shopify.ilya_fine_jewelry.tags": [],
        "prop.Y29yZT1TaWRlK3N0b25lK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": "E-F",
        "ebay.ivy_and_rose.category_id": "261975",
        "prop.Y29yZT1NYWRlK3RvK29yZGVyJmViYXk9JmV0c3k9JnNob3BpZnk9": "Yes",
        "product.availability": 999,
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrc2hhcGUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Oval",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY2xhcml0eSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "VS2",
        "prop.Y29yZT1TaWRlK3N0b25lK2NsYXJpdHkmZWJheT0mZXRzeT0mc2hvcGlmeT0": "VS"
      },
      "id": "b69068f4-9091-4b2d-ad41-aa6116dac065",
      "sku": "MTO-LG-DIA-14558"
    },
    {
      "data": {
        "prop.Y29yZT1Ub3RhbCtjYXJhdCt3ZWlnaHQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "2.40ct",
        "prop.Y29yZT1SZWNpcGllbnQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Women",
        "shopify.ivy_and_rose.collection_ids": [],
        "shopify.ivy_and_rose.tags": [],
        "prop.Y29yZT1TZXR0aW5nK3N0eWxlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1EaWFtb25kK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "shopify.ilya_fine_jewelry.product_type": null,
        "prop.Y29yZT1DbGFyaXR5JmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1TaWRlK3N0b25lK3NoYXBlJmViYXk9JmV0c3k9JnNob3BpZnk9": "Round",
        "etsy.ivy_and_rose.who_made": "i_did",
        "prop.Y29yZT1TaXplJmViYXk9JmV0c3k9JnNob3BpZnk9": "6.5 (Resizable)",
        "prop.Y29yZT1DZW50ZXIrbWluK3dpZHRoK2ZpdCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "etsy.ivy_and_rose.price": "50",
        "prop.Y29yZT1DZXJ0aWZpY2F0aW9uJmViYXk9JmV0c3k9JnNob3BpZnk9": "IGI",
        "etsy.ivy_and_rose.title": "2 Carat Certified Lab Created Diamond Engagement Ring Oval Shape Halo Pave Band",
        "ebay.ivy_and_rose.store_category_id": "14736792",
        "product.main_style": "Halo",
        "product.sku": "MTO-LG-DIA-12572",
        "shopify.ivy_and_rose.title": null,
        "shopify.ivy_and_rose.price": null,
        "prop.Y29yZT1TaWRlK3N0b25lcytxdWFudGl0eSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "etsy.ivy_and_rose.is_supply": false,
        "prop.Y29yZT1HcmFtK3dlaWdodCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1DZW50ZXIrbWF4K2xlbmd0aCtmaXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "ebay.ivy_and_rose.condition": "NEW",
        "prop.Y29yZT1TZWNvbmRhcnkrc3RvbmUmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1TaWRlK3N0b25lK2NhcmF0K3dlaWdodCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "0.40ct",
        "prop.Y29yZT1SaW5nK3NpemUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "6.5",
        "prop.Y29yZT1NZXRhbCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "14k White Gold",
        "prop.Y29yZT1UaGVtZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1BcHByb3hpbWF0ZSt0aW1lK3BlcmlvZCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "New",
        "prop.Y29yZT1NYWluK3N0b25lK3NoYXBlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1DZW50ZXIrbWluK2xlbmd0aCtmaXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "ebay.ivy_and_rose.secondary_category_id": "261994",
        "prop.Y29yZT1NYXgrc2l6ZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "10",
        "prop.Y29yZT1GYW5jeStkaWFtb25kK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "etsy.ivy_and_rose.shop_section_id": "17500487",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY2FyYXQrd2VpZ2h0JmViYXk9JmV0c3k9JnNob3BpZnk9": "2ct (approx)",
        "prop.Y29yZT1DdXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1NYXRlcmlhbCttdWx0aSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "14k White Gold",
        "prop.Y29yZT1DZW50ZXIrbWF4K3dpZHRoK2ZpdCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "ebay.ivy_and_rose.sub_title": null,
        "product.additional_styles": [],
        "product.price": "50",
        "prop.Y29yZT1NZXRhbCtwdXJpdHkmZWJheT0mZXRzeT0mc2hvcGlmeT0": "14k",
        "product.title": "2 CARAT CERTIFIED LAB CREATED DIAMOND ENGAGEMENT RING OVAL SHAPE HALO PAVE BAND",
        "prop.Y29yZT1UcmVhdG1lbnQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1NYWluK3N0b25lJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1TdHlsZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1PY2Nhc2lvbiZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "shopify.ivy_and_rose.product_type": null,
        "ebay.ivy_and_rose.price": "50",
        "product.description": "Why we love it: \n\r\nThis classic diamond accented ring is beautifully designed to showcase the 2 carat lab grown diamond center stone. The oval shape lab grown diamond is encircled by a bright halo of additional lab grown diamonds for a bright and bold look!\n\r\n\n\r\nHighlights:\n\r\n- Substantial 2ct oval cut lab diamond center\n\r\n- IGI Certified\n\r\n- Colorless and very clean with grades of F VS2\n\r\n- 0.40ct of lab diamond accents\n\r\n- Classic 14k white gold halo setting\n\r\n\n\r\nAdditional notes:\n\r\nThis listing reflects minimum guaranteed quality for the center diamond. The stone you receive will be the listed quality or better on all stats (carat weight, color, clarity, cut). Please contact us if you would like to see a certification for our current selection.",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Lab Grown Diamond (IGI Certified)",
        "etsy.ivy_and_rose.category_id": "1245",
        "shopify.ivy_and_rose.vendor": null,
        "product.cost": "10",
        "prop.Y29yZT1TaXphYmxlJmViYXk9JmV0c3k9JnNob3BpZnk9": "yes",
        "prop.Y29yZT1NaW4rc2l6ZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "4",
        "prop.Y29yZT1NYWluK3N0b25lK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1JdGVtK3R5cGUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Engagement Ring",
        "prop.Y29yZT1FcmEmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Modern",
        "product.internal_title": null,
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY29sb3ImZWJheT0mZXRzeT0mc2hvcGlmeT0": "F",
        "shopify.ilya_fine_jewelry.price": null,
        "prop.Y29yZT1Db2xsZWN0aW9uJmViYXk9JmV0c3k9JnNob3BpZnk9": "Allure",
        "product.notes": null,
        "shopify.ilya_fine_jewelry.title": null,
        "product.stock_number": null,
        "etsy.ivy_and_rose.when_made": "made_to_order",
        "prop.Y29yZT1DYW4rYmUrcGVyc29uYWxpemVkJmViYXk9JmV0c3k9JnNob3BpZnk9": "Yes",
        "product.location": "some",
        "shopify.ilya_fine_jewelry.seo_title": null,
        "prop.Y29yZT1OYXR1cmFsJTJGbGFiLWNyZWF0ZWQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Lab Grown",
        "ebay.ivy_and_rose.store_category2_id": "14736870",
        "shopify.ilya_fine_jewelry.vendor": null,
        "shopify.ivy_and_rose.seo_title": null,
        "prop.Y29yZT1HZW1zdG9uZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "Lab Grown Diamond (IGI Certified)",
        "prop.Y29yZT1TaWRlK3N0b25lJmViYXk9JmV0c3k9JnNob3BpZnk9": "Lab Grown Diamonds",
        "shopify.ilya_fine_jewelry.collection_ids": [],
        "shopify.ilya_fine_jewelry.tags": [],
        "prop.Y29yZT1TaWRlK3N0b25lK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": "E-F",
        "ebay.ivy_and_rose.category_id": "261975",
        "prop.Y29yZT1NYWRlK3RvK29yZGVyJmViYXk9JmV0c3k9JnNob3BpZnk9": "Yes",
        "product.availability": 999,
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrc2hhcGUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Oval",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY2xhcml0eSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "VS2",
        "prop.Y29yZT1TaWRlK3N0b25lK2NsYXJpdHkmZWJheT0mZXRzeT0mc2hvcGlmeT0": "VVS-VS"
      },
      "id": "bf0b5c9a-7504-430d-a3ff-a2505c35a08a",
      "sku": "MTO-LG-DIA-12572"
    },
    {
      "data": {
        "prop.Y29yZT1Ub3RhbCtjYXJhdCt3ZWlnaHQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "1.31ct",
        "prop.Y29yZT1SZWNpcGllbnQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Women",
        "shopify.ivy_and_rose.collection_ids": [],
        "shopify.ivy_and_rose.tags": [],
        "prop.Y29yZT1TZXR0aW5nK3N0eWxlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1EaWFtb25kK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "shopify.ilya_fine_jewelry.product_type": null,
        "prop.Y29yZT1DbGFyaXR5JmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1TaWRlK3N0b25lK3NoYXBlJmViYXk9JmV0c3k9JnNob3BpZnk9": "Sapphires: Custom Cut, Diamonds: Round",
        "etsy.ivy_and_rose.who_made": "i_did",
        "prop.Y29yZT1TaXplJmViYXk9JmV0c3k9JnNob3BpZnk9": "6.5 (Resizable)",
        "prop.Y29yZT1DZW50ZXIrbWluK3dpZHRoK2ZpdCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "etsy.ivy_and_rose.price": "50",
        "prop.Y29yZT1DZXJ0aWZpY2F0aW9uJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "etsy.ivy_and_rose.title": "1.31Ct Lab Created Diamond Sapphire Cocktail Ring Vintage Style 14K White Gold",
        "ebay.ivy_and_rose.store_category_id": "14736792",
        "product.main_style": "Halo",
        "product.sku": "MTO-LG-DIA-12568-BS",
        "shopify.ivy_and_rose.title": null,
        "shopify.ivy_and_rose.price": null,
        "prop.Y29yZT1TaWRlK3N0b25lcytxdWFudGl0eSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "etsy.ivy_and_rose.is_supply": false,
        "prop.Y29yZT1HcmFtK3dlaWdodCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1DZW50ZXIrbWF4K2xlbmd0aCtmaXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "ebay.ivy_and_rose.condition": "NEW",
        "prop.Y29yZT1TZWNvbmRhcnkrc3RvbmUmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1TaWRlK3N0b25lK2NhcmF0K3dlaWdodCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "Sapphires: 0.44ct; Diamonds: 0.12ct",
        "prop.Y29yZT1SaW5nK3NpemUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "6.5",
        "prop.Y29yZT1NZXRhbCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "14k White Gold",
        "prop.Y29yZT1UaGVtZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1BcHByb3hpbWF0ZSt0aW1lK3BlcmlvZCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "New - Vintage Reproduction",
        "prop.Y29yZT1NYWluK3N0b25lK3NoYXBlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1DZW50ZXIrbWluK2xlbmd0aCtmaXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "ebay.ivy_and_rose.secondary_category_id": null,
        "prop.Y29yZT1NYXgrc2l6ZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "10",
        "prop.Y29yZT1GYW5jeStkaWFtb25kK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "etsy.ivy_and_rose.shop_section_id": "20281911",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY2FyYXQrd2VpZ2h0JmViYXk9JmV0c3k9JnNob3BpZnk9": "0.75ct",
        "prop.Y29yZT1DdXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1NYXRlcmlhbCttdWx0aSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "14k White Gold",
        "prop.Y29yZT1DZW50ZXIrbWF4K3dpZHRoK2ZpdCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "ebay.ivy_and_rose.sub_title": null,
        "product.additional_styles": [
          "Dome"
        ],
        "product.price": "50",
        "prop.Y29yZT1NZXRhbCtwdXJpdHkmZWJheT0mZXRzeT0mc2hvcGlmeT0": "14k",
        "product.title": "1.31ct LAB CREATED DIAMOND SAPPHIRE COCKTAIL RING VINTAGE STYLE 14k WHITE GOLD",
        "prop.Y29yZT1UcmVhdG1lbnQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1NYWluK3N0b25lJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1TdHlsZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1PY2Nhc2lvbiZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "shopify.ivy_and_rose.product_type": null,
        "ebay.ivy_and_rose.price": "50",
        "product.description": "Why we love it: \n\r\nThis lab diamond and sapphire ring offers a stunning Art Deco style design, rich color, and gorgeous, substantial sparkle! Featuring a high quality round cut lab grown diamond, the ring has a beautifully crafted oval design set with brilliantly white and vibrant natural diamonds as well as rich blue custom cut natural sapphires.\n\r\n\n\r\nHighlights:\n\r\n- 0.75ct lab diamond center with an ideal cut\n\r\n- Very bright white color and exceptionally clean with grades of G VS2\n\r\n- Calibre cut natural sapphires\n\r\n- Natural diamond accent stones\n\r\n- Unique and elegant vintage style design\n\r\n- 14k white gold setting\n\r\n\n\r\nDimensions:\n\r\nThe ring measures 13.2mm across (north south) and 4.0mm from the finger to the top of the setting.",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Lab Grown Diamond",
        "etsy.ivy_and_rose.category_id": "1245",
        "shopify.ivy_and_rose.vendor": null,
        "product.cost": "10",
        "prop.Y29yZT1TaXphYmxlJmViYXk9JmV0c3k9JnNob3BpZnk9": "yes",
        "prop.Y29yZT1NaW4rc2l6ZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "4",
        "prop.Y29yZT1NYWluK3N0b25lK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1JdGVtK3R5cGUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Engagement Ring",
        "prop.Y29yZT1FcmEmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Vintage Inspired",
        "product.internal_title": null,
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY29sb3ImZWJheT0mZXRzeT0mc2hvcGlmeT0": "G",
        "shopify.ilya_fine_jewelry.price": null,
        "prop.Y29yZT1Db2xsZWN0aW9uJmViYXk9JmV0c3k9JnNob3BpZnk9": "Harmony",
        "product.notes": null,
        "shopify.ilya_fine_jewelry.title": null,
        "product.stock_number": null,
        "etsy.ivy_and_rose.when_made": "made_to_order",
        "prop.Y29yZT1DYW4rYmUrcGVyc29uYWxpemVkJmViYXk9JmV0c3k9JnNob3BpZnk9": "Yes",
        "product.location": "some",
        "shopify.ilya_fine_jewelry.seo_title": null,
        "prop.Y29yZT1OYXR1cmFsJTJGbGFiLWNyZWF0ZWQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Lab Grown",
        "ebay.ivy_and_rose.store_category2_id": "14736886",
        "shopify.ilya_fine_jewelry.vendor": null,
        "shopify.ivy_and_rose.seo_title": null,
        "prop.Y29yZT1HZW1zdG9uZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "Lab Grown Diamond",
        "prop.Y29yZT1TaWRlK3N0b25lJmViYXk9JmV0c3k9JnNob3BpZnk9": "Sapphires, Diamonds",
        "shopify.ilya_fine_jewelry.collection_ids": [],
        "shopify.ilya_fine_jewelry.tags": [],
        "prop.Y29yZT1TaWRlK3N0b25lK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": "Blue, G-H",
        "ebay.ivy_and_rose.category_id": "261975",
        "prop.Y29yZT1NYWRlK3RvK29yZGVyJmViYXk9JmV0c3k9JnNob3BpZnk9": "Yes",
        "product.availability": 2,
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrc2hhcGUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Round",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY2xhcml0eSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "VS2",
        "prop.Y29yZT1TaWRlK3N0b25lK2NsYXJpdHkmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Diamonds: VS1-SI1"
      },
      "id": "1e68adb3-10e5-4de5-b52a-a0fb0ac9ee10",
      "sku": "MTO-LG-DIA-12568-BS"
    },
    {
      "data": {
        "prop.Y29yZT1Ub3RhbCtjYXJhdCt3ZWlnaHQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "1.76ct",
        "prop.Y29yZT1SZWNpcGllbnQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Women",
        "shopify.ivy_and_rose.collection_ids": [],
        "shopify.ivy_and_rose.tags": [],
        "prop.Y29yZT1TZXR0aW5nK3N0eWxlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1EaWFtb25kK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "shopify.ilya_fine_jewelry.product_type": null,
        "prop.Y29yZT1DbGFyaXR5JmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1TaWRlK3N0b25lK3NoYXBlJmViYXk9JmV0c3k9JnNob3BpZnk9": "Emerald: Custom Cut, Diamonds: Round",
        "etsy.ivy_and_rose.who_made": "i_did",
        "prop.Y29yZT1TaXplJmViYXk9JmV0c3k9JnNob3BpZnk9": "6.5 (Resizable)",
        "prop.Y29yZT1DZW50ZXIrbWluK3dpZHRoK2ZpdCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "etsy.ivy_and_rose.price": "50",
        "prop.Y29yZT1DZXJ0aWZpY2F0aW9uJmViYXk9JmV0c3k9JnNob3BpZnk9": "IGI",
        "etsy.ivy_and_rose.title": "1.20Ct Lab Created Diamond Emerald Engagement Ring Vintage Style 14K White Gold",
        "ebay.ivy_and_rose.store_category_id": "14736792",
        "product.main_style": "Halo",
        "product.sku": "MTO-LG-DIA-12565-EM",
        "shopify.ivy_and_rose.title": null,
        "shopify.ivy_and_rose.price": null,
        "prop.Y29yZT1TaWRlK3N0b25lcytxdWFudGl0eSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "etsy.ivy_and_rose.is_supply": false,
        "prop.Y29yZT1HcmFtK3dlaWdodCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1DZW50ZXIrbWF4K2xlbmd0aCtmaXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "ebay.ivy_and_rose.condition": "NEW",
        "prop.Y29yZT1TZWNvbmRhcnkrc3RvbmUmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1TaWRlK3N0b25lK2NhcmF0K3dlaWdodCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "Emerald: 0.51ct; Diamonds: 0.05ct",
        "prop.Y29yZT1SaW5nK3NpemUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "6.5",
        "prop.Y29yZT1NZXRhbCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "14k White Gold",
        "prop.Y29yZT1UaGVtZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1BcHByb3hpbWF0ZSt0aW1lK3BlcmlvZCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "New - Vintage Reproduction",
        "prop.Y29yZT1NYWluK3N0b25lK3NoYXBlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1DZW50ZXIrbWluK2xlbmd0aCtmaXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "ebay.ivy_and_rose.secondary_category_id": null,
        "prop.Y29yZT1NYXgrc2l6ZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "10",
        "prop.Y29yZT1GYW5jeStkaWFtb25kK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "etsy.ivy_and_rose.shop_section_id": "20281911",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY2FyYXQrd2VpZ2h0JmViYXk9JmV0c3k9JnNob3BpZnk9": "1.20ct (approx)",
        "prop.Y29yZT1DdXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1NYXRlcmlhbCttdWx0aSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "14k White Gold",
        "prop.Y29yZT1DZW50ZXIrbWF4K3dpZHRoK2ZpdCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "ebay.ivy_and_rose.sub_title": null,
        "product.additional_styles": [
          "Dome"
        ],
        "product.price": "50",
        "prop.Y29yZT1NZXRhbCtwdXJpdHkmZWJheT0mZXRzeT0mc2hvcGlmeT0": "14k",
        "product.title": "1.20ct LAB CREATED DIAMOND EMERALD ENGAGEMENT RING VINTAGE STYLE 14k WHITE GOLD",
        "prop.Y29yZT1UcmVhdG1lbnQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1NYWluK3N0b25lJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1TdHlsZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1PY2Nhc2lvbiZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "shopify.ivy_and_rose.product_type": null,
        "ebay.ivy_and_rose.price": "50",
        "product.description": "Why we love it: \n\r\nThis lab grown diamond and emerald ring offers a stunning Art Deco style design, rich color, and gorgeous, substantial sparkle! It is an excellent choice for an engagement or cocktail ring.\n\r\n\n\r\nHighlights:\n\r\n- 1.20ct lab diamond center with an ideal cut\n\r\n- IGI certified\n\r\n- Very bright white color and exceptionally clean with grades of G VS2\n\r\n- Calibre cut natural emerald\n\r\n- Natural diamond accent stones\n\r\n- Unique and elegant vintage style design\n\r\n\n\r\nDimensions:\n\r\nThe ring measures 9.2mm across (north south) and 4.1mm from finger to top\n\r\n\n\r\nAdditional notes:\n\r\nThis listing reflects minimum guaranteed quality for the center diamond. The stone you receive will be the listed quality or better on all stats (carat weight, color, clarity, cut). Please contact us if you would like to see a certification for our current selection.",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Lab Grown Diamond (IGI Certified)",
        "etsy.ivy_and_rose.category_id": "1245",
        "shopify.ivy_and_rose.vendor": null,
        "product.cost": "10",
        "prop.Y29yZT1TaXphYmxlJmViYXk9JmV0c3k9JnNob3BpZnk9": "yes",
        "prop.Y29yZT1NaW4rc2l6ZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "4",
        "prop.Y29yZT1NYWluK3N0b25lK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1JdGVtK3R5cGUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Engagement Ring",
        "prop.Y29yZT1FcmEmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Vintage Inspired",
        "product.internal_title": null,
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY29sb3ImZWJheT0mZXRzeT0mc2hvcGlmeT0": "G",
        "shopify.ilya_fine_jewelry.price": null,
        "prop.Y29yZT1Db2xsZWN0aW9uJmViYXk9JmV0c3k9JnNob3BpZnk9": "Harmony",
        "product.notes": null,
        "shopify.ilya_fine_jewelry.title": null,
        "product.stock_number": null,
        "etsy.ivy_and_rose.when_made": "made_to_order",
        "prop.Y29yZT1DYW4rYmUrcGVyc29uYWxpemVkJmViYXk9JmV0c3k9JnNob3BpZnk9": "Yes",
        "product.location": "some",
        "shopify.ilya_fine_jewelry.seo_title": null,
        "prop.Y29yZT1OYXR1cmFsJTJGbGFiLWNyZWF0ZWQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Lab Grown",
        "ebay.ivy_and_rose.store_category2_id": "14736886",
        "shopify.ilya_fine_jewelry.vendor": null,
        "shopify.ivy_and_rose.seo_title": null,
        "prop.Y29yZT1HZW1zdG9uZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "Lab Grown Diamond (IGI Certified)",
        "prop.Y29yZT1TaWRlK3N0b25lJmViYXk9JmV0c3k9JnNob3BpZnk9": "Emerald, Diamonds",
        "shopify.ilya_fine_jewelry.collection_ids": [],
        "shopify.ilya_fine_jewelry.tags": [],
        "prop.Y29yZT1TaWRlK3N0b25lK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": "Green, G-H",
        "ebay.ivy_and_rose.category_id": "261975",
        "prop.Y29yZT1NYWRlK3RvK29yZGVyJmViYXk9JmV0c3k9JnNob3BpZnk9": "Yes",
        "product.availability": 1,
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrc2hhcGUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Round (Excellent Cut)",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY2xhcml0eSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "VS2",
        "prop.Y29yZT1TaWRlK3N0b25lK2NsYXJpdHkmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Diamonds: VS1-SI1"
      },
      "id": "b065691d-5394-46a8-be17-13087ee7dba5",
      "sku": "MTO-LG-DIA-12565-EM"
    },
    {
      "data": {
        "prop.Y29yZT1Ub3RhbCtjYXJhdCt3ZWlnaHQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "2.05ct",
        "prop.Y29yZT1SZWNpcGllbnQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Women",
        "shopify.ivy_and_rose.collection_ids": [],
        "shopify.ivy_and_rose.tags": [],
        "prop.Y29yZT1TZXR0aW5nK3N0eWxlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1EaWFtb25kK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "shopify.ilya_fine_jewelry.product_type": null,
        "prop.Y29yZT1DbGFyaXR5JmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1TaWRlK3N0b25lK3NoYXBlJmViYXk9JmV0c3k9JnNob3BpZnk9": "Sapphires: Custom Cut, Diamonds: Round",
        "etsy.ivy_and_rose.who_made": "i_did",
        "prop.Y29yZT1TaXplJmViYXk9JmV0c3k9JnNob3BpZnk9": "6.5 (Resizable)",
        "prop.Y29yZT1DZW50ZXIrbWluK3dpZHRoK2ZpdCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "etsy.ivy_and_rose.price": "50",
        "prop.Y29yZT1DZXJ0aWZpY2F0aW9uJmViYXk9JmV0c3k9JnNob3BpZnk9": "IGI",
        "etsy.ivy_and_rose.title": "1.20Ct Lab Created Diamond Sapphire Engagement Ring Vintage Style 14K White Gold",
        "ebay.ivy_and_rose.store_category_id": "14736792",
        "product.main_style": "Halo",
        "product.sku": "MTO-LG-DIA-12565-BS",
        "shopify.ivy_and_rose.title": null,
        "shopify.ivy_and_rose.price": null,
        "prop.Y29yZT1TaWRlK3N0b25lcytxdWFudGl0eSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "etsy.ivy_and_rose.is_supply": false,
        "prop.Y29yZT1HcmFtK3dlaWdodCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1DZW50ZXIrbWF4K2xlbmd0aCtmaXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "ebay.ivy_and_rose.condition": "NEW",
        "prop.Y29yZT1TZWNvbmRhcnkrc3RvbmUmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1TaWRlK3N0b25lK2NhcmF0K3dlaWdodCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "Sapphires: 0.80ct; Diamonds: 0.05ct",
        "prop.Y29yZT1SaW5nK3NpemUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "6.5",
        "prop.Y29yZT1NZXRhbCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "14k White Gold",
        "prop.Y29yZT1UaGVtZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1BcHByb3hpbWF0ZSt0aW1lK3BlcmlvZCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "New - Vintage Reproduction",
        "prop.Y29yZT1NYWluK3N0b25lK3NoYXBlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1DZW50ZXIrbWluK2xlbmd0aCtmaXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "ebay.ivy_and_rose.secondary_category_id": null,
        "prop.Y29yZT1NYXgrc2l6ZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "10",
        "prop.Y29yZT1GYW5jeStkaWFtb25kK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "etsy.ivy_and_rose.shop_section_id": "20281911",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY2FyYXQrd2VpZ2h0JmViYXk9JmV0c3k9JnNob3BpZnk9": "1.20ct (approx)",
        "prop.Y29yZT1DdXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1NYXRlcmlhbCttdWx0aSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "14k White Gold",
        "prop.Y29yZT1DZW50ZXIrbWF4K3dpZHRoK2ZpdCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "ebay.ivy_and_rose.sub_title": null,
        "product.additional_styles": [
          "Dome"
        ],
        "product.price": "50",
        "prop.Y29yZT1NZXRhbCtwdXJpdHkmZWJheT0mZXRzeT0mc2hvcGlmeT0": "14k",
        "product.title": "1.20ct LAB CREATED DIAMOND SAPPHIRE ENGAGEMENT RING VINTAGE STYLE 14k WHITE GOLD",
        "prop.Y29yZT1UcmVhdG1lbnQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1NYWluK3N0b25lJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1TdHlsZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1PY2Nhc2lvbiZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "shopify.ivy_and_rose.product_type": null,
        "ebay.ivy_and_rose.price": "50",
        "product.description": "Why we love it: \n\r\nThis lab grown diamond and sapphire ring offers a stunning Art Deco style design, rich color, and gorgeous, substantial sparkle! It is an excellent choice for an engagement or cocktail ring.\n\r\n\n\r\nHighlights:\n\r\n- 1.20ct lab diamond center with an ideal cut\n\r\n- IGI certified\n\r\n- Very bright white color and exceptionally clean with grades of G VS2\n\r\n- Calibre cut natural sapphires\n\r\n- Natural diamond accent stones\n\r\n- Unique and elegant vintage style design\n\r\n\n\r\nDimensions:\n\r\nThe ring measures 9.2mm across (north south) and 4.1mm from finger to top\n\r\n\n\r\nAdditional notes:\n\r\nThis listing reflects minimum guaranteed quality for the center diamond. The stone you receive will be the listed quality or better on all stats (carat weight, color, clarity, cut). Please contact us if you would like to see a certification for our current selection.",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Lab Grown Diamond (IGI Certified)",
        "etsy.ivy_and_rose.category_id": "1245",
        "shopify.ivy_and_rose.vendor": null,
        "product.cost": "10",
        "prop.Y29yZT1TaXphYmxlJmViYXk9JmV0c3k9JnNob3BpZnk9": "yes",
        "prop.Y29yZT1NaW4rc2l6ZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "4",
        "prop.Y29yZT1NYWluK3N0b25lK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1JdGVtK3R5cGUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Engagement Ring",
        "prop.Y29yZT1FcmEmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Vintage Inspired",
        "product.internal_title": null,
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY29sb3ImZWJheT0mZXRzeT0mc2hvcGlmeT0": "G",
        "shopify.ilya_fine_jewelry.price": null,
        "prop.Y29yZT1Db2xsZWN0aW9uJmViYXk9JmV0c3k9JnNob3BpZnk9": "Harmony",
        "product.notes": null,
        "shopify.ilya_fine_jewelry.title": null,
        "product.stock_number": null,
        "etsy.ivy_and_rose.when_made": "made_to_order",
        "prop.Y29yZT1DYW4rYmUrcGVyc29uYWxpemVkJmViYXk9JmV0c3k9JnNob3BpZnk9": "Yes",
        "product.location": "some",
        "shopify.ilya_fine_jewelry.seo_title": null,
        "prop.Y29yZT1OYXR1cmFsJTJGbGFiLWNyZWF0ZWQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Lab Grown",
        "ebay.ivy_and_rose.store_category2_id": "14736886",
        "shopify.ilya_fine_jewelry.vendor": null,
        "shopify.ivy_and_rose.seo_title": null,
        "prop.Y29yZT1HZW1zdG9uZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "Lab Grown Diamond (IGI Certified)",
        "prop.Y29yZT1TaWRlK3N0b25lJmViYXk9JmV0c3k9JnNob3BpZnk9": "Sapphires, Diamonds",
        "shopify.ilya_fine_jewelry.collection_ids": [],
        "shopify.ilya_fine_jewelry.tags": [],
        "prop.Y29yZT1TaWRlK3N0b25lK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": "Blue, G-H",
        "ebay.ivy_and_rose.category_id": "261975",
        "prop.Y29yZT1NYWRlK3RvK29yZGVyJmViYXk9JmV0c3k9JnNob3BpZnk9": "Yes",
        "product.availability": 1,
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrc2hhcGUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Round (Excellent Cut)",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY2xhcml0eSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "VS2",
        "prop.Y29yZT1TaWRlK3N0b25lK2NsYXJpdHkmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Diamonds: VS1-SI1"
      },
      "id": "e24a4513-4d4e-445e-8cce-679e04dc33c9",
      "sku": "MTO-LG-DIA-12565-BS"
    },
    {
      "data": {
        "prop.Y29yZT1Ub3RhbCtjYXJhdCt3ZWlnaHQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "2ctw",
        "prop.Y29yZT1SZWNpcGllbnQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Women",
        "shopify.ivy_and_rose.collection_ids": [],
        "shopify.ivy_and_rose.tags": [],
        "prop.Y29yZT1TZXR0aW5nK3N0eWxlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1EaWFtb25kK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "shopify.ilya_fine_jewelry.product_type": null,
        "prop.Y29yZT1DbGFyaXR5JmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1TaWRlK3N0b25lK3NoYXBlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "etsy.ivy_and_rose.who_made": "i_did",
        "prop.Y29yZT1TaXplJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1DZW50ZXIrbWluK3dpZHRoK2ZpdCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "etsy.ivy_and_rose.price": "50",
        "prop.Y29yZT1DZXJ0aWZpY2F0aW9uJmViYXk9JmV0c3k9JnNob3BpZnk9": "IGI",
        "etsy.ivy_and_rose.title": "2 Carat Lab Created Diamond Drop Earrings Huggie Hoop Emerald Cut Minimalist 14K",
        "ebay.ivy_and_rose.store_category_id": "14737505",
        "product.main_style": "Hoop &amp; Huggie",
        "product.sku": "MTO-LG-15957-2ct",
        "shopify.ivy_and_rose.title": null,
        "shopify.ivy_and_rose.price": null,
        "prop.Y29yZT1TaWRlK3N0b25lcytxdWFudGl0eSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "etsy.ivy_and_rose.is_supply": false,
        "prop.Y29yZT1HcmFtK3dlaWdodCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1DZW50ZXIrbWF4K2xlbmd0aCtmaXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "ebay.ivy_and_rose.condition": "NEW",
        "prop.Y29yZT1TZWNvbmRhcnkrc3RvbmUmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1TaWRlK3N0b25lK2NhcmF0K3dlaWdodCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1SaW5nK3NpemUmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1NZXRhbCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "14k White Gold",
        "prop.Y29yZT1UaGVtZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1BcHByb3hpbWF0ZSt0aW1lK3BlcmlvZCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "New",
        "prop.Y29yZT1NYWluK3N0b25lK3NoYXBlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1DZW50ZXIrbWluK2xlbmd0aCtmaXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "ebay.ivy_and_rose.secondary_category_id": null,
        "prop.Y29yZT1NYXgrc2l6ZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "10",
        "prop.Y29yZT1GYW5jeStkaWFtb25kK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "etsy.ivy_and_rose.shop_section_id": "17821498",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY2FyYXQrd2VpZ2h0JmViYXk9JmV0c3k9JnNob3BpZnk9": "2ctw (approx)",
        "prop.Y29yZT1DdXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1NYXRlcmlhbCttdWx0aSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "14k White Gold",
        "prop.Y29yZT1DZW50ZXIrbWF4K3dpZHRoK2ZpdCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "ebay.ivy_and_rose.sub_title": null,
        "product.additional_styles": [],
        "product.price": "50",
        "prop.Y29yZT1NZXRhbCtwdXJpdHkmZWJheT0mZXRzeT0mc2hvcGlmeT0": "14k",
        "product.title": "2 CARAT LAB CREATED DIAMOND DROP EARRINGS HUGGIE HOOP EMERALD CUT MINIMALIST 14k",
        "prop.Y29yZT1UcmVhdG1lbnQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1NYWluK3N0b25lJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1TdHlsZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1PY2Nhc2lvbiZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "shopify.ivy_and_rose.product_type": null,
        "ebay.ivy_and_rose.price": "50",
        "product.description": "Why we love it: \n\r\nThis sweet pair of 14k white gold huggie hoop earrings feature a substantial 2 carat pair of glittering emerald cut lab grown diamonds! The elegant and minimalist look is perfect for adding a touch of luxury to your everyday look.\n\r\n\n\r\nHighlights:\n\r\n- 2ct pair of lab diamonds\n\r\n- IGI Certified\n\r\n- Bright white F color and excellent VS1 clarity\n\r\n- Sleek emerald cuts\n\r\n- Classic 14k white gold huggie hoop settings\n\r\n\n\r\n**These earrings are made to order with our current offering for 1 carat emerald cut lab grown diamonds. This listing reflects minimum guaranteed quality. The stones you receive will be the listed quality or better on all stats (carat weight, color, clarity, cut). Please contact us if you would like to see a certification for our current selection.",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Lab Grown Diamonds (x2) (IGI Certified)",
        "etsy.ivy_and_rose.category_id": "1208",
        "shopify.ivy_and_rose.vendor": null,
        "product.cost": "10",
        "prop.Y29yZT1TaXphYmxlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1NaW4rc2l6ZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "4",
        "prop.Y29yZT1NYWluK3N0b25lK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1JdGVtK3R5cGUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Drop Earrings - Dangle",
        "prop.Y29yZT1FcmEmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Modern",
        "product.internal_title": null,
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY29sb3ImZWJheT0mZXRzeT0mc2hvcGlmeT0": "F",
        "shopify.ilya_fine_jewelry.price": null,
        "prop.Y29yZT1Db2xsZWN0aW9uJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "product.notes": null,
        "shopify.ilya_fine_jewelry.title": null,
        "product.stock_number": null,
        "etsy.ivy_and_rose.when_made": "made_to_order",
        "prop.Y29yZT1DYW4rYmUrcGVyc29uYWxpemVkJmViYXk9JmV0c3k9JnNob3BpZnk9": "Yes",
        "product.location": "some",
        "shopify.ilya_fine_jewelry.seo_title": null,
        "prop.Y29yZT1OYXR1cmFsJTJGbGFiLWNyZWF0ZWQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Lab Grown",
        "ebay.ivy_and_rose.store_category2_id": null,
        "shopify.ilya_fine_jewelry.vendor": null,
        "shopify.ivy_and_rose.seo_title": null,
        "prop.Y29yZT1HZW1zdG9uZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "Lab Grown Diamonds (x2) (IGI Certified)",
        "prop.Y29yZT1TaWRlK3N0b25lJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "shopify.ilya_fine_jewelry.collection_ids": [],
        "shopify.ilya_fine_jewelry.tags": [],
        "prop.Y29yZT1TaWRlK3N0b25lK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "ebay.ivy_and_rose.category_id": "261990",
        "prop.Y29yZT1NYWRlK3RvK29yZGVyJmViYXk9JmV0c3k9JnNob3BpZnk9": "Yes",
        "product.availability": 999,
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrc2hhcGUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Emerald Cut",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY2xhcml0eSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "VS1",
        "prop.Y29yZT1TaWRlK3N0b25lK2NsYXJpdHkmZWJheT0mZXRzeT0mc2hvcGlmeT0": null
      },
      "id": "017215c3-3dbd-463c-95fc-c7e8e0ac607c",
      "sku": "MTO-LG-15957-2ct"
    },
    {
      "data": {
        "prop.Y29yZT1Ub3RhbCtjYXJhdCt3ZWlnaHQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "1.16ct",
        "prop.Y29yZT1SZWNpcGllbnQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Women",
        "shopify.ivy_and_rose.collection_ids": [],
        "shopify.ivy_and_rose.tags": [],
        "prop.Y29yZT1TZXR0aW5nK3N0eWxlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1EaWFtb25kK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "shopify.ilya_fine_jewelry.product_type": null,
        "prop.Y29yZT1DbGFyaXR5JmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1TaWRlK3N0b25lK3NoYXBlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "etsy.ivy_and_rose.who_made": "someone_else",
        "prop.Y29yZT1TaXplJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1DZW50ZXIrbWluK3dpZHRoK2ZpdCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "etsy.ivy_and_rose.price": "50",
        "prop.Y29yZT1DZXJ0aWZpY2F0aW9uJmViYXk9JmV0c3k9JnNob3BpZnk9": "GIA",
        "etsy.ivy_and_rose.title": "1.16Ct Old Mine Cut Diamond Gia Certified J Si2 Loose Natural Antique Cushion",
        "ebay.ivy_and_rose.store_category_id": "14736769",
        "product.main_style": null,
        "product.sku": "20465",
        "shopify.ivy_and_rose.title": null,
        "shopify.ivy_and_rose.price": null,
        "prop.Y29yZT1TaWRlK3N0b25lcytxdWFudGl0eSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "etsy.ivy_and_rose.is_supply": true,
        "prop.Y29yZT1HcmFtK3dlaWdodCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1DZW50ZXIrbWF4K2xlbmd0aCtmaXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "ebay.ivy_and_rose.condition": "USED_EXCELLENT",
        "prop.Y29yZT1TZWNvbmRhcnkrc3RvbmUmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1TaWRlK3N0b25lK2NhcmF0K3dlaWdodCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1SaW5nK3NpemUmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1NZXRhbCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1UaGVtZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1BcHByb3hpbWF0ZSt0aW1lK3BlcmlvZCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "Victorian/Edwardian",
        "prop.Y29yZT1NYWluK3N0b25lK3NoYXBlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1DZW50ZXIrbWluK2xlbmd0aCtmaXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "ebay.ivy_and_rose.secondary_category_id": null,
        "prop.Y29yZT1NYXgrc2l6ZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "10",
        "prop.Y29yZT1GYW5jeStkaWFtb25kK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "etsy.ivy_and_rose.shop_section_id": "18036037",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY2FyYXQrd2VpZ2h0JmViYXk9JmV0c3k9JnNob3BpZnk9": "1.16ct (6.37mm x 6.19mm)",
        "prop.Y29yZT1DdXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1NYXRlcmlhbCttdWx0aSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1DZW50ZXIrbWF4K3dpZHRoK2ZpdCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "ebay.ivy_and_rose.sub_title": null,
        "product.additional_styles": [],
        "product.price": "50",
        "prop.Y29yZT1NZXRhbCtwdXJpdHkmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "product.title": "1.16ct OLD MINE CUT DIAMOND GIA CERTIFIED J SI2 LOOSE NATURAL ANTIQUE CUSHION",
        "prop.Y29yZT1UcmVhdG1lbnQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Not Treated, Not Enhanced",
        "prop.Y29yZT1NYWluK3N0b25lJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1TdHlsZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1PY2Nhc2lvbiZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "shopify.ivy_and_rose.product_type": null,
        "ebay.ivy_and_rose.price": "50",
        "product.description": "Highlights:\n\r\n- 1.16ct natural diamond\n\r\n- True antique. Cut around the turn of the 19th to 20th century.\n\r\n- Beautiful old mine cut\n\r\n- GIA certified\n\r\n- Nice white face up with J color\n\r\n- 100% eye clean with SI2 clarity\n\r\n- Fantastic sparkle and brilliance!\n\r\n\n\r\nAdditional Notes:\n\r\nThe diamond is being sold loose, but it can be mounted upon request.",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Diamond (GIA Certified)",
        "etsy.ivy_and_rose.category_id": "6648",
        "shopify.ivy_and_rose.vendor": null,
        "product.cost": "10",
        "prop.Y29yZT1TaXphYmxlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1NaW4rc2l6ZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "4",
        "prop.Y29yZT1NYWluK3N0b25lK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1JdGVtK3R5cGUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Loose Diamond",
        "prop.Y29yZT1FcmEmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Original Vintage",
        "product.internal_title": null,
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY29sb3ImZWJheT0mZXRzeT0mc2hvcGlmeT0": "J",
        "shopify.ilya_fine_jewelry.price": null,
        "prop.Y29yZT1Db2xsZWN0aW9uJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "product.notes": "Faces White!",
        "shopify.ilya_fine_jewelry.title": null,
        "product.stock_number": null,
        "etsy.ivy_and_rose.when_made": "1800s",
        "prop.Y29yZT1DYW4rYmUrcGVyc29uYWxpemVkJmViYXk9JmV0c3k9JnNob3BpZnk9": "Yes",
        "product.location": "some",
        "shopify.ilya_fine_jewelry.seo_title": null,
        "prop.Y29yZT1OYXR1cmFsJTJGbGFiLWNyZWF0ZWQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Natural",
        "ebay.ivy_and_rose.store_category2_id": "14736889",
        "shopify.ilya_fine_jewelry.vendor": null,
        "shopify.ivy_and_rose.seo_title": null,
        "prop.Y29yZT1HZW1zdG9uZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "Diamond (GIA Certified)",
        "prop.Y29yZT1TaWRlK3N0b25lJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "shopify.ilya_fine_jewelry.collection_ids": [],
        "shopify.ilya_fine_jewelry.tags": [],
        "prop.Y29yZT1TaWRlK3N0b25lK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "ebay.ivy_and_rose.category_id": "262026",
        "prop.Y29yZT1NYWRlK3RvK29yZGVyJmViYXk9JmV0c3k9JnNob3BpZnk9": "No",
        "product.availability": 1,
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrc2hhcGUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Old Mine Cut",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY2xhcml0eSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "SI2 (100% Eye Clean)",
        "prop.Y29yZT1TaWRlK3N0b25lK2NsYXJpdHkmZWJheT0mZXRzeT0mc2hvcGlmeT0": null
      },
      "id": "0d441508-9da5-4f90-87c0-c98836001af6",
      "sku": "20465"
    },
    {
      "data": {
        "prop.Y29yZT1Ub3RhbCtjYXJhdCt3ZWlnaHQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "0.67ct",
        "prop.Y29yZT1SZWNpcGllbnQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Women",
        "shopify.ivy_and_rose.collection_ids": [],
        "shopify.ivy_and_rose.tags": [],
        "prop.Y29yZT1TZXR0aW5nK3N0eWxlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1EaWFtb25kK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "shopify.ilya_fine_jewelry.product_type": null,
        "prop.Y29yZT1DbGFyaXR5JmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1TaWRlK3N0b25lK3NoYXBlJmViYXk9JmV0c3k9JnNob3BpZnk9": "Round",
        "etsy.ivy_and_rose.who_made": "i_did",
        "prop.Y29yZT1TaXplJmViYXk9JmV0c3k9JnNob3BpZnk9": "6.5 (Resizable)",
        "prop.Y29yZT1DZW50ZXIrbWluK3dpZHRoK2ZpdCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "etsy.ivy_and_rose.price": "50",
        "prop.Y29yZT1DZXJ0aWZpY2F0aW9uJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "etsy.ivy_and_rose.title": "Vintage Style Diamond Cocktail Ring Natural 14K White Gold Scalloped Round Cut",
        "ebay.ivy_and_rose.store_category_id": "14736886",
        "product.main_style": "Cocktail",
        "product.sku": "MTO-DIA-8160",
        "shopify.ivy_and_rose.title": null,
        "shopify.ivy_and_rose.price": null,
        "prop.Y29yZT1TaWRlK3N0b25lcytxdWFudGl0eSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "etsy.ivy_and_rose.is_supply": false,
        "prop.Y29yZT1HcmFtK3dlaWdodCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1DZW50ZXIrbWF4K2xlbmd0aCtmaXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "ebay.ivy_and_rose.condition": "NEW",
        "prop.Y29yZT1TZWNvbmRhcnkrc3RvbmUmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1TaWRlK3N0b25lK2NhcmF0K3dlaWdodCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "0.35ct",
        "prop.Y29yZT1SaW5nK3NpemUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "6.5",
        "prop.Y29yZT1NZXRhbCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "14k White Gold",
        "prop.Y29yZT1UaGVtZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1BcHByb3hpbWF0ZSt0aW1lK3BlcmlvZCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "New - Vintage Reproduction",
        "prop.Y29yZT1NYWluK3N0b25lK3NoYXBlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1DZW50ZXIrbWluK2xlbmd0aCtmaXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "ebay.ivy_and_rose.secondary_category_id": null,
        "prop.Y29yZT1NYXgrc2l6ZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "10",
        "prop.Y29yZT1GYW5jeStkaWFtb25kK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "etsy.ivy_and_rose.shop_section_id": "20281911",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY2FyYXQrd2VpZ2h0JmViYXk9JmV0c3k9JnNob3BpZnk9": "0.32ct (approx)",
        "prop.Y29yZT1DdXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1NYXRlcmlhbCttdWx0aSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "14k White Gold",
        "prop.Y29yZT1DZW50ZXIrbWF4K3dpZHRoK2ZpdCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "ebay.ivy_and_rose.sub_title": null,
        "product.additional_styles": [],
        "product.price": "50",
        "prop.Y29yZT1NZXRhbCtwdXJpdHkmZWJheT0mZXRzeT0mc2hvcGlmeT0": "14k",
        "product.title": "VINTAGE STYLE DIAMOND COCKTAIL RING NATURAL 14k WHITE GOLD SCALLOPED ROUND CUT",
        "prop.Y29yZT1UcmVhdG1lbnQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Not Treated, Not Enhanced",
        "prop.Y29yZT1NYWluK3N0b25lJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1TdHlsZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1PY2Nhc2lvbiZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "shopify.ivy_and_rose.product_type": null,
        "ebay.ivy_and_rose.price": "50",
        "product.description": "Why we love it: \n\r\nThis vintage style diamond ring is gorgeously detailed with milgrain, cutouts, and a diamond encrusted scalloped shape.\n\r\n\n\r\nHighlights:\n\r\n- 0.67ct of vibrant round cut natural diamonds\n\r\n- Bright white and eye clean diamonds\n\r\n- Romantic 14k white gold setting \n\r\n\n\r\nDimensions:\n\r\nThe ring measures 19.0mm across (north south) and 3.5mm from finger to top.",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Diamond",
        "etsy.ivy_and_rose.category_id": "1231",
        "shopify.ivy_and_rose.vendor": null,
        "product.cost": "10",
        "prop.Y29yZT1TaXphYmxlJmViYXk9JmV0c3k9JnNob3BpZnk9": "yes",
        "prop.Y29yZT1NaW4rc2l6ZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "4",
        "prop.Y29yZT1NYWluK3N0b25lK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1JdGVtK3R5cGUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Cocktail Ring - Other",
        "prop.Y29yZT1FcmEmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Vintage Inspired",
        "product.internal_title": null,
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY29sb3ImZWJheT0mZXRzeT0mc2hvcGlmeT0": "G-H",
        "shopify.ilya_fine_jewelry.price": null,
        "prop.Y29yZT1Db2xsZWN0aW9uJmViYXk9JmV0c3k9JnNob3BpZnk9": "Heritage",
        "product.notes": null,
        "shopify.ilya_fine_jewelry.title": null,
        "product.stock_number": null,
        "etsy.ivy_and_rose.when_made": "made_to_order",
        "prop.Y29yZT1DYW4rYmUrcGVyc29uYWxpemVkJmViYXk9JmV0c3k9JnNob3BpZnk9": "Yes",
        "product.location": "some",
        "shopify.ilya_fine_jewelry.seo_title": null,
        "prop.Y29yZT1OYXR1cmFsJTJGbGFiLWNyZWF0ZWQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Natural",
        "ebay.ivy_and_rose.store_category2_id": "14736870",
        "shopify.ilya_fine_jewelry.vendor": null,
        "shopify.ivy_and_rose.seo_title": null,
        "prop.Y29yZT1HZW1zdG9uZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "Diamond",
        "prop.Y29yZT1TaWRlK3N0b25lJmViYXk9JmV0c3k9JnNob3BpZnk9": "Diamonds",
        "shopify.ilya_fine_jewelry.collection_ids": [],
        "shopify.ilya_fine_jewelry.tags": [],
        "prop.Y29yZT1TaWRlK3N0b25lK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": "G-H",
        "ebay.ivy_and_rose.category_id": "261994",
        "prop.Y29yZT1NYWRlK3RvK29yZGVyJmViYXk9JmV0c3k9JnNob3BpZnk9": "Yes",
        "product.availability": 1,
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrc2hhcGUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Round",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY2xhcml0eSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "SI1-SI2",
        "prop.Y29yZT1TaWRlK3N0b25lK2NsYXJpdHkmZWJheT0mZXRzeT0mc2hvcGlmeT0": "SI"
      },
      "id": "3b67a74b-1809-4f47-9635-abe9f6e3dc31",
      "sku": "MTO-DIA-8160"
    },
    {
      "data": {
        "prop.Y29yZT1Ub3RhbCtjYXJhdCt3ZWlnaHQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "1.48ct",
        "prop.Y29yZT1SZWNpcGllbnQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Women",
        "shopify.ivy_and_rose.collection_ids": [],
        "shopify.ivy_and_rose.tags": [],
        "prop.Y29yZT1TZXR0aW5nK3N0eWxlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1EaWFtb25kK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "shopify.ilya_fine_jewelry.product_type": null,
        "prop.Y29yZT1DbGFyaXR5JmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1TaWRlK3N0b25lK3NoYXBlJmViYXk9JmV0c3k9JnNob3BpZnk9": "Emerald: Custom Cut, Diamonds: Round",
        "etsy.ivy_and_rose.who_made": "i_did",
        "prop.Y29yZT1TaXplJmViYXk9JmV0c3k9JnNob3BpZnk9": "6.5 (Resizable)",
        "prop.Y29yZT1DZW50ZXIrbWluK3dpZHRoK2ZpdCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "etsy.ivy_and_rose.price": "50",
        "prop.Y29yZT1DZXJ0aWZpY2F0aW9uJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "etsy.ivy_and_rose.title": "Diamond Emerald Engagement Ring Old European Cut Vintage Style Halo Yellow Gold",
        "ebay.ivy_and_rose.store_category_id": "14736792",
        "product.main_style": "Halo",
        "product.sku": "MTO-19690-12564-YG-EM",
        "shopify.ivy_and_rose.title": null,
        "shopify.ivy_and_rose.price": null,
        "prop.Y29yZT1TaWRlK3N0b25lcytxdWFudGl0eSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "etsy.ivy_and_rose.is_supply": false,
        "prop.Y29yZT1HcmFtK3dlaWdodCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1DZW50ZXIrbWF4K2xlbmd0aCtmaXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "ebay.ivy_and_rose.condition": "NEW",
        "prop.Y29yZT1TZWNvbmRhcnkrc3RvbmUmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1TaWRlK3N0b25lK2NhcmF0K3dlaWdodCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "Emerald: 0.33ct; Diamonds: 0.36ct",
        "prop.Y29yZT1SaW5nK3NpemUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "6.5",
        "prop.Y29yZT1NZXRhbCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "14k Yellow Gold",
        "prop.Y29yZT1UaGVtZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1BcHByb3hpbWF0ZSt0aW1lK3BlcmlvZCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "New - Vintage Reproduction",
        "prop.Y29yZT1NYWluK3N0b25lK3NoYXBlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1DZW50ZXIrbWluK2xlbmd0aCtmaXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "ebay.ivy_and_rose.secondary_category_id": null,
        "prop.Y29yZT1NYXgrc2l6ZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "10",
        "prop.Y29yZT1GYW5jeStkaWFtb25kK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "etsy.ivy_and_rose.shop_section_id": "20281911",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY2FyYXQrd2VpZ2h0JmViYXk9JmV0c3k9JnNob3BpZnk9": "0.79ct",
        "prop.Y29yZT1DdXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1NYXRlcmlhbCttdWx0aSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "14k Yellow Gold",
        "prop.Y29yZT1DZW50ZXIrbWF4K3dpZHRoK2ZpdCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "ebay.ivy_and_rose.sub_title": null,
        "product.additional_styles": [
          "Dome"
        ],
        "product.price": "50",
        "prop.Y29yZT1NZXRhbCtwdXJpdHkmZWJheT0mZXRzeT0mc2hvcGlmeT0": "14k",
        "product.title": "DIAMOND EMERALD ENGAGEMENT RING OLD EUROPEAN CUT VINTAGE STYLE HALO YELLOW GOLD",
        "prop.Y29yZT1UcmVhdG1lbnQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1NYWluK3N0b25lJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1TdHlsZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1PY2Nhc2lvbiZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "shopify.ivy_and_rose.product_type": null,
        "ebay.ivy_and_rose.price": "50",
        "product.description": "Why we love it: \n\r\nThis gorgeous diamond and emerald ring features a vibrant 0.79ct old European cut diamond set at the center of an elegant Art Deco style design. The diamond is accented by a bright green border of calibre cut emeralds and vibrant diamonds.\n\r\n\n\r\nHighlights:\n\r\n- 0.79ct old European cut diamond center\n\r\n- Original vintage diamond\n\r\n- Faces up with minimal warmth with a color grade of K-L. Appears bright against the rubies and yellow gold\n\r\n- Exceptionally clean with VS2 clarity\n\r\n- Calibre cut natural emeralds\n\r\n- Natural diamond accent stones\n\r\n- Unique and elegant vintage style design\n\r\n- 14k yellow gold setting\n\r\n\n\r\nDimensions:\n\r\nThe ring measures 9.2mm across (north south) and 4.1mm from finger to top",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Diamond",
        "etsy.ivy_and_rose.category_id": "1245",
        "shopify.ivy_and_rose.vendor": null,
        "product.cost": "10",
        "prop.Y29yZT1TaXphYmxlJmViYXk9JmV0c3k9JnNob3BpZnk9": "yes",
        "prop.Y29yZT1NaW4rc2l6ZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "4",
        "prop.Y29yZT1NYWluK3N0b25lK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1JdGVtK3R5cGUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Engagement Ring",
        "prop.Y29yZT1FcmEmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Vintage Inspired",
        "product.internal_title": null,
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY29sb3ImZWJheT0mZXRzeT0mc2hvcGlmeT0": "K-L",
        "shopify.ilya_fine_jewelry.price": null,
        "prop.Y29yZT1Db2xsZWN0aW9uJmViYXk9JmV0c3k9JnNob3BpZnk9": "Harmony",
        "product.notes": null,
        "shopify.ilya_fine_jewelry.title": null,
        "product.stock_number": null,
        "etsy.ivy_and_rose.when_made": "made_to_order",
        "prop.Y29yZT1DYW4rYmUrcGVyc29uYWxpemVkJmViYXk9JmV0c3k9JnNob3BpZnk9": "Yes",
        "product.location": "some",
        "shopify.ilya_fine_jewelry.seo_title": null,
        "prop.Y29yZT1OYXR1cmFsJTJGbGFiLWNyZWF0ZWQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Natural",
        "ebay.ivy_and_rose.store_category2_id": "14736886",
        "shopify.ilya_fine_jewelry.vendor": null,
        "shopify.ivy_and_rose.seo_title": null,
        "prop.Y29yZT1HZW1zdG9uZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "Diamond",
        "prop.Y29yZT1TaWRlK3N0b25lJmViYXk9JmV0c3k9JnNob3BpZnk9": "Emerald, Diamonds",
        "shopify.ilya_fine_jewelry.collection_ids": [],
        "shopify.ilya_fine_jewelry.tags": [],
        "prop.Y29yZT1TaWRlK3N0b25lK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": "Green, G-H",
        "ebay.ivy_and_rose.category_id": "261975",
        "prop.Y29yZT1NYWRlK3RvK29yZGVyJmViYXk9JmV0c3k9JnNob3BpZnk9": "Yes",
        "product.availability": 1,
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrc2hhcGUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Old European Cut",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY2xhcml0eSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "VS2",
        "prop.Y29yZT1TaWRlK3N0b25lK2NsYXJpdHkmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Diamonds: VS1-SI1"
      },
      "id": "c9489930-3ff3-48a9-a4fc-d873528c9cc2",
      "sku": "MTO-19690-12564-YG-EM"
    },
    {
      "data": {
        "prop.Y29yZT1Ub3RhbCtjYXJhdCt3ZWlnaHQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "1.62ct",
        "prop.Y29yZT1SZWNpcGllbnQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Women",
        "shopify.ivy_and_rose.collection_ids": [],
        "shopify.ivy_and_rose.tags": [],
        "prop.Y29yZT1TZXR0aW5nK3N0eWxlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1EaWFtb25kK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "shopify.ilya_fine_jewelry.product_type": null,
        "prop.Y29yZT1DbGFyaXR5JmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1TaWRlK3N0b25lK3NoYXBlJmViYXk9JmV0c3k9JnNob3BpZnk9": "Ruby: Custom Cut, Diamonds: Round",
        "etsy.ivy_and_rose.who_made": "i_did",
        "prop.Y29yZT1TaXplJmViYXk9JmV0c3k9JnNob3BpZnk9": "6.5 (Resizable)",
        "prop.Y29yZT1DZW50ZXIrbWluK3dpZHRoK2ZpdCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "etsy.ivy_and_rose.price": "50",
        "prop.Y29yZT1DZXJ0aWZpY2F0aW9uJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "etsy.ivy_and_rose.title": "Diamond Ruby Engagement Ring Vintage Style Round Cut Calibre Halo 14K White Gold",
        "ebay.ivy_and_rose.store_category_id": "14736792",
        "product.main_style": "Halo",
        "product.sku": "MTO-17152-12564-RB",
        "shopify.ivy_and_rose.title": null,
        "shopify.ivy_and_rose.price": null,
        "prop.Y29yZT1TaWRlK3N0b25lcytxdWFudGl0eSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "etsy.ivy_and_rose.is_supply": false,
        "prop.Y29yZT1HcmFtK3dlaWdodCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1DZW50ZXIrbWF4K2xlbmd0aCtmaXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "ebay.ivy_and_rose.condition": "NEW",
        "prop.Y29yZT1TZWNvbmRhcnkrc3RvbmUmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1TaWRlK3N0b25lK2NhcmF0K3dlaWdodCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "Rubies: 0.52ct, Diamonds: 0.34ct",
        "prop.Y29yZT1SaW5nK3NpemUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "6.5",
        "prop.Y29yZT1NZXRhbCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "14k White Gold",
        "prop.Y29yZT1UaGVtZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1BcHByb3hpbWF0ZSt0aW1lK3BlcmlvZCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "New - Vintage Reproduction",
        "prop.Y29yZT1NYWluK3N0b25lK3NoYXBlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1DZW50ZXIrbWluK2xlbmd0aCtmaXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "ebay.ivy_and_rose.secondary_category_id": null,
        "prop.Y29yZT1NYXgrc2l6ZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "10",
        "prop.Y29yZT1GYW5jeStkaWFtb25kK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "etsy.ivy_and_rose.shop_section_id": "20281911",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY2FyYXQrd2VpZ2h0JmViYXk9JmV0c3k9JnNob3BpZnk9": "0.76ct",
        "prop.Y29yZT1DdXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1NYXRlcmlhbCttdWx0aSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "14k White Gold",
        "prop.Y29yZT1DZW50ZXIrbWF4K3dpZHRoK2ZpdCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "ebay.ivy_and_rose.sub_title": null,
        "product.additional_styles": [
          "Dome"
        ],
        "product.price": "50",
        "prop.Y29yZT1NZXRhbCtwdXJpdHkmZWJheT0mZXRzeT0mc2hvcGlmeT0": "14k",
        "product.title": "DIAMOND RUBY ENGAGEMENT RING VINTAGE STYLE ROUND CUT CALIBRE HALO 14k WHITE GOLD",
        "prop.Y29yZT1UcmVhdG1lbnQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1NYWluK3N0b25lJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1TdHlsZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1PY2Nhc2lvbiZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "shopify.ivy_and_rose.product_type": null,
        "ebay.ivy_and_rose.price": "50",
        "product.description": "Why we love it: \n\r\nThis gorgeous diamond and ruby ring features a vibrant 0.76ct round brilliant cut diamond set at the center of an elegant Art Deco style design. The diamond is accented by a rich red border of calibre cut rubies and vibrant diamonds.\n\r\n\n\r\nHighlights:\n\r\n- 0.76ct round cut diamond center\n\r\n- Very bright white with a color grade of E-F\n\r\n- SI3 clarity with well hidden inclusions. Inclusions blend in very well with the sparkle\n\r\n- Calibre cut natural rubies\n\r\n- Natural diamond accent stones\n\r\n- Unique and elegant vintage style design\n\r\n- 14k white gold setting\n\r\n\n\r\nDimensions:\n\r\nThe ring measures 9.2mm across (north south) and 4.1mm from finger to top",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Diamond",
        "etsy.ivy_and_rose.category_id": "1245",
        "shopify.ivy_and_rose.vendor": null,
        "product.cost": "10",
        "prop.Y29yZT1TaXphYmxlJmViYXk9JmV0c3k9JnNob3BpZnk9": "yes",
        "prop.Y29yZT1NaW4rc2l6ZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "4",
        "prop.Y29yZT1NYWluK3N0b25lK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1JdGVtK3R5cGUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Engagement Ring",
        "prop.Y29yZT1FcmEmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Vintage Inspired",
        "product.internal_title": null,
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY29sb3ImZWJheT0mZXRzeT0mc2hvcGlmeT0": "E-F",
        "shopify.ilya_fine_jewelry.price": null,
        "prop.Y29yZT1Db2xsZWN0aW9uJmViYXk9JmV0c3k9JnNob3BpZnk9": "Harmony",
        "product.notes": null,
        "shopify.ilya_fine_jewelry.title": null,
        "product.stock_number": null,
        "etsy.ivy_and_rose.when_made": "made_to_order",
        "prop.Y29yZT1DYW4rYmUrcGVyc29uYWxpemVkJmViYXk9JmV0c3k9JnNob3BpZnk9": "Yes",
        "product.location": "some",
        "shopify.ilya_fine_jewelry.seo_title": null,
        "prop.Y29yZT1OYXR1cmFsJTJGbGFiLWNyZWF0ZWQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Natural",
        "ebay.ivy_and_rose.store_category2_id": "14736886",
        "shopify.ilya_fine_jewelry.vendor": null,
        "shopify.ivy_and_rose.seo_title": null,
        "prop.Y29yZT1HZW1zdG9uZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "Diamond",
        "prop.Y29yZT1TaWRlK3N0b25lJmViYXk9JmV0c3k9JnNob3BpZnk9": "Ruby, Diamonds",
        "shopify.ilya_fine_jewelry.collection_ids": [],
        "shopify.ilya_fine_jewelry.tags": [],
        "prop.Y29yZT1TaWRlK3N0b25lK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": "Red, G-H",
        "ebay.ivy_and_rose.category_id": "261975",
        "prop.Y29yZT1NYWRlK3RvK29yZGVyJmViYXk9JmV0c3k9JnNob3BpZnk9": "Yes",
        "product.availability": 1,
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrc2hhcGUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Round (Very Good - Excellent Cut)",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY2xhcml0eSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "SI3 (Generally Eye Clean)",
        "prop.Y29yZT1TaWRlK3N0b25lK2NsYXJpdHkmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Diamonds: VS1-SI1"
      },
      "id": "d09176da-3216-452d-a0a7-55e2059b16bc",
      "sku": "MTO-17152-12564-RB"
    },
    {
      "data": {
        "prop.Y29yZT1Ub3RhbCtjYXJhdCt3ZWlnaHQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "2ct",
        "prop.Y29yZT1SZWNpcGllbnQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Women",
        "shopify.ivy_and_rose.collection_ids": [],
        "shopify.ivy_and_rose.tags": [],
        "prop.Y29yZT1TZXR0aW5nK3N0eWxlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1EaWFtb25kK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "shopify.ilya_fine_jewelry.product_type": null,
        "prop.Y29yZT1DbGFyaXR5JmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1TaWRlK3N0b25lK3NoYXBlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "etsy.ivy_and_rose.who_made": "someone_else",
        "prop.Y29yZT1TaXplJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1DZW50ZXIrbWluK3dpZHRoK2ZpdCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "etsy.ivy_and_rose.price": "50",
        "prop.Y29yZT1DZXJ0aWZpY2F0aW9uJmViYXk9JmV0c3k9JnNob3BpZnk9": "IGI",
        "etsy.ivy_and_rose.title": "2 Carat Lab Created Diamond F Vs2 Igi Certified Marquise Shape Cut Loose Grown",
        "ebay.ivy_and_rose.store_category_id": "14736769",
        "product.main_style": null,
        "product.sku": "LG-DIA-MQ-2-FVS2",
        "shopify.ivy_and_rose.title": null,
        "shopify.ivy_and_rose.price": null,
        "prop.Y29yZT1TaWRlK3N0b25lcytxdWFudGl0eSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "etsy.ivy_and_rose.is_supply": true,
        "prop.Y29yZT1HcmFtK3dlaWdodCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1DZW50ZXIrbWF4K2xlbmd0aCtmaXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "ebay.ivy_and_rose.condition": "NEW",
        "prop.Y29yZT1TZWNvbmRhcnkrc3RvbmUmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1TaWRlK3N0b25lK2NhcmF0K3dlaWdodCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1SaW5nK3NpemUmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1NZXRhbCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1UaGVtZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1BcHByb3hpbWF0ZSt0aW1lK3BlcmlvZCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "New",
        "prop.Y29yZT1NYWluK3N0b25lK3NoYXBlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1DZW50ZXIrbWluK2xlbmd0aCtmaXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "ebay.ivy_and_rose.secondary_category_id": null,
        "prop.Y29yZT1NYXgrc2l6ZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "10",
        "prop.Y29yZT1GYW5jeStkaWFtb25kK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "etsy.ivy_and_rose.shop_section_id": "18036037",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY2FyYXQrd2VpZ2h0JmViYXk9JmV0c3k9JnNob3BpZnk9": "2ct (approx)",
        "prop.Y29yZT1DdXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1NYXRlcmlhbCttdWx0aSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1DZW50ZXIrbWF4K3dpZHRoK2ZpdCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "ebay.ivy_and_rose.sub_title": null,
        "product.additional_styles": [],
        "product.price": "50",
        "prop.Y29yZT1NZXRhbCtwdXJpdHkmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "product.title": "2 CARAT LAB CREATED DIAMOND F VS2 IGI CERTIFIED MARQUISE SHAPE CUT LOOSE GROWN",
        "prop.Y29yZT1UcmVhdG1lbnQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1NYWluK3N0b25lJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1TdHlsZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1PY2Nhc2lvbiZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "shopify.ivy_and_rose.product_type": null,
        "ebay.ivy_and_rose.price": "50",
        "product.description": "Highlights:\n\r\n- 2 carat size\n\r\n- Bright white with at least F color\n\r\n- Very clean with at least VS2 clarity\n\r\n- Well executed marquise cut with great proportions and sparkle!\n\r\n- This diamond is lab grown. It offers all the beauty and durability of an earth mined diamond at an excellent value.\n\r\n\n\r\nAdditional notes:\n\r\nThis listing reflects minimum guaranteed quality. The stone you receive will be the listed quality or better on all stats (carat weight, color, clarity, cut). Please contact us if you would like to see a certification for our current selection.",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Lab Grown Diamond (IGI Certified)",
        "etsy.ivy_and_rose.category_id": "6648",
        "shopify.ivy_and_rose.vendor": null,
        "product.cost": "10",
        "prop.Y29yZT1TaXphYmxlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1NaW4rc2l6ZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "4",
        "prop.Y29yZT1NYWluK3N0b25lK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1JdGVtK3R5cGUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Loose Diamond",
        "prop.Y29yZT1FcmEmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Modern",
        "product.internal_title": "loose - modern - diamond",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY29sb3ImZWJheT0mZXRzeT0mc2hvcGlmeT0": "F",
        "shopify.ilya_fine_jewelry.price": null,
        "prop.Y29yZT1Db2xsZWN0aW9uJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "product.notes": null,
        "shopify.ilya_fine_jewelry.title": null,
        "product.stock_number": null,
        "etsy.ivy_and_rose.when_made": "made_to_order",
        "prop.Y29yZT1DYW4rYmUrcGVyc29uYWxpemVkJmViYXk9JmV0c3k9JnNob3BpZnk9": "Yes",
        "product.location": "some",
        "shopify.ilya_fine_jewelry.seo_title": null,
        "prop.Y29yZT1OYXR1cmFsJTJGbGFiLWNyZWF0ZWQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Lab Grown",
        "ebay.ivy_and_rose.store_category2_id": "14736889",
        "shopify.ilya_fine_jewelry.vendor": null,
        "shopify.ivy_and_rose.seo_title": null,
        "prop.Y29yZT1HZW1zdG9uZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "Lab Grown Diamond (IGI Certified)",
        "prop.Y29yZT1TaWRlK3N0b25lJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "shopify.ilya_fine_jewelry.collection_ids": [],
        "shopify.ilya_fine_jewelry.tags": [],
        "prop.Y29yZT1TaWRlK3N0b25lK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "ebay.ivy_and_rose.category_id": "262026",
        "prop.Y29yZT1NYWRlK3RvK29yZGVyJmViYXk9JmV0c3k9JnNob3BpZnk9": "Yes",
        "product.availability": 999,
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrc2hhcGUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Marquise",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY2xhcml0eSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "VS2",
        "prop.Y29yZT1TaWRlK3N0b25lK2NsYXJpdHkmZWJheT0mZXRzeT0mc2hvcGlmeT0": null
      },
      "id": "55610ec4-786f-4acc-b794-b8e3a442d331",
      "sku": "LG-DIA-MQ-2-FVS2"
    },
    {
      "data": {
        "prop.Y29yZT1Ub3RhbCtjYXJhdCt3ZWlnaHQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "1ct",
        "prop.Y29yZT1SZWNpcGllbnQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Women",
        "shopify.ivy_and_rose.collection_ids": [],
        "shopify.ivy_and_rose.tags": [],
        "prop.Y29yZT1TZXR0aW5nK3N0eWxlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1EaWFtb25kK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "shopify.ilya_fine_jewelry.product_type": null,
        "prop.Y29yZT1DbGFyaXR5JmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1TaWRlK3N0b25lK3NoYXBlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "etsy.ivy_and_rose.who_made": "someone_else",
        "prop.Y29yZT1TaXplJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1DZW50ZXIrbWluK3dpZHRoK2ZpdCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "etsy.ivy_and_rose.price": "50",
        "prop.Y29yZT1DZXJ0aWZpY2F0aW9uJmViYXk9JmV0c3k9JnNob3BpZnk9": "IGI",
        "etsy.ivy_and_rose.title": "1 Carat Lab Created Diamond F Vs2 Igi Certified Marquise Shape Cut Loose Grown",
        "ebay.ivy_and_rose.store_category_id": "14736769",
        "product.main_style": null,
        "product.sku": "LG-DIA-MQ-1-FVS2",
        "shopify.ivy_and_rose.title": null,
        "shopify.ivy_and_rose.price": null,
        "prop.Y29yZT1TaWRlK3N0b25lcytxdWFudGl0eSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "etsy.ivy_and_rose.is_supply": true,
        "prop.Y29yZT1HcmFtK3dlaWdodCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1DZW50ZXIrbWF4K2xlbmd0aCtmaXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "ebay.ivy_and_rose.condition": "NEW",
        "prop.Y29yZT1TZWNvbmRhcnkrc3RvbmUmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1TaWRlK3N0b25lK2NhcmF0K3dlaWdodCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1SaW5nK3NpemUmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1NZXRhbCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1UaGVtZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1BcHByb3hpbWF0ZSt0aW1lK3BlcmlvZCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "New",
        "prop.Y29yZT1NYWluK3N0b25lK3NoYXBlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1DZW50ZXIrbWluK2xlbmd0aCtmaXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "ebay.ivy_and_rose.secondary_category_id": null,
        "prop.Y29yZT1NYXgrc2l6ZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "10",
        "prop.Y29yZT1GYW5jeStkaWFtb25kK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "etsy.ivy_and_rose.shop_section_id": "18036037",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY2FyYXQrd2VpZ2h0JmViYXk9JmV0c3k9JnNob3BpZnk9": "1ct (approx)",
        "prop.Y29yZT1DdXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1NYXRlcmlhbCttdWx0aSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1DZW50ZXIrbWF4K3dpZHRoK2ZpdCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "ebay.ivy_and_rose.sub_title": null,
        "product.additional_styles": [],
        "product.price": "50",
        "prop.Y29yZT1NZXRhbCtwdXJpdHkmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "product.title": "1 CARAT LAB CREATED DIAMOND F VS2 IGI CERTIFIED MARQUISE SHAPE CUT LOOSE GROWN",
        "prop.Y29yZT1UcmVhdG1lbnQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1NYWluK3N0b25lJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1TdHlsZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1PY2Nhc2lvbiZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "shopify.ivy_and_rose.product_type": null,
        "ebay.ivy_and_rose.price": "50",
        "product.description": "Highlights:\n\r\n- 1 carat size\n\r\n- Bright white with at least F color\n\r\n- Very clean with at least VS2 clarity\n\r\n- Well executed marquise cut with great proportions and sparkle!\n\r\n- This diamond is lab grown. It offers all the beauty and durability of an earth mined diamond at an excellent value.\n\r\n\n\r\nAdditional notes:\n\r\nThis listing reflects minimum guaranteed quality. The stone you receive will be the listed quality or better on all stats (carat weight, color, clarity, cut). Please contact us if you would like to see a certification for our current selection.",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Lab Grown Diamond (IGI Certified)",
        "etsy.ivy_and_rose.category_id": "6648",
        "shopify.ivy_and_rose.vendor": null,
        "product.cost": "10",
        "prop.Y29yZT1TaXphYmxlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1NaW4rc2l6ZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "4",
        "prop.Y29yZT1NYWluK3N0b25lK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1JdGVtK3R5cGUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Loose Diamond",
        "prop.Y29yZT1FcmEmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Modern",
        "product.internal_title": "loose - modern - diamond",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY29sb3ImZWJheT0mZXRzeT0mc2hvcGlmeT0": "F",
        "shopify.ilya_fine_jewelry.price": null,
        "prop.Y29yZT1Db2xsZWN0aW9uJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "product.notes": null,
        "shopify.ilya_fine_jewelry.title": null,
        "product.stock_number": null,
        "etsy.ivy_and_rose.when_made": "made_to_order",
        "prop.Y29yZT1DYW4rYmUrcGVyc29uYWxpemVkJmViYXk9JmV0c3k9JnNob3BpZnk9": "Yes",
        "product.location": "some",
        "shopify.ilya_fine_jewelry.seo_title": null,
        "prop.Y29yZT1OYXR1cmFsJTJGbGFiLWNyZWF0ZWQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Lab Grown",
        "ebay.ivy_and_rose.store_category2_id": "14736889",
        "shopify.ilya_fine_jewelry.vendor": null,
        "shopify.ivy_and_rose.seo_title": null,
        "prop.Y29yZT1HZW1zdG9uZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "Lab Grown Diamond (IGI Certified)",
        "prop.Y29yZT1TaWRlK3N0b25lJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "shopify.ilya_fine_jewelry.collection_ids": [],
        "shopify.ilya_fine_jewelry.tags": [],
        "prop.Y29yZT1TaWRlK3N0b25lK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "ebay.ivy_and_rose.category_id": "262026",
        "prop.Y29yZT1NYWRlK3RvK29yZGVyJmViYXk9JmV0c3k9JnNob3BpZnk9": "Yes",
        "product.availability": 999,
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrc2hhcGUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Marquise",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY2xhcml0eSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "VS2",
        "prop.Y29yZT1TaWRlK3N0b25lK2NsYXJpdHkmZWJheT0mZXRzeT0mc2hvcGlmeT0": null
      },
      "id": "dfce4ca6-4a9f-4589-b08c-cd8113553bc8",
      "sku": "LG-DIA-MQ-1-FVS2"
    },
    {
      "data": {
        "prop.Y29yZT1Ub3RhbCtjYXJhdCt3ZWlnaHQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "0.50ct",
        "prop.Y29yZT1SZWNpcGllbnQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Women",
        "shopify.ivy_and_rose.collection_ids": [],
        "shopify.ivy_and_rose.tags": [],
        "prop.Y29yZT1TZXR0aW5nK3N0eWxlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1EaWFtb25kK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "shopify.ilya_fine_jewelry.product_type": null,
        "prop.Y29yZT1DbGFyaXR5JmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1TaWRlK3N0b25lK3NoYXBlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "etsy.ivy_and_rose.who_made": "someone_else",
        "prop.Y29yZT1TaXplJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1DZW50ZXIrbWluK3dpZHRoK2ZpdCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "etsy.ivy_and_rose.price": "50",
        "prop.Y29yZT1DZXJ0aWZpY2F0aW9uJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "etsy.ivy_and_rose.title": "Lab Created Diamond Matching Pair 1/2 Carat Tapered Baguette Cut Loose 0.50Ct",
        "ebay.ivy_and_rose.store_category_id": "14736769",
        "product.main_style": null,
        "product.sku": "LG-19522-TB-.50",
        "shopify.ivy_and_rose.title": null,
        "shopify.ivy_and_rose.price": null,
        "prop.Y29yZT1TaWRlK3N0b25lcytxdWFudGl0eSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "etsy.ivy_and_rose.is_supply": true,
        "prop.Y29yZT1HcmFtK3dlaWdodCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1DZW50ZXIrbWF4K2xlbmd0aCtmaXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "ebay.ivy_and_rose.condition": "NEW",
        "prop.Y29yZT1TZWNvbmRhcnkrc3RvbmUmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1TaWRlK3N0b25lK2NhcmF0K3dlaWdodCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1SaW5nK3NpemUmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1NZXRhbCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1UaGVtZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1BcHByb3hpbWF0ZSt0aW1lK3BlcmlvZCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "New",
        "prop.Y29yZT1NYWluK3N0b25lK3NoYXBlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1DZW50ZXIrbWluK2xlbmd0aCtmaXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "ebay.ivy_and_rose.secondary_category_id": null,
        "prop.Y29yZT1NYXgrc2l6ZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "10",
        "prop.Y29yZT1GYW5jeStkaWFtb25kK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "etsy.ivy_and_rose.shop_section_id": "18036037",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY2FyYXQrd2VpZ2h0JmViYXk9JmV0c3k9JnNob3BpZnk9": "0.50ctw (5.80mm x 2.80mm) Avg.",
        "prop.Y29yZT1DdXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1NYXRlcmlhbCttdWx0aSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1DZW50ZXIrbWF4K3dpZHRoK2ZpdCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "ebay.ivy_and_rose.sub_title": null,
        "product.additional_styles": [],
        "product.price": "50",
        "prop.Y29yZT1NZXRhbCtwdXJpdHkmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "product.title": "LAB CREATED DIAMOND MATCHING PAIR 1/2 CARAT TAPERED BAGUETTE CUT LOOSE 0.50ct",
        "prop.Y29yZT1UcmVhdG1lbnQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1NYWluK3N0b25lJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1TdHlsZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1PY2Nhc2lvbiZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "shopify.ivy_and_rose.product_type": null,
        "ebay.ivy_and_rose.price": "50",
        "product.description": "Highlights:\n\r\n- ½ carat matched pair of tapered baguette lab diamonds\n\r\n- Bright white with E-F color\n\r\n- Very clean with VVS-VS clarity\n\r\n- The diamonds are lab grown. They offer all the beauty and durability of earth mined diamonds at an excellent value.\n\r\n\n\r\nAdditional Notes:\n\r\nThe diamonds are being sold loose, and would make perfect sides on a 3 stone ring. We are a full service jeweler, and would be happy to create a finished piece for you using these stones.",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Lab Grown Diamondx2",
        "etsy.ivy_and_rose.category_id": "6648",
        "shopify.ivy_and_rose.vendor": null,
        "product.cost": "10",
        "prop.Y29yZT1TaXphYmxlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1NaW4rc2l6ZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "4",
        "prop.Y29yZT1NYWluK3N0b25lK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1JdGVtK3R5cGUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Loose Diamond",
        "prop.Y29yZT1FcmEmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Modern",
        "product.internal_title": null,
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY29sb3ImZWJheT0mZXRzeT0mc2hvcGlmeT0": "E-F",
        "shopify.ilya_fine_jewelry.price": null,
        "prop.Y29yZT1Db2xsZWN0aW9uJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "product.notes": null,
        "shopify.ilya_fine_jewelry.title": null,
        "product.stock_number": null,
        "etsy.ivy_and_rose.when_made": "made_to_order",
        "prop.Y29yZT1DYW4rYmUrcGVyc29uYWxpemVkJmViYXk9JmV0c3k9JnNob3BpZnk9": "Yes",
        "product.location": "some",
        "shopify.ilya_fine_jewelry.seo_title": null,
        "prop.Y29yZT1OYXR1cmFsJTJGbGFiLWNyZWF0ZWQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Lab Grown",
        "ebay.ivy_and_rose.store_category2_id": "14736889",
        "shopify.ilya_fine_jewelry.vendor": null,
        "shopify.ivy_and_rose.seo_title": null,
        "prop.Y29yZT1HZW1zdG9uZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "Lab Grown Diamondx2",
        "prop.Y29yZT1TaWRlK3N0b25lJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "shopify.ilya_fine_jewelry.collection_ids": [],
        "shopify.ilya_fine_jewelry.tags": [],
        "prop.Y29yZT1TaWRlK3N0b25lK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "ebay.ivy_and_rose.category_id": "262026",
        "prop.Y29yZT1NYWRlK3RvK29yZGVyJmViYXk9JmV0c3k9JnNob3BpZnk9": "Yes",
        "product.availability": 5,
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrc2hhcGUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Tapered Baguette",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY2xhcml0eSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "VVS-VS",
        "prop.Y29yZT1TaWRlK3N0b25lK2NsYXJpdHkmZWJheT0mZXRzeT0mc2hvcGlmeT0": null
      },
      "id": "df5cf9f6-7fe8-4371-84fb-a423804eaef2",
      "sku": "LG-19522-TB-.50"
    },
    {
      "data": {
        "prop.Y29yZT1Ub3RhbCtjYXJhdCt3ZWlnaHQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "0.50ct",
        "prop.Y29yZT1SZWNpcGllbnQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Women",
        "shopify.ivy_and_rose.collection_ids": [],
        "shopify.ivy_and_rose.tags": [],
        "prop.Y29yZT1TZXR0aW5nK3N0eWxlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1EaWFtb25kK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "shopify.ilya_fine_jewelry.product_type": null,
        "prop.Y29yZT1DbGFyaXR5JmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1TaWRlK3N0b25lK3NoYXBlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "etsy.ivy_and_rose.who_made": "someone_else",
        "prop.Y29yZT1TaXplJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1DZW50ZXIrbWluK3dpZHRoK2ZpdCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "etsy.ivy_and_rose.price": "50",
        "prop.Y29yZT1DZXJ0aWZpY2F0aW9uJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "etsy.ivy_and_rose.title": "Lab Created Diamond Matching Pair 1/2Ct Half Moon Shape Cut Loose Accent Stones",
        "ebay.ivy_and_rose.store_category_id": "14736769",
        "product.main_style": null,
        "product.sku": "LG-19522-HM-.50",
        "shopify.ivy_and_rose.title": null,
        "shopify.ivy_and_rose.price": null,
        "prop.Y29yZT1TaWRlK3N0b25lcytxdWFudGl0eSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "etsy.ivy_and_rose.is_supply": true,
        "prop.Y29yZT1HcmFtK3dlaWdodCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1DZW50ZXIrbWF4K2xlbmd0aCtmaXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "ebay.ivy_and_rose.condition": "NEW",
        "prop.Y29yZT1TZWNvbmRhcnkrc3RvbmUmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1TaWRlK3N0b25lK2NhcmF0K3dlaWdodCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1SaW5nK3NpemUmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1NZXRhbCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1UaGVtZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1BcHByb3hpbWF0ZSt0aW1lK3BlcmlvZCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "New",
        "prop.Y29yZT1NYWluK3N0b25lK3NoYXBlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1DZW50ZXIrbWluK2xlbmd0aCtmaXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "ebay.ivy_and_rose.secondary_category_id": null,
        "prop.Y29yZT1NYXgrc2l6ZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "10",
        "prop.Y29yZT1GYW5jeStkaWFtb25kK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "etsy.ivy_and_rose.shop_section_id": "18036037",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY2FyYXQrd2VpZ2h0JmViYXk9JmV0c3k9JnNob3BpZnk9": "0.50ctw (5.40mm x 2.85mm) Avg.",
        "prop.Y29yZT1DdXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1NYXRlcmlhbCttdWx0aSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1DZW50ZXIrbWF4K3dpZHRoK2ZpdCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "ebay.ivy_and_rose.sub_title": null,
        "product.additional_styles": [],
        "product.price": "50",
        "prop.Y29yZT1NZXRhbCtwdXJpdHkmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "product.title": "LAB CREATED DIAMOND MATCHING PAIR 1/2ct HALF MOON SHAPE CUT LOOSE ACCENT STONES",
        "prop.Y29yZT1UcmVhdG1lbnQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1NYWluK3N0b25lJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1TdHlsZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1PY2Nhc2lvbiZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "shopify.ivy_and_rose.product_type": null,
        "ebay.ivy_and_rose.price": "50",
        "product.description": "Highlights:\n\r\n- ½ carat matched pair of half moon shape lab diamonds\n\r\n- Bright white with E-F color\n\r\n- Very clean with VVS-VS clarity\n\r\n- The diamonds are lab grown. They offer all the beauty and durability of earth mined diamonds at an excellent value.\n\r\n\n\r\nAdditional Notes:\n\r\nThe diamonds are being sold loose, and would make perfect sides on a 3 stone ring. We are a full service jeweler, and would be happy to create a finished piece for you using these stones.",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Lab Grown Diamondx2",
        "etsy.ivy_and_rose.category_id": "6648",
        "shopify.ivy_and_rose.vendor": null,
        "product.cost": "10",
        "prop.Y29yZT1TaXphYmxlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1NaW4rc2l6ZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "4",
        "prop.Y29yZT1NYWluK3N0b25lK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1JdGVtK3R5cGUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Loose Diamond",
        "prop.Y29yZT1FcmEmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Modern",
        "product.internal_title": null,
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY29sb3ImZWJheT0mZXRzeT0mc2hvcGlmeT0": "E-F",
        "shopify.ilya_fine_jewelry.price": null,
        "prop.Y29yZT1Db2xsZWN0aW9uJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "product.notes": null,
        "shopify.ilya_fine_jewelry.title": null,
        "product.stock_number": null,
        "etsy.ivy_and_rose.when_made": "made_to_order",
        "prop.Y29yZT1DYW4rYmUrcGVyc29uYWxpemVkJmViYXk9JmV0c3k9JnNob3BpZnk9": "Yes",
        "product.location": "some",
        "shopify.ilya_fine_jewelry.seo_title": null,
        "prop.Y29yZT1OYXR1cmFsJTJGbGFiLWNyZWF0ZWQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Lab Grown",
        "ebay.ivy_and_rose.store_category2_id": "14736889",
        "shopify.ilya_fine_jewelry.vendor": null,
        "shopify.ivy_and_rose.seo_title": null,
        "prop.Y29yZT1HZW1zdG9uZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "Lab Grown Diamondx2",
        "prop.Y29yZT1TaWRlK3N0b25lJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "shopify.ilya_fine_jewelry.collection_ids": [],
        "shopify.ilya_fine_jewelry.tags": [],
        "prop.Y29yZT1TaWRlK3N0b25lK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "ebay.ivy_and_rose.category_id": "262026",
        "prop.Y29yZT1NYWRlK3RvK29yZGVyJmViYXk9JmV0c3k9JnNob3BpZnk9": "Yes",
        "product.availability": 3,
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrc2hhcGUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Half Moon",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY2xhcml0eSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "VVS-VS",
        "prop.Y29yZT1TaWRlK3N0b25lK2NsYXJpdHkmZWJheT0mZXRzeT0mc2hvcGlmeT0": null
      },
      "id": "27a3087c-34b6-4bc0-9288-1df5b920d715",
      "sku": "LG-19522-HM-.50"
    },
    {
      "data": {
        "prop.Y29yZT1Ub3RhbCtjYXJhdCt3ZWlnaHQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "0.50ct",
        "prop.Y29yZT1SZWNpcGllbnQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Women",
        "shopify.ivy_and_rose.collection_ids": [],
        "shopify.ivy_and_rose.tags": [],
        "prop.Y29yZT1TZXR0aW5nK3N0eWxlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1EaWFtb25kK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "shopify.ilya_fine_jewelry.product_type": null,
        "prop.Y29yZT1DbGFyaXR5JmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1TaWRlK3N0b25lK3NoYXBlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "etsy.ivy_and_rose.who_made": "someone_else",
        "prop.Y29yZT1TaXplJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1DZW50ZXIrbWluK3dpZHRoK2ZpdCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "etsy.ivy_and_rose.price": "50",
        "prop.Y29yZT1DZXJ0aWZpY2F0aW9uJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "etsy.ivy_and_rose.title": "Lab Created Diamond Matching Pair 1/2 Carat Cadillac Cut Loose Accent Stones",
        "ebay.ivy_and_rose.store_category_id": "14736769",
        "product.main_style": null,
        "product.sku": "LG-19522-CAD-.50",
        "shopify.ivy_and_rose.title": null,
        "shopify.ivy_and_rose.price": null,
        "prop.Y29yZT1TaWRlK3N0b25lcytxdWFudGl0eSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "etsy.ivy_and_rose.is_supply": true,
        "prop.Y29yZT1HcmFtK3dlaWdodCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1DZW50ZXIrbWF4K2xlbmd0aCtmaXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "ebay.ivy_and_rose.condition": "NEW",
        "prop.Y29yZT1TZWNvbmRhcnkrc3RvbmUmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1TaWRlK3N0b25lK2NhcmF0K3dlaWdodCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1SaW5nK3NpemUmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1NZXRhbCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1UaGVtZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1BcHByb3hpbWF0ZSt0aW1lK3BlcmlvZCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "New",
        "prop.Y29yZT1NYWluK3N0b25lK3NoYXBlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1DZW50ZXIrbWluK2xlbmd0aCtmaXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "ebay.ivy_and_rose.secondary_category_id": null,
        "prop.Y29yZT1NYXgrc2l6ZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "10",
        "prop.Y29yZT1GYW5jeStkaWFtb25kK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "etsy.ivy_and_rose.shop_section_id": "18036037",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY2FyYXQrd2VpZ2h0JmViYXk9JmV0c3k9JnNob3BpZnk9": "0.50ctw (5.40mm - 3.15mm)",
        "prop.Y29yZT1DdXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1NYXRlcmlhbCttdWx0aSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1DZW50ZXIrbWF4K3dpZHRoK2ZpdCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "ebay.ivy_and_rose.sub_title": null,
        "product.additional_styles": [],
        "product.price": "50",
        "prop.Y29yZT1NZXRhbCtwdXJpdHkmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "product.title": "LAB CREATED DIAMOND MATCHING PAIR 1/2 CARAT CADILLAC CUT LOOSE ACCENT STONES",
        "prop.Y29yZT1UcmVhdG1lbnQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1NYWluK3N0b25lJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1TdHlsZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1PY2Nhc2lvbiZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "shopify.ivy_and_rose.product_type": null,
        "ebay.ivy_and_rose.price": "50",
        "product.description": "Highlights:\n\r\n- ½ carat matched pair of cadillac cut lab diamonds\n\r\n- Bright white with E-F color\n\r\n- Very clean with VVS-VS clarity\n\r\n- The diamonds are lab grown. They offer all the beauty and durability of earth mined diamonds at an excellent value.\n\r\n\n\r\nAdditional Notes:\n\r\nThe diamonds are being sold loose, and would make perfect sides on a 3 stone ring. We are a full service jeweler, and would be happy to create a finished piece for you using these stones.",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Lab Grown Diamondx2",
        "etsy.ivy_and_rose.category_id": "6648",
        "shopify.ivy_and_rose.vendor": null,
        "product.cost": "10",
        "prop.Y29yZT1TaXphYmxlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1NaW4rc2l6ZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "4",
        "prop.Y29yZT1NYWluK3N0b25lK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1JdGVtK3R5cGUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Loose Diamond",
        "prop.Y29yZT1FcmEmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Modern",
        "product.internal_title": null,
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY29sb3ImZWJheT0mZXRzeT0mc2hvcGlmeT0": "E-F",
        "shopify.ilya_fine_jewelry.price": null,
        "prop.Y29yZT1Db2xsZWN0aW9uJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "product.notes": null,
        "shopify.ilya_fine_jewelry.title": null,
        "product.stock_number": null,
        "etsy.ivy_and_rose.when_made": "made_to_order",
        "prop.Y29yZT1DYW4rYmUrcGVyc29uYWxpemVkJmViYXk9JmV0c3k9JnNob3BpZnk9": "Yes",
        "product.location": "some",
        "shopify.ilya_fine_jewelry.seo_title": null,
        "prop.Y29yZT1OYXR1cmFsJTJGbGFiLWNyZWF0ZWQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Lab Grown",
        "ebay.ivy_and_rose.store_category2_id": "14736889",
        "shopify.ilya_fine_jewelry.vendor": null,
        "shopify.ivy_and_rose.seo_title": null,
        "prop.Y29yZT1HZW1zdG9uZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "Lab Grown Diamondx2",
        "prop.Y29yZT1TaWRlK3N0b25lJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "shopify.ilya_fine_jewelry.collection_ids": [],
        "shopify.ilya_fine_jewelry.tags": [],
        "prop.Y29yZT1TaWRlK3N0b25lK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "ebay.ivy_and_rose.category_id": "262026",
        "prop.Y29yZT1NYWRlK3RvK29yZGVyJmViYXk9JmV0c3k9JnNob3BpZnk9": "Yes",
        "product.availability": 4,
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrc2hhcGUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Cadillac Cut",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY2xhcml0eSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "VVS-VS",
        "prop.Y29yZT1TaWRlK3N0b25lK2NsYXJpdHkmZWJheT0mZXRzeT0mc2hvcGlmeT0": null
      },
      "id": "6d96ee8b-7f46-4a4b-8ae7-fca9d6b07344",
      "sku": "LG-19522-CAD-.50"
    },
    {
      "data": {
        "prop.Y29yZT1Ub3RhbCtjYXJhdCt3ZWlnaHQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "1.37ct",
        "prop.Y29yZT1SZWNpcGllbnQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Women",
        "shopify.ivy_and_rose.collection_ids": [],
        "shopify.ivy_and_rose.tags": [],
        "prop.Y29yZT1TZXR0aW5nK3N0eWxlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1EaWFtb25kK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "shopify.ilya_fine_jewelry.product_type": null,
        "prop.Y29yZT1DbGFyaXR5JmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1TaWRlK3N0b25lK3NoYXBlJmViYXk9JmV0c3k9JnNob3BpZnk9": "Emerald: Custom Cut; Diamonds: Round",
        "etsy.ivy_and_rose.who_made": "i_did",
        "prop.Y29yZT1TaXplJmViYXk9JmV0c3k9JnNob3BpZnk9": "6.5 (Resizable)",
        "prop.Y29yZT1DZW50ZXIrbWluK3dpZHRoK2ZpdCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "etsy.ivy_and_rose.price": "50",
        "prop.Y29yZT1DZXJ0aWZpY2F0aW9uJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "etsy.ivy_and_rose.title": "Old Mine Cut Diamond Emerald Cocktail Ring Vintage Style White Gold Calibre Halo",
        "ebay.ivy_and_rose.store_category_id": "14736886",
        "product.main_style": "Cocktail",
        "product.sku": "20005-17324-16251-EM",
        "shopify.ivy_and_rose.title": null,
        "shopify.ivy_and_rose.price": null,
        "prop.Y29yZT1TaWRlK3N0b25lcytxdWFudGl0eSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "etsy.ivy_and_rose.is_supply": false,
        "prop.Y29yZT1HcmFtK3dlaWdodCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1DZW50ZXIrbWF4K2xlbmd0aCtmaXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "ebay.ivy_and_rose.condition": "NEW",
        "prop.Y29yZT1TZWNvbmRhcnkrc3RvbmUmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1TaWRlK3N0b25lK2NhcmF0K3dlaWdodCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "Emeralds: 0.34ct; Diamonds: 0.20ct",
        "prop.Y29yZT1SaW5nK3NpemUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "6.5",
        "prop.Y29yZT1NZXRhbCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "14k White Gold",
        "prop.Y29yZT1UaGVtZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1BcHByb3hpbWF0ZSt0aW1lK3BlcmlvZCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "New - Vintage Reproduction",
        "prop.Y29yZT1NYWluK3N0b25lK3NoYXBlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1DZW50ZXIrbWluK2xlbmd0aCtmaXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "ebay.ivy_and_rose.secondary_category_id": null,
        "prop.Y29yZT1NYXgrc2l6ZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "10",
        "prop.Y29yZT1GYW5jeStkaWFtb25kK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "etsy.ivy_and_rose.shop_section_id": "20281911",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY2FyYXQrd2VpZ2h0JmViYXk9JmV0c3k9JnNob3BpZnk9": "0.83ct",
        "prop.Y29yZT1DdXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1NYXRlcmlhbCttdWx0aSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "14k White Gold",
        "prop.Y29yZT1DZW50ZXIrbWF4K3dpZHRoK2ZpdCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "ebay.ivy_and_rose.sub_title": null,
        "product.additional_styles": [
          "Halo"
        ],
        "product.price": "50",
        "prop.Y29yZT1NZXRhbCtwdXJpdHkmZWJheT0mZXRzeT0mc2hvcGlmeT0": "14k",
        "product.title": "OLD MINE CUT DIAMOND EMERALD COCKTAIL RING VINTAGE STYLE WHITE GOLD CALIBRE HALO",
        "prop.Y29yZT1UcmVhdG1lbnQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Not Treated, Not Enhanced",
        "prop.Y29yZT1NYWluK3N0b25lJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1TdHlsZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1PY2Nhc2lvbiZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "shopify.ivy_and_rose.product_type": null,
        "ebay.ivy_and_rose.price": "50",
        "product.description": "Why we love it: \n\r\nThis diamond and emerald ring has an eye catching Art Deco style target design with a square double halo of glittering diamonds and calibre cut emerald! It features an original antique diamond with a gorgeous old mine cut. It is an excellent choice for an engagement or cocktail ring.\n\r\n\n\r\nHighlights:\n\r\n- 0.83ct diamond center\n\r\n- Gorgeous cushion shape old mine cut\n\r\n- Faces up with minimal warmth with a color grade of K-L. Appears bright against the emeralds\n\r\n- Completely eye clean with a clarity grade of SI1\n\r\n- Calibre cut natural emeralds\n\r\n- Bright white and eye clean diamond accent stones\n\r\n- Classic 14k white gold bezel setting\n\r\n\n\r\nDimensions:\n\r\n12.4mm across (north-south) and 4.8mm from the finger to the top of the ring.",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Diamond",
        "etsy.ivy_and_rose.category_id": "1231",
        "shopify.ivy_and_rose.vendor": null,
        "product.cost": "10",
        "prop.Y29yZT1TaXphYmxlJmViYXk9JmV0c3k9JnNob3BpZnk9": "yes",
        "prop.Y29yZT1NaW4rc2l6ZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "4",
        "prop.Y29yZT1NYWluK3N0b25lK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1JdGVtK3R5cGUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Cocktail Ring - Other",
        "prop.Y29yZT1FcmEmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Vintage Inspired",
        "product.internal_title": "ring - virtual one off",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY29sb3ImZWJheT0mZXRzeT0mc2hvcGlmeT0": "K-L",
        "shopify.ilya_fine_jewelry.price": null,
        "prop.Y29yZT1Db2xsZWN0aW9uJmViYXk9JmV0c3k9JnNob3BpZnk9": "Harmony",
        "product.notes": null,
        "shopify.ilya_fine_jewelry.title": null,
        "product.stock_number": null,
        "etsy.ivy_and_rose.when_made": "made_to_order",
        "prop.Y29yZT1DYW4rYmUrcGVyc29uYWxpemVkJmViYXk9JmV0c3k9JnNob3BpZnk9": "Yes",
        "product.location": "some",
        "shopify.ilya_fine_jewelry.seo_title": null,
        "prop.Y29yZT1OYXR1cmFsJTJGbGFiLWNyZWF0ZWQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Natural",
        "ebay.ivy_and_rose.store_category2_id": "14736870",
        "shopify.ilya_fine_jewelry.vendor": null,
        "shopify.ivy_and_rose.seo_title": null,
        "prop.Y29yZT1HZW1zdG9uZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "Diamond",
        "prop.Y29yZT1TaWRlK3N0b25lJmViYXk9JmV0c3k9JnNob3BpZnk9": "Emerald, Diamond",
        "shopify.ilya_fine_jewelry.collection_ids": [],
        "shopify.ilya_fine_jewelry.tags": [],
        "prop.Y29yZT1TaWRlK3N0b25lK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": "Emerald: Green; Diamond: G-H",
        "ebay.ivy_and_rose.category_id": "261994",
        "prop.Y29yZT1NYWRlK3RvK29yZGVyJmViYXk9JmV0c3k9JnNob3BpZnk9": "Yes",
        "product.availability": 1,
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrc2hhcGUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Old Mine Cut",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY2xhcml0eSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "SI1",
        "prop.Y29yZT1TaWRlK3N0b25lK2NsYXJpdHkmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Diamond: SI"
      },
      "id": "e025b4b9-b0ff-4ea7-ac96-e2b69b0007e8",
      "sku": "20005-17324-16251-EM"
    },
    {
      "data": {
        "prop.Y29yZT1Ub3RhbCtjYXJhdCt3ZWlnaHQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1SZWNpcGllbnQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Women",
        "shopify.ivy_and_rose.collection_ids": [],
        "shopify.ivy_and_rose.tags": [],
        "prop.Y29yZT1TZXR0aW5nK3N0eWxlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1EaWFtb25kK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "shopify.ilya_fine_jewelry.product_type": null,
        "prop.Y29yZT1DbGFyaXR5JmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1TaWRlK3N0b25lK3NoYXBlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "etsy.ivy_and_rose.who_made": "i_did",
        "prop.Y29yZT1TaXplJmViYXk9JmV0c3k9JnNob3BpZnk9": "15\"",
        "prop.Y29yZT1DZW50ZXIrbWluK3dpZHRoK2ZpdCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "etsy.ivy_and_rose.price": "50",
        "prop.Y29yZT1DZXJ0aWZpY2F0aW9uJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "etsy.ivy_and_rose.title": "Tiffany &amp; Co Sterling Silver Hematite Necklace Mens Tube Bead Aztec Zig Zag 15In",
        "ebay.ivy_and_rose.store_category_id": "14736770",
        "product.main_style": null,
        "product.sku": "20318",
        "shopify.ivy_and_rose.title": null,
        "shopify.ivy_and_rose.price": null,
        "prop.Y29yZT1TaWRlK3N0b25lcytxdWFudGl0eSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "etsy.ivy_and_rose.is_supply": false,
        "prop.Y29yZT1HcmFtK3dlaWdodCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "44.9 grams",
        "prop.Y29yZT1DZW50ZXIrbWF4K2xlbmd0aCtmaXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "ebay.ivy_and_rose.condition": "NEW",
        "prop.Y29yZT1TZWNvbmRhcnkrc3RvbmUmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1TaWRlK3N0b25lK2NhcmF0K3dlaWdodCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1SaW5nK3NpemUmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1NZXRhbCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "Sterling Silver",
        "prop.Y29yZT1UaGVtZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1BcHByb3hpbWF0ZSt0aW1lK3BlcmlvZCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "New",
        "prop.Y29yZT1NYWluK3N0b25lK3NoYXBlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1DZW50ZXIrbWluK2xlbmd0aCtmaXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "ebay.ivy_and_rose.secondary_category_id": "261993",
        "prop.Y29yZT1NYXgrc2l6ZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "10",
        "prop.Y29yZT1GYW5jeStkaWFtb25kK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "etsy.ivy_and_rose.shop_section_id": "17869125",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY2FyYXQrd2VpZ2h0JmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1DdXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1NYXRlcmlhbCttdWx0aSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1DZW50ZXIrbWF4K3dpZHRoK2ZpdCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "ebay.ivy_and_rose.sub_title": null,
        "product.additional_styles": [],
        "product.price": "50",
        "prop.Y29yZT1NZXRhbCtwdXJpdHkmZWJheT0mZXRzeT0mc2hvcGlmeT0": "925",
        "product.title": "TIFFANY &amp; CO STERLING SILVER HEMATITE NECKLACE MENS TUBE BEAD AZTEC ZIG ZAG 15in",
        "prop.Y29yZT1UcmVhdG1lbnQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1NYWluK3N0b25lJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1TdHlsZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1PY2Nhc2lvbiZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "shopify.ivy_and_rose.product_type": null,
        "ebay.ivy_and_rose.price": "50",
        "product.description": "Why we love it: \r\n\r\nThis silver and hematite necklace is an authentic Tiffany &amp; Co piece! The silver pieces are textured and interspersed with black hematite for a simple classic look.\r\n\r\n\r\n\r\nHighlights:\r\n\r\n- Authentic Tiffany &amp; Co, bearing the Tiffany hallmark\r\n\r\n- Sterling silver tube beads with “Aztec” zig-zag design\r\n\r\n- Black hematite disc accents\r\n\r\n- Versatile aesthetic for any gender\r\n\r\n\r\n\r\nDimensions:\r\n\r\nThe necklace is 15 inches in length and 4.4mm in width.",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "etsy.ivy_and_rose.category_id": "1221",
        "shopify.ivy_and_rose.vendor": null,
        "product.cost": "10",
        "prop.Y29yZT1TaXphYmxlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1NaW4rc2l6ZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "4",
        "prop.Y29yZT1NYWluK3N0b25lK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1JdGVtK3R5cGUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Necklace - Chain (New-No Stones)",
        "prop.Y29yZT1FcmEmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Modern",
        "product.internal_title": null,
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY29sb3ImZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "shopify.ilya_fine_jewelry.price": null,
        "prop.Y29yZT1Db2xsZWN0aW9uJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "product.notes": "4.4mm width (\"Aztec Etched zig zag)",
        "shopify.ilya_fine_jewelry.title": null,
        "product.stock_number": null,
        "etsy.ivy_and_rose.when_made": "2010_2019",
        "prop.Y29yZT1DYW4rYmUrcGVyc29uYWxpemVkJmViYXk9JmV0c3k9JnNob3BpZnk9": "Yes",
        "product.location": "some",
        "shopify.ilya_fine_jewelry.seo_title": null,
        "prop.Y29yZT1OYXR1cmFsJTJGbGFiLWNyZWF0ZWQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "ebay.ivy_and_rose.store_category2_id": null,
        "shopify.ilya_fine_jewelry.vendor": null,
        "shopify.ivy_and_rose.seo_title": null,
        "prop.Y29yZT1HZW1zdG9uZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1TaWRlK3N0b25lJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "shopify.ilya_fine_jewelry.collection_ids": [],
        "shopify.ilya_fine_jewelry.tags": [],
        "prop.Y29yZT1TaWRlK3N0b25lK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "ebay.ivy_and_rose.category_id": "137839",
        "prop.Y29yZT1NYWRlK3RvK29yZGVyJmViYXk9JmV0c3k9JnNob3BpZnk9": "No",
        "product.availability": 1,
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrc2hhcGUmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY2xhcml0eSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1TaWRlK3N0b25lK2NsYXJpdHkmZWJheT0mZXRzeT0mc2hvcGlmeT0": null
      },
      "id": "119ce236-a91e-4f80-928d-b14951404b50",
      "sku": "20318"
    },
    {
      "data": {
        "prop.Y29yZT1Ub3RhbCtjYXJhdCt3ZWlnaHQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "5.09ct",
        "prop.Y29yZT1SZWNpcGllbnQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Women",
        "shopify.ivy_and_rose.collection_ids": [],
        "shopify.ivy_and_rose.tags": [],
        "prop.Y29yZT1TZXR0aW5nK3N0eWxlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1EaWFtb25kK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "shopify.ilya_fine_jewelry.product_type": null,
        "prop.Y29yZT1DbGFyaXR5JmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1TaWRlK3N0b25lK3NoYXBlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "etsy.ivy_and_rose.who_made": "someone_else",
        "prop.Y29yZT1TaXplJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1DZW50ZXIrbWluK3dpZHRoK2ZpdCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "etsy.ivy_and_rose.price": "50",
        "prop.Y29yZT1DZXJ0aWZpY2F0aW9uJmViYXk9JmV0c3k9JnNob3BpZnk9": "GIA",
        "etsy.ivy_and_rose.title": "5.09Ct Old Mine Cut Diamond Gia Certified S-T Vs2 Loose Natural Antique 5 Carat",
        "ebay.ivy_and_rose.store_category_id": "14736769",
        "product.main_style": null,
        "product.sku": "20384",
        "shopify.ivy_and_rose.title": null,
        "shopify.ivy_and_rose.price": null,
        "prop.Y29yZT1TaWRlK3N0b25lcytxdWFudGl0eSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "etsy.ivy_and_rose.is_supply": true,
        "prop.Y29yZT1HcmFtK3dlaWdodCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1DZW50ZXIrbWF4K2xlbmd0aCtmaXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "ebay.ivy_and_rose.condition": "USED_EXCELLENT",
        "prop.Y29yZT1TZWNvbmRhcnkrc3RvbmUmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1TaWRlK3N0b25lK2NhcmF0K3dlaWdodCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1SaW5nK3NpemUmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1NZXRhbCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1UaGVtZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1BcHByb3hpbWF0ZSt0aW1lK3BlcmlvZCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "Victorian/Edwardian",
        "prop.Y29yZT1NYWluK3N0b25lK3NoYXBlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1DZW50ZXIrbWluK2xlbmd0aCtmaXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "ebay.ivy_and_rose.secondary_category_id": null,
        "prop.Y29yZT1NYXgrc2l6ZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "10",
        "prop.Y29yZT1GYW5jeStkaWFtb25kK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "etsy.ivy_and_rose.shop_section_id": "18036037",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY2FyYXQrd2VpZ2h0JmViYXk9JmV0c3k9JnNob3BpZnk9": "5.09ct (10.66mm x 10.12mm)",
        "prop.Y29yZT1DdXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1NYXRlcmlhbCttdWx0aSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1DZW50ZXIrbWF4K3dpZHRoK2ZpdCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "ebay.ivy_and_rose.sub_title": null,
        "product.additional_styles": [],
        "product.price": "50",
        "prop.Y29yZT1NZXRhbCtwdXJpdHkmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "product.title": "5.09ct OLD MINE CUT DIAMOND GIA CERTIFIED S-T VS2 LOOSE NATURAL ANTIQUE 5 CARAT",
        "prop.Y29yZT1UcmVhdG1lbnQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Not Treated, Not Enhanced",
        "prop.Y29yZT1NYWluK3N0b25lJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1TdHlsZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1PY2Nhc2lvbiZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "shopify.ivy_and_rose.product_type": null,
        "ebay.ivy_and_rose.price": "50",
        "product.description": "Highlights:\n\r\n- Impressive 5.09ct size!\n\r\n- True antique. Cut around the turn of the 19th to 20th century.\n\r\n- Beautiful old mine cut\n\r\n- GIA certified\n\r\n- S-T color with minimal warmth.\n\r\n- Exceptionally clean with VS2 clarity. Great VS2! Very close to VS1\n\r\n- Fantastic sparkle and brilliance!\n\r\n\n\r\nAdditional Notes:\n\r\nThe diamond is being sold loose, but it can be mounted upon request.",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Diamond (GIA Certified)",
        "etsy.ivy_and_rose.category_id": "6648",
        "shopify.ivy_and_rose.vendor": null,
        "product.cost": "10",
        "prop.Y29yZT1TaXphYmxlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1NaW4rc2l6ZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "4",
        "prop.Y29yZT1NYWluK3N0b25lK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1JdGVtK3R5cGUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Loose Diamond",
        "prop.Y29yZT1FcmEmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Original Vintage",
        "product.internal_title": null,
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY29sb3ImZWJheT0mZXRzeT0mc2hvcGlmeT0": "S-T",
        "shopify.ilya_fine_jewelry.price": null,
        "prop.Y29yZT1Db2xsZWN0aW9uJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "product.notes": "Gorgeous Face Up, VS2++",
        "shopify.ilya_fine_jewelry.title": null,
        "product.stock_number": null,
        "etsy.ivy_and_rose.when_made": "1800s",
        "prop.Y29yZT1DYW4rYmUrcGVyc29uYWxpemVkJmViYXk9JmV0c3k9JnNob3BpZnk9": "Yes",
        "product.location": "some",
        "shopify.ilya_fine_jewelry.seo_title": null,
        "prop.Y29yZT1OYXR1cmFsJTJGbGFiLWNyZWF0ZWQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Natural",
        "ebay.ivy_and_rose.store_category2_id": "14736889",
        "shopify.ilya_fine_jewelry.vendor": null,
        "shopify.ivy_and_rose.seo_title": null,
        "prop.Y29yZT1HZW1zdG9uZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "Diamond (GIA Certified)",
        "prop.Y29yZT1TaWRlK3N0b25lJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "shopify.ilya_fine_jewelry.collection_ids": [],
        "shopify.ilya_fine_jewelry.tags": [],
        "prop.Y29yZT1TaWRlK3N0b25lK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "ebay.ivy_and_rose.category_id": "262026",
        "prop.Y29yZT1NYWRlK3RvK29yZGVyJmViYXk9JmV0c3k9JnNob3BpZnk9": "No",
        "product.availability": 1,
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrc2hhcGUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Old Mine Cut",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY2xhcml0eSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "VS2",
        "prop.Y29yZT1TaWRlK3N0b25lK2NsYXJpdHkmZWJheT0mZXRzeT0mc2hvcGlmeT0": null
      },
      "id": "b26591d5-e3b1-4f05-ad17-24b7b745e20a",
      "sku": "20384"
    },
    {
      "data": {
        "prop.Y29yZT1Ub3RhbCtjYXJhdCt3ZWlnaHQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "1.90ct",
        "prop.Y29yZT1SZWNpcGllbnQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Women",
        "shopify.ivy_and_rose.collection_ids": [],
        "shopify.ivy_and_rose.tags": [],
        "prop.Y29yZT1TZXR0aW5nK3N0eWxlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1EaWFtb25kK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "shopify.ilya_fine_jewelry.product_type": null,
        "prop.Y29yZT1DbGFyaXR5JmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1TaWRlK3N0b25lK3NoYXBlJmViYXk9JmV0c3k9JnNob3BpZnk9": "Diamond: Vintage Single Cut, Ruby: Round",
        "etsy.ivy_and_rose.who_made": "someone_else",
        "prop.Y29yZT1TaXplJmViYXk9JmV0c3k9JnNob3BpZnk9": "9.5 (Resizable)",
        "prop.Y29yZT1DZW50ZXIrbWluK3dpZHRoK2ZpdCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "etsy.ivy_and_rose.price": "50",
        "prop.Y29yZT1DZXJ0aWZpY2F0aW9uJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "etsy.ivy_and_rose.title": "Designer Diamond Burma Ruby Cocktail Ring Pave Big Vintage Effy 14K Yellow Gold",
        "ebay.ivy_and_rose.store_category_id": "14736886",
        "product.main_style": "Cocktail",
        "product.sku": "19797",
        "shopify.ivy_and_rose.title": null,
        "shopify.ivy_and_rose.price": null,
        "prop.Y29yZT1TaWRlK3N0b25lcytxdWFudGl0eSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "etsy.ivy_and_rose.is_supply": false,
        "prop.Y29yZT1HcmFtK3dlaWdodCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "5.9 grams",
        "prop.Y29yZT1DZW50ZXIrbWF4K2xlbmd0aCtmaXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "ebay.ivy_and_rose.condition": "USED_EXCELLENT",
        "prop.Y29yZT1TZWNvbmRhcnkrc3RvbmUmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1TaWRlK3N0b25lK2NhcmF0K3dlaWdodCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "Diamond: 0.50ctw, Ruby: 1.40ctw",
        "prop.Y29yZT1SaW5nK3NpemUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "9.5",
        "prop.Y29yZT1NZXRhbCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "14k Yellow Gold",
        "prop.Y29yZT1UaGVtZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1BcHByb3hpbWF0ZSt0aW1lK3BlcmlvZCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "Retro",
        "prop.Y29yZT1NYWluK3N0b25lK3NoYXBlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1DZW50ZXIrbWluK2xlbmd0aCtmaXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "ebay.ivy_and_rose.secondary_category_id": "262014",
        "prop.Y29yZT1NYXgrc2l6ZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "10",
        "prop.Y29yZT1GYW5jeStkaWFtb25kK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "etsy.ivy_and_rose.shop_section_id": "21991708",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY2FyYXQrd2VpZ2h0JmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1DdXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1NYXRlcmlhbCttdWx0aSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "14k Yellow Gold",
        "prop.Y29yZT1DZW50ZXIrbWF4K3dpZHRoK2ZpdCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "ebay.ivy_and_rose.sub_title": null,
        "product.additional_styles": [],
        "product.price": "50",
        "prop.Y29yZT1NZXRhbCtwdXJpdHkmZWJheT0mZXRzeT0mc2hvcGlmeT0": "14k",
        "product.title": "DESIGNER DIAMOND BURMA RUBY COCKTAIL RING PAVE BIG VINTAGE EFFY 14k YELLOW GOLD",
        "prop.Y29yZT1UcmVhdG1lbnQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1NYWluK3N0b25lJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1TdHlsZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1PY2Nhc2lvbiZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "shopify.ivy_and_rose.product_type": null,
        "ebay.ivy_and_rose.price": "50",
        "product.description": "Why we love it: \n\r\nThis vintage designer ring bears the EFFY hallmark and is luxuriously designed with criss crossing loops of pave set diamonds and rich red rubies! Between the glittering diamonds and rubies and substantial 14k yellow gold setting, this ring has an air of glamour and luxury!\n\r\n\n\r\nHighlights:\n\r\n- 0.50ct of high quality G VS diamonds\n\r\n- 1.40ct of high quality rich red rubies. \n\r\n- Rubies have a geographic origin of Myanmar (Burma). This is the most sought after origin for rubies as this area is known to produce superior quality!\n\r\n- Large look!\n\r\n- Substantial 14k yellow gold setting\n\r\n- Designer piece, signed by EFFY\n\r\n\n\r\nDimensions:\n\r\nThe ring measures 16.3mm across (north to south) and 4.6mm from finger to top.",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "etsy.ivy_and_rose.category_id": "1231",
        "shopify.ivy_and_rose.vendor": null,
        "product.cost": "10",
        "prop.Y29yZT1TaXphYmxlJmViYXk9JmV0c3k9JnNob3BpZnk9": "yes",
        "prop.Y29yZT1NaW4rc2l6ZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "4",
        "prop.Y29yZT1NYWluK3N0b25lK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1JdGVtK3R5cGUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Cocktail Ring - Other",
        "prop.Y29yZT1FcmEmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Original Vintage",
        "product.internal_title": "ring - cocktail - vintage",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY29sb3ImZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "shopify.ilya_fine_jewelry.price": null,
        "prop.Y29yZT1Db2xsZWN0aW9uJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "product.notes": "16.3 x 4.6 (signed piece EFFY, Burma)",
        "shopify.ilya_fine_jewelry.title": null,
        "product.stock_number": null,
        "etsy.ivy_and_rose.when_made": "1950s",
        "prop.Y29yZT1DYW4rYmUrcGVyc29uYWxpemVkJmViYXk9JmV0c3k9JnNob3BpZnk9": "Yes",
        "product.location": "some",
        "shopify.ilya_fine_jewelry.seo_title": null,
        "prop.Y29yZT1OYXR1cmFsJTJGbGFiLWNyZWF0ZWQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Natural",
        "ebay.ivy_and_rose.store_category2_id": "14736870",
        "shopify.ilya_fine_jewelry.vendor": null,
        "shopify.ivy_and_rose.seo_title": null,
        "prop.Y29yZT1HZW1zdG9uZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "Diamond, Ruby",
        "prop.Y29yZT1TaWRlK3N0b25lJmViYXk9JmV0c3k9JnNob3BpZnk9": "Diamond, Ruby",
        "shopify.ilya_fine_jewelry.collection_ids": [],
        "shopify.ilya_fine_jewelry.tags": [],
        "prop.Y29yZT1TaWRlK3N0b25lK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": "Diamond:G, Ruby: Red",
        "ebay.ivy_and_rose.category_id": "261994",
        "prop.Y29yZT1NYWRlK3RvK29yZGVyJmViYXk9JmV0c3k9JnNob3BpZnk9": "No",
        "product.availability": 0,
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrc2hhcGUmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY2xhcml0eSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1TaWRlK3N0b25lK2NsYXJpdHkmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Diamond: VS"
      },
      "id": "3cb0591b-ba5f-47e8-9098-f2596748496c",
      "sku": "19797"
    },
    {
      "data": {
        "prop.Y29yZT1Ub3RhbCtjYXJhdCt3ZWlnaHQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "0.91ct",
        "prop.Y29yZT1SZWNpcGllbnQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Women",
        "shopify.ivy_and_rose.collection_ids": [],
        "shopify.ivy_and_rose.tags": [],
        "prop.Y29yZT1TZXR0aW5nK3N0eWxlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1EaWFtb25kK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "shopify.ilya_fine_jewelry.product_type": null,
        "prop.Y29yZT1DbGFyaXR5JmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1TaWRlK3N0b25lK3NoYXBlJmViYXk9JmV0c3k9JnNob3BpZnk9": "Diamond: Round, Sapphire: Marquise",
        "etsy.ivy_and_rose.who_made": "i_did",
        "prop.Y29yZT1TaXplJmViYXk9JmV0c3k9JnNob3BpZnk9": "5.75 (Resizable)",
        "prop.Y29yZT1DZW50ZXIrbWluK3dpZHRoK2ZpdCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "etsy.ivy_and_rose.price": "50",
        "prop.Y29yZT1DZXJ0aWZpY2F0aW9uJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "etsy.ivy_and_rose.title": "Sapphire Diamond Cocktail Ring 14K Yellow Gold Cluster Natural Oval Shape Estate",
        "ebay.ivy_and_rose.store_category_id": "6106058",
        "product.main_style": "Cocktail",
        "product.sku": "19658",
        "shopify.ivy_and_rose.title": null,
        "shopify.ivy_and_rose.price": null,
        "prop.Y29yZT1TaWRlK3N0b25lcytxdWFudGl0eSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "etsy.ivy_and_rose.is_supply": false,
        "prop.Y29yZT1HcmFtK3dlaWdodCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "2.3 grams",
        "prop.Y29yZT1DZW50ZXIrbWF4K2xlbmd0aCtmaXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "ebay.ivy_and_rose.condition": "NEW",
        "prop.Y29yZT1TZWNvbmRhcnkrc3RvbmUmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1TaWRlK3N0b25lK2NhcmF0K3dlaWdodCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "Diamond: 0.22ctw, Sapphire: 0.36ctw",
        "prop.Y29yZT1SaW5nK3NpemUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "5.75",
        "prop.Y29yZT1NZXRhbCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "14k Yellow Gold",
        "prop.Y29yZT1UaGVtZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1BcHByb3hpbWF0ZSt0aW1lK3BlcmlvZCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "New",
        "prop.Y29yZT1NYWluK3N0b25lK3NoYXBlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1DZW50ZXIrbWluK2xlbmd0aCtmaXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "ebay.ivy_and_rose.secondary_category_id": null,
        "prop.Y29yZT1NYXgrc2l6ZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "10",
        "prop.Y29yZT1GYW5jeStkaWFtb25kK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "etsy.ivy_and_rose.shop_section_id": "21991708",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY2FyYXQrd2VpZ2h0JmViYXk9JmV0c3k9JnNob3BpZnk9": "0.33ct",
        "prop.Y29yZT1DdXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1NYXRlcmlhbCttdWx0aSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "14k Yellow Gold",
        "prop.Y29yZT1DZW50ZXIrbWF4K3dpZHRoK2ZpdCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "ebay.ivy_and_rose.sub_title": null,
        "product.additional_styles": [],
        "product.price": "50",
        "prop.Y29yZT1NZXRhbCtwdXJpdHkmZWJheT0mZXRzeT0mc2hvcGlmeT0": "14k",
        "product.title": "SAPPHIRE DIAMOND COCKTAIL RING 14k YELLOW GOLD CLUSTER NATURAL OVAL SHAPE ESTATE",
        "prop.Y29yZT1UcmVhdG1lbnQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1NYWluK3N0b25lJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1TdHlsZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1PY2Nhc2lvbiZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "shopify.ivy_and_rose.product_type": null,
        "ebay.ivy_and_rose.price": "50",
        "product.description": "Why we love it: \n\r\nThis vintage sapphire and diamond ring features a rich blue sapphire set at the center of a cascading diamond and sapphire studded design\n\r\n\n\r\nHighlights:\n\r\n- 0.33ct natural sapphire center with a rich blue color\n\r\n- 0.22ct of nicely white and eye clean natural diamonds\n\r\n- Additional 0.36ct of natural sapphire accents\n\r\n- 14k yellow gold setting \n\r\n\n\r\nDimensions:\n\r\n14.0mm across (north-south) and 6.3mm from the finger to the top of the setting.",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Sapphire",
        "etsy.ivy_and_rose.category_id": "1231",
        "shopify.ivy_and_rose.vendor": null,
        "product.cost": "10",
        "prop.Y29yZT1TaXphYmxlJmViYXk9JmV0c3k9JnNob3BpZnk9": "yes",
        "prop.Y29yZT1NaW4rc2l6ZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "4",
        "prop.Y29yZT1NYWluK3N0b25lK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1JdGVtK3R5cGUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Cocktail Ring - Other",
        "prop.Y29yZT1FcmEmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Modern",
        "product.internal_title": "ring - cocktail - modern",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY29sb3ImZWJheT0mZXRzeT0mc2hvcGlmeT0": "Blue",
        "shopify.ilya_fine_jewelry.price": null,
        "prop.Y29yZT1Db2xsZWN0aW9uJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "product.notes": "14.0 x 6.3 (natural sapphire)",
        "shopify.ilya_fine_jewelry.title": null,
        "product.stock_number": null,
        "etsy.ivy_and_rose.when_made": "2010_2019",
        "prop.Y29yZT1DYW4rYmUrcGVyc29uYWxpemVkJmViYXk9JmV0c3k9JnNob3BpZnk9": "Yes",
        "product.location": "some",
        "shopify.ilya_fine_jewelry.seo_title": null,
        "prop.Y29yZT1OYXR1cmFsJTJGbGFiLWNyZWF0ZWQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Natural",
        "ebay.ivy_and_rose.store_category2_id": null,
        "shopify.ilya_fine_jewelry.vendor": null,
        "shopify.ivy_and_rose.seo_title": null,
        "prop.Y29yZT1HZW1zdG9uZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "Sapphire",
        "prop.Y29yZT1TaWRlK3N0b25lJmViYXk9JmV0c3k9JnNob3BpZnk9": "Diamond, Sapphire",
        "shopify.ilya_fine_jewelry.collection_ids": [],
        "shopify.ilya_fine_jewelry.tags": [],
        "prop.Y29yZT1TaWRlK3N0b25lK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": "Diamond: I-J, Sapphire: Blue",
        "ebay.ivy_and_rose.category_id": "261994",
        "prop.Y29yZT1NYWRlK3RvK29yZGVyJmViYXk9JmV0c3k9JnNob3BpZnk9": "No",
        "product.availability": 1,
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrc2hhcGUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Oval",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY2xhcml0eSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1TaWRlK3N0b25lK2NsYXJpdHkmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Diamond: VS2-SI1"
      },
      "id": "61327ce1-0998-4312-87b5-4d489813911f",
      "sku": "19658"
    },
    {
      "data": {
        "prop.Y29yZT1Ub3RhbCtjYXJhdCt3ZWlnaHQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "0.25ct",
        "prop.Y29yZT1SZWNpcGllbnQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Women",
        "shopify.ivy_and_rose.collection_ids": [],
        "shopify.ivy_and_rose.tags": [],
        "prop.Y29yZT1TZXR0aW5nK3N0eWxlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1EaWFtb25kK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "shopify.ilya_fine_jewelry.product_type": null,
        "prop.Y29yZT1DbGFyaXR5JmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1TaWRlK3N0b25lK3NoYXBlJmViYXk9JmV0c3k9JnNob3BpZnk9": "Vintage Single Cut",
        "etsy.ivy_and_rose.who_made": "someone_else",
        "prop.Y29yZT1TaXplJmViYXk9JmV0c3k9JnNob3BpZnk9": "14\" Chain",
        "prop.Y29yZT1DZW50ZXIrbWluK3dpZHRoK2ZpdCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "etsy.ivy_and_rose.price": "50",
        "prop.Y29yZT1DZXJ0aWZpY2F0aW9uJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "etsy.ivy_and_rose.title": "Vintage Diamond Chandelier Necklace 18K White Gold Drop Dangle Natural Estate",
        "ebay.ivy_and_rose.store_category_id": "5809673",
        "product.main_style": "Chandelier",
        "product.sku": "19562",
        "shopify.ivy_and_rose.title": null,
        "shopify.ivy_and_rose.price": null,
        "prop.Y29yZT1TaWRlK3N0b25lcytxdWFudGl0eSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "etsy.ivy_and_rose.is_supply": false,
        "prop.Y29yZT1HcmFtK3dlaWdodCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "6.9 grams",
        "prop.Y29yZT1DZW50ZXIrbWF4K2xlbmd0aCtmaXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "ebay.ivy_and_rose.condition": "USED_EXCELLENT",
        "prop.Y29yZT1TZWNvbmRhcnkrc3RvbmUmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1TaWRlK3N0b25lK2NhcmF0K3dlaWdodCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "0.25ctw",
        "prop.Y29yZT1SaW5nK3NpemUmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1NZXRhbCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "18k White Gold",
        "prop.Y29yZT1UaGVtZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1BcHByb3hpbWF0ZSt0aW1lK3BlcmlvZCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "Retro",
        "prop.Y29yZT1NYWluK3N0b25lK3NoYXBlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1DZW50ZXIrbWluK2xlbmd0aCtmaXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "ebay.ivy_and_rose.secondary_category_id": "262013",
        "prop.Y29yZT1NYXgrc2l6ZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "10",
        "prop.Y29yZT1GYW5jeStkaWFtb25kK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "etsy.ivy_and_rose.shop_section_id": "17869125",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY2FyYXQrd2VpZ2h0JmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1DdXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1NYXRlcmlhbCttdWx0aSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "18k White Gold",
        "prop.Y29yZT1DZW50ZXIrbWF4K3dpZHRoK2ZpdCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "ebay.ivy_and_rose.sub_title": null,
        "product.additional_styles": [],
        "product.price": "50",
        "prop.Y29yZT1NZXRhbCtwdXJpdHkmZWJheT0mZXRzeT0mc2hvcGlmeT0": "18k",
        "product.title": "VINTAGE DIAMOND CHANDELIER NECKLACE 18k WHITE GOLD DROP DANGLE NATURAL ESTATE",
        "prop.Y29yZT1UcmVhdG1lbnQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Not Treated, Not Enhanced",
        "prop.Y29yZT1NYWluK3N0b25lJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1TdHlsZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1PY2Nhc2lvbiZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "shopify.ivy_and_rose.product_type": null,
        "ebay.ivy_and_rose.price": "50",
        "product.description": "Why we love it: \n\r\nThis vintage diamond necklace is crafted in high quality 18k white gold with an elegant chandelier design. The pendant features three diamond studded chain drops for a beautifully dynamic look with lots of movement!\n\r\n\n\r\nHighlights:\n\r\n- Original vintage\n\r\n- 0.25ct of bright white and eye clean diamonds\n\r\n- 18k white gold setting and chain\n\r\n\n\r\nDimensions:\n\r\nThe necklace is 14 inches in length with a 2 in drop.",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "etsy.ivy_and_rose.category_id": "1217",
        "shopify.ivy_and_rose.vendor": null,
        "product.cost": "10",
        "prop.Y29yZT1TaXphYmxlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1NaW4rc2l6ZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "4",
        "prop.Y29yZT1NYWluK3N0b25lK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1JdGVtK3R5cGUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Necklace - Other",
        "prop.Y29yZT1FcmEmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Original Vintage",
        "product.internal_title": null,
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY29sb3ImZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "shopify.ilya_fine_jewelry.price": null,
        "prop.Y29yZT1Db2xsZWN0aW9uJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "product.notes": "2\" dangle",
        "shopify.ilya_fine_jewelry.title": null,
        "product.stock_number": null,
        "etsy.ivy_and_rose.when_made": "1950s",
        "prop.Y29yZT1DYW4rYmUrcGVyc29uYWxpemVkJmViYXk9JmV0c3k9JnNob3BpZnk9": "Yes",
        "product.location": "some",
        "shopify.ilya_fine_jewelry.seo_title": null,
        "prop.Y29yZT1OYXR1cmFsJTJGbGFiLWNyZWF0ZWQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Natural",
        "ebay.ivy_and_rose.store_category2_id": "14736889",
        "shopify.ilya_fine_jewelry.vendor": null,
        "shopify.ivy_and_rose.seo_title": null,
        "prop.Y29yZT1HZW1zdG9uZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "Diamond",
        "prop.Y29yZT1TaWRlK3N0b25lJmViYXk9JmV0c3k9JnNob3BpZnk9": "Diamond",
        "shopify.ilya_fine_jewelry.collection_ids": [],
        "shopify.ilya_fine_jewelry.tags": [],
        "prop.Y29yZT1TaWRlK3N0b25lK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": "G-H",
        "ebay.ivy_and_rose.category_id": "261993",
        "prop.Y29yZT1NYWRlK3RvK29yZGVyJmViYXk9JmV0c3k9JnNob3BpZnk9": "No",
        "product.availability": 1,
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrc2hhcGUmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY2xhcml0eSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1TaWRlK3N0b25lK2NsYXJpdHkmZWJheT0mZXRzeT0mc2hvcGlmeT0": "SI1-SI2"
      },
      "id": "718a4aa4-a5df-451f-ba32-de030dae13c1",
      "sku": "19562"
    },
    {
      "data": {
        "prop.Y29yZT1Ub3RhbCtjYXJhdCt3ZWlnaHQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "1.28ct",
        "prop.Y29yZT1SZWNpcGllbnQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Women",
        "shopify.ivy_and_rose.collection_ids": [],
        "shopify.ivy_and_rose.tags": [],
        "prop.Y29yZT1TZXR0aW5nK3N0eWxlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1EaWFtb25kK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "shopify.ilya_fine_jewelry.product_type": null,
        "prop.Y29yZT1DbGFyaXR5JmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1TaWRlK3N0b25lK3NoYXBlJmViYXk9JmV0c3k9JnNob3BpZnk9": "Vintage Single Cut",
        "etsy.ivy_and_rose.who_made": "someone_else",
        "prop.Y29yZT1TaXplJmViYXk9JmV0c3k9JnNob3BpZnk9": "6.0 (Resizable)",
        "prop.Y29yZT1DZW50ZXIrbWluK3dpZHRoK2ZpdCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "etsy.ivy_and_rose.price": "50",
        "prop.Y29yZT1DZXJ0aWZpY2F0aW9uJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "etsy.ivy_and_rose.title": "Vintage Diamond Cocktail Ring Old European Cut Platinum Art Deco Engagement",
        "ebay.ivy_and_rose.store_category_id": "14736886",
        "product.main_style": "Cocktail",
        "product.sku": "19529",
        "shopify.ivy_and_rose.title": null,
        "shopify.ivy_and_rose.price": null,
        "prop.Y29yZT1TaWRlK3N0b25lcytxdWFudGl0eSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "etsy.ivy_and_rose.is_supply": false,
        "prop.Y29yZT1HcmFtK3dlaWdodCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "3.5 grams",
        "prop.Y29yZT1DZW50ZXIrbWF4K2xlbmd0aCtmaXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "ebay.ivy_and_rose.condition": "USED_EXCELLENT",
        "prop.Y29yZT1TZWNvbmRhcnkrc3RvbmUmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1TaWRlK3N0b25lK2NhcmF0K3dlaWdodCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "0.50ctw",
        "prop.Y29yZT1SaW5nK3NpemUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "6",
        "prop.Y29yZT1NZXRhbCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "Platinum",
        "prop.Y29yZT1UaGVtZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1BcHByb3hpbWF0ZSt0aW1lK3BlcmlvZCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "Art Deco/Art Nouveau",
        "prop.Y29yZT1NYWluK3N0b25lK3NoYXBlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1DZW50ZXIrbWluK2xlbmd0aCtmaXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "ebay.ivy_and_rose.secondary_category_id": "262014",
        "prop.Y29yZT1NYXgrc2l6ZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "10",
        "prop.Y29yZT1GYW5jeStkaWFtb25kK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "etsy.ivy_and_rose.shop_section_id": "17746504",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY2FyYXQrd2VpZ2h0JmViYXk9JmV0c3k9JnNob3BpZnk9": "0.78ct",
        "prop.Y29yZT1DdXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1NYXRlcmlhbCttdWx0aSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "Platinum",
        "prop.Y29yZT1DZW50ZXIrbWF4K3dpZHRoK2ZpdCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "ebay.ivy_and_rose.sub_title": null,
        "product.additional_styles": [
          "Halo"
        ],
        "product.price": "50",
        "prop.Y29yZT1NZXRhbCtwdXJpdHkmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Plat",
        "product.title": "VINTAGE DIAMOND COCKTAIL RING OLD EUROPEAN CUT PLATINUM ART DECO ENGAGEMENT",
        "prop.Y29yZT1UcmVhdG1lbnQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Not Treated, Not Enhanced",
        "prop.Y29yZT1NYWluK3N0b25lJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1TdHlsZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1PY2Nhc2lvbiZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "shopify.ivy_and_rose.product_type": null,
        "ebay.ivy_and_rose.price": "50",
        "product.description": "Why we love it: \n\r\nThis vintage diamond ring is handmade with beautiful, diamond encrusted tiers and a gorgeous old European cut center diamond! This ring is a fantastic find with eye catching sparkle and an iconic Art Deco design!\n\r\n\n\r\nHighlights:\n\r\n- Original vintage. Art Deco era.\n\r\n- 0.78ct old European cut diamond center\n\r\n- Bright white and 100% eye clean, graded G SI1. \n\r\n- 0.50ct of bright white and eye clean accent diamonds\n\r\n- Solid platinum setting with an elegant geometric design\n\r\n\n\r\nDimensions:\n\r\n9.8mm across (north-south) and 4.7mm from the finger to the top of the ring",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Diamond",
        "etsy.ivy_and_rose.category_id": "1231",
        "shopify.ivy_and_rose.vendor": null,
        "product.cost": "10",
        "prop.Y29yZT1TaXphYmxlJmViYXk9JmV0c3k9JnNob3BpZnk9": "yes",
        "prop.Y29yZT1NaW4rc2l6ZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "4",
        "prop.Y29yZT1NYWluK3N0b25lK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1JdGVtK3R5cGUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Cocktail Ring - Other",
        "prop.Y29yZT1FcmEmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Original Vintage",
        "product.internal_title": "ring - cocktail - vintage",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY29sb3ImZWJheT0mZXRzeT0mc2hvcGlmeT0": "G",
        "shopify.ilya_fine_jewelry.price": null,
        "prop.Y29yZT1Db2xsZWN0aW9uJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "product.notes": "9.8 x 4.7 (True O.G. ITSOOT)",
        "shopify.ilya_fine_jewelry.title": null,
        "product.stock_number": null,
        "etsy.ivy_and_rose.when_made": "1920s",
        "prop.Y29yZT1DYW4rYmUrcGVyc29uYWxpemVkJmViYXk9JmV0c3k9JnNob3BpZnk9": "Yes",
        "product.location": "some",
        "shopify.ilya_fine_jewelry.seo_title": null,
        "prop.Y29yZT1OYXR1cmFsJTJGbGFiLWNyZWF0ZWQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Natural",
        "ebay.ivy_and_rose.store_category2_id": "14736870",
        "shopify.ilya_fine_jewelry.vendor": null,
        "shopify.ivy_and_rose.seo_title": null,
        "prop.Y29yZT1HZW1zdG9uZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "Diamond",
        "prop.Y29yZT1TaWRlK3N0b25lJmViYXk9JmV0c3k9JnNob3BpZnk9": "Diamond",
        "shopify.ilya_fine_jewelry.collection_ids": [],
        "shopify.ilya_fine_jewelry.tags": [],
        "prop.Y29yZT1TaWRlK3N0b25lK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": "F-G",
        "ebay.ivy_and_rose.category_id": "261994",
        "prop.Y29yZT1NYWRlK3RvK29yZGVyJmViYXk9JmV0c3k9JnNob3BpZnk9": "No",
        "product.availability": 0,
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrc2hhcGUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Old European Cut",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY2xhcml0eSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "SI1",
        "prop.Y29yZT1TaWRlK3N0b25lK2NsYXJpdHkmZWJheT0mZXRzeT0mc2hvcGlmeT0": "VS1-VS2, Couple SI"
      },
      "id": "5584c77b-4b28-4870-a2ff-863f2396d43a",
      "sku": "19529"
    },
    {
      "data": {
        "prop.Y29yZT1Ub3RhbCtjYXJhdCt3ZWlnaHQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "16.65ct",
        "prop.Y29yZT1SZWNpcGllbnQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Women",
        "shopify.ivy_and_rose.collection_ids": [],
        "shopify.ivy_and_rose.tags": [],
        "prop.Y29yZT1TZXR0aW5nK3N0eWxlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1EaWFtb25kK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "shopify.ilya_fine_jewelry.product_type": null,
        "prop.Y29yZT1DbGFyaXR5JmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1TaWRlK3N0b25lK3NoYXBlJmViYXk9JmV0c3k9JnNob3BpZnk9": "Diamond: Round, Topaz: Round, Square",
        "etsy.ivy_and_rose.who_made": "i_did",
        "prop.Y29yZT1TaXplJmViYXk9JmV0c3k9JnNob3BpZnk9": "16\" Chain",
        "prop.Y29yZT1DZW50ZXIrbWluK3dpZHRoK2ZpdCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "etsy.ivy_and_rose.price": "50",
        "prop.Y29yZT1DZXJ0aWZpY2F0aW9uJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "etsy.ivy_and_rose.title": "Blue Topaz Diamond Lariat Necklace 14K White Gold Briolette Drop Halo Natural",
        "ebay.ivy_and_rose.store_category_id": "5809673",
        "product.main_style": "Lariat",
        "product.sku": "19271",
        "shopify.ivy_and_rose.title": null,
        "shopify.ivy_and_rose.price": null,
        "prop.Y29yZT1TaWRlK3N0b25lcytxdWFudGl0eSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "etsy.ivy_and_rose.is_supply": false,
        "prop.Y29yZT1HcmFtK3dlaWdodCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "10.4 grams",
        "prop.Y29yZT1DZW50ZXIrbWF4K2xlbmd0aCtmaXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "ebay.ivy_and_rose.condition": "NEW",
        "prop.Y29yZT1TZWNvbmRhcnkrc3RvbmUmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1TaWRlK3N0b25lK2NhcmF0K3dlaWdodCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "Diamond: 1.00ctw, Topaz: 3.15ctw",
        "prop.Y29yZT1SaW5nK3NpemUmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1NZXRhbCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "14k White Gold",
        "prop.Y29yZT1UaGVtZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1BcHByb3hpbWF0ZSt0aW1lK3BlcmlvZCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "New",
        "prop.Y29yZT1NYWluK3N0b25lK3NoYXBlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1DZW50ZXIrbWluK2xlbmd0aCtmaXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "ebay.ivy_and_rose.secondary_category_id": null,
        "prop.Y29yZT1NYXgrc2l6ZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "10",
        "prop.Y29yZT1GYW5jeStkaWFtb25kK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "etsy.ivy_and_rose.shop_section_id": "17869125",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY2FyYXQrd2VpZ2h0JmViYXk9JmV0c3k9JnNob3BpZnk9": "Approx. 12.5ct",
        "prop.Y29yZT1DdXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1NYXRlcmlhbCttdWx0aSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "14k White Gold",
        "prop.Y29yZT1DZW50ZXIrbWF4K3dpZHRoK2ZpdCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "ebay.ivy_and_rose.sub_title": null,
        "product.additional_styles": [],
        "product.price": "50",
        "prop.Y29yZT1NZXRhbCtwdXJpdHkmZWJheT0mZXRzeT0mc2hvcGlmeT0": "14k",
        "product.title": "BLUE TOPAZ DIAMOND LARIAT NECKLACE 14k WHITE GOLD BRIOLETTE DROP HALO NATURAL",
        "prop.Y29yZT1UcmVhdG1lbnQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1NYWluK3N0b25lJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1TdHlsZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1PY2Nhc2lvbiZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "shopify.ivy_and_rose.product_type": null,
        "ebay.ivy_and_rose.price": "50",
        "product.description": "Why we love it: \n\r\nThis topaz and diamond necklace features a luscious blue topaz briolette dropped below an elegant lariat design studded with diamond bordered topaz.\n\r\n\n\r\nHighlights:\n\r\n- Large 12.5 carat blue topaz briolette with a bright blue hue.\n\r\n- Additional 3.15ct of blue topaz accents\n\r\n- 1 carat of natural diamonds\n\r\n- All bright white and completely eye clean, graded G-H VS2-SI2\n\r\n- 14k white gold chain and settings. Sturdy and substantial settings!\n\r\n\n\r\nDimensions:\n\r\nThe necklace is 16 inches in length with a 2 in drop.",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Topaz",
        "etsy.ivy_and_rose.category_id": "1217",
        "shopify.ivy_and_rose.vendor": null,
        "product.cost": "10",
        "prop.Y29yZT1TaXphYmxlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1NaW4rc2l6ZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "4",
        "prop.Y29yZT1NYWluK3N0b25lK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1JdGVtK3R5cGUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Necklace - Other",
        "prop.Y29yZT1FcmEmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Modern",
        "product.internal_title": null,
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY29sb3ImZWJheT0mZXRzeT0mc2hvcGlmeT0": "Blue",
        "shopify.ilya_fine_jewelry.price": null,
        "prop.Y29yZT1Db2xsZWN0aW9uJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "product.notes": "2\" drop (Significant gold)",
        "shopify.ilya_fine_jewelry.title": null,
        "product.stock_number": null,
        "etsy.ivy_and_rose.when_made": "2010_2019",
        "prop.Y29yZT1DYW4rYmUrcGVyc29uYWxpemVkJmViYXk9JmV0c3k9JnNob3BpZnk9": "Yes",
        "product.location": "some",
        "shopify.ilya_fine_jewelry.seo_title": null,
        "prop.Y29yZT1OYXR1cmFsJTJGbGFiLWNyZWF0ZWQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Natural",
        "ebay.ivy_and_rose.store_category2_id": null,
        "shopify.ilya_fine_jewelry.vendor": null,
        "shopify.ivy_and_rose.seo_title": null,
        "prop.Y29yZT1HZW1zdG9uZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "Topaz",
        "prop.Y29yZT1TaWRlK3N0b25lJmViYXk9JmV0c3k9JnNob3BpZnk9": "Diamond, Topaz",
        "shopify.ilya_fine_jewelry.collection_ids": [],
        "shopify.ilya_fine_jewelry.tags": [],
        "prop.Y29yZT1TaWRlK3N0b25lK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": "Diamond: G-H, Topaz: Blue",
        "ebay.ivy_and_rose.category_id": "261993",
        "prop.Y29yZT1NYWRlK3RvK29yZGVyJmViYXk9JmV0c3k9JnNob3BpZnk9": "No",
        "product.availability": 1,
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrc2hhcGUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Briolette",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY2xhcml0eSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1TaWRlK3N0b25lK2NsYXJpdHkmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Diamond: VS2-SI2"
      },
      "id": "39fddfdc-d673-407a-9afe-3ae20a2094a6",
      "sku": "19271"
    },
    {
      "data": {
        "prop.Y29yZT1Ub3RhbCtjYXJhdCt3ZWlnaHQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "0.57ct",
        "prop.Y29yZT1SZWNpcGllbnQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Women",
        "shopify.ivy_and_rose.collection_ids": [],
        "shopify.ivy_and_rose.tags": [],
        "prop.Y29yZT1TZXR0aW5nK3N0eWxlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1EaWFtb25kK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "shopify.ilya_fine_jewelry.product_type": null,
        "prop.Y29yZT1DbGFyaXR5JmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1TaWRlK3N0b25lK3NoYXBlJmViYXk9JmV0c3k9JnNob3BpZnk9": "Old European Cut",
        "etsy.ivy_and_rose.who_made": "someone_else",
        "prop.Y29yZT1TaXplJmViYXk9JmV0c3k9JnNob3BpZnk9": "5.25 (Resizable)",
        "prop.Y29yZT1DZW50ZXIrbWluK3dpZHRoK2ZpdCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "etsy.ivy_and_rose.price": "50",
        "prop.Y29yZT1DZXJ0aWZpY2F0aW9uJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "etsy.ivy_and_rose.title": "Vintage Diamond Cocktail Ring Art Deco Geometric 14K White Gold Old European Cut",
        "ebay.ivy_and_rose.store_category_id": "14736886",
        "product.main_style": "Cocktail",
        "product.sku": "19250",
        "shopify.ivy_and_rose.title": null,
        "shopify.ivy_and_rose.price": null,
        "prop.Y29yZT1TaWRlK3N0b25lcytxdWFudGl0eSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "2",
        "etsy.ivy_and_rose.is_supply": false,
        "prop.Y29yZT1HcmFtK3dlaWdodCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "2.6 grams",
        "prop.Y29yZT1DZW50ZXIrbWF4K2xlbmd0aCtmaXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "ebay.ivy_and_rose.condition": "USED_EXCELLENT",
        "prop.Y29yZT1TZWNvbmRhcnkrc3RvbmUmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1TaWRlK3N0b25lK2NhcmF0K3dlaWdodCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "0.32ctw",
        "prop.Y29yZT1SaW5nK3NpemUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "5.25",
        "prop.Y29yZT1NZXRhbCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "18k White Gold",
        "prop.Y29yZT1UaGVtZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1BcHByb3hpbWF0ZSt0aW1lK3BlcmlvZCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "Art Deco/Art Nouveau",
        "prop.Y29yZT1NYWluK3N0b25lK3NoYXBlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1DZW50ZXIrbWluK2xlbmd0aCtmaXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "ebay.ivy_and_rose.secondary_category_id": "262014",
        "prop.Y29yZT1NYXgrc2l6ZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "10",
        "prop.Y29yZT1GYW5jeStkaWFtb25kK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "etsy.ivy_and_rose.shop_section_id": "17746504",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY2FyYXQrd2VpZ2h0JmViYXk9JmV0c3k9JnNob3BpZnk9": "0.25ct",
        "prop.Y29yZT1DdXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1NYXRlcmlhbCttdWx0aSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "18k White Gold",
        "prop.Y29yZT1DZW50ZXIrbWF4K3dpZHRoK2ZpdCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "ebay.ivy_and_rose.sub_title": null,
        "product.additional_styles": [],
        "product.price": "50",
        "prop.Y29yZT1NZXRhbCtwdXJpdHkmZWJheT0mZXRzeT0mc2hvcGlmeT0": "18k",
        "product.title": "VINTAGE DIAMOND COCKTAIL RING ART DECO GEOMETRIC 14k WHITE GOLD OLD EUROPEAN CUT",
        "prop.Y29yZT1UcmVhdG1lbnQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Not Treated, Not Enhanced",
        "prop.Y29yZT1NYWluK3N0b25lJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1TdHlsZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1PY2Nhc2lvbiZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "shopify.ivy_and_rose.product_type": null,
        "ebay.ivy_and_rose.price": "50",
        "product.description": "Why we love it: \n\r\nThis vintage diamond ring features a trio of diamonds in a beautifully crafted geometric design with gorgeous fine filigree details.\n\r\n\n\r\nHighlights:\n\r\n- Original vintage from the Art Deco era\n\r\n- Three old European cut diamonds totaling 0.57ct\n\r\n- Lovely subtle champagne hue and all eye clean with clarity grades of SI1-SI2\n\r\n- Hand crafted 18k white gold setting\n\r\n\n\r\nDimensions:\n\r\n16.4mm across (north-south) and 4.0mm from the finger to the top of the setting.",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Diamond",
        "etsy.ivy_and_rose.category_id": "1231",
        "shopify.ivy_and_rose.vendor": null,
        "product.cost": "10",
        "prop.Y29yZT1TaXphYmxlJmViYXk9JmV0c3k9JnNob3BpZnk9": "yes",
        "prop.Y29yZT1NaW4rc2l6ZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "4",
        "prop.Y29yZT1NYWluK3N0b25lK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1JdGVtK3R5cGUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Cocktail Ring - Other",
        "prop.Y29yZT1FcmEmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Original Vintage",
        "product.internal_title": "ring - cocktail - vintage",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY29sb3ImZWJheT0mZXRzeT0mc2hvcGlmeT0": "Champagne",
        "shopify.ilya_fine_jewelry.price": null,
        "prop.Y29yZT1Db2xsZWN0aW9uJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "product.notes": "16.4 x 4.0",
        "shopify.ilya_fine_jewelry.title": null,
        "product.stock_number": null,
        "etsy.ivy_and_rose.when_made": "1920s",
        "prop.Y29yZT1DYW4rYmUrcGVyc29uYWxpemVkJmViYXk9JmV0c3k9JnNob3BpZnk9": "Yes",
        "product.location": "some",
        "shopify.ilya_fine_jewelry.seo_title": null,
        "prop.Y29yZT1OYXR1cmFsJTJGbGFiLWNyZWF0ZWQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Natural",
        "ebay.ivy_and_rose.store_category2_id": "14736870",
        "shopify.ilya_fine_jewelry.vendor": null,
        "shopify.ivy_and_rose.seo_title": null,
        "prop.Y29yZT1HZW1zdG9uZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "Diamond",
        "prop.Y29yZT1TaWRlK3N0b25lJmViYXk9JmV0c3k9JnNob3BpZnk9": "Diamond",
        "shopify.ilya_fine_jewelry.collection_ids": [],
        "shopify.ilya_fine_jewelry.tags": [],
        "prop.Y29yZT1TaWRlK3N0b25lK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": "Champagne",
        "ebay.ivy_and_rose.category_id": "261994",
        "prop.Y29yZT1NYWRlK3RvK29yZGVyJmViYXk9JmV0c3k9JnNob3BpZnk9": "No",
        "product.availability": 1,
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrc2hhcGUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "SI1",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY2xhcml0eSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1TaWRlK3N0b25lK2NsYXJpdHkmZWJheT0mZXRzeT0mc2hvcGlmeT0": "SI1-SI2"
      },
      "id": "0d902f46-d05c-4297-8e8a-2d10cd45b18b",
      "sku": "19250"
    },
    {
      "data": {
        "prop.Y29yZT1Ub3RhbCtjYXJhdCt3ZWlnaHQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1SZWNpcGllbnQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Women",
        "shopify.ivy_and_rose.collection_ids": [],
        "shopify.ivy_and_rose.tags": [],
        "prop.Y29yZT1TZXR0aW5nK3N0eWxlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1EaWFtb25kK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "shopify.ilya_fine_jewelry.product_type": null,
        "prop.Y29yZT1DbGFyaXR5JmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1TaWRlK3N0b25lK3NoYXBlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "etsy.ivy_and_rose.who_made": "someone_else",
        "prop.Y29yZT1TaXplJmViYXk9JmV0c3k9JnNob3BpZnk9": "8.75 (Resizable)",
        "prop.Y29yZT1DZW50ZXIrbWluK3dpZHRoK2ZpdCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "etsy.ivy_and_rose.price": "50",
        "prop.Y29yZT1DZXJ0aWZpY2F0aW9uJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "etsy.ivy_and_rose.title": "Vintage 14K Yellow Gold Ring Wedding Band Engraved Brush Finish Milgrain Estate",
        "ebay.ivy_and_rose.store_category_id": "14843984",
        "product.main_style": null,
        "product.sku": "19203",
        "shopify.ivy_and_rose.title": null,
        "shopify.ivy_and_rose.price": null,
        "prop.Y29yZT1TaWRlK3N0b25lcytxdWFudGl0eSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "etsy.ivy_and_rose.is_supply": false,
        "prop.Y29yZT1HcmFtK3dlaWdodCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "2.4 grams",
        "prop.Y29yZT1DZW50ZXIrbWF4K2xlbmd0aCtmaXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "ebay.ivy_and_rose.condition": "USED_EXCELLENT",
        "prop.Y29yZT1TZWNvbmRhcnkrc3RvbmUmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1TaWRlK3N0b25lK2NhcmF0K3dlaWdodCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1SaW5nK3NpemUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "8.75",
        "prop.Y29yZT1NZXRhbCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "10k Yellow Gold",
        "prop.Y29yZT1UaGVtZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1BcHByb3hpbWF0ZSt0aW1lK3BlcmlvZCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "Retro",
        "prop.Y29yZT1NYWluK3N0b25lK3NoYXBlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1DZW50ZXIrbWluK2xlbmd0aCtmaXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "ebay.ivy_and_rose.secondary_category_id": "262014",
        "prop.Y29yZT1NYXgrc2l6ZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "10",
        "prop.Y29yZT1GYW5jeStkaWFtb25kK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "etsy.ivy_and_rose.shop_section_id": "17822028",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY2FyYXQrd2VpZ2h0JmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1DdXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1NYXRlcmlhbCttdWx0aSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "10k Yellow Gold",
        "prop.Y29yZT1DZW50ZXIrbWF4K3dpZHRoK2ZpdCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "ebay.ivy_and_rose.sub_title": null,
        "product.additional_styles": [],
        "product.price": "50",
        "prop.Y29yZT1NZXRhbCtwdXJpdHkmZWJheT0mZXRzeT0mc2hvcGlmeT0": "10k",
        "product.title": "VINTAGE 14k YELLOW GOLD RING WEDDING BAND ENGRAVED BRUSH FINISH MILGRAIN ESTATE",
        "prop.Y29yZT1UcmVhdG1lbnQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1NYWluK3N0b25lJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1TdHlsZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1PY2Nhc2lvbiZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "shopify.ivy_and_rose.product_type": null,
        "ebay.ivy_and_rose.price": "50",
        "product.description": "Why we love it: \n\r\nThis vintage band is beautifully detailed with milgrain, engraving, and a gorgeous textured finish!\n\r\n\n\r\nHighlights:\n\r\n- Solid 10k yellow gold\n\r\n\n\r\nDimensions:\n\r\n4.7mm across (north-south) and 1.0mm in height.",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "etsy.ivy_and_rose.category_id": "1247",
        "shopify.ivy_and_rose.vendor": null,
        "product.cost": "10",
        "prop.Y29yZT1TaXphYmxlJmViYXk9JmV0c3k9JnNob3BpZnk9": "yes",
        "prop.Y29yZT1NaW4rc2l6ZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "4",
        "prop.Y29yZT1NYWluK3N0b25lK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1JdGVtK3R5cGUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Band - Wedding",
        "prop.Y29yZT1FcmEmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Original Vintage",
        "product.internal_title": "ring - wedding band - vintage",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY29sb3ImZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "shopify.ilya_fine_jewelry.price": null,
        "prop.Y29yZT1Db2xsZWN0aW9uJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "product.notes": "4.7 x 1.0 (very cool finish)",
        "shopify.ilya_fine_jewelry.title": null,
        "product.stock_number": null,
        "etsy.ivy_and_rose.when_made": "1950s",
        "prop.Y29yZT1DYW4rYmUrcGVyc29uYWxpemVkJmViYXk9JmV0c3k9JnNob3BpZnk9": "Yes",
        "product.location": "some",
        "shopify.ilya_fine_jewelry.seo_title": null,
        "prop.Y29yZT1OYXR1cmFsJTJGbGFiLWNyZWF0ZWQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "ebay.ivy_and_rose.store_category2_id": "14736886",
        "shopify.ilya_fine_jewelry.vendor": null,
        "shopify.ivy_and_rose.seo_title": null,
        "prop.Y29yZT1HZW1zdG9uZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1TaWRlK3N0b25lJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "shopify.ilya_fine_jewelry.collection_ids": [],
        "shopify.ilya_fine_jewelry.tags": [],
        "prop.Y29yZT1TaWRlK3N0b25lK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "ebay.ivy_and_rose.category_id": "261977",
        "prop.Y29yZT1NYWRlK3RvK29yZGVyJmViYXk9JmV0c3k9JnNob3BpZnk9": "No",
        "product.availability": 1,
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrc2hhcGUmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY2xhcml0eSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1TaWRlK3N0b25lK2NsYXJpdHkmZWJheT0mZXRzeT0mc2hvcGlmeT0": null
      },
      "id": "db9109a3-6320-4f1e-b5e6-ff509f5ed469",
      "sku": "19203"
    },
    {
      "data": {
        "prop.Y29yZT1Ub3RhbCtjYXJhdCt3ZWlnaHQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "2.15ct",
        "prop.Y29yZT1SZWNpcGllbnQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Women",
        "shopify.ivy_and_rose.collection_ids": [],
        "shopify.ivy_and_rose.tags": [],
        "prop.Y29yZT1TZXR0aW5nK3N0eWxlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1EaWFtb25kK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "shopify.ilya_fine_jewelry.product_type": null,
        "prop.Y29yZT1DbGFyaXR5JmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1TaWRlK3N0b25lK3NoYXBlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "etsy.ivy_and_rose.who_made": "someone_else",
        "prop.Y29yZT1TaXplJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1DZW50ZXIrbWluK3dpZHRoK2ZpdCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "etsy.ivy_and_rose.price": "50",
        "prop.Y29yZT1DZXJ0aWZpY2F0aW9uJmViYXk9JmV0c3k9JnNob3BpZnk9": "IGI",
        "etsy.ivy_and_rose.title": "2.15Ct Old European Cut Lab Created Diamond Stud Earrings Certified E Vvs 2Ct",
        "ebay.ivy_and_rose.store_category_id": "14736769",
        "product.main_style": null,
        "product.sku": "LG-20857",
        "shopify.ivy_and_rose.title": null,
        "shopify.ivy_and_rose.price": null,
        "prop.Y29yZT1TaWRlK3N0b25lcytxdWFudGl0eSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "etsy.ivy_and_rose.is_supply": true,
        "prop.Y29yZT1HcmFtK3dlaWdodCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1DZW50ZXIrbWF4K2xlbmd0aCtmaXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "ebay.ivy_and_rose.condition": "NEW",
        "prop.Y29yZT1TZWNvbmRhcnkrc3RvbmUmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1TaWRlK3N0b25lK2NhcmF0K3dlaWdodCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1SaW5nK3NpemUmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1NZXRhbCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1UaGVtZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1BcHByb3hpbWF0ZSt0aW1lK3BlcmlvZCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "New - Vintage Reproduction",
        "prop.Y29yZT1NYWluK3N0b25lK3NoYXBlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1DZW50ZXIrbWluK2xlbmd0aCtmaXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "ebay.ivy_and_rose.secondary_category_id": null,
        "prop.Y29yZT1NYXgrc2l6ZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "10",
        "prop.Y29yZT1GYW5jeStkaWFtb25kK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "etsy.ivy_and_rose.shop_section_id": "18036037",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY2FyYXQrd2VpZ2h0JmViYXk9JmV0c3k9JnNob3BpZnk9": "2.15ctw (6.36mm - 6.42mm)",
        "prop.Y29yZT1DdXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1NYXRlcmlhbCttdWx0aSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1DZW50ZXIrbWF4K3dpZHRoK2ZpdCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "ebay.ivy_and_rose.sub_title": null,
        "product.additional_styles": [],
        "product.price": "50",
        "prop.Y29yZT1NZXRhbCtwdXJpdHkmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "product.title": "2.15ct OLD EUROPEAN CUT LAB CREATED DIAMOND STUD EARRINGS CERTIFIED E VVS 2ct",
        "prop.Y29yZT1UcmVhdG1lbnQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1NYWluK3N0b25lJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1TdHlsZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1PY2Nhc2lvbiZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "shopify.ivy_and_rose.product_type": null,
        "ebay.ivy_and_rose.price": "50",
        "product.description": "Highlights:\n\r\n- 2.15 carat size matched pair\n\r\n- Old European cut! This is a reproduction of this charming vintage style!\n\r\n- IGI certified\n\r\n- Very bright white and colorless with E color\n\r\n- Very clean with a grade of VVS2-VS1\n\r\n- These diamonds are lab grown. Lab grown diamonds have all the beauty and durability of an earth mined diamond at an excellent value.\n\r\n\n\r\nAdditional notes:\n\r\n- The diamonds are being sold loose, but they can be mounted upon request into any style of mountings in any metal. They are perfect for studs!",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Diamondx2 (IGI Certified)",
        "etsy.ivy_and_rose.category_id": "6648",
        "shopify.ivy_and_rose.vendor": null,
        "product.cost": "10",
        "prop.Y29yZT1TaXphYmxlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1NaW4rc2l6ZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "4",
        "prop.Y29yZT1NYWluK3N0b25lK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1JdGVtK3R5cGUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Loose Diamond",
        "prop.Y29yZT1FcmEmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Vintage Inspired",
        "product.internal_title": "loose - vintage style - lab diamond",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY29sb3ImZWJheT0mZXRzeT0mc2hvcGlmeT0": "E",
        "shopify.ilya_fine_jewelry.price": null,
        "prop.Y29yZT1Db2xsZWN0aW9uJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "product.notes": null,
        "shopify.ilya_fine_jewelry.title": null,
        "product.stock_number": null,
        "etsy.ivy_and_rose.when_made": "2010_2019",
        "prop.Y29yZT1DYW4rYmUrcGVyc29uYWxpemVkJmViYXk9JmV0c3k9JnNob3BpZnk9": "Yes",
        "product.location": "some",
        "shopify.ilya_fine_jewelry.seo_title": null,
        "prop.Y29yZT1OYXR1cmFsJTJGbGFiLWNyZWF0ZWQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Lab Grown",
        "ebay.ivy_and_rose.store_category2_id": "14736889",
        "shopify.ilya_fine_jewelry.vendor": null,
        "shopify.ivy_and_rose.seo_title": null,
        "prop.Y29yZT1HZW1zdG9uZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "Diamondx2 (IGI Certified)",
        "prop.Y29yZT1TaWRlK3N0b25lJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "shopify.ilya_fine_jewelry.collection_ids": [],
        "shopify.ilya_fine_jewelry.tags": [],
        "prop.Y29yZT1TaWRlK3N0b25lK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "ebay.ivy_and_rose.category_id": "262026",
        "prop.Y29yZT1NYWRlK3RvK29yZGVyJmViYXk9JmV0c3k9JnNob3BpZnk9": "No",
        "product.availability": 1,
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrc2hhcGUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Old European Cut",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY2xhcml0eSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "VVS2-VS1",
        "prop.Y29yZT1TaWRlK3N0b25lK2NsYXJpdHkmZWJheT0mZXRzeT0mc2hvcGlmeT0": null
      },
      "id": "dbe273fd-a32e-4cae-9e5d-946d06915049",
      "sku": "LG-20857"
    },
    {
      "data": {
        "prop.Y29yZT1Ub3RhbCtjYXJhdCt3ZWlnaHQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "2.16ct",
        "prop.Y29yZT1SZWNpcGllbnQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Women",
        "shopify.ivy_and_rose.collection_ids": [],
        "shopify.ivy_and_rose.tags": [],
        "prop.Y29yZT1TZXR0aW5nK3N0eWxlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1EaWFtb25kK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "shopify.ilya_fine_jewelry.product_type": null,
        "prop.Y29yZT1DbGFyaXR5JmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1TaWRlK3N0b25lK3NoYXBlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "etsy.ivy_and_rose.who_made": "someone_else",
        "prop.Y29yZT1TaXplJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1DZW50ZXIrbWluK3dpZHRoK2ZpdCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "etsy.ivy_and_rose.price": "50",
        "prop.Y29yZT1DZXJ0aWZpY2F0aW9uJmViYXk9JmV0c3k9JnNob3BpZnk9": "IGI",
        "etsy.ivy_and_rose.title": "2.16Ct Old European Cut Lab Created Diamond Stud Earrings Certified D Vs 2 Carat",
        "ebay.ivy_and_rose.store_category_id": "14736769",
        "product.main_style": null,
        "product.sku": "LG-20856",
        "shopify.ivy_and_rose.title": null,
        "shopify.ivy_and_rose.price": null,
        "prop.Y29yZT1TaWRlK3N0b25lcytxdWFudGl0eSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "etsy.ivy_and_rose.is_supply": true,
        "prop.Y29yZT1HcmFtK3dlaWdodCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1DZW50ZXIrbWF4K2xlbmd0aCtmaXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "ebay.ivy_and_rose.condition": "NEW",
        "prop.Y29yZT1TZWNvbmRhcnkrc3RvbmUmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1TaWRlK3N0b25lK2NhcmF0K3dlaWdodCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1SaW5nK3NpemUmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1NZXRhbCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1UaGVtZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1BcHByb3hpbWF0ZSt0aW1lK3BlcmlvZCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "New - Vintage Reproduction",
        "prop.Y29yZT1NYWluK3N0b25lK3NoYXBlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1DZW50ZXIrbWluK2xlbmd0aCtmaXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "ebay.ivy_and_rose.secondary_category_id": null,
        "prop.Y29yZT1NYXgrc2l6ZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "10",
        "prop.Y29yZT1GYW5jeStkaWFtb25kK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "etsy.ivy_and_rose.shop_section_id": "18036037",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY2FyYXQrd2VpZ2h0JmViYXk9JmV0c3k9JnNob3BpZnk9": "2.16ctw (6.40mm -6.43mm)",
        "prop.Y29yZT1DdXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1NYXRlcmlhbCttdWx0aSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1DZW50ZXIrbWF4K3dpZHRoK2ZpdCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "ebay.ivy_and_rose.sub_title": null,
        "product.additional_styles": [],
        "product.price": "50",
        "prop.Y29yZT1NZXRhbCtwdXJpdHkmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "product.title": "2.16ct OLD EUROPEAN CUT LAB CREATED DIAMOND STUD EARRINGS CERTIFIED D VS 2 CARAT",
        "prop.Y29yZT1UcmVhdG1lbnQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1NYWluK3N0b25lJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1TdHlsZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1PY2Nhc2lvbiZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "shopify.ivy_and_rose.product_type": null,
        "ebay.ivy_and_rose.price": "50",
        "product.description": "Highlights:\n\r\n- 2.16 carat size matched pair\n\r\n- Old European cut! This is a reproduction of this charming vintage style!\n\r\n- IGI certified\n\r\n- Perfectly white and colorless with D color\n\r\n- Very clean with a grade of VS1\n\r\n- These diamonds are lab grown. Lab grown diamonds have all the beauty and durability of an earth mined diamond at an excellent value.\n\r\n\n\r\nAdditional notes:\n\r\n- The diamonds are being sold loose, but they can be mounted upon request into any style of mountings in any metal. They are perfect for studs!",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Diamondx2 (IGI Certified)",
        "etsy.ivy_and_rose.category_id": "6648",
        "shopify.ivy_and_rose.vendor": null,
        "product.cost": "10",
        "prop.Y29yZT1TaXphYmxlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1NaW4rc2l6ZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "4",
        "prop.Y29yZT1NYWluK3N0b25lK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1JdGVtK3R5cGUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Loose Diamond",
        "prop.Y29yZT1FcmEmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Vintage Inspired",
        "product.internal_title": "loose - vintage style - lab diamond",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY29sb3ImZWJheT0mZXRzeT0mc2hvcGlmeT0": "D",
        "shopify.ilya_fine_jewelry.price": null,
        "prop.Y29yZT1Db2xsZWN0aW9uJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "product.notes": null,
        "shopify.ilya_fine_jewelry.title": null,
        "product.stock_number": null,
        "etsy.ivy_and_rose.when_made": "2010_2019",
        "prop.Y29yZT1DYW4rYmUrcGVyc29uYWxpemVkJmViYXk9JmV0c3k9JnNob3BpZnk9": "Yes",
        "product.location": "some",
        "shopify.ilya_fine_jewelry.seo_title": null,
        "prop.Y29yZT1OYXR1cmFsJTJGbGFiLWNyZWF0ZWQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Lab Grown",
        "ebay.ivy_and_rose.store_category2_id": "14736889",
        "shopify.ilya_fine_jewelry.vendor": null,
        "shopify.ivy_and_rose.seo_title": null,
        "prop.Y29yZT1HZW1zdG9uZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "Diamondx2 (IGI Certified)",
        "prop.Y29yZT1TaWRlK3N0b25lJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "shopify.ilya_fine_jewelry.collection_ids": [],
        "shopify.ilya_fine_jewelry.tags": [],
        "prop.Y29yZT1TaWRlK3N0b25lK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "ebay.ivy_and_rose.category_id": "262026",
        "prop.Y29yZT1NYWRlK3RvK29yZGVyJmViYXk9JmV0c3k9JnNob3BpZnk9": "No",
        "product.availability": 1,
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrc2hhcGUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Old European Cut",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY2xhcml0eSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "VS1",
        "prop.Y29yZT1TaWRlK3N0b25lK2NsYXJpdHkmZWJheT0mZXRzeT0mc2hvcGlmeT0": null
      },
      "id": "835614b6-afff-4f62-92ab-18862dc95fad",
      "sku": "LG-20856"
    },
    {
      "data": {
        "prop.Y29yZT1Ub3RhbCtjYXJhdCt3ZWlnaHQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "4.02ct",
        "prop.Y29yZT1SZWNpcGllbnQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Women",
        "shopify.ivy_and_rose.collection_ids": [],
        "shopify.ivy_and_rose.tags": [],
        "prop.Y29yZT1TZXR0aW5nK3N0eWxlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1EaWFtb25kK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "shopify.ilya_fine_jewelry.product_type": null,
        "prop.Y29yZT1DbGFyaXR5JmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1TaWRlK3N0b25lK3NoYXBlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "etsy.ivy_and_rose.who_made": "someone_else",
        "prop.Y29yZT1TaXplJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1DZW50ZXIrbWluK3dpZHRoK2ZpdCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "etsy.ivy_and_rose.price": "50",
        "prop.Y29yZT1DZXJ0aWZpY2F0aW9uJmViYXk9JmV0c3k9JnNob3BpZnk9": "IGI",
        "etsy.ivy_and_rose.title": "4 Carat Old European Cut Lab Created Diamond Stud Earrings Certified Pair 4Ct",
        "ebay.ivy_and_rose.store_category_id": "14736769",
        "product.main_style": null,
        "product.sku": "LG-20847",
        "shopify.ivy_and_rose.title": null,
        "shopify.ivy_and_rose.price": null,
        "prop.Y29yZT1TaWRlK3N0b25lcytxdWFudGl0eSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "etsy.ivy_and_rose.is_supply": true,
        "prop.Y29yZT1HcmFtK3dlaWdodCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1DZW50ZXIrbWF4K2xlbmd0aCtmaXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "ebay.ivy_and_rose.condition": "NEW",
        "prop.Y29yZT1TZWNvbmRhcnkrc3RvbmUmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1TaWRlK3N0b25lK2NhcmF0K3dlaWdodCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1SaW5nK3NpemUmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1NZXRhbCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1UaGVtZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1BcHByb3hpbWF0ZSt0aW1lK3BlcmlvZCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "New - Vintage Reproduction",
        "prop.Y29yZT1NYWluK3N0b25lK3NoYXBlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1DZW50ZXIrbWluK2xlbmd0aCtmaXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "ebay.ivy_and_rose.secondary_category_id": null,
        "prop.Y29yZT1NYXgrc2l6ZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "10",
        "prop.Y29yZT1GYW5jeStkaWFtb25kK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "etsy.ivy_and_rose.shop_section_id": "18036037",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY2FyYXQrd2VpZ2h0JmViYXk9JmV0c3k9JnNob3BpZnk9": "4.02ctw (7.88mm - 7.93mm)",
        "prop.Y29yZT1DdXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1NYXRlcmlhbCttdWx0aSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1DZW50ZXIrbWF4K3dpZHRoK2ZpdCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "ebay.ivy_and_rose.sub_title": null,
        "product.additional_styles": [],
        "product.price": "50",
        "prop.Y29yZT1NZXRhbCtwdXJpdHkmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "product.title": "4 CARAT OLD EUROPEAN CUT LAB CREATED DIAMOND STUD EARRINGS CERTIFIED PAIR 4ct",
        "prop.Y29yZT1UcmVhdG1lbnQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1NYWluK3N0b25lJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1TdHlsZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1PY2Nhc2lvbiZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "shopify.ivy_and_rose.product_type": null,
        "ebay.ivy_and_rose.price": "50",
        "product.description": "Highlights:\n\r\n- 4.02 carat size matched pair\n\r\n- Old European cut! This is a reproduction of this charming vintage style!\n\r\n- IGI certified\n\r\n- Perfectly white and colorless with D color\n\r\n- Very clean with a grade of VS1\n\r\n- These diamonds are lab grown. Lab grown diamonds have all the beauty and durability of an earth mined diamond at an excellent value.\n\r\n\n\r\nAdditional notes:\n\r\n- The diamonds are being sold loose, but they can be mounted upon request into any style of mountings in any metal. They are perfect for studs!",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Diamondx2 (IGI Certified)",
        "etsy.ivy_and_rose.category_id": "6648",
        "shopify.ivy_and_rose.vendor": null,
        "product.cost": "10",
        "prop.Y29yZT1TaXphYmxlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1NaW4rc2l6ZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "4",
        "prop.Y29yZT1NYWluK3N0b25lK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1JdGVtK3R5cGUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Loose Diamond",
        "prop.Y29yZT1FcmEmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Vintage Inspired",
        "product.internal_title": "loose - vintage style - lab diamond",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY29sb3ImZWJheT0mZXRzeT0mc2hvcGlmeT0": "D",
        "shopify.ilya_fine_jewelry.price": null,
        "prop.Y29yZT1Db2xsZWN0aW9uJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "product.notes": null,
        "shopify.ilya_fine_jewelry.title": null,
        "product.stock_number": null,
        "etsy.ivy_and_rose.when_made": "2010_2019",
        "prop.Y29yZT1DYW4rYmUrcGVyc29uYWxpemVkJmViYXk9JmV0c3k9JnNob3BpZnk9": "Yes",
        "product.location": "some",
        "shopify.ilya_fine_jewelry.seo_title": null,
        "prop.Y29yZT1OYXR1cmFsJTJGbGFiLWNyZWF0ZWQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Lab Grown",
        "ebay.ivy_and_rose.store_category2_id": "14736889",
        "shopify.ilya_fine_jewelry.vendor": null,
        "shopify.ivy_and_rose.seo_title": null,
        "prop.Y29yZT1HZW1zdG9uZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "Diamondx2 (IGI Certified)",
        "prop.Y29yZT1TaWRlK3N0b25lJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "shopify.ilya_fine_jewelry.collection_ids": [],
        "shopify.ilya_fine_jewelry.tags": [],
        "prop.Y29yZT1TaWRlK3N0b25lK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "ebay.ivy_and_rose.category_id": "262026",
        "prop.Y29yZT1NYWRlK3RvK29yZGVyJmViYXk9JmV0c3k9JnNob3BpZnk9": "No",
        "product.availability": 1,
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrc2hhcGUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Old European Cut",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY2xhcml0eSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "VS1",
        "prop.Y29yZT1TaWRlK3N0b25lK2NsYXJpdHkmZWJheT0mZXRzeT0mc2hvcGlmeT0": null
      },
      "id": "9f9897c5-45d3-437d-ab57-b4501d2f87a0",
      "sku": "LG-20847"
    },
    {
      "data": {
        "prop.Y29yZT1Ub3RhbCtjYXJhdCt3ZWlnaHQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "3.61ct",
        "prop.Y29yZT1SZWNpcGllbnQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Women",
        "shopify.ivy_and_rose.collection_ids": [],
        "shopify.ivy_and_rose.tags": [],
        "prop.Y29yZT1TZXR0aW5nK3N0eWxlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1EaWFtb25kK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "shopify.ilya_fine_jewelry.product_type": null,
        "prop.Y29yZT1DbGFyaXR5JmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1TaWRlK3N0b25lK3NoYXBlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "etsy.ivy_and_rose.who_made": "someone_else",
        "prop.Y29yZT1TaXplJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1DZW50ZXIrbWluK3dpZHRoK2ZpdCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "etsy.ivy_and_rose.price": "50",
        "prop.Y29yZT1DZXJ0aWZpY2F0aW9uJmViYXk9JmV0c3k9JnNob3BpZnk9": "EGL-USA",
        "etsy.ivy_and_rose.title": "3.61Ct Old Transitional Cut Diamond Certified K Vs1 Loose Natural 3.5 Carat",
        "ebay.ivy_and_rose.store_category_id": "14736769",
        "product.main_style": null,
        "product.sku": "20342",
        "shopify.ivy_and_rose.title": null,
        "shopify.ivy_and_rose.price": null,
        "prop.Y29yZT1TaWRlK3N0b25lcytxdWFudGl0eSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "etsy.ivy_and_rose.is_supply": true,
        "prop.Y29yZT1HcmFtK3dlaWdodCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1DZW50ZXIrbWF4K2xlbmd0aCtmaXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "ebay.ivy_and_rose.condition": "USED_EXCELLENT",
        "prop.Y29yZT1TZWNvbmRhcnkrc3RvbmUmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1TaWRlK3N0b25lK2NhcmF0K3dlaWdodCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1SaW5nK3NpemUmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1NZXRhbCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1UaGVtZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1BcHByb3hpbWF0ZSt0aW1lK3BlcmlvZCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "Art Deco/Art Nouveau",
        "prop.Y29yZT1NYWluK3N0b25lK3NoYXBlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1DZW50ZXIrbWluK2xlbmd0aCtmaXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "ebay.ivy_and_rose.secondary_category_id": null,
        "prop.Y29yZT1NYXgrc2l6ZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "10",
        "prop.Y29yZT1GYW5jeStkaWFtb25kK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "etsy.ivy_and_rose.shop_section_id": "18036037",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY2FyYXQrd2VpZ2h0JmViYXk9JmV0c3k9JnNob3BpZnk9": "3.61ct (10.02mm - 9.94mm)",
        "prop.Y29yZT1DdXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1NYXRlcmlhbCttdWx0aSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1DZW50ZXIrbWF4K3dpZHRoK2ZpdCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "ebay.ivy_and_rose.sub_title": null,
        "product.additional_styles": [],
        "product.price": "50",
        "prop.Y29yZT1NZXRhbCtwdXJpdHkmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "product.title": "3.61ct OLD TRANSITIONAL CUT DIAMOND CERTIFIED K VS1 LOOSE NATURAL 3.5 CARAT",
        "prop.Y29yZT1UcmVhdG1lbnQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Not Treated, Not Enhanced",
        "prop.Y29yZT1NYWluK3N0b25lJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1TdHlsZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1PY2Nhc2lvbiZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "shopify.ivy_and_rose.product_type": null,
        "ebay.ivy_and_rose.price": "50",
        "product.description": "Highlights:\n\r\n- Original vintage diamond\n\r\n- EGL-USA certified\n\r\n- Impressive 3.61 carat size!\n\r\n- Gorgeous old transitional cut. Beautiful faceting!\n\r\n- Very minimal warmth of the face up with K color\n\r\n- Exceptionally clean with VS1 clarity\n\r\n\n\r\nAdditional Notes:\n\r\nThe diamond is being sold loose, but it can be mounted upon request.",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Diamond (EGL Certified)",
        "etsy.ivy_and_rose.category_id": "6648",
        "shopify.ivy_and_rose.vendor": null,
        "product.cost": "10",
        "prop.Y29yZT1TaXphYmxlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1NaW4rc2l6ZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "4",
        "prop.Y29yZT1NYWluK3N0b25lK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1JdGVtK3R5cGUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Loose Diamond",
        "prop.Y29yZT1FcmEmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Original Vintage",
        "product.internal_title": null,
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY29sb3ImZWJheT0mZXRzeT0mc2hvcGlmeT0": "K",
        "shopify.ilya_fine_jewelry.price": null,
        "prop.Y29yZT1Db2xsZWN0aW9uJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "product.notes": "Gorgeous Make SSYES",
        "shopify.ilya_fine_jewelry.title": null,
        "product.stock_number": null,
        "etsy.ivy_and_rose.when_made": "1920s",
        "prop.Y29yZT1DYW4rYmUrcGVyc29uYWxpemVkJmViYXk9JmV0c3k9JnNob3BpZnk9": "Yes",
        "product.location": "some",
        "shopify.ilya_fine_jewelry.seo_title": null,
        "prop.Y29yZT1OYXR1cmFsJTJGbGFiLWNyZWF0ZWQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Natural",
        "ebay.ivy_and_rose.store_category2_id": "14736889",
        "shopify.ilya_fine_jewelry.vendor": null,
        "shopify.ivy_and_rose.seo_title": null,
        "prop.Y29yZT1HZW1zdG9uZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "Diamond (EGL Certified)",
        "prop.Y29yZT1TaWRlK3N0b25lJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "shopify.ilya_fine_jewelry.collection_ids": [],
        "shopify.ilya_fine_jewelry.tags": [],
        "prop.Y29yZT1TaWRlK3N0b25lK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "ebay.ivy_and_rose.category_id": "262026",
        "prop.Y29yZT1NYWRlK3RvK29yZGVyJmViYXk9JmV0c3k9JnNob3BpZnk9": "No",
        "product.availability": 1,
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrc2hhcGUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Old Transitional Cut",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY2xhcml0eSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "VS1",
        "prop.Y29yZT1TaWRlK3N0b25lK2NsYXJpdHkmZWJheT0mZXRzeT0mc2hvcGlmeT0": null
      },
      "id": "65d0f53c-f2b8-48f4-8d5d-9f5083b391d5",
      "sku": "20342"
    },
    {
      "data": {
        "prop.Y29yZT1Ub3RhbCtjYXJhdCt3ZWlnaHQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "0.03ct",
        "prop.Y29yZT1SZWNpcGllbnQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Women",
        "shopify.ivy_and_rose.collection_ids": [],
        "shopify.ivy_and_rose.tags": [],
        "prop.Y29yZT1TZXR0aW5nK3N0eWxlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1EaWFtb25kK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "shopify.ilya_fine_jewelry.product_type": null,
        "prop.Y29yZT1DbGFyaXR5JmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1TaWRlK3N0b25lK3NoYXBlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "etsy.ivy_and_rose.who_made": "someone_else",
        "prop.Y29yZT1TaXplJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1DZW50ZXIrbWluK3dpZHRoK2ZpdCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "etsy.ivy_and_rose.price": "50",
        "prop.Y29yZT1DZXJ0aWZpY2F0aW9uJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "etsy.ivy_and_rose.title": "Vintage Diamond Filigree Pin Brooch 14K White Gold Floral Natural Estate Pendant",
        "ebay.ivy_and_rose.store_category_id": "5809673",
        "product.main_style": "Pendant",
        "product.sku": "20698",
        "shopify.ivy_and_rose.title": null,
        "shopify.ivy_and_rose.price": null,
        "prop.Y29yZT1TaWRlK3N0b25lcytxdWFudGl0eSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "etsy.ivy_and_rose.is_supply": false,
        "prop.Y29yZT1HcmFtK3dlaWdodCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "2.9 grams",
        "prop.Y29yZT1DZW50ZXIrbWF4K2xlbmd0aCtmaXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "ebay.ivy_and_rose.condition": "USED_EXCELLENT",
        "prop.Y29yZT1TZWNvbmRhcnkrc3RvbmUmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1TaWRlK3N0b25lK2NhcmF0K3dlaWdodCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1SaW5nK3NpemUmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1NZXRhbCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "14k White Gold",
        "prop.Y29yZT1UaGVtZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1BcHByb3hpbWF0ZSt0aW1lK3BlcmlvZCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "Art Deco/Art Nouveau",
        "prop.Y29yZT1NYWluK3N0b25lK3NoYXBlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1DZW50ZXIrbWluK2xlbmd0aCtmaXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "ebay.ivy_and_rose.secondary_category_id": "262013",
        "prop.Y29yZT1NYXgrc2l6ZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "10",
        "prop.Y29yZT1GYW5jeStkaWFtb25kK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "etsy.ivy_and_rose.shop_section_id": "17869125",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY2FyYXQrd2VpZ2h0JmViYXk9JmV0c3k9JnNob3BpZnk9": "0.03ct",
        "prop.Y29yZT1DdXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1NYXRlcmlhbCttdWx0aSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "14k White Gold",
        "prop.Y29yZT1DZW50ZXIrbWF4K3dpZHRoK2ZpdCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "ebay.ivy_and_rose.sub_title": null,
        "product.additional_styles": [
          "Bar"
        ],
        "product.price": "50",
        "prop.Y29yZT1NZXRhbCtwdXJpdHkmZWJheT0mZXRzeT0mc2hvcGlmeT0": "14k",
        "product.title": "VINTAGE DIAMOND FILIGREE PIN BROOCH 14k WHITE GOLD FLORAL NATURAL ESTATE PENDANT",
        "prop.Y29yZT1UcmVhdG1lbnQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Not Treated, Not Enhanced",
        "prop.Y29yZT1NYWluK3N0b25lJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1TdHlsZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "Pendant",
        "prop.Y29yZT1PY2Nhc2lvbiZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "shopify.ivy_and_rose.product_type": null,
        "ebay.ivy_and_rose.price": "50",
        "product.description": "Why we love it: \n\r\nThis vintage pin features a lively diamond at the center of a delicate floral filigree design.\n\r\n\n\r\nHighlights:\n\r\n- Original vintage\n\r\n- Beautifully white and exceptionally clean 0.03ct old European cut diamond\n\r\n- Solid 14k white gold with pin back\n\r\n\n\r\nDimensions:\n\r\n59 (2.3 inch) x 8.3mm\n\r\n\n\r\nAdditional Notes:\n\r\nThis pin can be converted to a pendant on request",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Diamond",
        "etsy.ivy_and_rose.category_id": "1229",
        "shopify.ivy_and_rose.vendor": null,
        "product.cost": "10",
        "prop.Y29yZT1TaXphYmxlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1NaW4rc2l6ZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "4",
        "prop.Y29yZT1NYWluK3N0b25lK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1JdGVtK3R5cGUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Necklace - Pendant",
        "prop.Y29yZT1FcmEmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Original Vintage",
        "product.internal_title": null,
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY29sb3ImZWJheT0mZXRzeT0mc2hvcGlmeT0": "I-J",
        "shopify.ilya_fine_jewelry.price": null,
        "prop.Y29yZT1Db2xsZWN0aW9uJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "product.notes": "8.3 x 59 (can be converted to necklance on requesT",
        "shopify.ilya_fine_jewelry.title": null,
        "product.stock_number": null,
        "etsy.ivy_and_rose.when_made": "1920s",
        "prop.Y29yZT1DYW4rYmUrcGVyc29uYWxpemVkJmViYXk9JmV0c3k9JnNob3BpZnk9": "Yes",
        "product.location": "some",
        "shopify.ilya_fine_jewelry.seo_title": null,
        "prop.Y29yZT1OYXR1cmFsJTJGbGFiLWNyZWF0ZWQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Natural",
        "ebay.ivy_and_rose.store_category2_id": "14736889",
        "shopify.ilya_fine_jewelry.vendor": null,
        "shopify.ivy_and_rose.seo_title": null,
        "prop.Y29yZT1HZW1zdG9uZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "Diamond",
        "prop.Y29yZT1TaWRlK3N0b25lJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "shopify.ilya_fine_jewelry.collection_ids": [],
        "shopify.ilya_fine_jewelry.tags": [],
        "prop.Y29yZT1TaWRlK3N0b25lK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "ebay.ivy_and_rose.category_id": "261993",
        "prop.Y29yZT1NYWRlK3RvK29yZGVyJmViYXk9JmV0c3k9JnNob3BpZnk9": "No",
        "product.availability": 1,
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrc2hhcGUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Old European Cut",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY2xhcml0eSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "VS2",
        "prop.Y29yZT1TaWRlK3N0b25lK2NsYXJpdHkmZWJheT0mZXRzeT0mc2hvcGlmeT0": null
      },
      "id": "b196ef79-c60a-4f4c-9f21-17cb92c04c45",
      "sku": "20698"
    },
    {
      "data": {
        "prop.Y29yZT1Ub3RhbCtjYXJhdCt3ZWlnaHQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "1.02ct",
        "prop.Y29yZT1SZWNpcGllbnQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Women",
        "shopify.ivy_and_rose.collection_ids": [],
        "shopify.ivy_and_rose.tags": [],
        "prop.Y29yZT1TZXR0aW5nK3N0eWxlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1EaWFtb25kK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "shopify.ilya_fine_jewelry.product_type": null,
        "prop.Y29yZT1DbGFyaXR5JmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1TaWRlK3N0b25lK3NoYXBlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "etsy.ivy_and_rose.who_made": "someone_else",
        "prop.Y29yZT1TaXplJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1DZW50ZXIrbWluK3dpZHRoK2ZpdCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "etsy.ivy_and_rose.price": "50",
        "prop.Y29yZT1DZXJ0aWZpY2F0aW9uJmViYXk9JmV0c3k9JnNob3BpZnk9": "IGI",
        "etsy.ivy_and_rose.title": "1 Carat Old European Cut Lab Created Diamond D Vs1 Certified Loose Vintage Style",
        "ebay.ivy_and_rose.store_category_id": "14736769",
        "product.main_style": null,
        "product.sku": "LG-20855",
        "shopify.ivy_and_rose.title": null,
        "shopify.ivy_and_rose.price": null,
        "prop.Y29yZT1TaWRlK3N0b25lcytxdWFudGl0eSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "etsy.ivy_and_rose.is_supply": true,
        "prop.Y29yZT1HcmFtK3dlaWdodCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1DZW50ZXIrbWF4K2xlbmd0aCtmaXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "ebay.ivy_and_rose.condition": "NEW",
        "prop.Y29yZT1TZWNvbmRhcnkrc3RvbmUmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1TaWRlK3N0b25lK2NhcmF0K3dlaWdodCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1SaW5nK3NpemUmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1NZXRhbCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1UaGVtZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1BcHByb3hpbWF0ZSt0aW1lK3BlcmlvZCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "New - Vintage Reproduction",
        "prop.Y29yZT1NYWluK3N0b25lK3NoYXBlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1DZW50ZXIrbWluK2xlbmd0aCtmaXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "ebay.ivy_and_rose.secondary_category_id": null,
        "prop.Y29yZT1NYXgrc2l6ZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "10",
        "prop.Y29yZT1GYW5jeStkaWFtb25kK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "etsy.ivy_and_rose.shop_section_id": "18036037",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY2FyYXQrd2VpZ2h0JmViYXk9JmV0c3k9JnNob3BpZnk9": "1.02ct (6.28mm - 6.32mm)",
        "prop.Y29yZT1DdXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1NYXRlcmlhbCttdWx0aSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1DZW50ZXIrbWF4K3dpZHRoK2ZpdCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "ebay.ivy_and_rose.sub_title": null,
        "product.additional_styles": [],
        "product.price": "50",
        "prop.Y29yZT1NZXRhbCtwdXJpdHkmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "product.title": "1 CARAT OLD EUROPEAN CUT LAB CREATED DIAMOND D VS1 CERTIFIED LOOSE VINTAGE STYLE",
        "prop.Y29yZT1UcmVhdG1lbnQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1NYWluK3N0b25lJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1TdHlsZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1PY2Nhc2lvbiZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "shopify.ivy_and_rose.product_type": null,
        "ebay.ivy_and_rose.price": "50",
        "product.description": "Highlights:\n\r\n- Substantial 1.02 carat size\n\r\n- Old European cut! This is a reproduction of this charming vintage style!\n\r\n- IGI certified\n\r\n- Perfectly white and colorless with D color\n\r\n- Very clean with a grade of VS1\n\r\n- This diamond is lab grown. It offers all the beauty and durability of an earth mined diamond at an excellent value.\n\r\n\n\r\nAdditional Notes:\n\r\nThe diamond is being sold loose, but it can be mounted upon request.",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Diamond (IGi Certified)",
        "etsy.ivy_and_rose.category_id": "6648",
        "shopify.ivy_and_rose.vendor": null,
        "product.cost": "10",
        "prop.Y29yZT1TaXphYmxlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1NaW4rc2l6ZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "4",
        "prop.Y29yZT1NYWluK3N0b25lK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1JdGVtK3R5cGUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Loose Diamond",
        "prop.Y29yZT1FcmEmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Vintage Inspired",
        "product.internal_title": "loose - vintage style - lab diamond",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY29sb3ImZWJheT0mZXRzeT0mc2hvcGlmeT0": "D",
        "shopify.ilya_fine_jewelry.price": null,
        "prop.Y29yZT1Db2xsZWN0aW9uJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "product.notes": null,
        "shopify.ilya_fine_jewelry.title": null,
        "product.stock_number": null,
        "etsy.ivy_and_rose.when_made": "2010_2019",
        "prop.Y29yZT1DYW4rYmUrcGVyc29uYWxpemVkJmViYXk9JmV0c3k9JnNob3BpZnk9": "Yes",
        "product.location": "some",
        "shopify.ilya_fine_jewelry.seo_title": null,
        "prop.Y29yZT1OYXR1cmFsJTJGbGFiLWNyZWF0ZWQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Lab Grown",
        "ebay.ivy_and_rose.store_category2_id": "14736889",
        "shopify.ilya_fine_jewelry.vendor": null,
        "shopify.ivy_and_rose.seo_title": null,
        "prop.Y29yZT1HZW1zdG9uZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "Diamond (IGi Certified)",
        "prop.Y29yZT1TaWRlK3N0b25lJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "shopify.ilya_fine_jewelry.collection_ids": [],
        "shopify.ilya_fine_jewelry.tags": [],
        "prop.Y29yZT1TaWRlK3N0b25lK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "ebay.ivy_and_rose.category_id": "262026",
        "prop.Y29yZT1NYWRlK3RvK29yZGVyJmViYXk9JmV0c3k9JnNob3BpZnk9": "No",
        "product.availability": 1,
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrc2hhcGUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Old European Cut",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY2xhcml0eSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "VS1",
        "prop.Y29yZT1TaWRlK3N0b25lK2NsYXJpdHkmZWJheT0mZXRzeT0mc2hvcGlmeT0": null
      },
      "id": "bc9331e9-8f28-43d9-9574-8315e467196c",
      "sku": "LG-20855"
    },
    {
      "data": {
        "prop.Y29yZT1Ub3RhbCtjYXJhdCt3ZWlnaHQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "1.03ct",
        "prop.Y29yZT1SZWNpcGllbnQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Women",
        "shopify.ivy_and_rose.collection_ids": [],
        "shopify.ivy_and_rose.tags": [],
        "prop.Y29yZT1TZXR0aW5nK3N0eWxlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1EaWFtb25kK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "shopify.ilya_fine_jewelry.product_type": null,
        "prop.Y29yZT1DbGFyaXR5JmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1TaWRlK3N0b25lK3NoYXBlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "etsy.ivy_and_rose.who_made": "someone_else",
        "prop.Y29yZT1TaXplJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1DZW50ZXIrbWluK3dpZHRoK2ZpdCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "etsy.ivy_and_rose.price": "50",
        "prop.Y29yZT1DZXJ0aWZpY2F0aW9uJmViYXk9JmV0c3k9JnNob3BpZnk9": "IGI",
        "etsy.ivy_and_rose.title": "1 Carat Old European Cut Lab Created Diamond Certified D Vs1 Loose Vintage Style",
        "ebay.ivy_and_rose.store_category_id": "14736769",
        "product.main_style": null,
        "product.sku": "LG-20854",
        "shopify.ivy_and_rose.title": null,
        "shopify.ivy_and_rose.price": null,
        "prop.Y29yZT1TaWRlK3N0b25lcytxdWFudGl0eSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "etsy.ivy_and_rose.is_supply": true,
        "prop.Y29yZT1HcmFtK3dlaWdodCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1DZW50ZXIrbWF4K2xlbmd0aCtmaXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "ebay.ivy_and_rose.condition": "NEW",
        "prop.Y29yZT1TZWNvbmRhcnkrc3RvbmUmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1TaWRlK3N0b25lK2NhcmF0K3dlaWdodCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1SaW5nK3NpemUmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1NZXRhbCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1UaGVtZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1BcHByb3hpbWF0ZSt0aW1lK3BlcmlvZCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "New - Vintage Reproduction",
        "prop.Y29yZT1NYWluK3N0b25lK3NoYXBlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1DZW50ZXIrbWluK2xlbmd0aCtmaXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "ebay.ivy_and_rose.secondary_category_id": null,
        "prop.Y29yZT1NYXgrc2l6ZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "10",
        "prop.Y29yZT1GYW5jeStkaWFtb25kK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "etsy.ivy_and_rose.shop_section_id": "18036037",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY2FyYXQrd2VpZ2h0JmViYXk9JmV0c3k9JnNob3BpZnk9": "1.03ct (6.34mm - 6.36mm)",
        "prop.Y29yZT1DdXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1NYXRlcmlhbCttdWx0aSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1DZW50ZXIrbWF4K3dpZHRoK2ZpdCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "ebay.ivy_and_rose.sub_title": null,
        "product.additional_styles": [],
        "product.price": "50",
        "prop.Y29yZT1NZXRhbCtwdXJpdHkmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "product.title": "1 CARAT OLD EUROPEAN CUT LAB CREATED DIAMOND CERTIFIED D VS1 LOOSE VINTAGE STYLE",
        "prop.Y29yZT1UcmVhdG1lbnQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1NYWluK3N0b25lJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1TdHlsZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1PY2Nhc2lvbiZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "shopify.ivy_and_rose.product_type": null,
        "ebay.ivy_and_rose.price": "50",
        "product.description": "Highlights:\n\r\n- Substantial 1.03 carat size\n\r\n- Old European cut! This is a reproduction of this charming vintage style!\n\r\n- IGI certified\n\r\n- Perfectly white and colorless with D color\n\r\n- Very clean with a grade of VS1\n\r\n- This diamond is lab grown. It offers all the beauty and durability of an earth mined diamond at an excellent value.\n\r\n\n\r\nAdditional Notes:\n\r\nThe diamond is being sold loose, but it can be mounted upon request.",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Diamond (IGi Certified)",
        "etsy.ivy_and_rose.category_id": "6648",
        "shopify.ivy_and_rose.vendor": null,
        "product.cost": "10",
        "prop.Y29yZT1TaXphYmxlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1NaW4rc2l6ZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "4",
        "prop.Y29yZT1NYWluK3N0b25lK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1JdGVtK3R5cGUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Loose Diamond",
        "prop.Y29yZT1FcmEmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Vintage Inspired",
        "product.internal_title": "loose - vintage style - lab diamond",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY29sb3ImZWJheT0mZXRzeT0mc2hvcGlmeT0": "D",
        "shopify.ilya_fine_jewelry.price": null,
        "prop.Y29yZT1Db2xsZWN0aW9uJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "product.notes": null,
        "shopify.ilya_fine_jewelry.title": null,
        "product.stock_number": null,
        "etsy.ivy_and_rose.when_made": "2010_2019",
        "prop.Y29yZT1DYW4rYmUrcGVyc29uYWxpemVkJmViYXk9JmV0c3k9JnNob3BpZnk9": "Yes",
        "product.location": "some",
        "shopify.ilya_fine_jewelry.seo_title": null,
        "prop.Y29yZT1OYXR1cmFsJTJGbGFiLWNyZWF0ZWQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Lab Grown",
        "ebay.ivy_and_rose.store_category2_id": "14736889",
        "shopify.ilya_fine_jewelry.vendor": null,
        "shopify.ivy_and_rose.seo_title": null,
        "prop.Y29yZT1HZW1zdG9uZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "Diamond (IGi Certified)",
        "prop.Y29yZT1TaWRlK3N0b25lJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "shopify.ilya_fine_jewelry.collection_ids": [],
        "shopify.ilya_fine_jewelry.tags": [],
        "prop.Y29yZT1TaWRlK3N0b25lK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "ebay.ivy_and_rose.category_id": "262026",
        "prop.Y29yZT1NYWRlK3RvK29yZGVyJmViYXk9JmV0c3k9JnNob3BpZnk9": "No",
        "product.availability": 1,
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrc2hhcGUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Old European Cut",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY2xhcml0eSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "VS1",
        "prop.Y29yZT1TaWRlK3N0b25lK2NsYXJpdHkmZWJheT0mZXRzeT0mc2hvcGlmeT0": null
      },
      "id": "feea129e-605d-4bd3-8edf-11a3ca68b741",
      "sku": "LG-20854"
    },
    {
      "data": {
        "prop.Y29yZT1Ub3RhbCtjYXJhdCt3ZWlnaHQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "1.00ct",
        "prop.Y29yZT1SZWNpcGllbnQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Women",
        "shopify.ivy_and_rose.collection_ids": [],
        "shopify.ivy_and_rose.tags": [],
        "prop.Y29yZT1TZXR0aW5nK3N0eWxlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1EaWFtb25kK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "shopify.ilya_fine_jewelry.product_type": null,
        "prop.Y29yZT1DbGFyaXR5JmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1TaWRlK3N0b25lK3NoYXBlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "etsy.ivy_and_rose.who_made": "someone_else",
        "prop.Y29yZT1TaXplJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1DZW50ZXIrbWluK3dpZHRoK2ZpdCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "etsy.ivy_and_rose.price": "50",
        "prop.Y29yZT1DZXJ0aWZpY2F0aW9uJmViYXk9JmV0c3k9JnNob3BpZnk9": "IGI",
        "etsy.ivy_and_rose.title": "1 Carat Old European Cut Lab Created Diamond Certified Loose D Vvs Vintage Style",
        "ebay.ivy_and_rose.store_category_id": "14736769",
        "product.main_style": null,
        "product.sku": "LG-20853",
        "shopify.ivy_and_rose.title": null,
        "shopify.ivy_and_rose.price": null,
        "prop.Y29yZT1TaWRlK3N0b25lcytxdWFudGl0eSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "etsy.ivy_and_rose.is_supply": true,
        "prop.Y29yZT1HcmFtK3dlaWdodCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1DZW50ZXIrbWF4K2xlbmd0aCtmaXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "ebay.ivy_and_rose.condition": "NEW",
        "prop.Y29yZT1TZWNvbmRhcnkrc3RvbmUmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1TaWRlK3N0b25lK2NhcmF0K3dlaWdodCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1SaW5nK3NpemUmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1NZXRhbCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1UaGVtZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1BcHByb3hpbWF0ZSt0aW1lK3BlcmlvZCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "New - Vintage Reproduction",
        "prop.Y29yZT1NYWluK3N0b25lK3NoYXBlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1DZW50ZXIrbWluK2xlbmd0aCtmaXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "ebay.ivy_and_rose.secondary_category_id": null,
        "prop.Y29yZT1NYXgrc2l6ZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "10",
        "prop.Y29yZT1GYW5jeStkaWFtb25kK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "etsy.ivy_and_rose.shop_section_id": "18036037",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY2FyYXQrd2VpZ2h0JmViYXk9JmV0c3k9JnNob3BpZnk9": "1.00ct (6.30mm - 6.29mm)",
        "prop.Y29yZT1DdXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1NYXRlcmlhbCttdWx0aSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1DZW50ZXIrbWF4K3dpZHRoK2ZpdCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "ebay.ivy_and_rose.sub_title": null,
        "product.additional_styles": [],
        "product.price": "50",
        "prop.Y29yZT1NZXRhbCtwdXJpdHkmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "product.title": "1 CARAT OLD EUROPEAN CUT LAB CREATED DIAMOND CERTIFIED LOOSE D VVS VINTAGE STYLE",
        "prop.Y29yZT1UcmVhdG1lbnQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1NYWluK3N0b25lJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1TdHlsZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1PY2Nhc2lvbiZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "shopify.ivy_and_rose.product_type": null,
        "ebay.ivy_and_rose.price": "50",
        "product.description": "Highlights:\n\r\n- Substantial 1.00 carat size\n\r\n- Old European cut! This is a reproduction of this charming vintage style!\n\r\n- IGI certified\n\r\n- Perfectly white and colorless with D color\n\r\n- Very clean with a grade of VVS2\n\r\n- This diamond is lab grown. It offers all the beauty and durability of an earth mined diamond at an excellent value.\n\r\n\n\r\nAdditional Notes:\n\r\nThe diamond is being sold loose, but it can be mounted upon request.",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Diamond (IGi Certified)",
        "etsy.ivy_and_rose.category_id": "6648",
        "shopify.ivy_and_rose.vendor": null,
        "product.cost": "10",
        "prop.Y29yZT1TaXphYmxlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1NaW4rc2l6ZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "4",
        "prop.Y29yZT1NYWluK3N0b25lK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1JdGVtK3R5cGUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Loose Diamond",
        "prop.Y29yZT1FcmEmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Vintage Inspired",
        "product.internal_title": "loose - vintage style - lab diamond",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY29sb3ImZWJheT0mZXRzeT0mc2hvcGlmeT0": "D",
        "shopify.ilya_fine_jewelry.price": null,
        "prop.Y29yZT1Db2xsZWN0aW9uJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "product.notes": null,
        "shopify.ilya_fine_jewelry.title": null,
        "product.stock_number": null,
        "etsy.ivy_and_rose.when_made": "2010_2019",
        "prop.Y29yZT1DYW4rYmUrcGVyc29uYWxpemVkJmViYXk9JmV0c3k9JnNob3BpZnk9": "Yes",
        "product.location": "some",
        "shopify.ilya_fine_jewelry.seo_title": null,
        "prop.Y29yZT1OYXR1cmFsJTJGbGFiLWNyZWF0ZWQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Lab Grown",
        "ebay.ivy_and_rose.store_category2_id": "14736889",
        "shopify.ilya_fine_jewelry.vendor": null,
        "shopify.ivy_and_rose.seo_title": null,
        "prop.Y29yZT1HZW1zdG9uZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "Diamond (IGi Certified)",
        "prop.Y29yZT1TaWRlK3N0b25lJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "shopify.ilya_fine_jewelry.collection_ids": [],
        "shopify.ilya_fine_jewelry.tags": [],
        "prop.Y29yZT1TaWRlK3N0b25lK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "ebay.ivy_and_rose.category_id": "262026",
        "prop.Y29yZT1NYWRlK3RvK29yZGVyJmViYXk9JmV0c3k9JnNob3BpZnk9": "No",
        "product.availability": 1,
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrc2hhcGUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Old European Cut",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY2xhcml0eSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "VVS2",
        "prop.Y29yZT1TaWRlK3N0b25lK2NsYXJpdHkmZWJheT0mZXRzeT0mc2hvcGlmeT0": null
      },
      "id": "a2d125dd-c1ce-47cd-bb58-e2f2c4cf2c3f",
      "sku": "LG-20853"
    },
    {
      "data": {
        "prop.Y29yZT1Ub3RhbCtjYXJhdCt3ZWlnaHQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "1.01ct",
        "prop.Y29yZT1SZWNpcGllbnQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Women",
        "shopify.ivy_and_rose.collection_ids": [],
        "shopify.ivy_and_rose.tags": [],
        "prop.Y29yZT1TZXR0aW5nK3N0eWxlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1EaWFtb25kK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "shopify.ilya_fine_jewelry.product_type": null,
        "prop.Y29yZT1DbGFyaXR5JmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1TaWRlK3N0b25lK3NoYXBlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "etsy.ivy_and_rose.who_made": "someone_else",
        "prop.Y29yZT1TaXplJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1DZW50ZXIrbWluK3dpZHRoK2ZpdCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "etsy.ivy_and_rose.price": "50",
        "prop.Y29yZT1DZXJ0aWZpY2F0aW9uJmViYXk9JmV0c3k9JnNob3BpZnk9": "IGI",
        "etsy.ivy_and_rose.title": "1 Carat Old European Cut Lab Created Diamond D Vvs Certified Loose Vintage Style",
        "ebay.ivy_and_rose.store_category_id": "14736769",
        "product.main_style": null,
        "product.sku": "LG-20852",
        "shopify.ivy_and_rose.title": null,
        "shopify.ivy_and_rose.price": null,
        "prop.Y29yZT1TaWRlK3N0b25lcytxdWFudGl0eSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "etsy.ivy_and_rose.is_supply": true,
        "prop.Y29yZT1HcmFtK3dlaWdodCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1DZW50ZXIrbWF4K2xlbmd0aCtmaXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "ebay.ivy_and_rose.condition": "NEW",
        "prop.Y29yZT1TZWNvbmRhcnkrc3RvbmUmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1TaWRlK3N0b25lK2NhcmF0K3dlaWdodCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1SaW5nK3NpemUmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1NZXRhbCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1UaGVtZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1BcHByb3hpbWF0ZSt0aW1lK3BlcmlvZCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "New - Vintage Reproduction",
        "prop.Y29yZT1NYWluK3N0b25lK3NoYXBlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1DZW50ZXIrbWluK2xlbmd0aCtmaXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "ebay.ivy_and_rose.secondary_category_id": null,
        "prop.Y29yZT1NYXgrc2l6ZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "10",
        "prop.Y29yZT1GYW5jeStkaWFtb25kK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "etsy.ivy_and_rose.shop_section_id": "18036037",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY2FyYXQrd2VpZ2h0JmViYXk9JmV0c3k9JnNob3BpZnk9": "1.01ct (6.30mm - 6.34mm)",
        "prop.Y29yZT1DdXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1NYXRlcmlhbCttdWx0aSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1DZW50ZXIrbWF4K3dpZHRoK2ZpdCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "ebay.ivy_and_rose.sub_title": null,
        "product.additional_styles": [],
        "product.price": "50",
        "prop.Y29yZT1NZXRhbCtwdXJpdHkmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "product.title": "1 CARAT OLD EUROPEAN CUT LAB CREATED DIAMOND D VVS CERTIFIED LOOSE VINTAGE STYLE",
        "prop.Y29yZT1UcmVhdG1lbnQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1NYWluK3N0b25lJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1TdHlsZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1PY2Nhc2lvbiZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "shopify.ivy_and_rose.product_type": null,
        "ebay.ivy_and_rose.price": "50",
        "product.description": "Highlights:\n\r\n- Substantial 1.01 carat size\n\r\n- Old European cut! This is a reproduction of this charming vintage style!\n\r\n- IGI certified\n\r\n- Perfectly white and colorless with D color\n\r\n- Very clean with a grade of VVS2\n\r\n- This diamond is lab grown. It offers all the beauty and durability of an earth mined diamond at an excellent value.\n\r\n\n\r\nAdditional Notes:\n\r\nThe diamond is being sold loose, but it can be mounted upon request.",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Diamond (IGi Certified)",
        "etsy.ivy_and_rose.category_id": "6648",
        "shopify.ivy_and_rose.vendor": null,
        "product.cost": "10",
        "prop.Y29yZT1TaXphYmxlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1NaW4rc2l6ZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "4",
        "prop.Y29yZT1NYWluK3N0b25lK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1JdGVtK3R5cGUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Loose Diamond",
        "prop.Y29yZT1FcmEmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Vintage Inspired",
        "product.internal_title": "loose - vintage style - lab diamond",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY29sb3ImZWJheT0mZXRzeT0mc2hvcGlmeT0": "D",
        "shopify.ilya_fine_jewelry.price": null,
        "prop.Y29yZT1Db2xsZWN0aW9uJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "product.notes": null,
        "shopify.ilya_fine_jewelry.title": null,
        "product.stock_number": null,
        "etsy.ivy_and_rose.when_made": "2010_2019",
        "prop.Y29yZT1DYW4rYmUrcGVyc29uYWxpemVkJmViYXk9JmV0c3k9JnNob3BpZnk9": "Yes",
        "product.location": "some",
        "shopify.ilya_fine_jewelry.seo_title": null,
        "prop.Y29yZT1OYXR1cmFsJTJGbGFiLWNyZWF0ZWQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Lab Grown",
        "ebay.ivy_and_rose.store_category2_id": "14736889",
        "shopify.ilya_fine_jewelry.vendor": null,
        "shopify.ivy_and_rose.seo_title": null,
        "prop.Y29yZT1HZW1zdG9uZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "Diamond (IGi Certified)",
        "prop.Y29yZT1TaWRlK3N0b25lJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "shopify.ilya_fine_jewelry.collection_ids": [],
        "shopify.ilya_fine_jewelry.tags": [],
        "prop.Y29yZT1TaWRlK3N0b25lK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "ebay.ivy_and_rose.category_id": "262026",
        "prop.Y29yZT1NYWRlK3RvK29yZGVyJmViYXk9JmV0c3k9JnNob3BpZnk9": "No",
        "product.availability": 1,
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrc2hhcGUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Old European Cut",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY2xhcml0eSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "VVS2",
        "prop.Y29yZT1TaWRlK3N0b25lK2NsYXJpdHkmZWJheT0mZXRzeT0mc2hvcGlmeT0": null
      },
      "id": "3215ae9e-7903-4fe2-a9af-9fb105173556",
      "sku": "LG-20852"
    },
    {
      "data": {
        "prop.Y29yZT1Ub3RhbCtjYXJhdCt3ZWlnaHQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "1.02ct",
        "prop.Y29yZT1SZWNpcGllbnQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Women",
        "shopify.ivy_and_rose.collection_ids": [],
        "shopify.ivy_and_rose.tags": [],
        "prop.Y29yZT1TZXR0aW5nK3N0eWxlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1EaWFtb25kK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "shopify.ilya_fine_jewelry.product_type": null,
        "prop.Y29yZT1DbGFyaXR5JmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1TaWRlK3N0b25lK3NoYXBlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "etsy.ivy_and_rose.who_made": "someone_else",
        "prop.Y29yZT1TaXplJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1DZW50ZXIrbWluK3dpZHRoK2ZpdCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "etsy.ivy_and_rose.price": "50",
        "prop.Y29yZT1DZXJ0aWZpY2F0aW9uJmViYXk9JmV0c3k9JnNob3BpZnk9": "IGI",
        "etsy.ivy_and_rose.title": "1 Carat Old European Cut Lab Created Diamond Certified D Vvs Loose Vintage Style",
        "ebay.ivy_and_rose.store_category_id": "14736769",
        "product.main_style": null,
        "product.sku": "LG-20851",
        "shopify.ivy_and_rose.title": null,
        "shopify.ivy_and_rose.price": null,
        "prop.Y29yZT1TaWRlK3N0b25lcytxdWFudGl0eSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "etsy.ivy_and_rose.is_supply": true,
        "prop.Y29yZT1HcmFtK3dlaWdodCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1DZW50ZXIrbWF4K2xlbmd0aCtmaXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "ebay.ivy_and_rose.condition": "NEW",
        "prop.Y29yZT1TZWNvbmRhcnkrc3RvbmUmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1TaWRlK3N0b25lK2NhcmF0K3dlaWdodCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1SaW5nK3NpemUmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1NZXRhbCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1UaGVtZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1BcHByb3hpbWF0ZSt0aW1lK3BlcmlvZCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "New - Vintage Reproduction",
        "prop.Y29yZT1NYWluK3N0b25lK3NoYXBlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1DZW50ZXIrbWluK2xlbmd0aCtmaXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "ebay.ivy_and_rose.secondary_category_id": null,
        "prop.Y29yZT1NYXgrc2l6ZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "10",
        "prop.Y29yZT1GYW5jeStkaWFtb25kK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "etsy.ivy_and_rose.shop_section_id": "18036037",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY2FyYXQrd2VpZ2h0JmViYXk9JmV0c3k9JnNob3BpZnk9": "1.02ct (6.31mm - 6.36mm)",
        "prop.Y29yZT1DdXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1NYXRlcmlhbCttdWx0aSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1DZW50ZXIrbWF4K3dpZHRoK2ZpdCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "ebay.ivy_and_rose.sub_title": null,
        "product.additional_styles": [],
        "product.price": "50",
        "prop.Y29yZT1NZXRhbCtwdXJpdHkmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "product.title": "1 CARAT OLD EUROPEAN CUT LAB CREATED DIAMOND CERTIFIED D VVS LOOSE VINTAGE STYLE",
        "prop.Y29yZT1UcmVhdG1lbnQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1NYWluK3N0b25lJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1TdHlsZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1PY2Nhc2lvbiZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "shopify.ivy_and_rose.product_type": null,
        "ebay.ivy_and_rose.price": "50",
        "product.description": "Highlights:\n\r\n- Substantial 1.02 carat size\n\r\n- Old European cut! This is a reproduction of this charming vintage style!\n\r\n- IGI certified\n\r\n- Perfectly white and colorless with D color\n\r\n- Very clean with a grade of VVS1\n\r\n- This diamond is lab grown. It offers all the beauty and durability of an earth mined diamond at an excellent value.\n\r\n\n\r\nAdditional Notes:\n\r\nThe diamond is being sold loose, but it can be mounted upon request.",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Diamond (IGi Certified)",
        "etsy.ivy_and_rose.category_id": "6648",
        "shopify.ivy_and_rose.vendor": null,
        "product.cost": "10",
        "prop.Y29yZT1TaXphYmxlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1NaW4rc2l6ZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "4",
        "prop.Y29yZT1NYWluK3N0b25lK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1JdGVtK3R5cGUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Loose Diamond",
        "prop.Y29yZT1FcmEmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Vintage Inspired",
        "product.internal_title": "loose - vintage style - lab diamond",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY29sb3ImZWJheT0mZXRzeT0mc2hvcGlmeT0": "D",
        "shopify.ilya_fine_jewelry.price": null,
        "prop.Y29yZT1Db2xsZWN0aW9uJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "product.notes": null,
        "shopify.ilya_fine_jewelry.title": null,
        "product.stock_number": null,
        "etsy.ivy_and_rose.when_made": "2010_2019",
        "prop.Y29yZT1DYW4rYmUrcGVyc29uYWxpemVkJmViYXk9JmV0c3k9JnNob3BpZnk9": "Yes",
        "product.location": "some",
        "shopify.ilya_fine_jewelry.seo_title": null,
        "prop.Y29yZT1OYXR1cmFsJTJGbGFiLWNyZWF0ZWQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Lab Grown",
        "ebay.ivy_and_rose.store_category2_id": "14736889",
        "shopify.ilya_fine_jewelry.vendor": null,
        "shopify.ivy_and_rose.seo_title": null,
        "prop.Y29yZT1HZW1zdG9uZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "Diamond (IGi Certified)",
        "prop.Y29yZT1TaWRlK3N0b25lJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "shopify.ilya_fine_jewelry.collection_ids": [],
        "shopify.ilya_fine_jewelry.tags": [],
        "prop.Y29yZT1TaWRlK3N0b25lK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "ebay.ivy_and_rose.category_id": "262026",
        "prop.Y29yZT1NYWRlK3RvK29yZGVyJmViYXk9JmV0c3k9JnNob3BpZnk9": "No",
        "product.availability": 1,
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrc2hhcGUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Old European Cut",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY2xhcml0eSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "VVS1",
        "prop.Y29yZT1TaWRlK3N0b25lK2NsYXJpdHkmZWJheT0mZXRzeT0mc2hvcGlmeT0": null
      },
      "id": "9601985a-367f-41c3-b368-3943ac545d67",
      "sku": "LG-20851"
    },
    {
      "data": {
        "prop.Y29yZT1Ub3RhbCtjYXJhdCt3ZWlnaHQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "2.04ct",
        "prop.Y29yZT1SZWNpcGllbnQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Women",
        "shopify.ivy_and_rose.collection_ids": [],
        "shopify.ivy_and_rose.tags": [],
        "prop.Y29yZT1TZXR0aW5nK3N0eWxlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1EaWFtb25kK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "shopify.ilya_fine_jewelry.product_type": null,
        "prop.Y29yZT1DbGFyaXR5JmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1TaWRlK3N0b25lK3NoYXBlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "etsy.ivy_and_rose.who_made": "someone_else",
        "prop.Y29yZT1TaXplJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1DZW50ZXIrbWluK3dpZHRoK2ZpdCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "etsy.ivy_and_rose.price": "50",
        "prop.Y29yZT1DZXJ0aWZpY2F0aW9uJmViYXk9JmV0c3k9JnNob3BpZnk9": "IGI",
        "etsy.ivy_and_rose.title": "2 Carat Old European Cut Lab Created Diamond Certified E Vs1 Loose Vintage Style",
        "ebay.ivy_and_rose.store_category_id": "14736769",
        "product.main_style": null,
        "product.sku": "LG-20849",
        "shopify.ivy_and_rose.title": null,
        "shopify.ivy_and_rose.price": null,
        "prop.Y29yZT1TaWRlK3N0b25lcytxdWFudGl0eSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "etsy.ivy_and_rose.is_supply": true,
        "prop.Y29yZT1HcmFtK3dlaWdodCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1DZW50ZXIrbWF4K2xlbmd0aCtmaXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "ebay.ivy_and_rose.condition": "NEW",
        "prop.Y29yZT1TZWNvbmRhcnkrc3RvbmUmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1TaWRlK3N0b25lK2NhcmF0K3dlaWdodCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1SaW5nK3NpemUmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1NZXRhbCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1UaGVtZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1BcHByb3hpbWF0ZSt0aW1lK3BlcmlvZCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "New - Vintage Reproduction",
        "prop.Y29yZT1NYWluK3N0b25lK3NoYXBlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1DZW50ZXIrbWluK2xlbmd0aCtmaXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "ebay.ivy_and_rose.secondary_category_id": null,
        "prop.Y29yZT1NYXgrc2l6ZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "10",
        "prop.Y29yZT1GYW5jeStkaWFtb25kK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "etsy.ivy_and_rose.shop_section_id": "18036037",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY2FyYXQrd2VpZ2h0JmViYXk9JmV0c3k9JnNob3BpZnk9": "2.04ct (7.94mm - 7.93mm)",
        "prop.Y29yZT1DdXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1NYXRlcmlhbCttdWx0aSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1DZW50ZXIrbWF4K3dpZHRoK2ZpdCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "ebay.ivy_and_rose.sub_title": null,
        "product.additional_styles": [],
        "product.price": "50",
        "prop.Y29yZT1NZXRhbCtwdXJpdHkmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "product.title": "2 CARAT OLD EUROPEAN CUT LAB CREATED DIAMOND CERTIFIED E VS1 LOOSE VINTAGE STYLE",
        "prop.Y29yZT1UcmVhdG1lbnQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1NYWluK3N0b25lJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1TdHlsZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1PY2Nhc2lvbiZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "shopify.ivy_and_rose.product_type": null,
        "ebay.ivy_and_rose.price": "50",
        "product.description": "Highlights:\n\r\n- Substantial 2.04 carat size\n\r\n- Old European cut! This is a reproduction of this charming vintage style!\n\r\n- IGI certified\n\r\n- Very bright white and colorless with E color\n\r\n- Very clean with a grade of VS1\n\r\n- This diamond is lab grown. It offers all the beauty and durability of an earth mined diamond at an excellent value.\n\r\n\n\r\nAdditional Notes:\n\r\nThe diamond is being sold loose, but it can be mounted upon request.",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Diamond (IGi Certified)",
        "etsy.ivy_and_rose.category_id": "6648",
        "shopify.ivy_and_rose.vendor": null,
        "product.cost": "10",
        "prop.Y29yZT1TaXphYmxlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1NaW4rc2l6ZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "4",
        "prop.Y29yZT1NYWluK3N0b25lK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1JdGVtK3R5cGUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Loose Diamond",
        "prop.Y29yZT1FcmEmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Vintage Inspired",
        "product.internal_title": "loose - vintage style - lab diamond",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY29sb3ImZWJheT0mZXRzeT0mc2hvcGlmeT0": "E",
        "shopify.ilya_fine_jewelry.price": null,
        "prop.Y29yZT1Db2xsZWN0aW9uJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "product.notes": null,
        "shopify.ilya_fine_jewelry.title": null,
        "product.stock_number": null,
        "etsy.ivy_and_rose.when_made": "2010_2019",
        "prop.Y29yZT1DYW4rYmUrcGVyc29uYWxpemVkJmViYXk9JmV0c3k9JnNob3BpZnk9": "Yes",
        "product.location": "some",
        "shopify.ilya_fine_jewelry.seo_title": null,
        "prop.Y29yZT1OYXR1cmFsJTJGbGFiLWNyZWF0ZWQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Lab Grown",
        "ebay.ivy_and_rose.store_category2_id": "14736889",
        "shopify.ilya_fine_jewelry.vendor": null,
        "shopify.ivy_and_rose.seo_title": null,
        "prop.Y29yZT1HZW1zdG9uZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "Diamond (IGi Certified)",
        "prop.Y29yZT1TaWRlK3N0b25lJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "shopify.ilya_fine_jewelry.collection_ids": [],
        "shopify.ilya_fine_jewelry.tags": [],
        "prop.Y29yZT1TaWRlK3N0b25lK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "ebay.ivy_and_rose.category_id": "262026",
        "prop.Y29yZT1NYWRlK3RvK29yZGVyJmViYXk9JmV0c3k9JnNob3BpZnk9": "No",
        "product.availability": 1,
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrc2hhcGUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Old European Cut",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY2xhcml0eSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "VS1",
        "prop.Y29yZT1TaWRlK3N0b25lK2NsYXJpdHkmZWJheT0mZXRzeT0mc2hvcGlmeT0": null
      },
      "id": "958c62c8-14ff-4743-b8ae-0b4ebfd98a5e",
      "sku": "LG-20849"
    },
    {
      "data": {
        "prop.Y29yZT1Ub3RhbCtjYXJhdCt3ZWlnaHQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "2.03ct",
        "prop.Y29yZT1SZWNpcGllbnQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Women",
        "shopify.ivy_and_rose.collection_ids": [],
        "shopify.ivy_and_rose.tags": [],
        "prop.Y29yZT1TZXR0aW5nK3N0eWxlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1EaWFtb25kK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "shopify.ilya_fine_jewelry.product_type": null,
        "prop.Y29yZT1DbGFyaXR5JmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1TaWRlK3N0b25lK3NoYXBlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "etsy.ivy_and_rose.who_made": "someone_else",
        "prop.Y29yZT1TaXplJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1DZW50ZXIrbWluK3dpZHRoK2ZpdCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "etsy.ivy_and_rose.price": "50",
        "prop.Y29yZT1DZXJ0aWZpY2F0aW9uJmViYXk9JmV0c3k9JnNob3BpZnk9": "IGI",
        "etsy.ivy_and_rose.title": "2 Carat Old European Cut Lab Created Diamond Certified E Vvs Loose Vintage Style",
        "ebay.ivy_and_rose.store_category_id": "14736769",
        "product.main_style": null,
        "product.sku": "LG-20848",
        "shopify.ivy_and_rose.title": null,
        "shopify.ivy_and_rose.price": null,
        "prop.Y29yZT1TaWRlK3N0b25lcytxdWFudGl0eSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "etsy.ivy_and_rose.is_supply": true,
        "prop.Y29yZT1HcmFtK3dlaWdodCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1DZW50ZXIrbWF4K2xlbmd0aCtmaXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "ebay.ivy_and_rose.condition": "NEW",
        "prop.Y29yZT1TZWNvbmRhcnkrc3RvbmUmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1TaWRlK3N0b25lK2NhcmF0K3dlaWdodCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1SaW5nK3NpemUmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1NZXRhbCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1UaGVtZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1BcHByb3hpbWF0ZSt0aW1lK3BlcmlvZCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "New - Vintage Reproduction",
        "prop.Y29yZT1NYWluK3N0b25lK3NoYXBlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1DZW50ZXIrbWluK2xlbmd0aCtmaXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "ebay.ivy_and_rose.secondary_category_id": null,
        "prop.Y29yZT1NYXgrc2l6ZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "10",
        "prop.Y29yZT1GYW5jeStkaWFtb25kK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "etsy.ivy_and_rose.shop_section_id": "18036037",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY2FyYXQrd2VpZ2h0JmViYXk9JmV0c3k9JnNob3BpZnk9": "2.03ct (7.93mm - 7.89mm)",
        "prop.Y29yZT1DdXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1NYXRlcmlhbCttdWx0aSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1DZW50ZXIrbWF4K3dpZHRoK2ZpdCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "ebay.ivy_and_rose.sub_title": null,
        "product.additional_styles": [],
        "product.price": "50",
        "prop.Y29yZT1NZXRhbCtwdXJpdHkmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "product.title": "2 CARAT OLD EUROPEAN CUT LAB CREATED DIAMOND CERTIFIED E VVS LOOSE VINTAGE STYLE",
        "prop.Y29yZT1UcmVhdG1lbnQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1NYWluK3N0b25lJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1TdHlsZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1PY2Nhc2lvbiZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "shopify.ivy_and_rose.product_type": null,
        "ebay.ivy_and_rose.price": "50",
        "product.description": "Highlights:\n\r\n- Substantial 2.03 carat size\n\r\n- Old European cut! This is a reproduction of this charming vintage style!\n\r\n- IGI certified\n\r\n- Very bright white and colorless with E color\n\r\n- Very clean with a grade of VVS2\n\r\n- This diamond is lab grown. It offers all the beauty and durability of an earth mined diamond at an excellent value.\n\r\n\n\r\nAdditional Notes:\n\r\nThe diamond is being sold loose, but it can be mounted upon request.",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Diamond (IGi Certified)",
        "etsy.ivy_and_rose.category_id": "6648",
        "shopify.ivy_and_rose.vendor": null,
        "product.cost": "10",
        "prop.Y29yZT1TaXphYmxlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1NaW4rc2l6ZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "4",
        "prop.Y29yZT1NYWluK3N0b25lK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1JdGVtK3R5cGUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Loose Diamond",
        "prop.Y29yZT1FcmEmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Vintage Inspired",
        "product.internal_title": "loose - vintage style - lab diamond",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY29sb3ImZWJheT0mZXRzeT0mc2hvcGlmeT0": "E",
        "shopify.ilya_fine_jewelry.price": null,
        "prop.Y29yZT1Db2xsZWN0aW9uJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "product.notes": null,
        "shopify.ilya_fine_jewelry.title": null,
        "product.stock_number": null,
        "etsy.ivy_and_rose.when_made": "2010_2019",
        "prop.Y29yZT1DYW4rYmUrcGVyc29uYWxpemVkJmViYXk9JmV0c3k9JnNob3BpZnk9": "Yes",
        "product.location": "some",
        "shopify.ilya_fine_jewelry.seo_title": null,
        "prop.Y29yZT1OYXR1cmFsJTJGbGFiLWNyZWF0ZWQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Lab Grown",
        "ebay.ivy_and_rose.store_category2_id": "14736889",
        "shopify.ilya_fine_jewelry.vendor": null,
        "shopify.ivy_and_rose.seo_title": null,
        "prop.Y29yZT1HZW1zdG9uZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "Diamond (IGi Certified)",
        "prop.Y29yZT1TaWRlK3N0b25lJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "shopify.ilya_fine_jewelry.collection_ids": [],
        "shopify.ilya_fine_jewelry.tags": [],
        "prop.Y29yZT1TaWRlK3N0b25lK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "ebay.ivy_and_rose.category_id": "262026",
        "prop.Y29yZT1NYWRlK3RvK29yZGVyJmViYXk9JmV0c3k9JnNob3BpZnk9": "No",
        "product.availability": 1,
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrc2hhcGUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Old European Cut",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY2xhcml0eSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "VVS2",
        "prop.Y29yZT1TaWRlK3N0b25lK2NsYXJpdHkmZWJheT0mZXRzeT0mc2hvcGlmeT0": null
      },
      "id": "3ccb5b63-6634-4f8e-9c79-9afd36d8fa04",
      "sku": "LG-20848"
    },
    {
      "data": {
        "prop.Y29yZT1Ub3RhbCtjYXJhdCt3ZWlnaHQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "2.00ct",
        "prop.Y29yZT1SZWNpcGllbnQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Women",
        "shopify.ivy_and_rose.collection_ids": [],
        "shopify.ivy_and_rose.tags": [],
        "prop.Y29yZT1TZXR0aW5nK3N0eWxlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1EaWFtb25kK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "shopify.ilya_fine_jewelry.product_type": null,
        "prop.Y29yZT1DbGFyaXR5JmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1TaWRlK3N0b25lK3NoYXBlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "etsy.ivy_and_rose.who_made": "someone_else",
        "prop.Y29yZT1TaXplJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1DZW50ZXIrbWluK3dpZHRoK2ZpdCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "etsy.ivy_and_rose.price": "50",
        "prop.Y29yZT1DZXJ0aWZpY2F0aW9uJmViYXk9JmV0c3k9JnNob3BpZnk9": "IGI",
        "etsy.ivy_and_rose.title": "2 Carat Old European Cut Lab Created Diamond Certified D Vs1 Loose Vintage Style",
        "ebay.ivy_and_rose.store_category_id": "14736769",
        "product.main_style": null,
        "product.sku": "LG-20846",
        "shopify.ivy_and_rose.title": null,
        "shopify.ivy_and_rose.price": null,
        "prop.Y29yZT1TaWRlK3N0b25lcytxdWFudGl0eSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "etsy.ivy_and_rose.is_supply": true,
        "prop.Y29yZT1HcmFtK3dlaWdodCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1DZW50ZXIrbWF4K2xlbmd0aCtmaXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "ebay.ivy_and_rose.condition": "NEW",
        "prop.Y29yZT1TZWNvbmRhcnkrc3RvbmUmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1TaWRlK3N0b25lK2NhcmF0K3dlaWdodCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1SaW5nK3NpemUmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1NZXRhbCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1UaGVtZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1BcHByb3hpbWF0ZSt0aW1lK3BlcmlvZCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "New - Vintage Reproduction",
        "prop.Y29yZT1NYWluK3N0b25lK3NoYXBlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1DZW50ZXIrbWluK2xlbmd0aCtmaXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "ebay.ivy_and_rose.secondary_category_id": null,
        "prop.Y29yZT1NYXgrc2l6ZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "10",
        "prop.Y29yZT1GYW5jeStkaWFtb25kK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "etsy.ivy_and_rose.shop_section_id": "18036037",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY2FyYXQrd2VpZ2h0JmViYXk9JmV0c3k9JnNob3BpZnk9": "2.00ct (7.85mm - 8.04mm)",
        "prop.Y29yZT1DdXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1NYXRlcmlhbCttdWx0aSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1DZW50ZXIrbWF4K3dpZHRoK2ZpdCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "ebay.ivy_and_rose.sub_title": null,
        "product.additional_styles": [],
        "product.price": "50",
        "prop.Y29yZT1NZXRhbCtwdXJpdHkmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "product.title": "2 CARAT OLD EUROPEAN CUT LAB CREATED DIAMOND CERTIFIED D VS1 LOOSE VINTAGE STYLE",
        "prop.Y29yZT1UcmVhdG1lbnQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1NYWluK3N0b25lJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1TdHlsZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1PY2Nhc2lvbiZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "shopify.ivy_and_rose.product_type": null,
        "ebay.ivy_and_rose.price": "50",
        "product.description": "Highlights:\n\r\n- Substantial 2.00 carat size\n\r\n- Old European cut! This is a reproduction of this charming vintage style!\n\r\n- IGI certified\n\r\n- Perfectly white and colorless with D color\n\r\n- Very clean with a grade of VS1\n\r\n- This diamond is lab grown. It offers all the beauty and durability of an earth mined diamond at an excellent value.\n\r\n\n\r\nAdditional Notes:\n\r\nThe diamond is being sold loose, but it can be mounted upon request.",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Diamond (IGi Certified)",
        "etsy.ivy_and_rose.category_id": "6648",
        "shopify.ivy_and_rose.vendor": null,
        "product.cost": "10",
        "prop.Y29yZT1TaXphYmxlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1NaW4rc2l6ZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "4",
        "prop.Y29yZT1NYWluK3N0b25lK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1JdGVtK3R5cGUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Loose Diamond",
        "prop.Y29yZT1FcmEmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Vintage Inspired",
        "product.internal_title": "loose - vintage style - lab diamond",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY29sb3ImZWJheT0mZXRzeT0mc2hvcGlmeT0": "D",
        "shopify.ilya_fine_jewelry.price": null,
        "prop.Y29yZT1Db2xsZWN0aW9uJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "product.notes": null,
        "shopify.ilya_fine_jewelry.title": null,
        "product.stock_number": null,
        "etsy.ivy_and_rose.when_made": "2010_2019",
        "prop.Y29yZT1DYW4rYmUrcGVyc29uYWxpemVkJmViYXk9JmV0c3k9JnNob3BpZnk9": "Yes",
        "product.location": "some",
        "shopify.ilya_fine_jewelry.seo_title": null,
        "prop.Y29yZT1OYXR1cmFsJTJGbGFiLWNyZWF0ZWQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Lab Grown",
        "ebay.ivy_and_rose.store_category2_id": "14736889",
        "shopify.ilya_fine_jewelry.vendor": null,
        "shopify.ivy_and_rose.seo_title": null,
        "prop.Y29yZT1HZW1zdG9uZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "Diamond (IGi Certified)",
        "prop.Y29yZT1TaWRlK3N0b25lJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "shopify.ilya_fine_jewelry.collection_ids": [],
        "shopify.ilya_fine_jewelry.tags": [],
        "prop.Y29yZT1TaWRlK3N0b25lK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "ebay.ivy_and_rose.category_id": "262026",
        "prop.Y29yZT1NYWRlK3RvK29yZGVyJmViYXk9JmV0c3k9JnNob3BpZnk9": "No",
        "product.availability": 1,
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrc2hhcGUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Old European Cut",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY2xhcml0eSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "VS1",
        "prop.Y29yZT1TaWRlK3N0b25lK2NsYXJpdHkmZWJheT0mZXRzeT0mc2hvcGlmeT0": null
      },
      "id": "b08fa6a4-61a7-477a-8849-3c6326a3fb56",
      "sku": "LG-20846"
    },
    {
      "data": {
        "prop.Y29yZT1Ub3RhbCtjYXJhdCt3ZWlnaHQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "2.04ct",
        "prop.Y29yZT1SZWNpcGllbnQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Women",
        "shopify.ivy_and_rose.collection_ids": [],
        "shopify.ivy_and_rose.tags": [],
        "prop.Y29yZT1TZXR0aW5nK3N0eWxlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1EaWFtb25kK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "shopify.ilya_fine_jewelry.product_type": null,
        "prop.Y29yZT1DbGFyaXR5JmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1TaWRlK3N0b25lK3NoYXBlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "etsy.ivy_and_rose.who_made": "someone_else",
        "prop.Y29yZT1TaXplJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1DZW50ZXIrbWluK3dpZHRoK2ZpdCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "etsy.ivy_and_rose.price": "50",
        "prop.Y29yZT1DZXJ0aWZpY2F0aW9uJmViYXk9JmV0c3k9JnNob3BpZnk9": "IGI",
        "etsy.ivy_and_rose.title": "2 Carat Old European Cut Lab Created Diamond Certified D Vvs Loose Vintage Style",
        "ebay.ivy_and_rose.store_category_id": "14736769",
        "product.main_style": null,
        "product.sku": "LG-20845",
        "shopify.ivy_and_rose.title": null,
        "shopify.ivy_and_rose.price": null,
        "prop.Y29yZT1TaWRlK3N0b25lcytxdWFudGl0eSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "etsy.ivy_and_rose.is_supply": true,
        "prop.Y29yZT1HcmFtK3dlaWdodCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1DZW50ZXIrbWF4K2xlbmd0aCtmaXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "ebay.ivy_and_rose.condition": "NEW",
        "prop.Y29yZT1TZWNvbmRhcnkrc3RvbmUmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1TaWRlK3N0b25lK2NhcmF0K3dlaWdodCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1SaW5nK3NpemUmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1NZXRhbCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1UaGVtZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1BcHByb3hpbWF0ZSt0aW1lK3BlcmlvZCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "New - Vintage Reproduction",
        "prop.Y29yZT1NYWluK3N0b25lK3NoYXBlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1DZW50ZXIrbWluK2xlbmd0aCtmaXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "ebay.ivy_and_rose.secondary_category_id": null,
        "prop.Y29yZT1NYXgrc2l6ZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "10",
        "prop.Y29yZT1GYW5jeStkaWFtb25kK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "etsy.ivy_and_rose.shop_section_id": "18036037",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY2FyYXQrd2VpZ2h0JmViYXk9JmV0c3k9JnNob3BpZnk9": "2.04ct (7.89mm - 7.95mm)",
        "prop.Y29yZT1DdXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1NYXRlcmlhbCttdWx0aSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1DZW50ZXIrbWF4K3dpZHRoK2ZpdCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "ebay.ivy_and_rose.sub_title": null,
        "product.additional_styles": [],
        "product.price": "50",
        "prop.Y29yZT1NZXRhbCtwdXJpdHkmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "product.title": "2 CARAT OLD EUROPEAN CUT LAB CREATED DIAMOND CERTIFIED D VVS LOOSE VINTAGE STYLE",
        "prop.Y29yZT1UcmVhdG1lbnQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1NYWluK3N0b25lJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1TdHlsZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1PY2Nhc2lvbiZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "shopify.ivy_and_rose.product_type": null,
        "ebay.ivy_and_rose.price": "50",
        "product.description": "Highlights:\n\r\n- Substantial 2.04 carat size\n\r\n- Old European cut! This is a reproduction of this charming vintage style!\n\r\n- IGI certified\n\r\n- Perfectly white and colorless with D color\n\r\n- Very clean with a grade of VVS2\n\r\n- This diamond is lab grown. It offers all the beauty and durability of an earth mined diamond at an excellent value.\n\r\n\n\r\nAdditional Notes:\n\r\nThe diamond is being sold loose, but it can be mounted upon request.",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Diamond (IGi Certified)",
        "etsy.ivy_and_rose.category_id": "6648",
        "shopify.ivy_and_rose.vendor": null,
        "product.cost": "10",
        "prop.Y29yZT1TaXphYmxlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1NaW4rc2l6ZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "4",
        "prop.Y29yZT1NYWluK3N0b25lK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1JdGVtK3R5cGUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Loose Diamond",
        "prop.Y29yZT1FcmEmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Vintage Inspired",
        "product.internal_title": "loose - vintage style - lab diamond",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY29sb3ImZWJheT0mZXRzeT0mc2hvcGlmeT0": "D",
        "shopify.ilya_fine_jewelry.price": null,
        "prop.Y29yZT1Db2xsZWN0aW9uJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "product.notes": null,
        "shopify.ilya_fine_jewelry.title": null,
        "product.stock_number": null,
        "etsy.ivy_and_rose.when_made": "2010_2019",
        "prop.Y29yZT1DYW4rYmUrcGVyc29uYWxpemVkJmViYXk9JmV0c3k9JnNob3BpZnk9": "Yes",
        "product.location": "some",
        "shopify.ilya_fine_jewelry.seo_title": null,
        "prop.Y29yZT1OYXR1cmFsJTJGbGFiLWNyZWF0ZWQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Lab Grown",
        "ebay.ivy_and_rose.store_category2_id": "14736889",
        "shopify.ilya_fine_jewelry.vendor": null,
        "shopify.ivy_and_rose.seo_title": null,
        "prop.Y29yZT1HZW1zdG9uZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "Diamond (IGi Certified)",
        "prop.Y29yZT1TaWRlK3N0b25lJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "shopify.ilya_fine_jewelry.collection_ids": [],
        "shopify.ilya_fine_jewelry.tags": [],
        "prop.Y29yZT1TaWRlK3N0b25lK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "ebay.ivy_and_rose.category_id": "262026",
        "prop.Y29yZT1NYWRlK3RvK29yZGVyJmViYXk9JmV0c3k9JnNob3BpZnk9": "No",
        "product.availability": 1,
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrc2hhcGUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Old European Cut",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY2xhcml0eSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "VVS2",
        "prop.Y29yZT1TaWRlK3N0b25lK2NsYXJpdHkmZWJheT0mZXRzeT0mc2hvcGlmeT0": null
      },
      "id": "92f21569-4b65-4057-825b-ee2282c6464c",
      "sku": "LG-20845"
    },
    {
      "data": {
        "prop.Y29yZT1Ub3RhbCtjYXJhdCt3ZWlnaHQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "0.98ct",
        "prop.Y29yZT1SZWNpcGllbnQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Women",
        "shopify.ivy_and_rose.collection_ids": [],
        "shopify.ivy_and_rose.tags": [],
        "prop.Y29yZT1TZXR0aW5nK3N0eWxlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1EaWFtb25kK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "shopify.ilya_fine_jewelry.product_type": null,
        "prop.Y29yZT1DbGFyaXR5JmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1TaWRlK3N0b25lK3NoYXBlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "etsy.ivy_and_rose.who_made": "someone_else",
        "prop.Y29yZT1TaXplJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1DZW50ZXIrbWluK3dpZHRoK2ZpdCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "etsy.ivy_and_rose.price": "50",
        "prop.Y29yZT1DZXJ0aWZpY2F0aW9uJmViYXk9JmV0c3k9JnNob3BpZnk9": "GIA",
        "etsy.ivy_and_rose.title": "1 Carat Asscher Cut Diamond Gia Certified I Vs2 Loose Natural Emerald Shape 1Ct",
        "ebay.ivy_and_rose.store_category_id": "14736769",
        "product.main_style": null,
        "product.sku": "20340",
        "shopify.ivy_and_rose.title": null,
        "shopify.ivy_and_rose.price": null,
        "prop.Y29yZT1TaWRlK3N0b25lcytxdWFudGl0eSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "etsy.ivy_and_rose.is_supply": true,
        "prop.Y29yZT1HcmFtK3dlaWdodCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1DZW50ZXIrbWF4K2xlbmd0aCtmaXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "ebay.ivy_and_rose.condition": "NEW",
        "prop.Y29yZT1TZWNvbmRhcnkrc3RvbmUmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1TaWRlK3N0b25lK2NhcmF0K3dlaWdodCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1SaW5nK3NpemUmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1NZXRhbCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1UaGVtZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1BcHByb3hpbWF0ZSt0aW1lK3BlcmlvZCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "New",
        "prop.Y29yZT1NYWluK3N0b25lK3NoYXBlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1DZW50ZXIrbWluK2xlbmd0aCtmaXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "ebay.ivy_and_rose.secondary_category_id": null,
        "prop.Y29yZT1NYXgrc2l6ZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "10",
        "prop.Y29yZT1GYW5jeStkaWFtb25kK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "etsy.ivy_and_rose.shop_section_id": "18036037",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY2FyYXQrd2VpZ2h0JmViYXk9JmV0c3k9JnNob3BpZnk9": "0.98ct (5.28mm x 4.99mm)",
        "prop.Y29yZT1DdXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1NYXRlcmlhbCttdWx0aSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1DZW50ZXIrbWF4K3dpZHRoK2ZpdCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "ebay.ivy_and_rose.sub_title": null,
        "product.additional_styles": [],
        "product.price": "50",
        "prop.Y29yZT1NZXRhbCtwdXJpdHkmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "product.title": "1 CARAT ASSCHER CUT DIAMOND GIA CERTIFIED I VS2 LOOSE NATURAL EMERALD SHAPE 1ct",
        "prop.Y29yZT1UcmVhdG1lbnQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Not Treated, Not Enhanced",
        "prop.Y29yZT1NYWluK3N0b25lJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1TdHlsZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1PY2Nhc2lvbiZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "shopify.ivy_and_rose.product_type": null,
        "ebay.ivy_and_rose.price": "50",
        "product.description": "Highlights:\n\r\n- 0.98ct natural diamond\n\r\n- Asscher cut\n\r\n- GIA certified\n\r\n- Faces up nicely white with I color\n\r\n- Excellent VS2 clarity\n\r\n- Fantastic sparkle and brilliance!\n\r\n\n\r\nAdditional Notes:\n\r\nThe diamond is being sold loose, but it can be mounted upon request.",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Diamond (GIA Certified)",
        "etsy.ivy_and_rose.category_id": "6648",
        "shopify.ivy_and_rose.vendor": null,
        "product.cost": "10",
        "prop.Y29yZT1TaXphYmxlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1NaW4rc2l6ZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "4",
        "prop.Y29yZT1NYWluK3N0b25lK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1JdGVtK3R5cGUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Loose Diamond",
        "prop.Y29yZT1FcmEmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Modern",
        "product.internal_title": null,
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY29sb3ImZWJheT0mZXRzeT0mc2hvcGlmeT0": "I",
        "shopify.ilya_fine_jewelry.price": null,
        "prop.Y29yZT1Db2xsZWN0aW9uJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "product.notes": "List as Asscher and emerald cut",
        "shopify.ilya_fine_jewelry.title": null,
        "product.stock_number": null,
        "etsy.ivy_and_rose.when_made": "2010_2019",
        "prop.Y29yZT1DYW4rYmUrcGVyc29uYWxpemVkJmViYXk9JmV0c3k9JnNob3BpZnk9": "Yes",
        "product.location": "some",
        "shopify.ilya_fine_jewelry.seo_title": null,
        "prop.Y29yZT1OYXR1cmFsJTJGbGFiLWNyZWF0ZWQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Natural",
        "ebay.ivy_and_rose.store_category2_id": "14736889",
        "shopify.ilya_fine_jewelry.vendor": null,
        "shopify.ivy_and_rose.seo_title": null,
        "prop.Y29yZT1HZW1zdG9uZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "Diamond (GIA Certified)",
        "prop.Y29yZT1TaWRlK3N0b25lJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "shopify.ilya_fine_jewelry.collection_ids": [],
        "shopify.ilya_fine_jewelry.tags": [],
        "prop.Y29yZT1TaWRlK3N0b25lK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "ebay.ivy_and_rose.category_id": "262026",
        "prop.Y29yZT1NYWRlK3RvK29yZGVyJmViYXk9JmV0c3k9JnNob3BpZnk9": "No",
        "product.availability": 1,
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrc2hhcGUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Asscher/Emerald Cut",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY2xhcml0eSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "VS2",
        "prop.Y29yZT1TaWRlK3N0b25lK2NsYXJpdHkmZWJheT0mZXRzeT0mc2hvcGlmeT0": null
      },
      "id": "174054c6-8cab-476d-adff-677ea06128fc",
      "sku": "20340"
    },
    {
      "data": {
        "prop.Y29yZT1Ub3RhbCtjYXJhdCt3ZWlnaHQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "1.00ct",
        "prop.Y29yZT1SZWNpcGllbnQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Women",
        "shopify.ivy_and_rose.collection_ids": [],
        "shopify.ivy_and_rose.tags": [],
        "prop.Y29yZT1TZXR0aW5nK3N0eWxlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1EaWFtb25kK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "shopify.ilya_fine_jewelry.product_type": null,
        "prop.Y29yZT1DbGFyaXR5JmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1TaWRlK3N0b25lK3NoYXBlJmViYXk9JmV0c3k9JnNob3BpZnk9": "Round",
        "etsy.ivy_and_rose.who_made": "i_did",
        "prop.Y29yZT1TaXplJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1DZW50ZXIrbWluK3dpZHRoK2ZpdCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "etsy.ivy_and_rose.price": "50",
        "prop.Y29yZT1DZXJ0aWZpY2F0aW9uJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "etsy.ivy_and_rose.title": "1 Carat Natural Diamond Dangle Drop Earrings Teardrop 14K White Gold Pear Shape",
        "ebay.ivy_and_rose.store_category_id": "14737505",
        "product.main_style": "Drop &amp; Dangle",
        "product.sku": "19569-19568",
        "shopify.ivy_and_rose.title": null,
        "shopify.ivy_and_rose.price": null,
        "prop.Y29yZT1TaWRlK3N0b25lcytxdWFudGl0eSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "etsy.ivy_and_rose.is_supply": false,
        "prop.Y29yZT1HcmFtK3dlaWdodCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "5.1 grams",
        "prop.Y29yZT1DZW50ZXIrbWF4K2xlbmd0aCtmaXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "ebay.ivy_and_rose.condition": "NEW",
        "prop.Y29yZT1TZWNvbmRhcnkrc3RvbmUmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1TaWRlK3N0b25lK2NhcmF0K3dlaWdodCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "1.00ctw",
        "prop.Y29yZT1SaW5nK3NpemUmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1NZXRhbCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "14k White Gold",
        "prop.Y29yZT1UaGVtZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1BcHByb3hpbWF0ZSt0aW1lK3BlcmlvZCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "New",
        "prop.Y29yZT1NYWluK3N0b25lK3NoYXBlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1DZW50ZXIrbWluK2xlbmd0aCtmaXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "ebay.ivy_and_rose.secondary_category_id": null,
        "prop.Y29yZT1NYXgrc2l6ZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "10",
        "prop.Y29yZT1GYW5jeStkaWFtb25kK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "etsy.ivy_and_rose.shop_section_id": "17821498",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY2FyYXQrd2VpZ2h0JmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1DdXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1NYXRlcmlhbCttdWx0aSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "14k White Gold",
        "prop.Y29yZT1DZW50ZXIrbWF4K3dpZHRoK2ZpdCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "ebay.ivy_and_rose.sub_title": null,
        "product.additional_styles": [],
        "product.price": "50",
        "prop.Y29yZT1NZXRhbCtwdXJpdHkmZWJheT0mZXRzeT0mc2hvcGlmeT0": "14k",
        "product.title": "1 CARAT NATURAL DIAMOND DANGLE DROP EARRINGS TEARDROP 14k WHITE GOLD PEAR SHAPE",
        "prop.Y29yZT1UcmVhdG1lbnQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Not Treated, Not Enhanced",
        "prop.Y29yZT1NYWluK3N0b25lJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1TdHlsZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "Dangle/Drop",
        "prop.Y29yZT1PY2Nhc2lvbiZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "shopify.ivy_and_rose.product_type": null,
        "ebay.ivy_and_rose.price": "50",
        "product.description": "Why we love it: \r\n\r\nThese diamond drop earrings offer elegant length and movement with bright diamond accents dangling below a diamond encrusted teardrop shape..\r\n\r\n\r\n\r\nHighlights:\r\n\r\n- 1 carat of bright white and eye clean G-H SI1-SI2 diamonds. \r\n\r\n- Natural, earth mined diamonds\r\n\r\n- 14k white gold settings\r\n\r\n\r\n\r\nDimensions:\r\n\r\n32mm (1.25 inch) in length",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "etsy.ivy_and_rose.category_id": "1208",
        "shopify.ivy_and_rose.vendor": null,
        "product.cost": "10",
        "prop.Y29yZT1TaXphYmxlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1NaW4rc2l6ZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "4",
        "prop.Y29yZT1NYWluK3N0b25lK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1JdGVtK3R5cGUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Drop Earrings - Dangle",
        "prop.Y29yZT1FcmEmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Modern",
        "product.internal_title": null,
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY29sb3ImZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "shopify.ilya_fine_jewelry.price": null,
        "prop.Y29yZT1Db2xsZWN0aW9uJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "product.notes": "32.0 x 3.0",
        "shopify.ilya_fine_jewelry.title": null,
        "product.stock_number": null,
        "etsy.ivy_and_rose.when_made": "2010_2019",
        "prop.Y29yZT1DYW4rYmUrcGVyc29uYWxpemVkJmViYXk9JmV0c3k9JnNob3BpZnk9": "Yes",
        "product.location": "some",
        "shopify.ilya_fine_jewelry.seo_title": null,
        "prop.Y29yZT1OYXR1cmFsJTJGbGFiLWNyZWF0ZWQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Natural",
        "ebay.ivy_and_rose.store_category2_id": null,
        "shopify.ilya_fine_jewelry.vendor": null,
        "shopify.ivy_and_rose.seo_title": null,
        "prop.Y29yZT1HZW1zdG9uZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "Diamond",
        "prop.Y29yZT1TaWRlK3N0b25lJmViYXk9JmV0c3k9JnNob3BpZnk9": "Diamond",
        "shopify.ilya_fine_jewelry.collection_ids": [],
        "shopify.ilya_fine_jewelry.tags": [],
        "prop.Y29yZT1TaWRlK3N0b25lK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": "G-H",
        "ebay.ivy_and_rose.category_id": "261990",
        "prop.Y29yZT1NYWRlK3RvK29yZGVyJmViYXk9JmV0c3k9JnNob3BpZnk9": "No",
        "product.availability": 1,
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrc2hhcGUmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY2xhcml0eSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1TaWRlK3N0b25lK2NsYXJpdHkmZWJheT0mZXRzeT0mc2hvcGlmeT0": "SI1-SI2"
      },
      "id": "b76b6cee-2452-4cfc-ac54-bdfd2d28de80",
      "sku": "19569-19568"
    },
    {
      "data": {
        "prop.Y29yZT1Ub3RhbCtjYXJhdCt3ZWlnaHQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "2.20ct",
        "prop.Y29yZT1SZWNpcGllbnQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Women",
        "shopify.ivy_and_rose.collection_ids": [],
        "shopify.ivy_and_rose.tags": [],
        "prop.Y29yZT1TZXR0aW5nK3N0eWxlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1EaWFtb25kK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "shopify.ilya_fine_jewelry.product_type": null,
        "prop.Y29yZT1DbGFyaXR5JmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1TaWRlK3N0b25lK3NoYXBlJmViYXk9JmV0c3k9JnNob3BpZnk9": "Round",
        "etsy.ivy_and_rose.who_made": "i_did",
        "prop.Y29yZT1TaXplJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1DZW50ZXIrbWluK3dpZHRoK2ZpdCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "etsy.ivy_and_rose.price": "50",
        "prop.Y29yZT1DZXJ0aWZpY2F0aW9uJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "etsy.ivy_and_rose.title": "Ruby Diamond Stud Earrings 14K White Gold 1.80Ct Oval Shape Natural Rich Red",
        "ebay.ivy_and_rose.store_category_id": "14737504",
        "product.main_style": "Stud",
        "product.sku": "19547-19546",
        "shopify.ivy_and_rose.title": null,
        "shopify.ivy_and_rose.price": null,
        "prop.Y29yZT1TaWRlK3N0b25lcytxdWFudGl0eSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "etsy.ivy_and_rose.is_supply": false,
        "prop.Y29yZT1HcmFtK3dlaWdodCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "3.1 grams",
        "prop.Y29yZT1DZW50ZXIrbWF4K2xlbmd0aCtmaXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "ebay.ivy_and_rose.condition": "NEW",
        "prop.Y29yZT1TZWNvbmRhcnkrc3RvbmUmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1TaWRlK3N0b25lK2NhcmF0K3dlaWdodCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "0.40ctw",
        "prop.Y29yZT1SaW5nK3NpemUmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1NZXRhbCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "14k White Gold",
        "prop.Y29yZT1UaGVtZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1BcHByb3hpbWF0ZSt0aW1lK3BlcmlvZCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "New",
        "prop.Y29yZT1NYWluK3N0b25lK3NoYXBlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1DZW50ZXIrbWluK2xlbmd0aCtmaXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "ebay.ivy_and_rose.secondary_category_id": null,
        "prop.Y29yZT1NYXgrc2l6ZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "10",
        "prop.Y29yZT1GYW5jeStkaWFtb25kK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "etsy.ivy_and_rose.shop_section_id": "17821498",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY2FyYXQrd2VpZ2h0JmViYXk9JmV0c3k9JnNob3BpZnk9": "1.80ctw",
        "prop.Y29yZT1DdXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1NYXRlcmlhbCttdWx0aSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "14k White Gold",
        "prop.Y29yZT1DZW50ZXIrbWF4K3dpZHRoK2ZpdCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "ebay.ivy_and_rose.sub_title": null,
        "product.additional_styles": [
          "Halo"
        ],
        "product.price": "50",
        "prop.Y29yZT1NZXRhbCtwdXJpdHkmZWJheT0mZXRzeT0mc2hvcGlmeT0": "14k",
        "product.title": "RUBY DIAMOND STUD EARRINGS 14k WHITE GOLD 1.80ct OVAL SHAPE NATURAL RICH RED",
        "prop.Y29yZT1UcmVhdG1lbnQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1NYWluK3N0b25lJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1TdHlsZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "Stud",
        "prop.Y29yZT1PY2Nhc2lvbiZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "shopify.ivy_and_rose.product_type": null,
        "ebay.ivy_and_rose.price": "50",
        "product.description": "Why we love it: \n\r\nThese high quality and classic stud earrings feature rich red oval shape rubies paired with loops of vibrant diamonds.\n\r\n\n\r\nHighlights:\n\r\n- 1.80ctw pair of oval shape rubies with rich red color\n\r\n- 0.40ct of bright white and eye clean diamond accents\n\r\n- Classic 14k white gold settings\n\r\n\n\r\nDimensions:\n\r\n14.3 x 4.8mm.",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Rubyx2",
        "etsy.ivy_and_rose.category_id": "1214",
        "shopify.ivy_and_rose.vendor": null,
        "product.cost": "10",
        "prop.Y29yZT1TaXphYmxlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1NaW4rc2l6ZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "4",
        "prop.Y29yZT1NYWluK3N0b25lK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1JdGVtK3R5cGUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Stud Earrings - Mounted",
        "prop.Y29yZT1FcmEmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Modern",
        "product.internal_title": null,
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY29sb3ImZWJheT0mZXRzeT0mc2hvcGlmeT0": "Red",
        "shopify.ilya_fine_jewelry.price": null,
        "prop.Y29yZT1Db2xsZWN0aW9uJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "product.notes": "14.3 x 4.8",
        "shopify.ilya_fine_jewelry.title": null,
        "product.stock_number": null,
        "etsy.ivy_and_rose.when_made": "2010_2019",
        "prop.Y29yZT1DYW4rYmUrcGVyc29uYWxpemVkJmViYXk9JmV0c3k9JnNob3BpZnk9": "Yes",
        "product.location": "some",
        "shopify.ilya_fine_jewelry.seo_title": null,
        "prop.Y29yZT1OYXR1cmFsJTJGbGFiLWNyZWF0ZWQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Natural",
        "ebay.ivy_and_rose.store_category2_id": null,
        "shopify.ilya_fine_jewelry.vendor": null,
        "shopify.ivy_and_rose.seo_title": null,
        "prop.Y29yZT1HZW1zdG9uZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "Rubyx2",
        "prop.Y29yZT1TaWRlK3N0b25lJmViYXk9JmV0c3k9JnNob3BpZnk9": "Diamond",
        "shopify.ilya_fine_jewelry.collection_ids": [],
        "shopify.ilya_fine_jewelry.tags": [],
        "prop.Y29yZT1TaWRlK3N0b25lK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": "H-I",
        "ebay.ivy_and_rose.category_id": "261990",
        "prop.Y29yZT1NYWRlK3RvK29yZGVyJmViYXk9JmV0c3k9JnNob3BpZnk9": "No",
        "product.availability": 1,
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrc2hhcGUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Oval",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY2xhcml0eSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1TaWRlK3N0b25lK2NsYXJpdHkmZWJheT0mZXRzeT0mc2hvcGlmeT0": "VS2-SI2"
      },
      "id": "bdedef9f-3e07-47af-b0a2-7d3bc6afdb16",
      "sku": "19547-19546"
    },
    {
      "data": {
        "prop.Y29yZT1Ub3RhbCtjYXJhdCt3ZWlnaHQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "0.25ct",
        "prop.Y29yZT1SZWNpcGllbnQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Women",
        "shopify.ivy_and_rose.collection_ids": [],
        "shopify.ivy_and_rose.tags": [],
        "prop.Y29yZT1TZXR0aW5nK3N0eWxlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1EaWFtb25kK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "shopify.ilya_fine_jewelry.product_type": null,
        "prop.Y29yZT1DbGFyaXR5JmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1TaWRlK3N0b25lK3NoYXBlJmViYXk9JmV0c3k9JnNob3BpZnk9": "Round (Very Good-Excellent Cut)",
        "etsy.ivy_and_rose.who_made": "i_did",
        "prop.Y29yZT1TaXplJmViYXk9JmV0c3k9JnNob3BpZnk9": "5.5 (Resizable)",
        "prop.Y29yZT1DZW50ZXIrbWluK3dpZHRoK2ZpdCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "etsy.ivy_and_rose.price": "50",
        "prop.Y29yZT1DZXJ0aWZpY2F0aW9uJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "etsy.ivy_and_rose.title": "Natural Diamond Wedding Ring Stacking Band 14K White Gold 1/4Ct Channel Setting",
        "ebay.ivy_and_rose.store_category_id": "14843984",
        "product.main_style": "Channel",
        "product.sku": "19252-19251",
        "shopify.ivy_and_rose.title": null,
        "shopify.ivy_and_rose.price": null,
        "prop.Y29yZT1TaWRlK3N0b25lcytxdWFudGl0eSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "etsy.ivy_and_rose.is_supply": false,
        "prop.Y29yZT1HcmFtK3dlaWdodCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "1.2 grams",
        "prop.Y29yZT1DZW50ZXIrbWF4K2xlbmd0aCtmaXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "ebay.ivy_and_rose.condition": "NEW",
        "prop.Y29yZT1TZWNvbmRhcnkrc3RvbmUmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1TaWRlK3N0b25lK2NhcmF0K3dlaWdodCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "0.25ctw",
        "prop.Y29yZT1SaW5nK3NpemUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "5.5",
        "prop.Y29yZT1NZXRhbCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "14k White Gold",
        "prop.Y29yZT1UaGVtZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1BcHByb3hpbWF0ZSt0aW1lK3BlcmlvZCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "New",
        "prop.Y29yZT1NYWluK3N0b25lK3NoYXBlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1DZW50ZXIrbWluK2xlbmd0aCtmaXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "ebay.ivy_and_rose.secondary_category_id": "261994",
        "prop.Y29yZT1NYXgrc2l6ZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "10",
        "prop.Y29yZT1GYW5jeStkaWFtb25kK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "etsy.ivy_and_rose.shop_section_id": "17822028",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY2FyYXQrd2VpZ2h0JmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1DdXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1NYXRlcmlhbCttdWx0aSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "14k White Gold",
        "prop.Y29yZT1DZW50ZXIrbWF4K3dpZHRoK2ZpdCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "ebay.ivy_and_rose.sub_title": null,
        "product.additional_styles": [],
        "product.price": "50",
        "prop.Y29yZT1NZXRhbCtwdXJpdHkmZWJheT0mZXRzeT0mc2hvcGlmeT0": "14k",
        "product.title": "NATURAL DIAMOND WEDDING RING STACKING BAND 14k WHITE GOLD 1/4ct CHANNEL SETTING",
        "prop.Y29yZT1UcmVhdG1lbnQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Not Treated, Not Enhanced",
        "prop.Y29yZT1NYWluK3N0b25lJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1TdHlsZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "Channel",
        "prop.Y29yZT1PY2Nhc2lvbiZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "shopify.ivy_and_rose.product_type": null,
        "ebay.ivy_and_rose.price": "50",
        "product.description": "Why we love it: \n\r\nThis diamond band has a classic design with 1/4 carat of natural diamonds across the face! The channel setting offers a sleek silhouette.\n\r\n\n\r\nHighlights:\n\r\n- 1/4 carat of bright white and eye clean diamonds\n\r\n- All very good to excellent cut for fantastic sparkle\n\r\n- Sleek and classic 14k white gold channel setting\n\r\n\n\r\nDimensions:\n\r\nThe ring measures 2.1mm across (north south) and 1.9mm from finger to top",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "etsy.ivy_and_rose.category_id": "1247",
        "shopify.ivy_and_rose.vendor": null,
        "product.cost": "10",
        "prop.Y29yZT1TaXphYmxlJmViYXk9JmV0c3k9JnNob3BpZnk9": "yes",
        "prop.Y29yZT1NaW4rc2l6ZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "4",
        "prop.Y29yZT1NYWluK3N0b25lK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1JdGVtK3R5cGUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Band - Wedding",
        "prop.Y29yZT1FcmEmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Modern",
        "product.internal_title": "ring - wedding band - modern",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY29sb3ImZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "shopify.ilya_fine_jewelry.price": null,
        "prop.Y29yZT1Db2xsZWN0aW9uJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "product.notes": "2.1 x 1.9",
        "shopify.ilya_fine_jewelry.title": null,
        "product.stock_number": null,
        "etsy.ivy_and_rose.when_made": "2010_2019",
        "prop.Y29yZT1DYW4rYmUrcGVyc29uYWxpemVkJmViYXk9JmV0c3k9JnNob3BpZnk9": "Yes",
        "product.location": "some",
        "shopify.ilya_fine_jewelry.seo_title": null,
        "prop.Y29yZT1OYXR1cmFsJTJGbGFiLWNyZWF0ZWQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Natural",
        "ebay.ivy_and_rose.store_category2_id": "14736870",
        "shopify.ilya_fine_jewelry.vendor": null,
        "shopify.ivy_and_rose.seo_title": null,
        "prop.Y29yZT1HZW1zdG9uZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "Diamond",
        "prop.Y29yZT1TaWRlK3N0b25lJmViYXk9JmV0c3k9JnNob3BpZnk9": "Diamond",
        "shopify.ilya_fine_jewelry.collection_ids": [],
        "shopify.ilya_fine_jewelry.tags": [],
        "prop.Y29yZT1TaWRlK3N0b25lK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": "F-G",
        "ebay.ivy_and_rose.category_id": "261977",
        "prop.Y29yZT1NYWRlK3RvK29yZGVyJmViYXk9JmV0c3k9JnNob3BpZnk9": "No",
        "product.availability": 2,
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrc2hhcGUmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY2xhcml0eSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1TaWRlK3N0b25lK2NsYXJpdHkmZWJheT0mZXRzeT0mc2hvcGlmeT0": "SI1-SI2"
      },
      "id": "e542405c-dd92-4a07-8595-42a02e36ec09",
      "sku": "19252-19251"
    },
    {
      "data": {
        "prop.Y29yZT1Ub3RhbCtjYXJhdCt3ZWlnaHQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1SZWNpcGllbnQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Women",
        "shopify.ivy_and_rose.collection_ids": [],
        "shopify.ivy_and_rose.tags": [],
        "prop.Y29yZT1TZXR0aW5nK3N0eWxlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1EaWFtb25kK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "shopify.ilya_fine_jewelry.product_type": null,
        "prop.Y29yZT1DbGFyaXR5JmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1TaWRlK3N0b25lK3NoYXBlJmViYXk9JmV0c3k9JnNob3BpZnk9": "Round",
        "etsy.ivy_and_rose.who_made": "someone_else",
        "prop.Y29yZT1TaXplJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1DZW50ZXIrbWluK3dpZHRoK2ZpdCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "etsy.ivy_and_rose.price": "50",
        "prop.Y29yZT1DZXJ0aWZpY2F0aW9uJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "etsy.ivy_and_rose.title": "Vintage Opal Dangle Earrings 14K Yellow Gold Drop Estate Jewelry Natural Pear",
        "ebay.ivy_and_rose.store_category_id": "14737505",
        "product.main_style": "Drop &amp; Dangle",
        "product.sku": "20701",
        "shopify.ivy_and_rose.title": null,
        "shopify.ivy_and_rose.price": null,
        "prop.Y29yZT1TaWRlK3N0b25lcytxdWFudGl0eSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "etsy.ivy_and_rose.is_supply": false,
        "prop.Y29yZT1HcmFtK3dlaWdodCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "4.6 grams",
        "prop.Y29yZT1DZW50ZXIrbWF4K2xlbmd0aCtmaXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "ebay.ivy_and_rose.condition": "USED_EXCELLENT",
        "prop.Y29yZT1TZWNvbmRhcnkrc3RvbmUmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1TaWRlK3N0b25lK2NhcmF0K3dlaWdodCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "4mm",
        "prop.Y29yZT1SaW5nK3NpemUmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1NZXRhbCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "14k Yellow Gold",
        "prop.Y29yZT1UaGVtZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1BcHByb3hpbWF0ZSt0aW1lK3BlcmlvZCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "Retro",
        "prop.Y29yZT1NYWluK3N0b25lK3NoYXBlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1DZW50ZXIrbWluK2xlbmd0aCtmaXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "ebay.ivy_and_rose.secondary_category_id": "262008",
        "prop.Y29yZT1NYXgrc2l6ZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "10",
        "prop.Y29yZT1GYW5jeStkaWFtb25kK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "etsy.ivy_and_rose.shop_section_id": "17821498",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY2FyYXQrd2VpZ2h0JmViYXk9JmV0c3k9JnNob3BpZnk9": "7.5 x 4.5mm",
        "prop.Y29yZT1DdXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1NYXRlcmlhbCttdWx0aSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "14k Yellow Gold",
        "prop.Y29yZT1DZW50ZXIrbWF4K3dpZHRoK2ZpdCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "ebay.ivy_and_rose.sub_title": null,
        "product.additional_styles": [],
        "product.price": "50",
        "prop.Y29yZT1NZXRhbCtwdXJpdHkmZWJheT0mZXRzeT0mc2hvcGlmeT0": "14k",
        "product.title": "VINTAGE OPAL DANGLE EARRINGS 14k YELLOW GOLD DROP ESTATE JEWELRY NATURAL PEAR",
        "prop.Y29yZT1UcmVhdG1lbnQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1NYWluK3N0b25lJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1TdHlsZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "Dangle/Drop",
        "prop.Y29yZT1PY2Nhc2lvbiZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "shopify.ivy_and_rose.product_type": null,
        "ebay.ivy_and_rose.price": "50",
        "product.description": "Why we love it: \r\n\r\nThis charming pair of vintage earrings features natural opals set in an intricately textured 14k yellow gold design!\r\n\r\n\r\n\r\nHighlights:\r\n\r\n- Original vintage\r\n\r\n- Hand crafted \r\n\r\n- Opals flash blue and green fire\r\n\r\n- Hand crafted 14k yellow gold settings\r\n\r\n\r\n\r\nDimensions:\r\n\r\n24.4 x 2.9mm",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Opalx2",
        "etsy.ivy_and_rose.category_id": "1208",
        "shopify.ivy_and_rose.vendor": null,
        "product.cost": "10",
        "prop.Y29yZT1TaXphYmxlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1NaW4rc2l6ZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "4",
        "prop.Y29yZT1NYWluK3N0b25lK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1JdGVtK3R5cGUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Drop Earrings - Dangle",
        "prop.Y29yZT1FcmEmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Original Vintage",
        "product.internal_title": null,
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY29sb3ImZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "shopify.ilya_fine_jewelry.price": null,
        "prop.Y29yZT1Db2xsZWN0aW9uJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "product.notes": "24.4 x 2.9 (Natural Opal!)",
        "shopify.ilya_fine_jewelry.title": null,
        "product.stock_number": null,
        "etsy.ivy_and_rose.when_made": "1950s",
        "prop.Y29yZT1DYW4rYmUrcGVyc29uYWxpemVkJmViYXk9JmV0c3k9JnNob3BpZnk9": "Yes",
        "product.location": "some",
        "shopify.ilya_fine_jewelry.seo_title": null,
        "prop.Y29yZT1OYXR1cmFsJTJGbGFiLWNyZWF0ZWQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Natural",
        "ebay.ivy_and_rose.store_category2_id": "14736887",
        "shopify.ilya_fine_jewelry.vendor": null,
        "shopify.ivy_and_rose.seo_title": null,
        "prop.Y29yZT1HZW1zdG9uZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "Opalx2",
        "prop.Y29yZT1TaWRlK3N0b25lJmViYXk9JmV0c3k9JnNob3BpZnk9": "Opalx2",
        "shopify.ilya_fine_jewelry.collection_ids": [],
        "shopify.ilya_fine_jewelry.tags": [],
        "prop.Y29yZT1TaWRlK3N0b25lK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "ebay.ivy_and_rose.category_id": "261990",
        "prop.Y29yZT1NYWRlK3RvK29yZGVyJmViYXk9JmV0c3k9JnNob3BpZnk9": "No",
        "product.availability": 0,
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrc2hhcGUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Pear Shape",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY2xhcml0eSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1TaWRlK3N0b25lK2NsYXJpdHkmZWJheT0mZXRzeT0mc2hvcGlmeT0": null
      },
      "id": "b786fc79-16a1-43ad-83e1-a4c134537649",
      "sku": "20701"
    },
    {
      "data": {
        "prop.Y29yZT1Ub3RhbCtjYXJhdCt3ZWlnaHQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "1.00ct",
        "prop.Y29yZT1SZWNpcGllbnQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Women",
        "shopify.ivy_and_rose.collection_ids": [],
        "shopify.ivy_and_rose.tags": [],
        "prop.Y29yZT1TZXR0aW5nK3N0eWxlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1EaWFtb25kK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "shopify.ilya_fine_jewelry.product_type": null,
        "prop.Y29yZT1DbGFyaXR5JmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1TaWRlK3N0b25lK3NoYXBlJmViYXk9JmV0c3k9JnNob3BpZnk9": "Aquamarine: Round, Oval",
        "etsy.ivy_and_rose.who_made": "someone_else",
        "prop.Y29yZT1TaXplJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1DZW50ZXIrbWluK3dpZHRoK2ZpdCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "etsy.ivy_and_rose.price": "50",
        "prop.Y29yZT1DZXJ0aWZpY2F0aW9uJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "etsy.ivy_and_rose.title": "Antique Aquamarine Pearl Pendant 15K Yellow Gold English Art Nouveau Pin Brooch",
        "ebay.ivy_and_rose.store_category_id": "5809673",
        "product.main_style": "Pendant",
        "product.sku": "20690",
        "shopify.ivy_and_rose.title": null,
        "shopify.ivy_and_rose.price": null,
        "prop.Y29yZT1TaWRlK3N0b25lcytxdWFudGl0eSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "etsy.ivy_and_rose.is_supply": false,
        "prop.Y29yZT1HcmFtK3dlaWdodCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "4.4 grams",
        "prop.Y29yZT1DZW50ZXIrbWF4K2xlbmd0aCtmaXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "ebay.ivy_and_rose.condition": "USED_EXCELLENT",
        "prop.Y29yZT1TZWNvbmRhcnkrc3RvbmUmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1TaWRlK3N0b25lK2NhcmF0K3dlaWdodCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "Aquamarine: Approx. 1.00ctw",
        "prop.Y29yZT1SaW5nK3NpemUmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1NZXRhbCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "15k Yellow Gold",
        "prop.Y29yZT1UaGVtZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1BcHByb3hpbWF0ZSt0aW1lK3BlcmlvZCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "Art Deco/Art Nouveau",
        "prop.Y29yZT1NYWluK3N0b25lK3NoYXBlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1DZW50ZXIrbWluK2xlbmd0aCtmaXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "ebay.ivy_and_rose.secondary_category_id": "262013",
        "prop.Y29yZT1NYXgrc2l6ZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "10",
        "prop.Y29yZT1GYW5jeStkaWFtb25kK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "etsy.ivy_and_rose.shop_section_id": "17869125",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY2FyYXQrd2VpZ2h0JmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1DdXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1NYXRlcmlhbCttdWx0aSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "15k Yellow Gold",
        "prop.Y29yZT1DZW50ZXIrbWF4K3dpZHRoK2ZpdCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "ebay.ivy_and_rose.sub_title": null,
        "product.additional_styles": [],
        "product.price": "50",
        "prop.Y29yZT1NZXRhbCtwdXJpdHkmZWJheT0mZXRzeT0mc2hvcGlmeT0": "15k",
        "product.title": "ANTIQUE AQUAMARINE PEARL PENDANT 15k YELLOW GOLD ENGLISH ART NOUVEAU PIN BROOCH",
        "prop.Y29yZT1UcmVhdG1lbnQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1NYWluK3N0b25lJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1TdHlsZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "Pendant",
        "prop.Y29yZT1PY2Nhc2lvbiZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "shopify.ivy_and_rose.product_type": null,
        "ebay.ivy_and_rose.price": "50",
        "product.description": "Why we love it: \n\r\nCrafted in the Art Nouveau era, this vintage pin/pendant is a fantastic find with sky blue aquamarine accents and a romantic, pearl studded floral design! \n\r\n\n\r\nHighlights:\n\r\n- Original Art Nouveau\n\r\n- English antique\n\r\n- 1 carat natural aquamarines\n\r\n- Beautiful and romantic seed pearls\n\r\n- 15k yellow gold, stamped 15c\n\r\n- Masterful craftsmanship! Made by a true artist!\n\r\n\n\r\nDimensions:\n\r\n1.5 inch in length\n\r\n\n\r\nAdditional Notes:\n\r\nFeatures both a bail and a pin back, so it can be worn either way",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "etsy.ivy_and_rose.category_id": "1229",
        "shopify.ivy_and_rose.vendor": null,
        "product.cost": "10",
        "prop.Y29yZT1TaXphYmxlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1NaW4rc2l6ZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "4",
        "prop.Y29yZT1NYWluK3N0b25lK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1JdGVtK3R5cGUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Necklace - Pendant",
        "prop.Y29yZT1FcmEmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Original Vintage",
        "product.internal_title": null,
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY29sb3ImZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "shopify.ilya_fine_jewelry.price": null,
        "prop.Y29yZT1Db2xsZWN0aW9uJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "product.notes": "1.5\" Lenght (Original art nouvaue, English antique, stamp \"15c\"",
        "shopify.ilya_fine_jewelry.title": null,
        "product.stock_number": null,
        "etsy.ivy_and_rose.when_made": "1920s",
        "prop.Y29yZT1DYW4rYmUrcGVyc29uYWxpemVkJmViYXk9JmV0c3k9JnNob3BpZnk9": "Yes",
        "product.location": "some",
        "shopify.ilya_fine_jewelry.seo_title": null,
        "prop.Y29yZT1OYXR1cmFsJTJGbGFiLWNyZWF0ZWQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Natural",
        "ebay.ivy_and_rose.store_category2_id": "14736889",
        "shopify.ilya_fine_jewelry.vendor": null,
        "shopify.ivy_and_rose.seo_title": null,
        "prop.Y29yZT1HZW1zdG9uZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "Aquamarine, Pearl",
        "prop.Y29yZT1TaWRlK3N0b25lJmViYXk9JmV0c3k9JnNob3BpZnk9": "Aquamarine, Pearl",
        "shopify.ilya_fine_jewelry.collection_ids": [],
        "shopify.ilya_fine_jewelry.tags": [],
        "prop.Y29yZT1TaWRlK3N0b25lK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": "Aquamarine: Blue, Pearl: White",
        "ebay.ivy_and_rose.category_id": "261993",
        "prop.Y29yZT1NYWRlK3RvK29yZGVyJmViYXk9JmV0c3k9JnNob3BpZnk9": "No",
        "product.availability": 1,
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrc2hhcGUmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY2xhcml0eSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1TaWRlK3N0b25lK2NsYXJpdHkmZWJheT0mZXRzeT0mc2hvcGlmeT0": null
      },
      "id": "1b98cd5d-cc20-4a58-8132-efdf53b0420c",
      "sku": "20690"
    },
    {
      "data": {
        "prop.Y29yZT1Ub3RhbCtjYXJhdCt3ZWlnaHQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "0.50ct",
        "prop.Y29yZT1SZWNpcGllbnQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Women",
        "shopify.ivy_and_rose.collection_ids": [],
        "shopify.ivy_and_rose.tags": [],
        "prop.Y29yZT1TZXR0aW5nK3N0eWxlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1EaWFtb25kK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "shopify.ilya_fine_jewelry.product_type": null,
        "prop.Y29yZT1DbGFyaXR5JmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1TaWRlK3N0b25lK3NoYXBlJmViYXk9JmV0c3k9JnNob3BpZnk9": "Round",
        "etsy.ivy_and_rose.who_made": "i_did",
        "prop.Y29yZT1TaXplJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1DZW50ZXIrbWluK3dpZHRoK2ZpdCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "etsy.ivy_and_rose.price": "50",
        "prop.Y29yZT1DZXJ0aWZpY2F0aW9uJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "etsy.ivy_and_rose.title": "Diamond Cluster Stud Earrings Round Cut 14K White Gold Natural 1/2Ct G-H Si1-Si2",
        "ebay.ivy_and_rose.store_category_id": "14737504",
        "product.main_style": "Stud",
        "product.sku": "20313",
        "shopify.ivy_and_rose.title": null,
        "shopify.ivy_and_rose.price": null,
        "prop.Y29yZT1TaWRlK3N0b25lcytxdWFudGl0eSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "etsy.ivy_and_rose.is_supply": false,
        "prop.Y29yZT1HcmFtK3dlaWdodCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "1.5 grams",
        "prop.Y29yZT1DZW50ZXIrbWF4K2xlbmd0aCtmaXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "ebay.ivy_and_rose.condition": "NEW",
        "prop.Y29yZT1TZWNvbmRhcnkrc3RvbmUmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1TaWRlK3N0b25lK2NhcmF0K3dlaWdodCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "0.50ctw",
        "prop.Y29yZT1SaW5nK3NpemUmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1NZXRhbCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "14k White Gold",
        "prop.Y29yZT1UaGVtZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1BcHByb3hpbWF0ZSt0aW1lK3BlcmlvZCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "New",
        "prop.Y29yZT1NYWluK3N0b25lK3NoYXBlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1DZW50ZXIrbWluK2xlbmd0aCtmaXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "ebay.ivy_and_rose.secondary_category_id": null,
        "prop.Y29yZT1NYXgrc2l6ZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "10",
        "prop.Y29yZT1GYW5jeStkaWFtb25kK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "etsy.ivy_and_rose.shop_section_id": "17821498",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY2FyYXQrd2VpZ2h0JmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1DdXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1NYXRlcmlhbCttdWx0aSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "14k White Gold",
        "prop.Y29yZT1DZW50ZXIrbWF4K3dpZHRoK2ZpdCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "ebay.ivy_and_rose.sub_title": null,
        "product.additional_styles": [
          "Cluster"
        ],
        "product.price": "50",
        "prop.Y29yZT1NZXRhbCtwdXJpdHkmZWJheT0mZXRzeT0mc2hvcGlmeT0": "14k",
        "product.title": "DIAMOND CLUSTER STUD EARRINGS ROUND CUT 14k WHITE GOLD NATURAL 1/2ct G-H SI1-SI2",
        "prop.Y29yZT1UcmVhdG1lbnQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Not Treated, Not Enhanced",
        "prop.Y29yZT1NYWluK3N0b25lJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1TdHlsZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "Stud",
        "prop.Y29yZT1PY2Nhc2lvbiZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "shopify.ivy_and_rose.product_type": null,
        "ebay.ivy_and_rose.price": "50",
        "product.description": "Why we love it: \n\r\nThese diamond cluster earrings offer a substantial look with eye catching sparkle!\n\r\n\n\r\nHighlights:\n\r\n- 0.50ct of natural diamonds\n\r\n- Bright white and eye clean with grades of G-H SI1-SI2\n\r\n- Cluster design offers a large look\n\r\n- Classic 14k white gold settings\n\r\n\n\r\nDimensions:\n\r\n7.2mm in diameter",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "etsy.ivy_and_rose.category_id": "1214",
        "shopify.ivy_and_rose.vendor": null,
        "product.cost": "10",
        "prop.Y29yZT1TaXphYmxlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1NaW4rc2l6ZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "4",
        "prop.Y29yZT1NYWluK3N0b25lK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1JdGVtK3R5cGUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Stud Earrings - Mounted",
        "prop.Y29yZT1FcmEmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Modern",
        "product.internal_title": null,
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY29sb3ImZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "shopify.ilya_fine_jewelry.price": null,
        "prop.Y29yZT1Db2xsZWN0aW9uJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "product.notes": "7.2 x 2.3",
        "shopify.ilya_fine_jewelry.title": null,
        "product.stock_number": null,
        "etsy.ivy_and_rose.when_made": "2010_2019",
        "prop.Y29yZT1DYW4rYmUrcGVyc29uYWxpemVkJmViYXk9JmV0c3k9JnNob3BpZnk9": "Yes",
        "product.location": "some",
        "shopify.ilya_fine_jewelry.seo_title": null,
        "prop.Y29yZT1OYXR1cmFsJTJGbGFiLWNyZWF0ZWQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Natural",
        "ebay.ivy_and_rose.store_category2_id": null,
        "shopify.ilya_fine_jewelry.vendor": null,
        "shopify.ivy_and_rose.seo_title": null,
        "prop.Y29yZT1HZW1zdG9uZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "Diamond",
        "prop.Y29yZT1TaWRlK3N0b25lJmViYXk9JmV0c3k9JnNob3BpZnk9": "Diamond",
        "shopify.ilya_fine_jewelry.collection_ids": [],
        "shopify.ilya_fine_jewelry.tags": [],
        "prop.Y29yZT1TaWRlK3N0b25lK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": "G-H",
        "ebay.ivy_and_rose.category_id": "261990",
        "prop.Y29yZT1NYWRlK3RvK29yZGVyJmViYXk9JmV0c3k9JnNob3BpZnk9": "No",
        "product.availability": 1,
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrc2hhcGUmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY2xhcml0eSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1TaWRlK3N0b25lK2NsYXJpdHkmZWJheT0mZXRzeT0mc2hvcGlmeT0": "SI1-SI2"
      },
      "id": "0dc19b06-edbf-415a-9cb5-a2942c599aa9",
      "sku": "20313"
    },
    {
      "data": {
        "prop.Y29yZT1Ub3RhbCtjYXJhdCt3ZWlnaHQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "0.05ct",
        "prop.Y29yZT1SZWNpcGllbnQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Women",
        "shopify.ivy_and_rose.collection_ids": [],
        "shopify.ivy_and_rose.tags": [],
        "prop.Y29yZT1TZXR0aW5nK3N0eWxlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1EaWFtb25kK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "shopify.ilya_fine_jewelry.product_type": null,
        "prop.Y29yZT1DbGFyaXR5JmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1TaWRlK3N0b25lK3NoYXBlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "etsy.ivy_and_rose.who_made": "someone_else",
        "prop.Y29yZT1TaXplJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1DZW50ZXIrbWluK3dpZHRoK2ZpdCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "etsy.ivy_and_rose.price": "50",
        "prop.Y29yZT1DZXJ0aWZpY2F0aW9uJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "etsy.ivy_and_rose.title": "Vintage Sapphire 10K Yellow Gold Pendant Dangle Drop Engraved Necklace Art Deco",
        "ebay.ivy_and_rose.store_category_id": "5809673",
        "product.main_style": "Pendant",
        "product.sku": "20308",
        "shopify.ivy_and_rose.title": null,
        "shopify.ivy_and_rose.price": null,
        "prop.Y29yZT1TaWRlK3N0b25lcytxdWFudGl0eSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "etsy.ivy_and_rose.is_supply": false,
        "prop.Y29yZT1HcmFtK3dlaWdodCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "0.7 grams",
        "prop.Y29yZT1DZW50ZXIrbWF4K2xlbmd0aCtmaXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "ebay.ivy_and_rose.condition": "USED_EXCELLENT",
        "prop.Y29yZT1TZWNvbmRhcnkrc3RvbmUmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1TaWRlK3N0b25lK2NhcmF0K3dlaWdodCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1SaW5nK3NpemUmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1NZXRhbCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "10k Yellow Gold",
        "prop.Y29yZT1UaGVtZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1BcHByb3hpbWF0ZSt0aW1lK3BlcmlvZCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "Art Deco/Art Nouveau",
        "prop.Y29yZT1NYWluK3N0b25lK3NoYXBlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1DZW50ZXIrbWluK2xlbmd0aCtmaXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "ebay.ivy_and_rose.secondary_category_id": "262013",
        "prop.Y29yZT1NYXgrc2l6ZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "10",
        "prop.Y29yZT1GYW5jeStkaWFtb25kK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "etsy.ivy_and_rose.shop_section_id": "17869125",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY2FyYXQrd2VpZ2h0JmViYXk9JmV0c3k9JnNob3BpZnk9": "0.05ct",
        "prop.Y29yZT1DdXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1NYXRlcmlhbCttdWx0aSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "10k Yellow Gold",
        "prop.Y29yZT1DZW50ZXIrbWF4K3dpZHRoK2ZpdCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "ebay.ivy_and_rose.sub_title": null,
        "product.additional_styles": [],
        "product.price": "50",
        "prop.Y29yZT1NZXRhbCtwdXJpdHkmZWJheT0mZXRzeT0mc2hvcGlmeT0": "10k",
        "product.title": "VINTAGE SAPPHIRE 10k YELLOW GOLD PENDANT DANGLE DROP ENGRAVED NECKLACE ART DECO",
        "prop.Y29yZT1UcmVhdG1lbnQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1NYWluK3N0b25lJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1TdHlsZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "Pendant",
        "prop.Y29yZT1PY2Nhc2lvbiZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "shopify.ivy_and_rose.product_type": null,
        "ebay.ivy_and_rose.price": "50",
        "product.description": "Why we love it: \n\r\nThis vintage gold and sapphire pendant has an elegant drop design with beautiful scrolling details!\n\r\n\n\r\nHighlights:\n\r\n- Original vintage\n\r\n- Solid 10k yellow gold\n\r\n- Light blue natural sapphire center\n\r\n\n\r\nDimensions:\n\r\n1 inch in length",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Sapphire",
        "etsy.ivy_and_rose.category_id": "1229",
        "shopify.ivy_and_rose.vendor": null,
        "product.cost": "10",
        "prop.Y29yZT1TaXphYmxlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1NaW4rc2l6ZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "4",
        "prop.Y29yZT1NYWluK3N0b25lK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1JdGVtK3R5cGUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Necklace - Pendant",
        "prop.Y29yZT1FcmEmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Original Vintage",
        "product.internal_title": null,
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY29sb3ImZWJheT0mZXRzeT0mc2hvcGlmeT0": "Light Blue",
        "shopify.ilya_fine_jewelry.price": null,
        "prop.Y29yZT1Db2xsZWN0aW9uJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "product.notes": "1\" lenght",
        "shopify.ilya_fine_jewelry.title": null,
        "product.stock_number": null,
        "etsy.ivy_and_rose.when_made": "1920s",
        "prop.Y29yZT1DYW4rYmUrcGVyc29uYWxpemVkJmViYXk9JmV0c3k9JnNob3BpZnk9": "Yes",
        "product.location": "some",
        "shopify.ilya_fine_jewelry.seo_title": null,
        "prop.Y29yZT1OYXR1cmFsJTJGbGFiLWNyZWF0ZWQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Natural",
        "ebay.ivy_and_rose.store_category2_id": "14736889",
        "shopify.ilya_fine_jewelry.vendor": null,
        "shopify.ivy_and_rose.seo_title": null,
        "prop.Y29yZT1HZW1zdG9uZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "Sapphire",
        "prop.Y29yZT1TaWRlK3N0b25lJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "shopify.ilya_fine_jewelry.collection_ids": [],
        "shopify.ilya_fine_jewelry.tags": [],
        "prop.Y29yZT1TaWRlK3N0b25lK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "ebay.ivy_and_rose.category_id": "261993",
        "prop.Y29yZT1NYWRlK3RvK29yZGVyJmViYXk9JmV0c3k9JnNob3BpZnk9": "No",
        "product.availability": 1,
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrc2hhcGUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Round",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY2xhcml0eSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1TaWRlK3N0b25lK2NsYXJpdHkmZWJheT0mZXRzeT0mc2hvcGlmeT0": null
      },
      "id": "c80e01a8-710e-41d6-98d9-9f69a2573f52",
      "sku": "20308"
    },
    {
      "data": {
        "prop.Y29yZT1Ub3RhbCtjYXJhdCt3ZWlnaHQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "28.25ct",
        "prop.Y29yZT1SZWNpcGllbnQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Women",
        "shopify.ivy_and_rose.collection_ids": [],
        "shopify.ivy_and_rose.tags": [],
        "prop.Y29yZT1TZXR0aW5nK3N0eWxlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1EaWFtb25kK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "shopify.ilya_fine_jewelry.product_type": null,
        "prop.Y29yZT1DbGFyaXR5JmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1TaWRlK3N0b25lK3NoYXBlJmViYXk9JmV0c3k9JnNob3BpZnk9": "Rose Cut, Old European Cut",
        "etsy.ivy_and_rose.who_made": "someone_else",
        "prop.Y29yZT1TaXplJmViYXk9JmV0c3k9JnNob3BpZnk9": "15\" Chain",
        "prop.Y29yZT1DZW50ZXIrbWluK3dpZHRoK2ZpdCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "etsy.ivy_and_rose.price": "50",
        "prop.Y29yZT1DZXJ0aWZpY2F0aW9uJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "etsy.ivy_and_rose.title": "Art Nouveau Amethyst Diamond Necklace Antique 18K Yellow Gold Floral Pendant",
        "ebay.ivy_and_rose.store_category_id": "5809673",
        "product.main_style": null,
        "product.sku": "20306",
        "shopify.ivy_and_rose.title": null,
        "shopify.ivy_and_rose.price": null,
        "prop.Y29yZT1TaWRlK3N0b25lcytxdWFudGl0eSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "etsy.ivy_and_rose.is_supply": false,
        "prop.Y29yZT1HcmFtK3dlaWdodCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "11.8 grams",
        "prop.Y29yZT1DZW50ZXIrbWF4K2xlbmd0aCtmaXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "ebay.ivy_and_rose.condition": "USED_EXCELLENT",
        "prop.Y29yZT1TZWNvbmRhcnkrc3RvbmUmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1TaWRlK3N0b25lK2NhcmF0K3dlaWdodCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "0.25ctw",
        "prop.Y29yZT1SaW5nK3NpemUmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1NZXRhbCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "14k, 18k Yellow Gold, Platinum",
        "prop.Y29yZT1UaGVtZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1BcHByb3hpbWF0ZSt0aW1lK3BlcmlvZCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "Art Deco/Art Nouveau",
        "prop.Y29yZT1NYWluK3N0b25lK3NoYXBlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1DZW50ZXIrbWluK2xlbmd0aCtmaXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "ebay.ivy_and_rose.secondary_category_id": "262013",
        "prop.Y29yZT1NYXgrc2l6ZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "10",
        "prop.Y29yZT1GYW5jeStkaWFtb25kK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "etsy.ivy_and_rose.shop_section_id": "17869125",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY2FyYXQrd2VpZ2h0JmViYXk9JmV0c3k9JnNob3BpZnk9": "28ct",
        "prop.Y29yZT1DdXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1NYXRlcmlhbCttdWx0aSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "14k, 18k Yellow Gold, Platinum",
        "prop.Y29yZT1DZW50ZXIrbWF4K3dpZHRoK2ZpdCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "ebay.ivy_and_rose.sub_title": null,
        "product.additional_styles": [],
        "product.price": "50",
        "prop.Y29yZT1NZXRhbCtwdXJpdHkmZWJheT0mZXRzeT0mc2hvcGlmeT0": "14k, 18k, Plat",
        "product.title": "ART NOUVEAU AMETHYST DIAMOND NECKLACE ANTIQUE 18k YELLOW GOLD FLORAL PENDANT",
        "prop.Y29yZT1UcmVhdG1lbnQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1NYWluK3N0b25lJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1TdHlsZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "Pendant",
        "prop.Y29yZT1PY2Nhc2lvbiZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "shopify.ivy_and_rose.product_type": null,
        "ebay.ivy_and_rose.price": "50",
        "product.description": "Why we love it: \r\n\r\nCrafted in the early 20th century, this Art Nouveau era necklace is beautifully hand crafted with floral diamond studded details. The bright purple amethyst stands out strikingly and is perfectly set off by glittering diamond accents.\r\n\r\n\r\n\r\nHighlights:\r\n\r\n- Original Art Nouveau\r\n\r\n- Large 28 carat round cut amethyst with rich purple color and a lively play of light! \r\n\r\n- Beautiful and rare old European cut and rose cut accent diamonds\r\n\r\n- Pendant is crafted in 14k yellow gold topped with platinum\r\n\r\n- 18k yellow gold chain\r\n\r\n\r\n\r\nDimensions:\r\n\r\nChain is 15 inches in length. Pendant measures 36.1 x 13.3mm\r\n\r\n\r\n\r\nAdditional Notes:\r\n\r\nThe necklace was crafted at the turn of the 19th to 20th century. The amethyst was replaced at a later date.",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Amethyst",
        "etsy.ivy_and_rose.category_id": "1217",
        "shopify.ivy_and_rose.vendor": null,
        "product.cost": "10",
        "prop.Y29yZT1TaXphYmxlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1NaW4rc2l6ZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "4",
        "prop.Y29yZT1NYWluK3N0b25lK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1JdGVtK3R5cGUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Necklace - Other",
        "prop.Y29yZT1FcmEmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Original Vintage",
        "product.internal_title": "necklace - vintage",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY29sb3ImZWJheT0mZXRzeT0mc2hvcGlmeT0": "Purple",
        "shopify.ilya_fine_jewelry.price": null,
        "prop.Y29yZT1Db2xsZWN0aW9uJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "product.notes": "36.1 x 13.3 (OG, Amethyst is new)",
        "shopify.ilya_fine_jewelry.title": null,
        "product.stock_number": null,
        "etsy.ivy_and_rose.when_made": "1920s",
        "prop.Y29yZT1DYW4rYmUrcGVyc29uYWxpemVkJmViYXk9JmV0c3k9JnNob3BpZnk9": "Yes",
        "product.location": "some",
        "shopify.ilya_fine_jewelry.seo_title": null,
        "prop.Y29yZT1OYXR1cmFsJTJGbGFiLWNyZWF0ZWQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Natural",
        "ebay.ivy_and_rose.store_category2_id": "14736889",
        "shopify.ilya_fine_jewelry.vendor": null,
        "shopify.ivy_and_rose.seo_title": null,
        "prop.Y29yZT1HZW1zdG9uZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "Amethyst",
        "prop.Y29yZT1TaWRlK3N0b25lJmViYXk9JmV0c3k9JnNob3BpZnk9": "Diamond",
        "shopify.ilya_fine_jewelry.collection_ids": [],
        "shopify.ilya_fine_jewelry.tags": [],
        "prop.Y29yZT1TaWRlK3N0b25lK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": "F-H",
        "ebay.ivy_and_rose.category_id": "261993",
        "prop.Y29yZT1NYWRlK3RvK29yZGVyJmViYXk9JmV0c3k9JnNob3BpZnk9": "No",
        "product.availability": 0,
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrc2hhcGUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Round",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY2xhcml0eSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1TaWRlK3N0b25lK2NsYXJpdHkmZWJheT0mZXRzeT0mc2hvcGlmeT0": "VS2-SI3"
      },
      "id": "d4ef5078-ee01-4436-b65e-00714136a212",
      "sku": "20306"
    },
    {
      "data": {
        "prop.Y29yZT1Ub3RhbCtjYXJhdCt3ZWlnaHQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "0.05ct",
        "prop.Y29yZT1SZWNpcGllbnQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Women",
        "shopify.ivy_and_rose.collection_ids": [],
        "shopify.ivy_and_rose.tags": [],
        "prop.Y29yZT1TZXR0aW5nK3N0eWxlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1EaWFtb25kK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "shopify.ilya_fine_jewelry.product_type": null,
        "prop.Y29yZT1DbGFyaXR5JmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1TaWRlK3N0b25lK3NoYXBlJmViYXk9JmV0c3k9JnNob3BpZnk9": "Round, Vintage Single Cut",
        "etsy.ivy_and_rose.who_made": "someone_else",
        "prop.Y29yZT1TaXplJmViYXk9JmV0c3k9JnNob3BpZnk9": "9.75 (Resizable)",
        "prop.Y29yZT1DZW50ZXIrbWluK3dpZHRoK2ZpdCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "etsy.ivy_and_rose.price": "50",
        "prop.Y29yZT1DZXJ0aWZpY2F0aW9uJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "etsy.ivy_and_rose.title": "Vintage Diamond Ring Wide Satin Finish 10K Two Tone Yellow Gold Illusion Setting",
        "ebay.ivy_and_rose.store_category_id": "14736886",
        "product.main_style": "Cocktail",
        "product.sku": "20270",
        "shopify.ivy_and_rose.title": null,
        "shopify.ivy_and_rose.price": null,
        "prop.Y29yZT1TaWRlK3N0b25lcytxdWFudGl0eSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "3",
        "etsy.ivy_and_rose.is_supply": false,
        "prop.Y29yZT1HcmFtK3dlaWdodCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "3.9 grams",
        "prop.Y29yZT1DZW50ZXIrbWF4K2xlbmd0aCtmaXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "ebay.ivy_and_rose.condition": "USED_EXCELLENT",
        "prop.Y29yZT1TZWNvbmRhcnkrc3RvbmUmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1TaWRlK3N0b25lK2NhcmF0K3dlaWdodCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "0.05ctw",
        "prop.Y29yZT1SaW5nK3NpemUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "9.75",
        "prop.Y29yZT1NZXRhbCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "10k Yellow Gold",
        "prop.Y29yZT1UaGVtZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1BcHByb3hpbWF0ZSt0aW1lK3BlcmlvZCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "Retro",
        "prop.Y29yZT1NYWluK3N0b25lK3NoYXBlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1DZW50ZXIrbWluK2xlbmd0aCtmaXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "ebay.ivy_and_rose.secondary_category_id": "262014",
        "prop.Y29yZT1NYXgrc2l6ZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "10",
        "prop.Y29yZT1GYW5jeStkaWFtb25kK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "etsy.ivy_and_rose.shop_section_id": "21991708",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY2FyYXQrd2VpZ2h0JmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1DdXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1NYXRlcmlhbCttdWx0aSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "10k Yellow Gold",
        "prop.Y29yZT1DZW50ZXIrbWF4K3dpZHRoK2ZpdCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "ebay.ivy_and_rose.sub_title": null,
        "product.additional_styles": [
          "Flush"
        ],
        "product.price": "50",
        "prop.Y29yZT1NZXRhbCtwdXJpdHkmZWJheT0mZXRzeT0mc2hvcGlmeT0": "10k",
        "product.title": "VINTAGE DIAMOND RING WIDE SATIN FINISH 10k TWO TONE YELLOW GOLD ILLUSION SETTING",
        "prop.Y29yZT1UcmVhdG1lbnQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Not Treated, Not Enhanced",
        "prop.Y29yZT1NYWluK3N0b25lJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1TdHlsZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "Cocktail",
        "prop.Y29yZT1PY2Nhc2lvbiZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "shopify.ivy_and_rose.product_type": null,
        "ebay.ivy_and_rose.price": "50",
        "product.description": "Why we love it: \n\r\nThis vintage diamond ring has a bold and broad design featuring bright diamonds! Its very unique two tone design is finished with a stunning, hand applied satin finish!\n\r\n\n\r\nHighlights:\n\r\n- Original vintage\n\r\n- 0.05ct natural diamonds with bright white G color and excellent VS clarity\n\r\n- Unique and well executed illusion setting! \n\r\n- Beautiful satin finish sets off the diamonds and high polish white gold details! \n\r\n- Wide 10k yellow and white gold setting\n\r\n- Versatile aesthetic is suitable for any gender\n\r\n\n\r\nDimensions:\n\r\n10.2mm across (north-south) and 4.5mm from the finger to the top of the setting.",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "etsy.ivy_and_rose.category_id": "1231",
        "shopify.ivy_and_rose.vendor": null,
        "product.cost": "10",
        "prop.Y29yZT1TaXphYmxlJmViYXk9JmV0c3k9JnNob3BpZnk9": "yes",
        "prop.Y29yZT1NaW4rc2l6ZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "4",
        "prop.Y29yZT1NYWluK3N0b25lK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1JdGVtK3R5cGUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Cocktail Ring - Other",
        "prop.Y29yZT1FcmEmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Original Vintage",
        "product.internal_title": "ring - cocktail - vintage",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY29sb3ImZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "shopify.ilya_fine_jewelry.price": null,
        "prop.Y29yZT1Db2xsZWN0aW9uJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "product.notes": "10.2 x 4.5 (very cool illusion setting, awesome finish)",
        "shopify.ilya_fine_jewelry.title": null,
        "product.stock_number": null,
        "etsy.ivy_and_rose.when_made": "1950s",
        "prop.Y29yZT1DYW4rYmUrcGVyc29uYWxpemVkJmViYXk9JmV0c3k9JnNob3BpZnk9": "Yes",
        "product.location": "some",
        "shopify.ilya_fine_jewelry.seo_title": null,
        "prop.Y29yZT1OYXR1cmFsJTJGbGFiLWNyZWF0ZWQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Natural",
        "ebay.ivy_and_rose.store_category2_id": "14736870",
        "shopify.ilya_fine_jewelry.vendor": null,
        "shopify.ivy_and_rose.seo_title": null,
        "prop.Y29yZT1HZW1zdG9uZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "Diamond",
        "prop.Y29yZT1TaWRlK3N0b25lJmViYXk9JmV0c3k9JnNob3BpZnk9": "Diamond",
        "shopify.ilya_fine_jewelry.collection_ids": [],
        "shopify.ilya_fine_jewelry.tags": [],
        "prop.Y29yZT1TaWRlK3N0b25lK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": "G",
        "ebay.ivy_and_rose.category_id": "261994",
        "prop.Y29yZT1NYWRlK3RvK29yZGVyJmViYXk9JmV0c3k9JnNob3BpZnk9": "No",
        "product.availability": 1,
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrc2hhcGUmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY2xhcml0eSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1TaWRlK3N0b25lK2NsYXJpdHkmZWJheT0mZXRzeT0mc2hvcGlmeT0": "VS"
      },
      "id": "3f916666-9d34-4085-a1ff-c0cce6300948",
      "sku": "20270"
    },
    {
      "data": {
        "prop.Y29yZT1Ub3RhbCtjYXJhdCt3ZWlnaHQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "0.07ct",
        "prop.Y29yZT1SZWNpcGllbnQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Women",
        "shopify.ivy_and_rose.collection_ids": [],
        "shopify.ivy_and_rose.tags": [],
        "prop.Y29yZT1TZXR0aW5nK3N0eWxlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1EaWFtb25kK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "shopify.ilya_fine_jewelry.product_type": null,
        "prop.Y29yZT1DbGFyaXR5JmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1TaWRlK3N0b25lK3NoYXBlJmViYXk9JmV0c3k9JnNob3BpZnk9": "Square Cut",
        "etsy.ivy_and_rose.who_made": "someone_else",
        "prop.Y29yZT1TaXplJmViYXk9JmV0c3k9JnNob3BpZnk9": "6.0 (Resizable)",
        "prop.Y29yZT1DZW50ZXIrbWluK3dpZHRoK2ZpdCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "etsy.ivy_and_rose.price": "50",
        "prop.Y29yZT1DZXJ0aWZpY2F0aW9uJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "etsy.ivy_and_rose.title": "Vintage Diamond Sapphire Cocktail Ring Two Tone 14K Yellow White Gold Estate",
        "ebay.ivy_and_rose.store_category_id": "14736886",
        "product.main_style": "Cocktail",
        "product.sku": "19789",
        "shopify.ivy_and_rose.title": null,
        "shopify.ivy_and_rose.price": null,
        "prop.Y29yZT1TaWRlK3N0b25lcytxdWFudGl0eSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "etsy.ivy_and_rose.is_supply": false,
        "prop.Y29yZT1HcmFtK3dlaWdodCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "1.8 grams",
        "prop.Y29yZT1DZW50ZXIrbWF4K2xlbmd0aCtmaXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "ebay.ivy_and_rose.condition": "USED_EXCELLENT",
        "prop.Y29yZT1TZWNvbmRhcnkrc3RvbmUmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1TaWRlK3N0b25lK2NhcmF0K3dlaWdodCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1SaW5nK3NpemUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "6",
        "prop.Y29yZT1NZXRhbCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "14k Yellow &amp; White Gold",
        "prop.Y29yZT1UaGVtZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1BcHByb3hpbWF0ZSt0aW1lK3BlcmlvZCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "Art Deco/Art Nouveau",
        "prop.Y29yZT1NYWluK3N0b25lK3NoYXBlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1DZW50ZXIrbWluK2xlbmd0aCtmaXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "ebay.ivy_and_rose.secondary_category_id": "262014",
        "prop.Y29yZT1NYXgrc2l6ZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "10",
        "prop.Y29yZT1GYW5jeStkaWFtb25kK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "etsy.ivy_and_rose.shop_section_id": "17746504",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY2FyYXQrd2VpZ2h0JmViYXk9JmV0c3k9JnNob3BpZnk9": "0.07ct",
        "prop.Y29yZT1DdXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1NYXRlcmlhbCttdWx0aSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "14k Yellow &amp; White Gold",
        "prop.Y29yZT1DZW50ZXIrbWF4K3dpZHRoK2ZpdCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "ebay.ivy_and_rose.sub_title": null,
        "product.additional_styles": [],
        "product.price": "50",
        "prop.Y29yZT1NZXRhbCtwdXJpdHkmZWJheT0mZXRzeT0mc2hvcGlmeT0": "14k",
        "product.title": "VINTAGE DIAMOND SAPPHIRE COCKTAIL RING TWO TONE 14k YELLOW WHITE GOLD ESTATE",
        "prop.Y29yZT1UcmVhdG1lbnQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1NYWluK3N0b25lJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1TdHlsZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "Cocktail",
        "prop.Y29yZT1PY2Nhc2lvbiZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "shopify.ivy_and_rose.product_type": null,
        "ebay.ivy_and_rose.price": "50",
        "product.description": "Why we love it: \n\r\nCrafted in two tone 14k gold, this vintage ring features a glittering diamond flanked by rich blue sapphires and set off by elegant vintage details.\n\r\n\n\r\nHighlights:\n\r\n- Original vintage\n\r\n- Bright white and eye clean 0.07ct old European cut diamond center\n\r\n- High quality with bright white G color and excellent VS2 clarity\n\r\n- Sapphire accents with very well saturated blue color\n\r\n- 14k white and yellow gold setting\n\r\n\n\r\nDimensions:\n\r\n5.4mm across (north-south) and 3.8mm in height.\n\r\n\n\r\nAdditional Notes:\n\r\nSapphires are likely synthetic as this was common practice at the time.",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Diamond",
        "etsy.ivy_and_rose.category_id": "1231",
        "shopify.ivy_and_rose.vendor": null,
        "product.cost": "10",
        "prop.Y29yZT1TaXphYmxlJmViYXk9JmV0c3k9JnNob3BpZnk9": "yes",
        "prop.Y29yZT1NaW4rc2l6ZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "4",
        "prop.Y29yZT1NYWluK3N0b25lK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1JdGVtK3R5cGUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Cocktail Ring - Other",
        "prop.Y29yZT1FcmEmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Original Vintage",
        "product.internal_title": "ring - cocktail - vintage",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY29sb3ImZWJheT0mZXRzeT0mc2hvcGlmeT0": "G",
        "shopify.ilya_fine_jewelry.price": null,
        "prop.Y29yZT1Db2xsZWN0aW9uJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "product.notes": "5.4 x 3.8",
        "shopify.ilya_fine_jewelry.title": null,
        "product.stock_number": null,
        "etsy.ivy_and_rose.when_made": "1920s",
        "prop.Y29yZT1DYW4rYmUrcGVyc29uYWxpemVkJmViYXk9JmV0c3k9JnNob3BpZnk9": "Yes",
        "product.location": "some",
        "shopify.ilya_fine_jewelry.seo_title": null,
        "prop.Y29yZT1OYXR1cmFsJTJGbGFiLWNyZWF0ZWQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "ebay.ivy_and_rose.store_category2_id": "14736870",
        "shopify.ilya_fine_jewelry.vendor": null,
        "shopify.ivy_and_rose.seo_title": null,
        "prop.Y29yZT1HZW1zdG9uZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "Diamond",
        "prop.Y29yZT1TaWRlK3N0b25lJmViYXk9JmV0c3k9JnNob3BpZnk9": "Synthetic Sapphire",
        "shopify.ilya_fine_jewelry.collection_ids": [],
        "shopify.ilya_fine_jewelry.tags": [],
        "prop.Y29yZT1TaWRlK3N0b25lK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": "Blue",
        "ebay.ivy_and_rose.category_id": "261994",
        "prop.Y29yZT1NYWRlK3RvK29yZGVyJmViYXk9JmV0c3k9JnNob3BpZnk9": "No",
        "product.availability": 0,
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrc2hhcGUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Old European Cut",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY2xhcml0eSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "VS2",
        "prop.Y29yZT1TaWRlK3N0b25lK2NsYXJpdHkmZWJheT0mZXRzeT0mc2hvcGlmeT0": null
      },
      "id": "d7581342-0b2c-4fbc-bf63-8effbfb6c2d9",
      "sku": "19789"
    },
    {
      "data": {
        "prop.Y29yZT1Ub3RhbCtjYXJhdCt3ZWlnaHQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "0.71ct",
        "prop.Y29yZT1SZWNpcGllbnQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Women",
        "shopify.ivy_and_rose.collection_ids": [],
        "shopify.ivy_and_rose.tags": [],
        "prop.Y29yZT1TZXR0aW5nK3N0eWxlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1EaWFtb25kK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "shopify.ilya_fine_jewelry.product_type": null,
        "prop.Y29yZT1DbGFyaXR5JmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1TaWRlK3N0b25lK3NoYXBlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "etsy.ivy_and_rose.who_made": "someone_else",
        "prop.Y29yZT1TaXplJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1DZW50ZXIrbWluK3dpZHRoK2ZpdCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "etsy.ivy_and_rose.price": "50",
        "prop.Y29yZT1DZXJ0aWZpY2F0aW9uJmViYXk9JmV0c3k9JnNob3BpZnk9": "GIA",
        "etsy.ivy_and_rose.title": "0.71Ct Old European Cut Diamond Gia Certified I Si1 Vintage Loose Natural Estate",
        "ebay.ivy_and_rose.store_category_id": "14736769",
        "product.main_style": null,
        "product.sku": "20341",
        "shopify.ivy_and_rose.title": null,
        "shopify.ivy_and_rose.price": null,
        "prop.Y29yZT1TaWRlK3N0b25lcytxdWFudGl0eSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "etsy.ivy_and_rose.is_supply": true,
        "prop.Y29yZT1HcmFtK3dlaWdodCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1DZW50ZXIrbWF4K2xlbmd0aCtmaXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "ebay.ivy_and_rose.condition": "USED_EXCELLENT",
        "prop.Y29yZT1TZWNvbmRhcnkrc3RvbmUmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1TaWRlK3N0b25lK2NhcmF0K3dlaWdodCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1SaW5nK3NpemUmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1NZXRhbCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1UaGVtZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1BcHByb3hpbWF0ZSt0aW1lK3BlcmlvZCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "Art Deco/Art Nouveau",
        "prop.Y29yZT1NYWluK3N0b25lK3NoYXBlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1DZW50ZXIrbWluK2xlbmd0aCtmaXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "ebay.ivy_and_rose.secondary_category_id": null,
        "prop.Y29yZT1NYXgrc2l6ZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "10",
        "prop.Y29yZT1GYW5jeStkaWFtb25kK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "etsy.ivy_and_rose.shop_section_id": "18036037",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY2FyYXQrd2VpZ2h0JmViYXk9JmV0c3k9JnNob3BpZnk9": "0.71ct (5.17mm - 5.46mm)",
        "prop.Y29yZT1DdXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1NYXRlcmlhbCttdWx0aSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1DZW50ZXIrbWF4K3dpZHRoK2ZpdCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "ebay.ivy_and_rose.sub_title": null,
        "product.additional_styles": [],
        "product.price": "50",
        "prop.Y29yZT1NZXRhbCtwdXJpdHkmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "product.title": "0.71ct OLD EUROPEAN CUT DIAMOND GIA CERTIFIED I SI1 VINTAGE LOOSE NATURAL ESTATE",
        "prop.Y29yZT1UcmVhdG1lbnQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Not Treated, Not Enhanced",
        "prop.Y29yZT1NYWluK3N0b25lJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1TdHlsZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1PY2Nhc2lvbiZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "shopify.ivy_and_rose.product_type": null,
        "ebay.ivy_and_rose.price": "50",
        "product.description": "Highlights:\n\r\n- 0.71ct natural diamond\n\r\n- Original vintage diamond\n\r\n- Beautiful old European cut.\n\r\n- GIA certified\n\r\n- Faces up nicely white with I color\n\r\n- Completely eye clean with a clarity grade of SI1\n\r\n- Fantastic sparkle and brilliance!\n\r\n\n\r\nAdditional Notes:\n\r\nThe diamond is being sold loose, but it can be mounted upon request.",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Diamond (GIA Certified)",
        "etsy.ivy_and_rose.category_id": "6648",
        "shopify.ivy_and_rose.vendor": null,
        "product.cost": "10",
        "prop.Y29yZT1TaXphYmxlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1NaW4rc2l6ZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "4",
        "prop.Y29yZT1NYWluK3N0b25lK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1JdGVtK3R5cGUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Loose Diamond",
        "prop.Y29yZT1FcmEmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Original Vintage",
        "product.internal_title": null,
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY29sb3ImZWJheT0mZXRzeT0mc2hvcGlmeT0": "I",
        "shopify.ilya_fine_jewelry.price": null,
        "prop.Y29yZT1Db2xsZWN0aW9uJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "product.notes": null,
        "shopify.ilya_fine_jewelry.title": null,
        "product.stock_number": null,
        "etsy.ivy_and_rose.when_made": "1920s",
        "prop.Y29yZT1DYW4rYmUrcGVyc29uYWxpemVkJmViYXk9JmV0c3k9JnNob3BpZnk9": "Yes",
        "product.location": "some",
        "shopify.ilya_fine_jewelry.seo_title": null,
        "prop.Y29yZT1OYXR1cmFsJTJGbGFiLWNyZWF0ZWQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Natural",
        "ebay.ivy_and_rose.store_category2_id": "14736889",
        "shopify.ilya_fine_jewelry.vendor": null,
        "shopify.ivy_and_rose.seo_title": null,
        "prop.Y29yZT1HZW1zdG9uZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "Diamond (GIA Certified)",
        "prop.Y29yZT1TaWRlK3N0b25lJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "shopify.ilya_fine_jewelry.collection_ids": [],
        "shopify.ilya_fine_jewelry.tags": [],
        "prop.Y29yZT1TaWRlK3N0b25lK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "ebay.ivy_and_rose.category_id": "262026",
        "prop.Y29yZT1NYWRlK3RvK29yZGVyJmViYXk9JmV0c3k9JnNob3BpZnk9": "No",
        "product.availability": 1,
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrc2hhcGUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Old European Cut",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY2xhcml0eSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "SI1",
        "prop.Y29yZT1TaWRlK3N0b25lK2NsYXJpdHkmZWJheT0mZXRzeT0mc2hvcGlmeT0": null
      },
      "id": "c59e950e-bfc9-4aae-a198-e4279a6de807",
      "sku": "20341"
    },
    {
      "data": {
        "prop.Y29yZT1Ub3RhbCtjYXJhdCt3ZWlnaHQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1SZWNpcGllbnQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Women",
        "shopify.ivy_and_rose.collection_ids": [],
        "shopify.ivy_and_rose.tags": [],
        "prop.Y29yZT1TZXR0aW5nK3N0eWxlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1EaWFtb25kK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "shopify.ilya_fine_jewelry.product_type": null,
        "prop.Y29yZT1DbGFyaXR5JmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1TaWRlK3N0b25lK3NoYXBlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "etsy.ivy_and_rose.who_made": "someone_else",
        "prop.Y29yZT1TaXplJmViYXk9JmV0c3k9JnNob3BpZnk9": "5",
        "prop.Y29yZT1DZW50ZXIrbWluK3dpZHRoK2ZpdCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "etsy.ivy_and_rose.price": "50",
        "prop.Y29yZT1DZXJ0aWZpY2F0aW9uJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "etsy.ivy_and_rose.title": "Vintage 14K Tri Tone Gold Flower Ring Eternity Band Yellow Rose Green Jabel",
        "ebay.ivy_and_rose.store_category_id": "14843984",
        "product.main_style": null,
        "product.sku": "19766",
        "shopify.ivy_and_rose.title": null,
        "shopify.ivy_and_rose.price": null,
        "prop.Y29yZT1TaWRlK3N0b25lcytxdWFudGl0eSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "etsy.ivy_and_rose.is_supply": false,
        "prop.Y29yZT1HcmFtK3dlaWdodCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "2.1 grams",
        "prop.Y29yZT1DZW50ZXIrbWF4K2xlbmd0aCtmaXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "ebay.ivy_and_rose.condition": "USED_EXCELLENT",
        "prop.Y29yZT1TZWNvbmRhcnkrc3RvbmUmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1TaWRlK3N0b25lK2NhcmF0K3dlaWdodCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1SaW5nK3NpemUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "5",
        "prop.Y29yZT1NZXRhbCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "14k Tri Tone",
        "prop.Y29yZT1UaGVtZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1BcHByb3hpbWF0ZSt0aW1lK3BlcmlvZCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "Retro",
        "prop.Y29yZT1NYWluK3N0b25lK3NoYXBlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1DZW50ZXIrbWluK2xlbmd0aCtmaXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "ebay.ivy_and_rose.secondary_category_id": "262014",
        "prop.Y29yZT1NYXgrc2l6ZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "10",
        "prop.Y29yZT1GYW5jeStkaWFtb25kK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "etsy.ivy_and_rose.shop_section_id": "17822028",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY2FyYXQrd2VpZ2h0JmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1DdXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1NYXRlcmlhbCttdWx0aSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "14k Tri Tone",
        "prop.Y29yZT1DZW50ZXIrbWF4K3dpZHRoK2ZpdCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "ebay.ivy_and_rose.sub_title": null,
        "product.additional_styles": [],
        "product.price": "50",
        "prop.Y29yZT1NZXRhbCtwdXJpdHkmZWJheT0mZXRzeT0mc2hvcGlmeT0": "14k",
        "product.title": "VINTAGE 14k TRI TONE GOLD FLOWER RING ETERNITY BAND YELLOW ROSE GREEN JABEL",
        "prop.Y29yZT1UcmVhdG1lbnQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1NYWluK3N0b25lJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1TdHlsZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1PY2Nhc2lvbiZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "shopify.ivy_and_rose.product_type": null,
        "ebay.ivy_and_rose.price": "50",
        "product.description": "Why we love it: \n\r\nThis romantic vintage ring features a tri tone floral design with green gold leaves and rose gold blossoms against a yellow gold backdrop!\n\r\n\n\r\nHighlights:\n\r\n- Vintage. Signed Jabel\n\r\n- Eternity design with the flower pattern continuing all the way around the band\n\r\n- Tri tone 14k gold\n\r\n- Rare green gold accents!\n\r\n\n\r\nDimensions:\n\r\n2.8mm across (north-south) and 1.6mm from the finger to the top of the ring",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "etsy.ivy_and_rose.category_id": "1247",
        "shopify.ivy_and_rose.vendor": null,
        "product.cost": "10",
        "prop.Y29yZT1TaXphYmxlJmViYXk9JmV0c3k9JnNob3BpZnk9": "no",
        "prop.Y29yZT1NaW4rc2l6ZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "4",
        "prop.Y29yZT1NYWluK3N0b25lK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1JdGVtK3R5cGUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Band - Wedding",
        "prop.Y29yZT1FcmEmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Original Vintage",
        "product.internal_title": "ring - wedding band - vintage",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY29sb3ImZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "shopify.ilya_fine_jewelry.price": null,
        "prop.Y29yZT1Db2xsZWN0aW9uJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "product.notes": "2.8 x 1.6",
        "shopify.ilya_fine_jewelry.title": null,
        "product.stock_number": null,
        "etsy.ivy_and_rose.when_made": "1950s",
        "prop.Y29yZT1DYW4rYmUrcGVyc29uYWxpemVkJmViYXk9JmV0c3k9JnNob3BpZnk9": "Yes",
        "product.location": "some",
        "shopify.ilya_fine_jewelry.seo_title": null,
        "prop.Y29yZT1OYXR1cmFsJTJGbGFiLWNyZWF0ZWQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "ebay.ivy_and_rose.store_category2_id": "14736886",
        "shopify.ilya_fine_jewelry.vendor": null,
        "shopify.ivy_and_rose.seo_title": null,
        "prop.Y29yZT1HZW1zdG9uZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1TaWRlK3N0b25lJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "shopify.ilya_fine_jewelry.collection_ids": [],
        "shopify.ilya_fine_jewelry.tags": [],
        "prop.Y29yZT1TaWRlK3N0b25lK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "ebay.ivy_and_rose.category_id": "261977",
        "prop.Y29yZT1NYWRlK3RvK29yZGVyJmViYXk9JmV0c3k9JnNob3BpZnk9": "No",
        "product.availability": 0,
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrc2hhcGUmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY2xhcml0eSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1TaWRlK3N0b25lK2NsYXJpdHkmZWJheT0mZXRzeT0mc2hvcGlmeT0": null
      },
      "id": "bc12058e-f14f-457b-a9ce-061becf3bd32",
      "sku": "19766"
    },
    {
      "data": {
        "prop.Y29yZT1Ub3RhbCtjYXJhdCt3ZWlnaHQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "0.05ct",
        "prop.Y29yZT1SZWNpcGllbnQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Women",
        "shopify.ivy_and_rose.collection_ids": [],
        "shopify.ivy_and_rose.tags": [],
        "prop.Y29yZT1TZXR0aW5nK3N0eWxlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1EaWFtb25kK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "shopify.ilya_fine_jewelry.product_type": null,
        "prop.Y29yZT1DbGFyaXR5JmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1TaWRlK3N0b25lK3NoYXBlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "etsy.ivy_and_rose.who_made": "someone_else",
        "prop.Y29yZT1TaXplJmViYXk9JmV0c3k9JnNob3BpZnk9": "7\"",
        "prop.Y29yZT1DZW50ZXIrbWluK3dpZHRoK2ZpdCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "etsy.ivy_and_rose.price": "50",
        "prop.Y29yZT1DZXJ0aWZpY2F0aW9uJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "etsy.ivy_and_rose.title": "Vintage Diamond Sapphire 14K White Gold Bracelet Filigree Art Deco Blue Estate",
        "ebay.ivy_and_rose.store_category_id": "5979473",
        "product.main_style": "Tennis",
        "product.sku": "19556",
        "shopify.ivy_and_rose.title": null,
        "shopify.ivy_and_rose.price": null,
        "prop.Y29yZT1TaWRlK3N0b25lcytxdWFudGl0eSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "etsy.ivy_and_rose.is_supply": false,
        "prop.Y29yZT1HcmFtK3dlaWdodCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "7.7 grams",
        "prop.Y29yZT1DZW50ZXIrbWF4K2xlbmd0aCtmaXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "ebay.ivy_and_rose.condition": "USED_EXCELLENT",
        "prop.Y29yZT1TZWNvbmRhcnkrc3RvbmUmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1TaWRlK3N0b25lK2NhcmF0K3dlaWdodCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1SaW5nK3NpemUmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1NZXRhbCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "14k White Gold",
        "prop.Y29yZT1UaGVtZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1BcHByb3hpbWF0ZSt0aW1lK3BlcmlvZCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "Art Deco/Art Nouveau",
        "prop.Y29yZT1NYWluK3N0b25lK3NoYXBlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1DZW50ZXIrbWluK2xlbmd0aCtmaXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "ebay.ivy_and_rose.secondary_category_id": "262003",
        "prop.Y29yZT1NYXgrc2l6ZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "10",
        "prop.Y29yZT1GYW5jeStkaWFtb25kK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "etsy.ivy_and_rose.shop_section_id": "17865866",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY2FyYXQrd2VpZ2h0JmViYXk9JmV0c3k9JnNob3BpZnk9": "0.05ct",
        "prop.Y29yZT1DdXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1NYXRlcmlhbCttdWx0aSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "14k White Gold",
        "prop.Y29yZT1DZW50ZXIrbWF4K3dpZHRoK2ZpdCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "ebay.ivy_and_rose.sub_title": null,
        "product.additional_styles": [
          "Filigree"
        ],
        "product.price": "50",
        "prop.Y29yZT1NZXRhbCtwdXJpdHkmZWJheT0mZXRzeT0mc2hvcGlmeT0": "14k",
        "product.title": "VINTAGE DIAMOND SAPPHIRE 14k WHITE GOLD BRACELET FILIGREE ART DECO BLUE ESTATE",
        "prop.Y29yZT1UcmVhdG1lbnQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1NYWluK3N0b25lJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1TdHlsZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "Tennis",
        "prop.Y29yZT1PY2Nhc2lvbiZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "shopify.ivy_and_rose.product_type": null,
        "ebay.ivy_and_rose.price": "50",
        "product.description": "Why we love it: \n\r\nThis stunning and unique diamond and sapphire studded bracelet is elegantly designed with an artful, ethereal structure and was masterfully crafted by hand a lifetime in the past during the illustrious Art Deco era. \n\r\n\n\r\nHighlights:\n\r\n- 0.05ct old transitional cut diamond\n\r\n- High quality, bright white, and exceptionally clean with grades of G VS\n\r\n- Rich blue sapphires\n\r\n- Solid 14k white gold\n\r\n- Intricate filigree\n\r\n\n\r\nDimensions:\n\r\n6.4mm in width. 7 inches in length \n\r\n\n\r\nAdditional Notes:\n\r\nSapphires are synthetic as this was common practice at the time. The show some minor abrasions",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Diamond",
        "etsy.ivy_and_rose.category_id": "1193",
        "shopify.ivy_and_rose.vendor": null,
        "product.cost": "10",
        "prop.Y29yZT1TaXphYmxlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1NaW4rc2l6ZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "4",
        "prop.Y29yZT1NYWluK3N0b25lK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1JdGVtK3R5cGUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Bracelet (All Other)",
        "prop.Y29yZT1FcmEmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Original Vintage",
        "product.internal_title": null,
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY29sb3ImZWJheT0mZXRzeT0mc2hvcGlmeT0": "G",
        "shopify.ilya_fine_jewelry.price": null,
        "prop.Y29yZT1Db2xsZWN0aW9uJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "product.notes": "6.4mm width (some abrasions on sapphire)",
        "shopify.ilya_fine_jewelry.title": null,
        "product.stock_number": null,
        "etsy.ivy_and_rose.when_made": "1920s",
        "prop.Y29yZT1DYW4rYmUrcGVyc29uYWxpemVkJmViYXk9JmV0c3k9JnNob3BpZnk9": "Yes",
        "product.location": "some",
        "shopify.ilya_fine_jewelry.seo_title": null,
        "prop.Y29yZT1OYXR1cmFsJTJGbGFiLWNyZWF0ZWQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "ebay.ivy_and_rose.store_category2_id": "14736888",
        "shopify.ilya_fine_jewelry.vendor": null,
        "shopify.ivy_and_rose.seo_title": null,
        "prop.Y29yZT1HZW1zdG9uZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "Diamond",
        "prop.Y29yZT1TaWRlK3N0b25lJmViYXk9JmV0c3k9JnNob3BpZnk9": "Synthetic Sapphire",
        "shopify.ilya_fine_jewelry.collection_ids": [],
        "shopify.ilya_fine_jewelry.tags": [],
        "prop.Y29yZT1TaWRlK3N0b25lK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": "Blue",
        "ebay.ivy_and_rose.category_id": "261988",
        "prop.Y29yZT1NYWRlK3RvK29yZGVyJmViYXk9JmV0c3k9JnNob3BpZnk9": "No",
        "product.availability": 1,
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrc2hhcGUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Old Transitional Cut",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY2xhcml0eSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "VS",
        "prop.Y29yZT1TaWRlK3N0b25lK2NsYXJpdHkmZWJheT0mZXRzeT0mc2hvcGlmeT0": null
      },
      "id": "89cf0c5e-f8b5-4696-837a-e9c5240e02b7",
      "sku": "19556"
    },
    {
      "data": {
        "prop.Y29yZT1Ub3RhbCtjYXJhdCt3ZWlnaHQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1SZWNpcGllbnQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Women",
        "shopify.ivy_and_rose.collection_ids": [],
        "shopify.ivy_and_rose.tags": [],
        "prop.Y29yZT1TZXR0aW5nK3N0eWxlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1EaWFtb25kK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "shopify.ilya_fine_jewelry.product_type": null,
        "prop.Y29yZT1DbGFyaXR5JmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1TaWRlK3N0b25lK3NoYXBlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "etsy.ivy_and_rose.who_made": "someone_else",
        "prop.Y29yZT1TaXplJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1DZW50ZXIrbWluK3dpZHRoK2ZpdCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "etsy.ivy_and_rose.price": "50",
        "prop.Y29yZT1DZXJ0aWZpY2F0aW9uJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "etsy.ivy_and_rose.title": "Vintage Pink Pearl 14K Yellow Gold Earrings Floral Leaf Retro Estate Stud Gift",
        "ebay.ivy_and_rose.store_category_id": "14737505",
        "product.main_style": "Stud",
        "product.sku": "19551",
        "shopify.ivy_and_rose.title": null,
        "shopify.ivy_and_rose.price": null,
        "prop.Y29yZT1TaWRlK3N0b25lcytxdWFudGl0eSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "etsy.ivy_and_rose.is_supply": false,
        "prop.Y29yZT1HcmFtK3dlaWdodCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "3.3 grams",
        "prop.Y29yZT1DZW50ZXIrbWF4K2xlbmd0aCtmaXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "ebay.ivy_and_rose.condition": "USED_EXCELLENT",
        "prop.Y29yZT1TZWNvbmRhcnkrc3RvbmUmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1TaWRlK3N0b25lK2NhcmF0K3dlaWdodCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1SaW5nK3NpemUmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1NZXRhbCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "14k Yellow Gold",
        "prop.Y29yZT1UaGVtZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1BcHByb3hpbWF0ZSt0aW1lK3BlcmlvZCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "Retro",
        "prop.Y29yZT1NYWluK3N0b25lK3NoYXBlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1DZW50ZXIrbWluK2xlbmd0aCtmaXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "ebay.ivy_and_rose.secondary_category_id": "262008",
        "prop.Y29yZT1NYXgrc2l6ZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "10",
        "prop.Y29yZT1GYW5jeStkaWFtb25kK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "etsy.ivy_and_rose.shop_section_id": "17821498",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY2FyYXQrd2VpZ2h0JmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1DdXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1NYXRlcmlhbCttdWx0aSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "14k Yellow Gold",
        "prop.Y29yZT1DZW50ZXIrbWF4K3dpZHRoK2ZpdCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "ebay.ivy_and_rose.sub_title": null,
        "product.additional_styles": [],
        "product.price": "50",
        "prop.Y29yZT1NZXRhbCtwdXJpdHkmZWJheT0mZXRzeT0mc2hvcGlmeT0": "14k",
        "product.title": "VINTAGE PINK PEARL 14k YELLOW GOLD EARRINGS FLORAL LEAF RETRO ESTATE STUD GIFT",
        "prop.Y29yZT1UcmVhdG1lbnQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1NYWluK3N0b25lJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1TdHlsZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "Stud",
        "prop.Y29yZT1PY2Nhc2lvbiZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "shopify.ivy_and_rose.product_type": null,
        "ebay.ivy_and_rose.price": "50",
        "product.description": "Why we love it: \n\r\nThese vintage pearl earrings are classic and romantic with lustrous pearls set above yellow gold leaves.\n\r\n\n\r\nHighlights:\n\r\n- Original vintage\n\r\n- Lustrous pearls with a subtle pink hue\n\r\n- Solid 14k yellow gold\n\r\n- Screw backs for unpierced ears\n\r\n\n\r\nDimensions:\n\r\n16.5 x 6.7mm",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Pearlx2",
        "etsy.ivy_and_rose.category_id": "1208",
        "shopify.ivy_and_rose.vendor": null,
        "product.cost": "10",
        "prop.Y29yZT1TaXphYmxlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1NaW4rc2l6ZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "4",
        "prop.Y29yZT1NYWluK3N0b25lK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1JdGVtK3R5cGUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Drop Earrings - Dangle",
        "prop.Y29yZT1FcmEmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Original Vintage",
        "product.internal_title": null,
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY29sb3ImZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "shopify.ilya_fine_jewelry.price": null,
        "prop.Y29yZT1Db2xsZWN0aW9uJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "product.notes": "16.5 x 6.7 (Pinkish hue on pearls)",
        "shopify.ilya_fine_jewelry.title": null,
        "product.stock_number": null,
        "etsy.ivy_and_rose.when_made": "1950s",
        "prop.Y29yZT1DYW4rYmUrcGVyc29uYWxpemVkJmViYXk9JmV0c3k9JnNob3BpZnk9": "Yes",
        "product.location": "some",
        "shopify.ilya_fine_jewelry.seo_title": null,
        "prop.Y29yZT1OYXR1cmFsJTJGbGFiLWNyZWF0ZWQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Natural",
        "ebay.ivy_and_rose.store_category2_id": "14736887",
        "shopify.ilya_fine_jewelry.vendor": null,
        "shopify.ivy_and_rose.seo_title": null,
        "prop.Y29yZT1HZW1zdG9uZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "Pearlx2",
        "prop.Y29yZT1TaWRlK3N0b25lJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "shopify.ilya_fine_jewelry.collection_ids": [],
        "shopify.ilya_fine_jewelry.tags": [],
        "prop.Y29yZT1TaWRlK3N0b25lK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "ebay.ivy_and_rose.category_id": "261990",
        "prop.Y29yZT1NYWRlK3RvK29yZGVyJmViYXk9JmV0c3k9JnNob3BpZnk9": "No",
        "product.availability": 1,
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrc2hhcGUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Round",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY2xhcml0eSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1TaWRlK3N0b25lK2NsYXJpdHkmZWJheT0mZXRzeT0mc2hvcGlmeT0": null
      },
      "id": "4ac0da72-ba21-4907-8906-6ffa5b83023b",
      "sku": "19551"
    },
    {
      "data": {
        "prop.Y29yZT1Ub3RhbCtjYXJhdCt3ZWlnaHQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "0.76ct",
        "prop.Y29yZT1SZWNpcGllbnQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Women",
        "shopify.ivy_and_rose.collection_ids": [],
        "shopify.ivy_and_rose.tags": [],
        "prop.Y29yZT1TZXR0aW5nK3N0eWxlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1EaWFtb25kK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "shopify.ilya_fine_jewelry.product_type": null,
        "prop.Y29yZT1DbGFyaXR5JmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1TaWRlK3N0b25lK3NoYXBlJmViYXk9JmV0c3k9JnNob3BpZnk9": "Round",
        "etsy.ivy_and_rose.who_made": "i_did",
        "prop.Y29yZT1TaXplJmViYXk9JmV0c3k9JnNob3BpZnk9": "19\"",
        "prop.Y29yZT1DZW50ZXIrbWluK3dpZHRoK2ZpdCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "etsy.ivy_and_rose.price": "50",
        "prop.Y29yZT1DZXJ0aWZpY2F0aW9uJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "etsy.ivy_and_rose.title": "Ruby Diamond Pendant Necklace 14K Yellow Gold Pear Shape Halo Natural Teardrop",
        "ebay.ivy_and_rose.store_category_id": "5809673",
        "product.main_style": "Pendant",
        "product.sku": "19545",
        "shopify.ivy_and_rose.title": null,
        "shopify.ivy_and_rose.price": null,
        "prop.Y29yZT1TaWRlK3N0b25lcytxdWFudGl0eSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "etsy.ivy_and_rose.is_supply": false,
        "prop.Y29yZT1HcmFtK3dlaWdodCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "2.1 grams",
        "prop.Y29yZT1DZW50ZXIrbWF4K2xlbmd0aCtmaXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "ebay.ivy_and_rose.condition": "NEW",
        "prop.Y29yZT1TZWNvbmRhcnkrc3RvbmUmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1TaWRlK3N0b25lK2NhcmF0K3dlaWdodCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "0.20ctw",
        "prop.Y29yZT1SaW5nK3NpemUmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1NZXRhbCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "14k Yellow Gold",
        "prop.Y29yZT1UaGVtZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1BcHByb3hpbWF0ZSt0aW1lK3BlcmlvZCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "New",
        "prop.Y29yZT1NYWluK3N0b25lK3NoYXBlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1DZW50ZXIrbWluK2xlbmd0aCtmaXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "ebay.ivy_and_rose.secondary_category_id": null,
        "prop.Y29yZT1NYXgrc2l6ZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "10",
        "prop.Y29yZT1GYW5jeStkaWFtb25kK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "etsy.ivy_and_rose.shop_section_id": "17869125",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY2FyYXQrd2VpZ2h0JmViYXk9JmV0c3k9JnNob3BpZnk9": "0.56ct",
        "prop.Y29yZT1DdXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1NYXRlcmlhbCttdWx0aSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "14k Yellow Gold",
        "prop.Y29yZT1DZW50ZXIrbWF4K3dpZHRoK2ZpdCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "ebay.ivy_and_rose.sub_title": null,
        "product.additional_styles": [
          "Halo"
        ],
        "product.price": "50",
        "prop.Y29yZT1NZXRhbCtwdXJpdHkmZWJheT0mZXRzeT0mc2hvcGlmeT0": "14k",
        "product.title": "RUBY DIAMOND PENDANT NECKLACE 14k YELLOW GOLD PEAR SHAPE HALO NATURAL TEARDROP",
        "prop.Y29yZT1UcmVhdG1lbnQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1NYWluK3N0b25lJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1TdHlsZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "Pendant",
        "prop.Y29yZT1PY2Nhc2lvbiZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "shopify.ivy_and_rose.product_type": null,
        "ebay.ivy_and_rose.price": "50",
        "product.description": "Why we love it: \n\r\nThis ruby and diamond pendant offers a classic look with a rich red ruby surrounded by glittering white diamonds!\n\r\n\n\r\nHighlights:\n\r\n- 0.55ct pear shape ruby center with vivid color!\n\r\n- 0.20ct of vibrant, bright white, and eye clean diamonds\n\r\n- Classic 14k yellow gold setting\n\r\n- 19 inch 14k yellow gold chain\n\r\n\n\r\nDimensions:\n\r\nPendant measures 12.9 x 5.3mm",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Ruby",
        "etsy.ivy_and_rose.category_id": "1217",
        "shopify.ivy_and_rose.vendor": null,
        "product.cost": "10",
        "prop.Y29yZT1TaXphYmxlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1NaW4rc2l6ZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "4",
        "prop.Y29yZT1NYWluK3N0b25lK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1JdGVtK3R5cGUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Necklace - Other",
        "prop.Y29yZT1FcmEmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Modern",
        "product.internal_title": null,
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY29sb3ImZWJheT0mZXRzeT0mc2hvcGlmeT0": "Red",
        "shopify.ilya_fine_jewelry.price": null,
        "prop.Y29yZT1Db2xsZWN0aW9uJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "product.notes": "12.9 x 5.3 (Natural Ruby, amazing color)",
        "shopify.ilya_fine_jewelry.title": null,
        "product.stock_number": null,
        "etsy.ivy_and_rose.when_made": "2010_2019",
        "prop.Y29yZT1DYW4rYmUrcGVyc29uYWxpemVkJmViYXk9JmV0c3k9JnNob3BpZnk9": "Yes",
        "product.location": "some",
        "shopify.ilya_fine_jewelry.seo_title": null,
        "prop.Y29yZT1OYXR1cmFsJTJGbGFiLWNyZWF0ZWQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Natural",
        "ebay.ivy_and_rose.store_category2_id": null,
        "shopify.ilya_fine_jewelry.vendor": null,
        "shopify.ivy_and_rose.seo_title": null,
        "prop.Y29yZT1HZW1zdG9uZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "Ruby",
        "prop.Y29yZT1TaWRlK3N0b25lJmViYXk9JmV0c3k9JnNob3BpZnk9": "Diamond",
        "shopify.ilya_fine_jewelry.collection_ids": [],
        "shopify.ilya_fine_jewelry.tags": [],
        "prop.Y29yZT1TaWRlK3N0b25lK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": "G",
        "ebay.ivy_and_rose.category_id": "261993",
        "prop.Y29yZT1NYWRlK3RvK29yZGVyJmViYXk9JmV0c3k9JnNob3BpZnk9": "No",
        "product.availability": 1,
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrc2hhcGUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Pear Shape",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY2xhcml0eSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1TaWRlK3N0b25lK2NsYXJpdHkmZWJheT0mZXRzeT0mc2hvcGlmeT0": "VS2-SI1"
      },
      "id": "523a2d85-06ed-434f-886d-fd04c0d46b7b",
      "sku": "19545"
    },
    {
      "data": {
        "prop.Y29yZT1Ub3RhbCtjYXJhdCt3ZWlnaHQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "0.33ct",
        "prop.Y29yZT1SZWNpcGllbnQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Women",
        "shopify.ivy_and_rose.collection_ids": [],
        "shopify.ivy_and_rose.tags": [],
        "prop.Y29yZT1TZXR0aW5nK3N0eWxlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1EaWFtb25kK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "shopify.ilya_fine_jewelry.product_type": null,
        "prop.Y29yZT1DbGFyaXR5JmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1TaWRlK3N0b25lK3NoYXBlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "etsy.ivy_and_rose.who_made": "someone_else",
        "prop.Y29yZT1TaXplJmViYXk9JmV0c3k9JnNob3BpZnk9": "3.75 (Resizable)",
        "prop.Y29yZT1DZW50ZXIrbWluK3dpZHRoK2ZpdCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "etsy.ivy_and_rose.price": "50",
        "prop.Y29yZT1DZXJ0aWZpY2F0aW9uJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "etsy.ivy_and_rose.title": "Art Deco Diamond Engagement Ring G-H Vs 18K White Gold Filigree Old Cut Natural",
        "ebay.ivy_and_rose.store_category_id": "14736792",
        "product.main_style": "Solitaire",
        "product.sku": "19540",
        "shopify.ivy_and_rose.title": null,
        "shopify.ivy_and_rose.price": null,
        "prop.Y29yZT1TaWRlK3N0b25lcytxdWFudGl0eSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "etsy.ivy_and_rose.is_supply": false,
        "prop.Y29yZT1HcmFtK3dlaWdodCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "1.6 grams",
        "prop.Y29yZT1DZW50ZXIrbWF4K2xlbmd0aCtmaXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "ebay.ivy_and_rose.condition": "USED_EXCELLENT",
        "prop.Y29yZT1TZWNvbmRhcnkrc3RvbmUmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1TaWRlK3N0b25lK2NhcmF0K3dlaWdodCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1SaW5nK3NpemUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "3.75",
        "prop.Y29yZT1NZXRhbCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "18k White Gold",
        "prop.Y29yZT1UaGVtZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1BcHByb3hpbWF0ZSt0aW1lK3BlcmlvZCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "Art Deco/Art Nouveau",
        "prop.Y29yZT1NYWluK3N0b25lK3NoYXBlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1DZW50ZXIrbWluK2xlbmd0aCtmaXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "ebay.ivy_and_rose.secondary_category_id": "262014",
        "prop.Y29yZT1NYXgrc2l6ZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "10",
        "prop.Y29yZT1GYW5jeStkaWFtb25kK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "etsy.ivy_and_rose.shop_section_id": "17500487",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY2FyYXQrd2VpZ2h0JmViYXk9JmV0c3k9JnNob3BpZnk9": "0.33ct",
        "prop.Y29yZT1DdXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1NYXRlcmlhbCttdWx0aSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "18k White Gold",
        "prop.Y29yZT1DZW50ZXIrbWF4K3dpZHRoK2ZpdCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "ebay.ivy_and_rose.sub_title": null,
        "product.additional_styles": [],
        "product.price": "50",
        "prop.Y29yZT1NZXRhbCtwdXJpdHkmZWJheT0mZXRzeT0mc2hvcGlmeT0": "18k",
        "product.title": "ART DECO DIAMOND ENGAGEMENT RING G-H VS 18k WHITE GOLD FILIGREE OLD CUT NATURAL",
        "prop.Y29yZT1UcmVhdG1lbnQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Not Treated, Not Enhanced",
        "prop.Y29yZT1NYWluK3N0b25lJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1TdHlsZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "Solitaire",
        "prop.Y29yZT1PY2Nhc2lvbiZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "shopify.ivy_and_rose.product_type": null,
        "ebay.ivy_and_rose.price": "50",
        "product.description": "Why we love it: \n\r\nThis original vintage engagement ring has a romantic design featuring a beautiful 0.33ct old European cut diamond paired with an intricately detailed 18k white gold setting.\n\r\n\n\r\nHighlights:\n\r\n- Original vintage circa the Art Deco era\n\r\n- 0.33ct high quality old transitional cut center diamond\n\r\n- Bright white and exceptionally clean, graded G-H VS\n\r\n- Gorgeously crafted with fine filigree and engraving\n\r\n- High quality 18k white gold setting with well preserved details\n\r\n\n\r\nDimensions:\n\r\nThe ring measures 7.2mm across (north to south) and 4.4mm from finger to top.",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Diamond",
        "etsy.ivy_and_rose.category_id": "1245",
        "shopify.ivy_and_rose.vendor": null,
        "product.cost": "10",
        "prop.Y29yZT1TaXphYmxlJmViYXk9JmV0c3k9JnNob3BpZnk9": "yes",
        "prop.Y29yZT1NaW4rc2l6ZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "4",
        "prop.Y29yZT1NYWluK3N0b25lK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1JdGVtK3R5cGUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Engagement Ring",
        "prop.Y29yZT1FcmEmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Original Vintage",
        "product.internal_title": "ring - engagement - vintage",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY29sb3ImZWJheT0mZXRzeT0mc2hvcGlmeT0": "G-H",
        "shopify.ilya_fine_jewelry.price": null,
        "prop.Y29yZT1Db2xsZWN0aW9uJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "product.notes": "7.2 x 4.4",
        "shopify.ilya_fine_jewelry.title": null,
        "product.stock_number": null,
        "etsy.ivy_and_rose.when_made": "1920s",
        "prop.Y29yZT1DYW4rYmUrcGVyc29uYWxpemVkJmViYXk9JmV0c3k9JnNob3BpZnk9": "Yes",
        "product.location": "some",
        "shopify.ilya_fine_jewelry.seo_title": null,
        "prop.Y29yZT1OYXR1cmFsJTJGbGFiLWNyZWF0ZWQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Natural",
        "ebay.ivy_and_rose.store_category2_id": "14736886",
        "shopify.ilya_fine_jewelry.vendor": null,
        "shopify.ivy_and_rose.seo_title": null,
        "prop.Y29yZT1HZW1zdG9uZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "Diamond",
        "prop.Y29yZT1TaWRlK3N0b25lJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "shopify.ilya_fine_jewelry.collection_ids": [],
        "shopify.ilya_fine_jewelry.tags": [],
        "prop.Y29yZT1TaWRlK3N0b25lK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "ebay.ivy_and_rose.category_id": "261975",
        "prop.Y29yZT1NYWRlK3RvK29yZGVyJmViYXk9JmV0c3k9JnNob3BpZnk9": "No",
        "product.availability": 1,
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrc2hhcGUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Old Transitional Cut",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY2xhcml0eSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "VS",
        "prop.Y29yZT1TaWRlK3N0b25lK2NsYXJpdHkmZWJheT0mZXRzeT0mc2hvcGlmeT0": null
      },
      "id": "9b61f569-b8da-44bd-9ff4-9b42251c6fea",
      "sku": "19540"
    },
    {
      "data": {
        "prop.Y29yZT1Ub3RhbCtjYXJhdCt3ZWlnaHQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "0.05ct",
        "prop.Y29yZT1SZWNpcGllbnQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Women",
        "shopify.ivy_and_rose.collection_ids": [],
        "shopify.ivy_and_rose.tags": [],
        "prop.Y29yZT1TZXR0aW5nK3N0eWxlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1EaWFtb25kK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "shopify.ilya_fine_jewelry.product_type": null,
        "prop.Y29yZT1DbGFyaXR5JmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1TaWRlK3N0b25lK3NoYXBlJmViYXk9JmV0c3k9JnNob3BpZnk9": "Rose Cut",
        "etsy.ivy_and_rose.who_made": "someone_else",
        "prop.Y29yZT1TaXplJmViYXk9JmV0c3k9JnNob3BpZnk9": "6.75 (Resizable)",
        "prop.Y29yZT1DZW50ZXIrbWluK3dpZHRoK2ZpdCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "etsy.ivy_and_rose.price": "50",
        "prop.Y29yZT1DZXJ0aWZpY2F0aW9uJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "etsy.ivy_and_rose.title": "French Antique Ruby Diamond Cocktail Ring 18K Yellow Gold 1900S Estate Jewelry",
        "ebay.ivy_and_rose.store_category_id": "14736886",
        "product.main_style": "Halo",
        "product.sku": "19539",
        "shopify.ivy_and_rose.title": null,
        "shopify.ivy_and_rose.price": null,
        "prop.Y29yZT1TaWRlK3N0b25lcytxdWFudGl0eSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "etsy.ivy_and_rose.is_supply": false,
        "prop.Y29yZT1HcmFtK3dlaWdodCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "1.9 grams",
        "prop.Y29yZT1DZW50ZXIrbWF4K2xlbmd0aCtmaXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "ebay.ivy_and_rose.condition": "USED_EXCELLENT",
        "prop.Y29yZT1TZWNvbmRhcnkrc3RvbmUmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1TaWRlK3N0b25lK2NhcmF0K3dlaWdodCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "0.05ctw",
        "prop.Y29yZT1SaW5nK3NpemUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "6.75",
        "prop.Y29yZT1NZXRhbCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "18k Yellow Gold",
        "prop.Y29yZT1UaGVtZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1BcHByb3hpbWF0ZSt0aW1lK3BlcmlvZCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "Art Deco/Art Nouveau",
        "prop.Y29yZT1NYWluK3N0b25lK3NoYXBlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1DZW50ZXIrbWluK2xlbmd0aCtmaXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "ebay.ivy_and_rose.secondary_category_id": "262014",
        "prop.Y29yZT1NYXgrc2l6ZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "10",
        "prop.Y29yZT1GYW5jeStkaWFtb25kK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "etsy.ivy_and_rose.shop_section_id": "17746504",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY2FyYXQrd2VpZ2h0JmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1DdXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1NYXRlcmlhbCttdWx0aSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "18k Yellow Gold",
        "prop.Y29yZT1DZW50ZXIrbWF4K3dpZHRoK2ZpdCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "ebay.ivy_and_rose.sub_title": null,
        "product.additional_styles": [
          "Bezel"
        ],
        "product.price": "50",
        "prop.Y29yZT1NZXRhbCtwdXJpdHkmZWJheT0mZXRzeT0mc2hvcGlmeT0": "18k",
        "product.title": "FRENCH ANTIQUE RUBY DIAMOND COCKTAIL RING 18k YELLOW GOLD 1900s ESTATE JEWELRY",
        "prop.Y29yZT1UcmVhdG1lbnQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1NYWluK3N0b25lJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1TdHlsZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "Halo",
        "prop.Y29yZT1PY2Nhc2lvbiZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "shopify.ivy_and_rose.product_type": null,
        "ebay.ivy_and_rose.price": "50",
        "product.description": "Why we love it: \n\r\nThis vintage ruby and diamond ring features a rich red ruby set at the center of an elegant diamond and ruby design!\n\r\n\n\r\nHighlights:\n\r\n- French antique!\n\r\n- Handmade around the turn of the 19th to 20th century\n\r\n- Rich red ruby* center and accents\n\r\n- 0.05ct of rare rose cut diamond accents\n\r\n- 18k yellow gold setting topped with platinum\n\r\n\n\r\nDimensions:\n\r\n9.6mm across (north-south) and 4.0mm from the finger to the top of the ring\n\r\n\n\r\nAdditional Notes:\n\r\nRubies are likely synthetic as this was common practice at the time.",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Synthetic Ruby",
        "etsy.ivy_and_rose.category_id": "1231",
        "shopify.ivy_and_rose.vendor": null,
        "product.cost": "10",
        "prop.Y29yZT1TaXphYmxlJmViYXk9JmV0c3k9JnNob3BpZnk9": "yes",
        "prop.Y29yZT1NaW4rc2l6ZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "4",
        "prop.Y29yZT1NYWluK3N0b25lK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1JdGVtK3R5cGUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Cocktail Ring - Other",
        "prop.Y29yZT1FcmEmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Original Vintage",
        "product.internal_title": "ring - cocktail - vintage",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY29sb3ImZWJheT0mZXRzeT0mc2hvcGlmeT0": "Red",
        "shopify.ilya_fine_jewelry.price": null,
        "prop.Y29yZT1Db2xsZWN0aW9uJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "product.notes": "9.6 x 4.0 (True French antique)",
        "shopify.ilya_fine_jewelry.title": null,
        "product.stock_number": null,
        "etsy.ivy_and_rose.when_made": "1920s",
        "prop.Y29yZT1DYW4rYmUrcGVyc29uYWxpemVkJmViYXk9JmV0c3k9JnNob3BpZnk9": "Yes",
        "product.location": "some",
        "shopify.ilya_fine_jewelry.seo_title": null,
        "prop.Y29yZT1OYXR1cmFsJTJGbGFiLWNyZWF0ZWQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "ebay.ivy_and_rose.store_category2_id": "6106058",
        "shopify.ilya_fine_jewelry.vendor": null,
        "shopify.ivy_and_rose.seo_title": null,
        "prop.Y29yZT1HZW1zdG9uZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "Synthetic Ruby",
        "prop.Y29yZT1TaWRlK3N0b25lJmViYXk9JmV0c3k9JnNob3BpZnk9": "Diamond",
        "shopify.ilya_fine_jewelry.collection_ids": [],
        "shopify.ilya_fine_jewelry.tags": [],
        "prop.Y29yZT1TaWRlK3N0b25lK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": "I-J",
        "ebay.ivy_and_rose.category_id": "261994",
        "prop.Y29yZT1NYWRlK3RvK29yZGVyJmViYXk9JmV0c3k9JnNob3BpZnk9": "No",
        "product.availability": 0,
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrc2hhcGUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Round",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY2xhcml0eSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1TaWRlK3N0b25lK2NsYXJpdHkmZWJheT0mZXRzeT0mc2hvcGlmeT0": "SI"
      },
      "id": "7fc5033a-31af-4cf8-97d9-e7ac54507ad0",
      "sku": "19539"
    },
    {
      "data": {
        "prop.Y29yZT1Ub3RhbCtjYXJhdCt3ZWlnaHQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "1.15ct",
        "prop.Y29yZT1SZWNpcGllbnQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Women",
        "shopify.ivy_and_rose.collection_ids": [],
        "shopify.ivy_and_rose.tags": [],
        "prop.Y29yZT1TZXR0aW5nK3N0eWxlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1EaWFtb25kK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "shopify.ilya_fine_jewelry.product_type": null,
        "prop.Y29yZT1DbGFyaXR5JmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1TaWRlK3N0b25lK3NoYXBlJmViYXk9JmV0c3k9JnNob3BpZnk9": "Square Step Cut",
        "etsy.ivy_and_rose.who_made": "someone_else",
        "prop.Y29yZT1TaXplJmViYXk9JmV0c3k9JnNob3BpZnk9": "5.75",
        "prop.Y29yZT1DZW50ZXIrbWluK3dpZHRoK2ZpdCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "etsy.ivy_and_rose.price": "50",
        "prop.Y29yZT1DZXJ0aWZpY2F0aW9uJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "etsy.ivy_and_rose.title": "Vintage Diamond Eternity Ring Carre Cut Platinum Wedding Band Channel 1.15Ct",
        "ebay.ivy_and_rose.store_category_id": "14843984",
        "product.main_style": "Channel",
        "product.sku": "19269",
        "shopify.ivy_and_rose.title": null,
        "shopify.ivy_and_rose.price": null,
        "prop.Y29yZT1TaWRlK3N0b25lcytxdWFudGl0eSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "etsy.ivy_and_rose.is_supply": false,
        "prop.Y29yZT1HcmFtK3dlaWdodCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "1.6 grams",
        "prop.Y29yZT1DZW50ZXIrbWF4K2xlbmd0aCtmaXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "ebay.ivy_and_rose.condition": "USED_EXCELLENT",
        "prop.Y29yZT1TZWNvbmRhcnkrc3RvbmUmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1TaWRlK3N0b25lK2NhcmF0K3dlaWdodCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "1.15ctw",
        "prop.Y29yZT1SaW5nK3NpemUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "5.75",
        "prop.Y29yZT1NZXRhbCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "Platinum",
        "prop.Y29yZT1UaGVtZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1BcHByb3hpbWF0ZSt0aW1lK3BlcmlvZCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "Art Deco/Art Nouveau",
        "prop.Y29yZT1NYWluK3N0b25lK3NoYXBlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1DZW50ZXIrbWluK2xlbmd0aCtmaXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "ebay.ivy_and_rose.secondary_category_id": "262014",
        "prop.Y29yZT1NYXgrc2l6ZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "10",
        "prop.Y29yZT1GYW5jeStkaWFtb25kK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "etsy.ivy_and_rose.shop_section_id": "17822028",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY2FyYXQrd2VpZ2h0JmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1DdXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1NYXRlcmlhbCttdWx0aSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "Platinum",
        "prop.Y29yZT1DZW50ZXIrbWF4K3dpZHRoK2ZpdCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "ebay.ivy_and_rose.sub_title": null,
        "product.additional_styles": [
          "Eternity"
        ],
        "product.price": "50",
        "prop.Y29yZT1NZXRhbCtwdXJpdHkmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Plat",
        "product.title": "VINTAGE DIAMOND ETERNITY RING CARRE CUT PLATINUM WEDDING BAND CHANNEL 1.15ct",
        "prop.Y29yZT1UcmVhdG1lbnQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Not Treated, Not Enhanced",
        "prop.Y29yZT1NYWluK3N0b25lJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1TdHlsZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "Channel",
        "prop.Y29yZT1PY2Nhc2lvbiZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "shopify.ivy_and_rose.product_type": null,
        "ebay.ivy_and_rose.price": "50",
        "product.description": "Why we love it: \n\r\nThis original vintage diamond eternity band has a sleek and sophisticated look featuring very rare carre cut diamonds!\n\r\n\n\r\nHighlights:\n\r\n- 1.15 carat of high quality natural diamonds\n\r\n- High quality, bright white, and eye clean, graded F-H VS1-SI1\n\r\n- Rare carre cuts!\n\r\n- Art Deco vintage\n\r\n- Platinum setting\n\r\n- Eternity ring design with diamond continuing all the way around\n\r\n- Engraved with a leaf design and “Lucky”\n\r\n- Sleek channel setting\n\r\n\n\r\nDimensions:\n\r\nThe ring measures 2.1mm across (north to south) and 1.5mm from finger to top.\n\r\n\n\r\nSizing Note:\n\r\nThe ring is currently a size 5.75, and it cannot be resized due to the eternity design.",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "etsy.ivy_and_rose.category_id": "1247",
        "shopify.ivy_and_rose.vendor": null,
        "product.cost": "10",
        "prop.Y29yZT1TaXphYmxlJmViYXk9JmV0c3k9JnNob3BpZnk9": "no",
        "prop.Y29yZT1NaW4rc2l6ZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "4",
        "prop.Y29yZT1NYWluK3N0b25lK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1JdGVtK3R5cGUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Band - Wedding",
        "prop.Y29yZT1FcmEmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Original Vintage",
        "product.internal_title": "ring - wedding band - vintage",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY29sb3ImZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "shopify.ilya_fine_jewelry.price": null,
        "prop.Y29yZT1Db2xsZWN0aW9uJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "product.notes": "2.1 x 1.5 (antique band, engraved \" Lucky\", No resizing)",
        "shopify.ilya_fine_jewelry.title": null,
        "product.stock_number": null,
        "etsy.ivy_and_rose.when_made": "1920s",
        "prop.Y29yZT1DYW4rYmUrcGVyc29uYWxpemVkJmViYXk9JmV0c3k9JnNob3BpZnk9": "Yes",
        "product.location": "some",
        "shopify.ilya_fine_jewelry.seo_title": null,
        "prop.Y29yZT1OYXR1cmFsJTJGbGFiLWNyZWF0ZWQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Natural",
        "ebay.ivy_and_rose.store_category2_id": "14736886",
        "shopify.ilya_fine_jewelry.vendor": null,
        "shopify.ivy_and_rose.seo_title": null,
        "prop.Y29yZT1HZW1zdG9uZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "Diamond",
        "prop.Y29yZT1TaWRlK3N0b25lJmViYXk9JmV0c3k9JnNob3BpZnk9": "Diamond",
        "shopify.ilya_fine_jewelry.collection_ids": [],
        "shopify.ilya_fine_jewelry.tags": [],
        "prop.Y29yZT1TaWRlK3N0b25lK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": "G-H",
        "ebay.ivy_and_rose.category_id": "261977",
        "prop.Y29yZT1NYWRlK3RvK29yZGVyJmViYXk9JmV0c3k9JnNob3BpZnk9": "No",
        "product.availability": 0,
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrc2hhcGUmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY2xhcml0eSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1TaWRlK3N0b25lK2NsYXJpdHkmZWJheT0mZXRzeT0mc2hvcGlmeT0": "VS1-SI1"
      },
      "id": "98ece94e-6b8b-4dec-b019-7bc12ebdc3d5",
      "sku": "19269"
    },
    {
      "data": {
        "prop.Y29yZT1Ub3RhbCtjYXJhdCt3ZWlnaHQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "3.45ct",
        "prop.Y29yZT1SZWNpcGllbnQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Women",
        "shopify.ivy_and_rose.collection_ids": [],
        "shopify.ivy_and_rose.tags": [],
        "prop.Y29yZT1TZXR0aW5nK3N0eWxlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1EaWFtb25kK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "shopify.ilya_fine_jewelry.product_type": null,
        "prop.Y29yZT1DbGFyaXR5JmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1TaWRlK3N0b25lK3NoYXBlJmViYXk9JmV0c3k9JnNob3BpZnk9": "Round",
        "etsy.ivy_and_rose.who_made": "i_did",
        "prop.Y29yZT1TaXplJmViYXk9JmV0c3k9JnNob3BpZnk9": "9.0 (Resizable)",
        "prop.Y29yZT1DZW50ZXIrbWluK3dpZHRoK2ZpdCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "etsy.ivy_and_rose.price": "50",
        "prop.Y29yZT1DZXJ0aWZpY2F0aW9uJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "etsy.ivy_and_rose.title": "Diamond Spessartite Garnet Cocktail Ring Wide Band Red Orange 14K White Gold",
        "ebay.ivy_and_rose.store_category_id": "6106058",
        "product.main_style": "Pave",
        "product.sku": "19263",
        "shopify.ivy_and_rose.title": null,
        "shopify.ivy_and_rose.price": null,
        "prop.Y29yZT1TaWRlK3N0b25lcytxdWFudGl0eSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "etsy.ivy_and_rose.is_supply": false,
        "prop.Y29yZT1HcmFtK3dlaWdodCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "7.5 grams",
        "prop.Y29yZT1DZW50ZXIrbWF4K2xlbmd0aCtmaXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "ebay.ivy_and_rose.condition": "NEW",
        "prop.Y29yZT1TZWNvbmRhcnkrc3RvbmUmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1TaWRlK3N0b25lK2NhcmF0K3dlaWdodCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "Diamond: 0.20ctw, Garnet: 3.25ctw",
        "prop.Y29yZT1SaW5nK3NpemUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "9",
        "prop.Y29yZT1NZXRhbCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "14k Yellow Gold",
        "prop.Y29yZT1UaGVtZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1BcHByb3hpbWF0ZSt0aW1lK3BlcmlvZCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "New",
        "prop.Y29yZT1NYWluK3N0b25lK3NoYXBlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1DZW50ZXIrbWluK2xlbmd0aCtmaXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "ebay.ivy_and_rose.secondary_category_id": null,
        "prop.Y29yZT1NYXgrc2l6ZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "10",
        "prop.Y29yZT1GYW5jeStkaWFtb25kK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "etsy.ivy_and_rose.shop_section_id": "21991708",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY2FyYXQrd2VpZ2h0JmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1DdXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1NYXRlcmlhbCttdWx0aSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "14k Yellow Gold",
        "prop.Y29yZT1DZW50ZXIrbWF4K3dpZHRoK2ZpdCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "ebay.ivy_and_rose.sub_title": null,
        "product.additional_styles": [],
        "product.price": "50",
        "prop.Y29yZT1NZXRhbCtwdXJpdHkmZWJheT0mZXRzeT0mc2hvcGlmeT0": "14k",
        "product.title": "DIAMOND SPESSARTITE GARNET COCKTAIL RING WIDE BAND RED ORANGE 14k WHITE GOLD",
        "prop.Y29yZT1UcmVhdG1lbnQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1NYWluK3N0b25lJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1TdHlsZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "Pave",
        "prop.Y29yZT1PY2Nhc2lvbiZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "shopify.ivy_and_rose.product_type": null,
        "ebay.ivy_and_rose.price": "50",
        "product.description": "Why we love it: \n\r\nThis diamond cocktail ring has a large and eye catching look with a broad swath of rich orange-red garnets bounded by lines of bright white diamonds!\n\r\n\n\r\nHighlights:\n\r\n- 3.25ct of spessartite garnets\n\r\n- Gorgeous, vivid orangy-red color!\n\r\n- 0.20ct of bright white and eye clean G-H SI2-SI3 diamonds\n\r\n- Substantial 14k white gold setting\n\r\n\n\r\nDimensions:\n\r\nThe ring measures 15.6mm across (north to south) and 4.5mm from finger to top.",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "etsy.ivy_and_rose.category_id": "1240",
        "shopify.ivy_and_rose.vendor": null,
        "product.cost": "10",
        "prop.Y29yZT1TaXphYmxlJmViYXk9JmV0c3k9JnNob3BpZnk9": "yes",
        "prop.Y29yZT1NaW4rc2l6ZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "4",
        "prop.Y29yZT1NYWluK3N0b25lK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1JdGVtK3R5cGUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Cocktail Ring- Statement",
        "prop.Y29yZT1FcmEmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Modern",
        "product.internal_title": "ring - cocktail - modern",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY29sb3ImZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "shopify.ilya_fine_jewelry.price": null,
        "prop.Y29yZT1Db2xsZWN0aW9uJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "product.notes": "15.6 x 4.5 (amazing orangish red color)",
        "shopify.ilya_fine_jewelry.title": null,
        "product.stock_number": null,
        "etsy.ivy_and_rose.when_made": "2010_2019",
        "prop.Y29yZT1DYW4rYmUrcGVyc29uYWxpemVkJmViYXk9JmV0c3k9JnNob3BpZnk9": "Yes",
        "product.location": "some",
        "shopify.ilya_fine_jewelry.seo_title": null,
        "prop.Y29yZT1OYXR1cmFsJTJGbGFiLWNyZWF0ZWQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Natural",
        "ebay.ivy_and_rose.store_category2_id": null,
        "shopify.ilya_fine_jewelry.vendor": null,
        "shopify.ivy_and_rose.seo_title": null,
        "prop.Y29yZT1HZW1zdG9uZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "Diamond, Garnet",
        "prop.Y29yZT1TaWRlK3N0b25lJmViYXk9JmV0c3k9JnNob3BpZnk9": "Diamond, Garnet",
        "shopify.ilya_fine_jewelry.collection_ids": [],
        "shopify.ilya_fine_jewelry.tags": [],
        "prop.Y29yZT1TaWRlK3N0b25lK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": "Diamond: G-H, Garnet, Orangish Red",
        "ebay.ivy_and_rose.category_id": "261994",
        "prop.Y29yZT1NYWRlK3RvK29yZGVyJmViYXk9JmV0c3k9JnNob3BpZnk9": "No",
        "product.availability": 0,
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrc2hhcGUmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY2xhcml0eSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1TaWRlK3N0b25lK2NsYXJpdHkmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Diamond: SI2-SI3"
      },
      "id": "840f15dd-7ee6-42cf-a108-24caf975828d",
      "sku": "19263"
    },
    {
      "data": {
        "prop.Y29yZT1Ub3RhbCtjYXJhdCt3ZWlnaHQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "0.03ct",
        "prop.Y29yZT1SZWNpcGllbnQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Women",
        "shopify.ivy_and_rose.collection_ids": [],
        "shopify.ivy_and_rose.tags": [],
        "prop.Y29yZT1TZXR0aW5nK3N0eWxlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1EaWFtb25kK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "shopify.ilya_fine_jewelry.product_type": null,
        "prop.Y29yZT1DbGFyaXR5JmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1TaWRlK3N0b25lK3NoYXBlJmViYXk9JmV0c3k9JnNob3BpZnk9": "Vintage Single Cut",
        "etsy.ivy_and_rose.who_made": "someone_else",
        "prop.Y29yZT1TaXplJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1DZW50ZXIrbWluK3dpZHRoK2ZpdCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "etsy.ivy_and_rose.price": "50",
        "prop.Y29yZT1DZXJ0aWZpY2F0aW9uJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "etsy.ivy_and_rose.title": "Vintage Pearl Diamond 14K White Gold Earrings Retro Estate Stud Gift Classic",
        "ebay.ivy_and_rose.store_category_id": "14737505",
        "product.main_style": "Stud",
        "product.sku": "19262",
        "shopify.ivy_and_rose.title": null,
        "shopify.ivy_and_rose.price": null,
        "prop.Y29yZT1TaWRlK3N0b25lcytxdWFudGl0eSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "2",
        "etsy.ivy_and_rose.is_supply": false,
        "prop.Y29yZT1HcmFtK3dlaWdodCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "2.0 grams",
        "prop.Y29yZT1DZW50ZXIrbWF4K2xlbmd0aCtmaXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "ebay.ivy_and_rose.condition": "USED_EXCELLENT",
        "prop.Y29yZT1TZWNvbmRhcnkrc3RvbmUmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1TaWRlK3N0b25lK2NhcmF0K3dlaWdodCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "0.03ctw",
        "prop.Y29yZT1SaW5nK3NpemUmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1NZXRhbCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "14k White Gold",
        "prop.Y29yZT1UaGVtZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1BcHByb3hpbWF0ZSt0aW1lK3BlcmlvZCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "Retro",
        "prop.Y29yZT1NYWluK3N0b25lK3NoYXBlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1DZW50ZXIrbWluK2xlbmd0aCtmaXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "ebay.ivy_and_rose.secondary_category_id": "262008",
        "prop.Y29yZT1NYXgrc2l6ZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "10",
        "prop.Y29yZT1GYW5jeStkaWFtb25kK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "etsy.ivy_and_rose.shop_section_id": "17821498",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY2FyYXQrd2VpZ2h0JmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1DdXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1NYXRlcmlhbCttdWx0aSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "14k White Gold",
        "prop.Y29yZT1DZW50ZXIrbWF4K3dpZHRoK2ZpdCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "ebay.ivy_and_rose.sub_title": null,
        "product.additional_styles": [],
        "product.price": "50",
        "prop.Y29yZT1NZXRhbCtwdXJpdHkmZWJheT0mZXRzeT0mc2hvcGlmeT0": "14k",
        "product.title": "VINTAGE PEARL DIAMOND 14k WHITE GOLD EARRINGS RETRO ESTATE STUD GIFT CLASSIC",
        "prop.Y29yZT1UcmVhdG1lbnQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1NYWluK3N0b25lJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1TdHlsZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "Stud",
        "prop.Y29yZT1PY2Nhc2lvbiZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "shopify.ivy_and_rose.product_type": null,
        "ebay.ivy_and_rose.price": "50",
        "product.description": "Why we love it: \n\r\nThese vintage pearl earrings are classic and romantic with lustrous pearls set above glittering diamond accents.\n\r\n\n\r\nHighlights:\n\r\n- Original vintage\n\r\n- Lustrous pearls\n\r\n- Nicely white and exceptionally clean diamonds\n\r\n- Solid 14k white gold\n\r\n- Screw backs for unpierced ears\n\r\n\n\r\nDimensions:\n\r\n15.3 x 6.3mm",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Pearlx2",
        "etsy.ivy_and_rose.category_id": "1208",
        "shopify.ivy_and_rose.vendor": null,
        "product.cost": "10",
        "prop.Y29yZT1TaXphYmxlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1NaW4rc2l6ZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "4",
        "prop.Y29yZT1NYWluK3N0b25lK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1JdGVtK3R5cGUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Drop Earrings - Dangle",
        "prop.Y29yZT1FcmEmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Original Vintage",
        "product.internal_title": null,
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY29sb3ImZWJheT0mZXRzeT0mc2hvcGlmeT0": "White",
        "shopify.ilya_fine_jewelry.price": null,
        "prop.Y29yZT1Db2xsZWN0aW9uJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "product.notes": "15.3 x 6.3 (Vintage)",
        "shopify.ilya_fine_jewelry.title": null,
        "product.stock_number": null,
        "etsy.ivy_and_rose.when_made": "1950s",
        "prop.Y29yZT1DYW4rYmUrcGVyc29uYWxpemVkJmViYXk9JmV0c3k9JnNob3BpZnk9": "Yes",
        "product.location": "some",
        "shopify.ilya_fine_jewelry.seo_title": null,
        "prop.Y29yZT1OYXR1cmFsJTJGbGFiLWNyZWF0ZWQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Natural",
        "ebay.ivy_and_rose.store_category2_id": "14736887",
        "shopify.ilya_fine_jewelry.vendor": null,
        "shopify.ivy_and_rose.seo_title": null,
        "prop.Y29yZT1HZW1zdG9uZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "Pearlx2",
        "prop.Y29yZT1TaWRlK3N0b25lJmViYXk9JmV0c3k9JnNob3BpZnk9": "Diamond",
        "shopify.ilya_fine_jewelry.collection_ids": [],
        "shopify.ilya_fine_jewelry.tags": [],
        "prop.Y29yZT1TaWRlK3N0b25lK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": "I-J",
        "ebay.ivy_and_rose.category_id": "261990",
        "prop.Y29yZT1NYWRlK3RvK29yZGVyJmViYXk9JmV0c3k9JnNob3BpZnk9": "No",
        "product.availability": 1,
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrc2hhcGUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Round",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY2xhcml0eSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1TaWRlK3N0b25lK2NsYXJpdHkmZWJheT0mZXRzeT0mc2hvcGlmeT0": "VS"
      },
      "id": "24120362-63d4-467c-bf45-bcfabcfd396e",
      "sku": "19262"
    },
    {
      "data": {
        "prop.Y29yZT1Ub3RhbCtjYXJhdCt3ZWlnaHQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "0.03ct",
        "prop.Y29yZT1SZWNpcGllbnQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Women",
        "shopify.ivy_and_rose.collection_ids": [],
        "shopify.ivy_and_rose.tags": [],
        "prop.Y29yZT1TZXR0aW5nK3N0eWxlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1EaWFtb25kK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "shopify.ilya_fine_jewelry.product_type": null,
        "prop.Y29yZT1DbGFyaXR5JmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1TaWRlK3N0b25lK3NoYXBlJmViYXk9JmV0c3k9JnNob3BpZnk9": "Round",
        "etsy.ivy_and_rose.who_made": "someone_else",
        "prop.Y29yZT1TaXplJmViYXk9JmV0c3k9JnNob3BpZnk9": "6.5 (Resizable)",
        "prop.Y29yZT1DZW50ZXIrbWluK3dpZHRoK2ZpdCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "etsy.ivy_and_rose.price": "50",
        "prop.Y29yZT1DZXJ0aWZpY2F0aW9uJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "etsy.ivy_and_rose.title": "Pearl Diamond Cocktail Ring Natural Estate Split Shank 14K White Gold Jewelry",
        "ebay.ivy_and_rose.store_category_id": "14736886",
        "product.main_style": "Accent Stones",
        "product.sku": "18718",
        "shopify.ivy_and_rose.title": null,
        "shopify.ivy_and_rose.price": null,
        "prop.Y29yZT1TaWRlK3N0b25lcytxdWFudGl0eSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "2",
        "etsy.ivy_and_rose.is_supply": false,
        "prop.Y29yZT1HcmFtK3dlaWdodCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "2.3 grams",
        "prop.Y29yZT1DZW50ZXIrbWF4K2xlbmd0aCtmaXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "ebay.ivy_and_rose.condition": "USED_EXCELLENT",
        "prop.Y29yZT1TZWNvbmRhcnkrc3RvbmUmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1TaWRlK3N0b25lK2NhcmF0K3dlaWdodCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "0.03ctw",
        "prop.Y29yZT1SaW5nK3NpemUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "6.5",
        "prop.Y29yZT1NZXRhbCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "14k White Gold",
        "prop.Y29yZT1UaGVtZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1BcHByb3hpbWF0ZSt0aW1lK3BlcmlvZCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "Retro",
        "prop.Y29yZT1NYWluK3N0b25lK3NoYXBlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1DZW50ZXIrbWluK2xlbmd0aCtmaXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "ebay.ivy_and_rose.secondary_category_id": "262014",
        "prop.Y29yZT1NYXgrc2l6ZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "10",
        "prop.Y29yZT1GYW5jeStkaWFtb25kK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "etsy.ivy_and_rose.shop_section_id": "17500487",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY2FyYXQrd2VpZ2h0JmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1DdXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1NYXRlcmlhbCttdWx0aSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "14k White Gold",
        "prop.Y29yZT1DZW50ZXIrbWF4K3dpZHRoK2ZpdCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "ebay.ivy_and_rose.sub_title": null,
        "product.additional_styles": [
          "Prong"
        ],
        "product.price": "50",
        "prop.Y29yZT1NZXRhbCtwdXJpdHkmZWJheT0mZXRzeT0mc2hvcGlmeT0": "14k",
        "product.title": "PEARL DIAMOND COCKTAIL RING NATURAL ESTATE SPLIT SHANK 14k WHITE GOLD JEWELRY",
        "prop.Y29yZT1UcmVhdG1lbnQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1NYWluK3N0b25lJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1TdHlsZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "Accent Stones",
        "prop.Y29yZT1PY2Nhc2lvbiZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "shopify.ivy_and_rose.product_type": null,
        "ebay.ivy_and_rose.price": "50",
        "product.description": "Why we love it: \n\r\nThis romantic pearl ring features a lustrous pearl set off by a pair glittering diamonds. The diamonds add bright sparkle to the sweet romance of the pearl.\n\r\n\n\r\nHighlights:\n\r\n- Lustrous center pearl\n\r\n- 0.03ct pair of bright white and eye clean accent diamonds\n\r\n- Solid 14k white gold setting\n\r\n\n\r\nDimensions:\n\r\n6.7mm across (north-south) and 7.5mm from the finger to the top of the ring",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Pearl",
        "etsy.ivy_and_rose.category_id": "1231",
        "shopify.ivy_and_rose.vendor": null,
        "product.cost": "10",
        "prop.Y29yZT1TaXphYmxlJmViYXk9JmV0c3k9JnNob3BpZnk9": "yes",
        "prop.Y29yZT1NaW4rc2l6ZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "4",
        "prop.Y29yZT1NYWluK3N0b25lK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1JdGVtK3R5cGUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Cocktail Ring - Other",
        "prop.Y29yZT1FcmEmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Original Vintage",
        "product.internal_title": "ring - cocktail - vintage",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY29sb3ImZWJheT0mZXRzeT0mc2hvcGlmeT0": "White",
        "shopify.ilya_fine_jewelry.price": null,
        "prop.Y29yZT1Db2xsZWN0aW9uJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "product.notes": "6.7 x 7.5",
        "shopify.ilya_fine_jewelry.title": null,
        "product.stock_number": null,
        "etsy.ivy_and_rose.when_made": "1950s",
        "prop.Y29yZT1DYW4rYmUrcGVyc29uYWxpemVkJmViYXk9JmV0c3k9JnNob3BpZnk9": "Yes",
        "product.location": "some",
        "shopify.ilya_fine_jewelry.seo_title": null,
        "prop.Y29yZT1OYXR1cmFsJTJGbGFiLWNyZWF0ZWQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Natural",
        "ebay.ivy_and_rose.store_category2_id": "6106058",
        "shopify.ilya_fine_jewelry.vendor": null,
        "shopify.ivy_and_rose.seo_title": null,
        "prop.Y29yZT1HZW1zdG9uZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "Pearl",
        "prop.Y29yZT1TaWRlK3N0b25lJmViYXk9JmV0c3k9JnNob3BpZnk9": "Diamond",
        "shopify.ilya_fine_jewelry.collection_ids": [],
        "shopify.ilya_fine_jewelry.tags": [],
        "prop.Y29yZT1TaWRlK3N0b25lK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": "F-G",
        "ebay.ivy_and_rose.category_id": "261994",
        "prop.Y29yZT1NYWRlK3RvK29yZGVyJmViYXk9JmV0c3k9JnNob3BpZnk9": "No",
        "product.availability": 1,
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrc2hhcGUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Round",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY2xhcml0eSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1TaWRlK3N0b25lK2NsYXJpdHkmZWJheT0mZXRzeT0mc2hvcGlmeT0": "SI"
      },
      "id": "4b976512-1691-435d-a39f-f4a0fe388f35",
      "sku": "18718"
    },
    {
      "data": {
        "prop.Y29yZT1Ub3RhbCtjYXJhdCt3ZWlnaHQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "2.34ct",
        "prop.Y29yZT1SZWNpcGllbnQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Women",
        "shopify.ivy_and_rose.collection_ids": [],
        "shopify.ivy_and_rose.tags": [],
        "prop.Y29yZT1TZXR0aW5nK3N0eWxlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1EaWFtb25kK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "shopify.ilya_fine_jewelry.product_type": null,
        "prop.Y29yZT1DbGFyaXR5JmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1TaWRlK3N0b25lK3NoYXBlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "etsy.ivy_and_rose.who_made": "someone_else",
        "prop.Y29yZT1TaXplJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1DZW50ZXIrbWluK3dpZHRoK2ZpdCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "etsy.ivy_and_rose.price": "50",
        "prop.Y29yZT1DZXJ0aWZpY2F0aW9uJmViYXk9JmV0c3k9JnNob3BpZnk9": "GIA",
        "etsy.ivy_and_rose.title": "2.34Ct Old European Cut Diamond Gia Certified Q-R Vvs1 Vintage Loose Natural",
        "ebay.ivy_and_rose.store_category_id": "14736769",
        "product.main_style": null,
        "product.sku": "20338",
        "shopify.ivy_and_rose.title": null,
        "shopify.ivy_and_rose.price": null,
        "prop.Y29yZT1TaWRlK3N0b25lcytxdWFudGl0eSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "etsy.ivy_and_rose.is_supply": true,
        "prop.Y29yZT1HcmFtK3dlaWdodCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1DZW50ZXIrbWF4K2xlbmd0aCtmaXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "ebay.ivy_and_rose.condition": "USED_EXCELLENT",
        "prop.Y29yZT1TZWNvbmRhcnkrc3RvbmUmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1TaWRlK3N0b25lK2NhcmF0K3dlaWdodCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1SaW5nK3NpemUmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1NZXRhbCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1UaGVtZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1BcHByb3hpbWF0ZSt0aW1lK3BlcmlvZCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "Art Deco/Art Nouveau",
        "prop.Y29yZT1NYWluK3N0b25lK3NoYXBlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1DZW50ZXIrbWluK2xlbmd0aCtmaXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "ebay.ivy_and_rose.secondary_category_id": null,
        "prop.Y29yZT1NYXgrc2l6ZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "10",
        "prop.Y29yZT1GYW5jeStkaWFtb25kK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "etsy.ivy_and_rose.shop_section_id": "18036037",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY2FyYXQrd2VpZ2h0JmViYXk9JmV0c3k9JnNob3BpZnk9": "2.34ct (8.62mm - 8.68mm)",
        "prop.Y29yZT1DdXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1NYXRlcmlhbCttdWx0aSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1DZW50ZXIrbWF4K3dpZHRoK2ZpdCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "ebay.ivy_and_rose.sub_title": null,
        "product.additional_styles": [],
        "product.price": "50",
        "prop.Y29yZT1NZXRhbCtwdXJpdHkmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "product.title": "2.34ct OLD EUROPEAN CUT DIAMOND GIA CERTIFIED Q-R VVS1 VINTAGE LOOSE NATURAL",
        "prop.Y29yZT1UcmVhdG1lbnQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Not Treated, Not Enhanced",
        "prop.Y29yZT1NYWluK3N0b25lJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1TdHlsZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1PY2Nhc2lvbiZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "shopify.ivy_and_rose.product_type": null,
        "ebay.ivy_and_rose.price": "50",
        "product.description": "Highlights:\n\r\n- Large 2.34ct size!\n\r\n- Original vintage diamond\n\r\n- Beautiful old European cut.\n\r\n- GIA certified\n\r\n- Subtle warmth adds vintage charm with Q-R color\n\r\n- Excellent VVS1 clarity\n\r\n- Fantastic sparkle and brilliance!\n\r\n\n\r\nAdditional Notes:\n\r\nThe diamond is being sold loose, but it can be mounted upon request.",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Diamond (GIA Certified)",
        "etsy.ivy_and_rose.category_id": "6648",
        "shopify.ivy_and_rose.vendor": null,
        "product.cost": "10",
        "prop.Y29yZT1TaXphYmxlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1NaW4rc2l6ZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "4",
        "prop.Y29yZT1NYWluK3N0b25lK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1JdGVtK3R5cGUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Loose Diamond",
        "prop.Y29yZT1FcmEmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Original Vintage",
        "product.internal_title": null,
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY29sb3ImZWJheT0mZXRzeT0mc2hvcGlmeT0": "Q-R",
        "shopify.ilya_fine_jewelry.price": null,
        "prop.Y29yZT1Db2xsZWN0aW9uJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "product.notes": null,
        "shopify.ilya_fine_jewelry.title": null,
        "product.stock_number": null,
        "etsy.ivy_and_rose.when_made": "1920s",
        "prop.Y29yZT1DYW4rYmUrcGVyc29uYWxpemVkJmViYXk9JmV0c3k9JnNob3BpZnk9": "Yes",
        "product.location": "some",
        "shopify.ilya_fine_jewelry.seo_title": null,
        "prop.Y29yZT1OYXR1cmFsJTJGbGFiLWNyZWF0ZWQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Natural",
        "ebay.ivy_and_rose.store_category2_id": "14736889",
        "shopify.ilya_fine_jewelry.vendor": null,
        "shopify.ivy_and_rose.seo_title": null,
        "prop.Y29yZT1HZW1zdG9uZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "Diamond (GIA Certified)",
        "prop.Y29yZT1TaWRlK3N0b25lJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "shopify.ilya_fine_jewelry.collection_ids": [],
        "shopify.ilya_fine_jewelry.tags": [],
        "prop.Y29yZT1TaWRlK3N0b25lK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "ebay.ivy_and_rose.category_id": "262026",
        "prop.Y29yZT1NYWRlK3RvK29yZGVyJmViYXk9JmV0c3k9JnNob3BpZnk9": "No",
        "product.availability": 1,
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrc2hhcGUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Old European Cut",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY2xhcml0eSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "VVS1",
        "prop.Y29yZT1TaWRlK3N0b25lK2NsYXJpdHkmZWJheT0mZXRzeT0mc2hvcGlmeT0": null
      },
      "id": "07a7c36a-feba-486f-88d6-c0ddc95f007a",
      "sku": "20338"
    },
    {
      "data": {
        "prop.Y29yZT1Ub3RhbCtjYXJhdCt3ZWlnaHQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "0.88ct",
        "prop.Y29yZT1SZWNpcGllbnQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Women",
        "shopify.ivy_and_rose.collection_ids": [],
        "shopify.ivy_and_rose.tags": [],
        "prop.Y29yZT1TZXR0aW5nK3N0eWxlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1EaWFtb25kK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "shopify.ilya_fine_jewelry.product_type": null,
        "prop.Y29yZT1DbGFyaXR5JmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1TaWRlK3N0b25lK3NoYXBlJmViYXk9JmV0c3k9JnNob3BpZnk9": "Round, Vintage Single Cut",
        "etsy.ivy_and_rose.who_made": "someone_else",
        "prop.Y29yZT1TaXplJmViYXk9JmV0c3k9JnNob3BpZnk9": "5.5 (Resizable)",
        "prop.Y29yZT1DZW50ZXIrbWluK3dpZHRoK2ZpdCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "etsy.ivy_and_rose.price": "50",
        "prop.Y29yZT1DZXJ0aWZpY2F0aW9uJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "etsy.ivy_and_rose.title": "Old European Cut Diamond Cocktail Ring Vintage Retro Bypass White Gold Estate",
        "ebay.ivy_and_rose.store_category_id": "14736886",
        "product.main_style": "Cocktail",
        "product.sku": "17648",
        "shopify.ivy_and_rose.title": null,
        "shopify.ivy_and_rose.price": null,
        "prop.Y29yZT1TaWRlK3N0b25lcytxdWFudGl0eSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "etsy.ivy_and_rose.is_supply": false,
        "prop.Y29yZT1HcmFtK3dlaWdodCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "2.9 grams",
        "prop.Y29yZT1DZW50ZXIrbWF4K2xlbmd0aCtmaXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "ebay.ivy_and_rose.condition": "USED_EXCELLENT",
        "prop.Y29yZT1TZWNvbmRhcnkrc3RvbmUmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1TaWRlK3N0b25lK2NhcmF0K3dlaWdodCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "0.50ctw",
        "prop.Y29yZT1SaW5nK3NpemUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "5.5",
        "prop.Y29yZT1NZXRhbCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "14k White Gold",
        "prop.Y29yZT1UaGVtZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1BcHByb3hpbWF0ZSt0aW1lK3BlcmlvZCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "Retro",
        "prop.Y29yZT1NYWluK3N0b25lK3NoYXBlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1DZW50ZXIrbWluK2xlbmd0aCtmaXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "ebay.ivy_and_rose.secondary_category_id": "262014",
        "prop.Y29yZT1NYXgrc2l6ZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "10",
        "prop.Y29yZT1GYW5jeStkaWFtb25kK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "etsy.ivy_and_rose.shop_section_id": "21991708",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY2FyYXQrd2VpZ2h0JmViYXk9JmV0c3k9JnNob3BpZnk9": "0.33ct",
        "prop.Y29yZT1DdXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1NYXRlcmlhbCttdWx0aSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "14k White Gold",
        "prop.Y29yZT1DZW50ZXIrbWF4K3dpZHRoK2ZpdCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "ebay.ivy_and_rose.sub_title": null,
        "product.additional_styles": [],
        "product.price": "50",
        "prop.Y29yZT1NZXRhbCtwdXJpdHkmZWJheT0mZXRzeT0mc2hvcGlmeT0": "14k",
        "product.title": "OLD EUROPEAN CUT DIAMOND COCKTAIL RING VINTAGE RETRO BYPASS WHITE GOLD ESTATE",
        "prop.Y29yZT1UcmVhdG1lbnQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Not Treated, Not Enhanced",
        "prop.Y29yZT1NYWluK3N0b25lJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1TdHlsZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "Cocktail",
        "prop.Y29yZT1PY2Nhc2lvbiZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "shopify.ivy_and_rose.product_type": null,
        "ebay.ivy_and_rose.price": "50",
        "product.description": "Why we love it: \n\r\nThis vintage diamond ring has a unique and elegant design with an old European cut diamond center, a diamond studded face, and a geometric bypass design.\n\r\n\n\r\nHighlights:\n\r\n- Original vintage from the retro era\n\r\n- 0.33ct old European cut diamond center\n\r\n- Bright white and eye clean with grades of G SI2\n\r\n- Additional 0.50ct of bright white and eye clean accent diamonds\n\r\n- 14k white gold setting\n\r\n\n\r\nDimensions:\n\r\n14.9mm across (north-south) and 6.0mm from the finger to the top of the setting.",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Diamond",
        "etsy.ivy_and_rose.category_id": "1231",
        "shopify.ivy_and_rose.vendor": null,
        "product.cost": "10",
        "prop.Y29yZT1TaXphYmxlJmViYXk9JmV0c3k9JnNob3BpZnk9": "yes",
        "prop.Y29yZT1NaW4rc2l6ZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "4",
        "prop.Y29yZT1NYWluK3N0b25lK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1JdGVtK3R5cGUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Cocktail Ring - Other",
        "prop.Y29yZT1FcmEmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Original Vintage",
        "product.internal_title": "ring - cocktail - vintage",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY29sb3ImZWJheT0mZXRzeT0mc2hvcGlmeT0": "G-H",
        "shopify.ilya_fine_jewelry.price": null,
        "prop.Y29yZT1Db2xsZWN0aW9uJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "product.notes": "14.9 x 6.0",
        "shopify.ilya_fine_jewelry.title": null,
        "product.stock_number": null,
        "etsy.ivy_and_rose.when_made": "1950s",
        "prop.Y29yZT1DYW4rYmUrcGVyc29uYWxpemVkJmViYXk9JmV0c3k9JnNob3BpZnk9": "Yes",
        "product.location": "some",
        "shopify.ilya_fine_jewelry.seo_title": null,
        "prop.Y29yZT1OYXR1cmFsJTJGbGFiLWNyZWF0ZWQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Natural",
        "ebay.ivy_and_rose.store_category2_id": "14736870",
        "shopify.ilya_fine_jewelry.vendor": null,
        "shopify.ivy_and_rose.seo_title": null,
        "prop.Y29yZT1HZW1zdG9uZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "Diamond",
        "prop.Y29yZT1TaWRlK3N0b25lJmViYXk9JmV0c3k9JnNob3BpZnk9": "Diamond",
        "shopify.ilya_fine_jewelry.collection_ids": [],
        "shopify.ilya_fine_jewelry.tags": [],
        "prop.Y29yZT1TaWRlK3N0b25lK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": "F-H",
        "ebay.ivy_and_rose.category_id": "261994",
        "prop.Y29yZT1NYWRlK3RvK29yZGVyJmViYXk9JmV0c3k9JnNob3BpZnk9": "No",
        "product.availability": 0,
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrc2hhcGUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Old European Cut",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY2xhcml0eSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "SI2",
        "prop.Y29yZT1TaWRlK3N0b25lK2NsYXJpdHkmZWJheT0mZXRzeT0mc2hvcGlmeT0": "VS1-SI1"
      },
      "id": "bd385617-9d22-4e30-96c7-2b7a9ccc400f",
      "sku": "17648"
    },
    {
      "data": {
        "prop.Y29yZT1Ub3RhbCtjYXJhdCt3ZWlnaHQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "0.27ct",
        "prop.Y29yZT1SZWNpcGllbnQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Women",
        "shopify.ivy_and_rose.collection_ids": [],
        "shopify.ivy_and_rose.tags": [],
        "prop.Y29yZT1TZXR0aW5nK3N0eWxlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1EaWFtb25kK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "shopify.ilya_fine_jewelry.product_type": null,
        "prop.Y29yZT1DbGFyaXR5JmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1TaWRlK3N0b25lK3NoYXBlJmViYXk9JmV0c3k9JnNob3BpZnk9": "Vintage Single Cut",
        "etsy.ivy_and_rose.who_made": "someone_else",
        "prop.Y29yZT1TaXplJmViYXk9JmV0c3k9JnNob3BpZnk9": "5.25 (Resizable)",
        "prop.Y29yZT1DZW50ZXIrbWluK3dpZHRoK2ZpdCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "etsy.ivy_and_rose.price": "50",
        "prop.Y29yZT1DZXJ0aWZpY2F0aW9uJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "etsy.ivy_and_rose.title": "Vintage Diamond Engagement Ring G-H Vs Old European Cut Two Tone Gold Natural",
        "ebay.ivy_and_rose.store_category_id": "14736792",
        "product.main_style": "Accent Stones",
        "product.sku": "16679",
        "shopify.ivy_and_rose.title": null,
        "shopify.ivy_and_rose.price": null,
        "prop.Y29yZT1TaWRlK3N0b25lcytxdWFudGl0eSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "2",
        "etsy.ivy_and_rose.is_supply": false,
        "prop.Y29yZT1HcmFtK3dlaWdodCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "1.3 grams",
        "prop.Y29yZT1DZW50ZXIrbWF4K2xlbmd0aCtmaXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "ebay.ivy_and_rose.condition": "USED_EXCELLENT",
        "prop.Y29yZT1TZWNvbmRhcnkrc3RvbmUmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1TaWRlK3N0b25lK2NhcmF0K3dlaWdodCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "0.02ctw",
        "prop.Y29yZT1SaW5nK3NpemUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "5.25",
        "prop.Y29yZT1NZXRhbCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "14k Yellow &amp; White Gold",
        "prop.Y29yZT1UaGVtZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1BcHByb3hpbWF0ZSt0aW1lK3BlcmlvZCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "Art Deco/Art Nouveau",
        "prop.Y29yZT1NYWluK3N0b25lK3NoYXBlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1DZW50ZXIrbWluK2xlbmd0aCtmaXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "ebay.ivy_and_rose.secondary_category_id": "262014",
        "prop.Y29yZT1NYXgrc2l6ZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "10",
        "prop.Y29yZT1GYW5jeStkaWFtb25kK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "etsy.ivy_and_rose.shop_section_id": "17500487",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY2FyYXQrd2VpZ2h0JmViYXk9JmV0c3k9JnNob3BpZnk9": "0.25ct",
        "prop.Y29yZT1DdXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1NYXRlcmlhbCttdWx0aSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "14k Yellow &amp; White Gold",
        "prop.Y29yZT1DZW50ZXIrbWF4K3dpZHRoK2ZpdCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "ebay.ivy_and_rose.sub_title": null,
        "product.additional_styles": [],
        "product.price": "50",
        "prop.Y29yZT1NZXRhbCtwdXJpdHkmZWJheT0mZXRzeT0mc2hvcGlmeT0": "14k",
        "product.title": "VINTAGE DIAMOND ENGAGEMENT RING G-H VS OLD EUROPEAN CUT TWO TONE GOLD NATURAL",
        "prop.Y29yZT1UcmVhdG1lbnQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Not Treated, Not Enhanced",
        "prop.Y29yZT1NYWluK3N0b25lJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1TdHlsZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "Accent Stones",
        "prop.Y29yZT1PY2Nhc2lvbiZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "shopify.ivy_and_rose.product_type": null,
        "ebay.ivy_and_rose.price": "50",
        "product.description": "Why we love it: \n\r\nThis original vintage engagement ring showcases a 0.25ct old European cut diamond in a classic setting with vintage details that add charm without clutter.\n\r\n\n\r\nHighlights:\n\r\n- Original vintage\n\r\n- 0.45ct center diamond with a beautiful old European cut\n\r\n- High quality, bright white, and exceptionally clean with grades of G-H VS\n\r\n- 0.02ct of high quality bright white and exceptionally clean side diamonds\n\r\n- Classic two tone 14k yellow and white gold setting\n\r\n\n\r\nDimensions:\n\r\n5.9mm across (north-south) and 3.8mm from the finger to the top of the setting.",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Diamond",
        "etsy.ivy_and_rose.category_id": "1245",
        "shopify.ivy_and_rose.vendor": null,
        "product.cost": "10",
        "prop.Y29yZT1TaXphYmxlJmViYXk9JmV0c3k9JnNob3BpZnk9": "yes",
        "prop.Y29yZT1NaW4rc2l6ZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "4",
        "prop.Y29yZT1NYWluK3N0b25lK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1JdGVtK3R5cGUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Engagement Ring",
        "prop.Y29yZT1FcmEmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Original Vintage",
        "product.internal_title": "ring - engagement - vintage",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY29sb3ImZWJheT0mZXRzeT0mc2hvcGlmeT0": "G-H",
        "shopify.ilya_fine_jewelry.price": null,
        "prop.Y29yZT1Db2xsZWN0aW9uJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "product.notes": "5.9 x 3.8",
        "shopify.ilya_fine_jewelry.title": null,
        "product.stock_number": null,
        "etsy.ivy_and_rose.when_made": "1920s",
        "prop.Y29yZT1DYW4rYmUrcGVyc29uYWxpemVkJmViYXk9JmV0c3k9JnNob3BpZnk9": "Yes",
        "product.location": "some",
        "shopify.ilya_fine_jewelry.seo_title": null,
        "prop.Y29yZT1OYXR1cmFsJTJGbGFiLWNyZWF0ZWQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Natural",
        "ebay.ivy_and_rose.store_category2_id": "14736886",
        "shopify.ilya_fine_jewelry.vendor": null,
        "shopify.ivy_and_rose.seo_title": null,
        "prop.Y29yZT1HZW1zdG9uZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "Diamond",
        "prop.Y29yZT1TaWRlK3N0b25lJmViYXk9JmV0c3k9JnNob3BpZnk9": "Diamond",
        "shopify.ilya_fine_jewelry.collection_ids": [],
        "shopify.ilya_fine_jewelry.tags": [],
        "prop.Y29yZT1TaWRlK3N0b25lK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": "G",
        "ebay.ivy_and_rose.category_id": "261975",
        "prop.Y29yZT1NYWRlK3RvK29yZGVyJmViYXk9JmV0c3k9JnNob3BpZnk9": "No",
        "product.availability": 1,
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrc2hhcGUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Old European Cut",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY2xhcml0eSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "VS",
        "prop.Y29yZT1TaWRlK3N0b25lK2NsYXJpdHkmZWJheT0mZXRzeT0mc2hvcGlmeT0": "VS"
      },
      "id": "c09c75ea-7519-461c-9740-7b3fb8fb48d9",
      "sku": "16679"
    },
    {
      "data": {
        "prop.Y29yZT1Ub3RhbCtjYXJhdCt3ZWlnaHQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "0.15ct",
        "prop.Y29yZT1SZWNpcGllbnQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Women",
        "shopify.ivy_and_rose.collection_ids": [],
        "shopify.ivy_and_rose.tags": [],
        "prop.Y29yZT1TZXR0aW5nK3N0eWxlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1EaWFtb25kK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "shopify.ilya_fine_jewelry.product_type": null,
        "prop.Y29yZT1DbGFyaXR5JmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1TaWRlK3N0b25lK3NoYXBlJmViYXk9JmV0c3k9JnNob3BpZnk9": "Vintage Single Cut",
        "etsy.ivy_and_rose.who_made": "someone_else",
        "prop.Y29yZT1TaXplJmViYXk9JmV0c3k9JnNob3BpZnk9": "7",
        "prop.Y29yZT1DZW50ZXIrbWluK3dpZHRoK2ZpdCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "etsy.ivy_and_rose.price": "50",
        "prop.Y29yZT1DZXJ0aWZpY2F0aW9uJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "etsy.ivy_and_rose.title": "Vintage Diamond Platinum Wedding Ring Stacking Band Engraved Channel Natural",
        "ebay.ivy_and_rose.store_category_id": "14843984",
        "product.main_style": "Channel",
        "product.sku": "19613",
        "shopify.ivy_and_rose.title": null,
        "shopify.ivy_and_rose.price": null,
        "prop.Y29yZT1TaWRlK3N0b25lcytxdWFudGl0eSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "etsy.ivy_and_rose.is_supply": false,
        "prop.Y29yZT1HcmFtK3dlaWdodCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "2.1 grams",
        "prop.Y29yZT1DZW50ZXIrbWF4K2xlbmd0aCtmaXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "ebay.ivy_and_rose.condition": "USED_EXCELLENT",
        "prop.Y29yZT1TZWNvbmRhcnkrc3RvbmUmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1TaWRlK3N0b25lK2NhcmF0K3dlaWdodCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "0.15ctw",
        "prop.Y29yZT1SaW5nK3NpemUmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1NZXRhbCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "Platinum",
        "prop.Y29yZT1UaGVtZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1BcHByb3hpbWF0ZSt0aW1lK3BlcmlvZCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "Art Deco/Art Nouveau",
        "prop.Y29yZT1NYWluK3N0b25lK3NoYXBlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1DZW50ZXIrbWluK2xlbmd0aCtmaXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "ebay.ivy_and_rose.secondary_category_id": "262014",
        "prop.Y29yZT1NYXgrc2l6ZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "10",
        "prop.Y29yZT1GYW5jeStkaWFtb25kK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "etsy.ivy_and_rose.shop_section_id": "17822028",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY2FyYXQrd2VpZ2h0JmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1DdXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1NYXRlcmlhbCttdWx0aSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "Platinum",
        "prop.Y29yZT1DZW50ZXIrbWF4K3dpZHRoK2ZpdCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "ebay.ivy_and_rose.sub_title": null,
        "product.additional_styles": [],
        "product.price": "50",
        "prop.Y29yZT1NZXRhbCtwdXJpdHkmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Plat",
        "product.title": "VINTAGE DIAMOND PLATINUM WEDDING RING STACKING BAND ENGRAVED CHANNEL NATURAL",
        "prop.Y29yZT1UcmVhdG1lbnQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Not Treated, Not Enhanced",
        "prop.Y29yZT1NYWluK3N0b25lJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1TdHlsZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1PY2Nhc2lvbiZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "shopify.ivy_and_rose.product_type": null,
        "ebay.ivy_and_rose.price": "50",
        "product.description": "This vintage diamond band has a classic design that will stay forever in style! It is perfect for a wedding band or stand alone piece.\n\r\n\n\r\nHighlights:\n\r\n- 0.15ct of high quality F-G VS1-SI1 diamonds\n\r\n- High quality platinum setting with engraved details continuing all the way around the band\n\r\n- Gorgeous and very well preserved details!\n\r\n\n\r\nDimensions:\n\r\n2.4mm across (north-south) and 1.3mm from the finger to the top of the setting.\n\r\n\n\r\nAdditional Notes:\n\r\nThe ring is a size 7. Due to the continuous engraving, this ring can’t be resized without breaking the pattern.",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "etsy.ivy_and_rose.category_id": "1247",
        "shopify.ivy_and_rose.vendor": null,
        "product.cost": "10",
        "prop.Y29yZT1TaXphYmxlJmViYXk9JmV0c3k9JnNob3BpZnk9": "no",
        "prop.Y29yZT1NaW4rc2l6ZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "4",
        "prop.Y29yZT1NYWluK3N0b25lK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1JdGVtK3R5cGUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Band - Wedding",
        "prop.Y29yZT1FcmEmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Original Vintage",
        "product.internal_title": "ring - wedding band - vintage",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY29sb3ImZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "shopify.ilya_fine_jewelry.price": null,
        "prop.Y29yZT1Db2xsZWN0aW9uJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "product.notes": "2.5 x 1.3 (Incredible details)",
        "shopify.ilya_fine_jewelry.title": null,
        "product.stock_number": null,
        "etsy.ivy_and_rose.when_made": "1920s",
        "prop.Y29yZT1DYW4rYmUrcGVyc29uYWxpemVkJmViYXk9JmV0c3k9JnNob3BpZnk9": "Yes",
        "product.location": "some",
        "shopify.ilya_fine_jewelry.seo_title": null,
        "prop.Y29yZT1OYXR1cmFsJTJGbGFiLWNyZWF0ZWQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Natural",
        "ebay.ivy_and_rose.store_category2_id": "14736886",
        "shopify.ilya_fine_jewelry.vendor": null,
        "shopify.ivy_and_rose.seo_title": null,
        "prop.Y29yZT1HZW1zdG9uZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "Diamond",
        "prop.Y29yZT1TaWRlK3N0b25lJmViYXk9JmV0c3k9JnNob3BpZnk9": "Diamond",
        "shopify.ilya_fine_jewelry.collection_ids": [],
        "shopify.ilya_fine_jewelry.tags": [],
        "prop.Y29yZT1TaWRlK3N0b25lK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": "F-G",
        "ebay.ivy_and_rose.category_id": "261977",
        "prop.Y29yZT1NYWRlK3RvK29yZGVyJmViYXk9JmV0c3k9JnNob3BpZnk9": "No",
        "product.availability": 1,
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrc2hhcGUmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY2xhcml0eSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1TaWRlK3N0b25lK2NsYXJpdHkmZWJheT0mZXRzeT0mc2hvcGlmeT0": "VS1-SI1"
      },
      "id": "1a8a688b-c720-4d02-a63f-599f48418e84",
      "sku": "19613"
    },
    {
      "data": {
        "prop.Y29yZT1Ub3RhbCtjYXJhdCt3ZWlnaHQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "0.75ct",
        "prop.Y29yZT1SZWNpcGllbnQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Women",
        "shopify.ivy_and_rose.collection_ids": [],
        "shopify.ivy_and_rose.tags": [],
        "prop.Y29yZT1TZXR0aW5nK3N0eWxlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1EaWFtb25kK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "shopify.ilya_fine_jewelry.product_type": null,
        "prop.Y29yZT1DbGFyaXR5JmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1TaWRlK3N0b25lK3NoYXBlJmViYXk9JmV0c3k9JnNob3BpZnk9": "Round (Excellent Cut)",
        "etsy.ivy_and_rose.who_made": "i_did",
        "prop.Y29yZT1TaXplJmViYXk9JmV0c3k9JnNob3BpZnk9": "6.0 (Resizable)",
        "prop.Y29yZT1DZW50ZXIrbWluK3dpZHRoK2ZpdCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "etsy.ivy_and_rose.price": "50",
        "prop.Y29yZT1DZXJ0aWZpY2F0aW9uJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "etsy.ivy_and_rose.title": "3/4 Carat G Vs Diamond Platinum Eternity Ring Wedding Band Excellent Cut Natural",
        "ebay.ivy_and_rose.store_category_id": "14843984",
        "product.main_style": "Prong",
        "product.sku": "19601",
        "shopify.ivy_and_rose.title": null,
        "shopify.ivy_and_rose.price": null,
        "prop.Y29yZT1TaWRlK3N0b25lcytxdWFudGl0eSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "12",
        "etsy.ivy_and_rose.is_supply": false,
        "prop.Y29yZT1HcmFtK3dlaWdodCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "3.8 grams",
        "prop.Y29yZT1DZW50ZXIrbWF4K2xlbmd0aCtmaXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "ebay.ivy_and_rose.condition": "NEW",
        "prop.Y29yZT1TZWNvbmRhcnkrc3RvbmUmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1TaWRlK3N0b25lK2NhcmF0K3dlaWdodCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "0.75ctw",
        "prop.Y29yZT1SaW5nK3NpemUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "6",
        "prop.Y29yZT1NZXRhbCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "Platinum",
        "prop.Y29yZT1UaGVtZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1BcHByb3hpbWF0ZSt0aW1lK3BlcmlvZCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "New",
        "prop.Y29yZT1NYWluK3N0b25lK3NoYXBlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1DZW50ZXIrbWluK2xlbmd0aCtmaXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "ebay.ivy_and_rose.secondary_category_id": "261994",
        "prop.Y29yZT1NYXgrc2l6ZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "10",
        "prop.Y29yZT1GYW5jeStkaWFtb25kK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "etsy.ivy_and_rose.shop_section_id": "17822028",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY2FyYXQrd2VpZ2h0JmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1DdXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1NYXRlcmlhbCttdWx0aSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "Platinum",
        "prop.Y29yZT1DZW50ZXIrbWF4K3dpZHRoK2ZpdCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "ebay.ivy_and_rose.sub_title": null,
        "product.additional_styles": [],
        "product.price": "50",
        "prop.Y29yZT1NZXRhbCtwdXJpdHkmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Plat",
        "product.title": "3/4 CARAT G VS DIAMOND PLATINUM ETERNITY RING WEDDING BAND EXCELLENT CUT NATURAL",
        "prop.Y29yZT1UcmVhdG1lbnQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Not Treated, Not Enhanced",
        "prop.Y29yZT1NYWluK3N0b25lJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1TdHlsZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1PY2Nhc2lvbiZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "shopify.ivy_and_rose.product_type": null,
        "ebay.ivy_and_rose.price": "50",
        "product.description": "Why we love it: \n\r\nThis diamond band is very high quality crafted in platinum with top quality diamonds! \n\r\n\n\r\nHighlights:\n\r\n- ¾ carat of bright white and exceptionally clean G-H VS1-VS2 diamonds\n\r\n- Excellent cut for fantastic sparkle!\n\r\n- Sleek and classic platinum setting\n\r\n\n\r\nDimensions:\n\r\nThe ring measures 2.6mm across (north south) and 2.2mm from finger to top",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "etsy.ivy_and_rose.category_id": "1247",
        "shopify.ivy_and_rose.vendor": null,
        "product.cost": "10",
        "prop.Y29yZT1TaXphYmxlJmViYXk9JmV0c3k9JnNob3BpZnk9": "yes",
        "prop.Y29yZT1NaW4rc2l6ZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "4",
        "prop.Y29yZT1NYWluK3N0b25lK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1JdGVtK3R5cGUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Band - Wedding",
        "prop.Y29yZT1FcmEmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Modern",
        "product.internal_title": "ring - wedding band - modern",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY29sb3ImZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "shopify.ilya_fine_jewelry.price": null,
        "prop.Y29yZT1Db2xsZWN0aW9uJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "product.notes": "2.6 x 2.2 (Incredible quality)",
        "shopify.ilya_fine_jewelry.title": null,
        "product.stock_number": null,
        "etsy.ivy_and_rose.when_made": "2010_2019",
        "prop.Y29yZT1DYW4rYmUrcGVyc29uYWxpemVkJmViYXk9JmV0c3k9JnNob3BpZnk9": "Yes",
        "product.location": "some",
        "shopify.ilya_fine_jewelry.seo_title": null,
        "prop.Y29yZT1OYXR1cmFsJTJGbGFiLWNyZWF0ZWQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Natural",
        "ebay.ivy_and_rose.store_category2_id": "14736870",
        "shopify.ilya_fine_jewelry.vendor": null,
        "shopify.ivy_and_rose.seo_title": null,
        "prop.Y29yZT1HZW1zdG9uZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "Diamond",
        "prop.Y29yZT1TaWRlK3N0b25lJmViYXk9JmV0c3k9JnNob3BpZnk9": "Diamond",
        "shopify.ilya_fine_jewelry.collection_ids": [],
        "shopify.ilya_fine_jewelry.tags": [],
        "prop.Y29yZT1TaWRlK3N0b25lK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": "G-H",
        "ebay.ivy_and_rose.category_id": "261977",
        "prop.Y29yZT1NYWRlK3RvK29yZGVyJmViYXk9JmV0c3k9JnNob3BpZnk9": "No",
        "product.availability": 1,
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrc2hhcGUmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY2xhcml0eSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1TaWRlK3N0b25lK2NsYXJpdHkmZWJheT0mZXRzeT0mc2hvcGlmeT0": "VS1-VS2"
      },
      "id": "fe253fd2-44b3-4fd8-a787-1eef3cf35183",
      "sku": "19601"
    },
    {
      "data": {
        "prop.Y29yZT1Ub3RhbCtjYXJhdCt3ZWlnaHQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1SZWNpcGllbnQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Women",
        "shopify.ivy_and_rose.collection_ids": [],
        "shopify.ivy_and_rose.tags": [],
        "prop.Y29yZT1TZXR0aW5nK3N0eWxlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1EaWFtb25kK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "shopify.ilya_fine_jewelry.product_type": null,
        "prop.Y29yZT1DbGFyaXR5JmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1TaWRlK3N0b25lK3NoYXBlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "etsy.ivy_and_rose.who_made": "i_did",
        "prop.Y29yZT1TaXplJmViYXk9JmV0c3k9JnNob3BpZnk9": "16\"",
        "prop.Y29yZT1DZW50ZXIrbWluK3dpZHRoK2ZpdCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "etsy.ivy_and_rose.price": "50",
        "prop.Y29yZT1DZXJ0aWZpY2F0aW9uJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "etsy.ivy_and_rose.title": "Solid 14K Yellow Gold Omega Chain 16 Inch 2.8Mm Herringbone Snake Necklace Estate",
        "ebay.ivy_and_rose.store_category_id": "14736770",
        "product.main_style": "Necklace",
        "product.sku": "19541",
        "shopify.ivy_and_rose.title": null,
        "shopify.ivy_and_rose.price": null,
        "prop.Y29yZT1TaWRlK3N0b25lcytxdWFudGl0eSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "etsy.ivy_and_rose.is_supply": false,
        "prop.Y29yZT1HcmFtK3dlaWdodCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "8.4 grams",
        "prop.Y29yZT1DZW50ZXIrbWF4K2xlbmd0aCtmaXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "ebay.ivy_and_rose.condition": "NEW",
        "prop.Y29yZT1TZWNvbmRhcnkrc3RvbmUmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1TaWRlK3N0b25lK2NhcmF0K3dlaWdodCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1SaW5nK3NpemUmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1NZXRhbCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "14k Yellow Gold",
        "prop.Y29yZT1UaGVtZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1BcHByb3hpbWF0ZSt0aW1lK3BlcmlvZCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "New",
        "prop.Y29yZT1NYWluK3N0b25lK3NoYXBlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1DZW50ZXIrbWluK2xlbmd0aCtmaXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "ebay.ivy_and_rose.secondary_category_id": "261993",
        "prop.Y29yZT1NYXgrc2l6ZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "10",
        "prop.Y29yZT1GYW5jeStkaWFtb25kK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "etsy.ivy_and_rose.shop_section_id": "17869125",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY2FyYXQrd2VpZ2h0JmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1DdXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1NYXRlcmlhbCttdWx0aSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "14k Yellow Gold",
        "prop.Y29yZT1DZW50ZXIrbWF4K3dpZHRoK2ZpdCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "ebay.ivy_and_rose.sub_title": null,
        "product.additional_styles": [
          "Chain"
        ],
        "product.price": "50",
        "prop.Y29yZT1NZXRhbCtwdXJpdHkmZWJheT0mZXRzeT0mc2hvcGlmeT0": "14k",
        "product.title": "SOLID 14K YELLOW GOLD OMEGA CHAIN 16in 2.8mm HERRINGBONE SNAKE NECKLACE ESTATE",
        "prop.Y29yZT1UcmVhdG1lbnQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1NYWluK3N0b25lJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1TdHlsZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1PY2Nhc2lvbiZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "shopify.ivy_and_rose.product_type": null,
        "ebay.ivy_and_rose.price": "50",
        "product.description": "Why we love it: \n\r\nThis 14k yellow gold omega chain necklace has sleek look and an air of vintage charm!\n\r\n\n\r\nHighlights:\n\r\n- Solid 14k yellow gold\n\r\n- Made in Italy\n\r\n\n\r\nDimensions:\n\r\n2.8mm width",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "etsy.ivy_and_rose.category_id": "1221",
        "shopify.ivy_and_rose.vendor": null,
        "product.cost": "10",
        "prop.Y29yZT1TaXphYmxlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1NaW4rc2l6ZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "4",
        "prop.Y29yZT1NYWluK3N0b25lK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1JdGVtK3R5cGUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Necklace - Chain (New-No Stones)",
        "prop.Y29yZT1FcmEmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Modern",
        "product.internal_title": null,
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY29sb3ImZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "shopify.ilya_fine_jewelry.price": null,
        "prop.Y29yZT1Db2xsZWN0aW9uJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "product.notes": "2.8mm width (Omega necklace, signed \"Milar\" Made in Italy",
        "shopify.ilya_fine_jewelry.title": null,
        "product.stock_number": null,
        "etsy.ivy_and_rose.when_made": "2010_2019",
        "prop.Y29yZT1DYW4rYmUrcGVyc29uYWxpemVkJmViYXk9JmV0c3k9JnNob3BpZnk9": "Yes",
        "product.location": "some",
        "shopify.ilya_fine_jewelry.seo_title": null,
        "prop.Y29yZT1OYXR1cmFsJTJGbGFiLWNyZWF0ZWQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "ebay.ivy_and_rose.store_category2_id": null,
        "shopify.ilya_fine_jewelry.vendor": null,
        "shopify.ivy_and_rose.seo_title": null,
        "prop.Y29yZT1HZW1zdG9uZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1TaWRlK3N0b25lJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "shopify.ilya_fine_jewelry.collection_ids": [],
        "shopify.ilya_fine_jewelry.tags": [],
        "prop.Y29yZT1TaWRlK3N0b25lK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "ebay.ivy_and_rose.category_id": "137839",
        "prop.Y29yZT1NYWRlK3RvK29yZGVyJmViYXk9JmV0c3k9JnNob3BpZnk9": "No",
        "product.availability": 1,
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrc2hhcGUmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY2xhcml0eSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1TaWRlK3N0b25lK2NsYXJpdHkmZWJheT0mZXRzeT0mc2hvcGlmeT0": null
      },
      "id": "3c0feaf7-ca50-4e29-8c8b-06372473e22b",
      "sku": "19541"
    },
    {
      "data": {
        "prop.Y29yZT1Ub3RhbCtjYXJhdCt3ZWlnaHQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "1.33ct",
        "prop.Y29yZT1SZWNpcGllbnQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Women",
        "shopify.ivy_and_rose.collection_ids": [],
        "shopify.ivy_and_rose.tags": [],
        "prop.Y29yZT1TZXR0aW5nK3N0eWxlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1EaWFtb25kK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "shopify.ilya_fine_jewelry.product_type": null,
        "prop.Y29yZT1DbGFyaXR5JmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1TaWRlK3N0b25lK3NoYXBlJmViYXk9JmV0c3k9JnNob3BpZnk9": "Round",
        "etsy.ivy_and_rose.who_made": "i_did",
        "prop.Y29yZT1TaXplJmViYXk9JmV0c3k9JnNob3BpZnk9": "5.25 (Resizable)",
        "prop.Y29yZT1DZW50ZXIrbWluK3dpZHRoK2ZpdCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "etsy.ivy_and_rose.price": "50",
        "prop.Y29yZT1DZXJ0aWZpY2F0aW9uJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "etsy.ivy_and_rose.title": "Diamond Ruby Engagement Ring Marquise Cut Natural Three Stone Halo White Gold",
        "ebay.ivy_and_rose.store_category_id": "14736792",
        "product.main_style": "Halo",
        "product.sku": "19251-19252",
        "shopify.ivy_and_rose.title": null,
        "shopify.ivy_and_rose.price": null,
        "prop.Y29yZT1TaWRlK3N0b25lcytxdWFudGl0eSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "etsy.ivy_and_rose.is_supply": false,
        "prop.Y29yZT1HcmFtK3dlaWdodCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "3.3 grams",
        "prop.Y29yZT1DZW50ZXIrbWF4K2xlbmd0aCtmaXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "ebay.ivy_and_rose.condition": "NEW",
        "prop.Y29yZT1TZWNvbmRhcnkrc3RvbmUmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1TaWRlK3N0b25lK2NhcmF0K3dlaWdodCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "Diamond: 0.40ctw, Ruby: 0.18ctw",
        "prop.Y29yZT1SaW5nK3NpemUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "5.25",
        "prop.Y29yZT1NZXRhbCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "14k White Gold",
        "prop.Y29yZT1UaGVtZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1BcHByb3hpbWF0ZSt0aW1lK3BlcmlvZCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "New",
        "prop.Y29yZT1NYWluK3N0b25lK3NoYXBlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1DZW50ZXIrbWluK2xlbmd0aCtmaXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "ebay.ivy_and_rose.secondary_category_id": "261994",
        "prop.Y29yZT1NYXgrc2l6ZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "10",
        "prop.Y29yZT1GYW5jeStkaWFtb25kK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "etsy.ivy_and_rose.shop_section_id": "17500487",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY2FyYXQrd2VpZ2h0JmViYXk9JmV0c3k9JnNob3BpZnk9": "Approx. 0.75ct",
        "prop.Y29yZT1DdXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1NYXRlcmlhbCttdWx0aSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "14k White Gold",
        "prop.Y29yZT1DZW50ZXIrbWF4K3dpZHRoK2ZpdCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "ebay.ivy_and_rose.sub_title": null,
        "product.additional_styles": [
          "Gemstone"
        ],
        "product.price": "50",
        "prop.Y29yZT1NZXRhbCtwdXJpdHkmZWJheT0mZXRzeT0mc2hvcGlmeT0": "14k",
        "product.title": "DIAMOND RUBY ENGAGEMENT RING MARQUISE CUT NATURAL THREE STONE HALO WHITE GOLD",
        "prop.Y29yZT1UcmVhdG1lbnQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1NYWluK3N0b25lJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1TdHlsZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1PY2Nhc2lvbiZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "shopify.ivy_and_rose.product_type": null,
        "ebay.ivy_and_rose.price": "50",
        "product.description": "Why we love it: \n\r\nThis striking three stone ring features a lively 0.75ct marquise cut diamond flanked by rich red rubies and surrounded by a glittering diamond halo!\n\r\n\n\r\nHighlights:\n\r\n- 0.75ct marquise cut diamond center\n\r\n- Very bright white and eye clean with grades of E-F SI2\n\r\n- 0.18ct pair of rich red rubies\n\r\n- Very high quality rubies! Clean, lively, and very bright color!\n\r\n- Additional 0.40ct of bright white and eye clean accent diamonds\n\r\n- 14k white gold setting\n\r\n\n\r\nDimensions:\n\r\n13.5mm across (north-south) and 6.6mm from the finger to the top of the setting.",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Diamond",
        "etsy.ivy_and_rose.category_id": "1245",
        "shopify.ivy_and_rose.vendor": null,
        "product.cost": "10",
        "prop.Y29yZT1TaXphYmxlJmViYXk9JmV0c3k9JnNob3BpZnk9": "yes",
        "prop.Y29yZT1NaW4rc2l6ZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "4",
        "prop.Y29yZT1NYWluK3N0b25lK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1JdGVtK3R5cGUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Engagement Ring",
        "prop.Y29yZT1FcmEmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Modern",
        "product.internal_title": "ring - engagement - modern",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY29sb3ImZWJheT0mZXRzeT0mc2hvcGlmeT0": "E-F",
        "shopify.ilya_fine_jewelry.price": null,
        "prop.Y29yZT1Db2xsZWN0aW9uJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "product.notes": "13.5 x 6.6 (white inclusions, Ruby-top top top quality)",
        "shopify.ilya_fine_jewelry.title": null,
        "product.stock_number": null,
        "etsy.ivy_and_rose.when_made": "2010_2019",
        "prop.Y29yZT1DYW4rYmUrcGVyc29uYWxpemVkJmViYXk9JmV0c3k9JnNob3BpZnk9": "Yes",
        "product.location": "some",
        "shopify.ilya_fine_jewelry.seo_title": null,
        "prop.Y29yZT1OYXR1cmFsJTJGbGFiLWNyZWF0ZWQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Natural",
        "ebay.ivy_and_rose.store_category2_id": "14736870",
        "shopify.ilya_fine_jewelry.vendor": null,
        "shopify.ivy_and_rose.seo_title": null,
        "prop.Y29yZT1HZW1zdG9uZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "Diamond",
        "prop.Y29yZT1TaWRlK3N0b25lJmViYXk9JmV0c3k9JnNob3BpZnk9": "Diamond, Ruby",
        "shopify.ilya_fine_jewelry.collection_ids": [],
        "shopify.ilya_fine_jewelry.tags": [],
        "prop.Y29yZT1TaWRlK3N0b25lK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": "Diamond: F-G, Ruby: Red",
        "ebay.ivy_and_rose.category_id": "261975",
        "prop.Y29yZT1NYWRlK3RvK29yZGVyJmViYXk9JmV0c3k9JnNob3BpZnk9": "No",
        "product.availability": 0,
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrc2hhcGUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Marquise",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY2xhcml0eSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "SI2 (Eye Clean)",
        "prop.Y29yZT1TaWRlK3N0b25lK2NsYXJpdHkmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Diamond: VS2-SI1"
      },
      "id": "8faaf089-7285-4dab-9e18-e5224afe9bfb",
      "sku": "19251-19252"
    },
    {
      "data": {
        "prop.Y29yZT1Ub3RhbCtjYXJhdCt3ZWlnaHQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "1.00ct",
        "prop.Y29yZT1SZWNpcGllbnQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Women",
        "shopify.ivy_and_rose.collection_ids": [],
        "shopify.ivy_and_rose.tags": [],
        "prop.Y29yZT1TZXR0aW5nK3N0eWxlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1EaWFtb25kK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "shopify.ilya_fine_jewelry.product_type": null,
        "prop.Y29yZT1DbGFyaXR5JmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1TaWRlK3N0b25lK3NoYXBlJmViYXk9JmV0c3k9JnNob3BpZnk9": "Round",
        "etsy.ivy_and_rose.who_made": "i_did",
        "prop.Y29yZT1TaXplJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1DZW50ZXIrbWluK3dpZHRoK2ZpdCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "etsy.ivy_and_rose.price": "50",
        "prop.Y29yZT1DZXJ0aWZpY2F0aW9uJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "etsy.ivy_and_rose.title": "1Ct Natural Diamond Bangle Bracelet 10K Yellow Gold 15.6Gm Cuff Estate Jewelry",
        "ebay.ivy_and_rose.store_category_id": "5979473",
        "product.main_style": "Bangle",
        "product.sku": "20317",
        "shopify.ivy_and_rose.title": null,
        "shopify.ivy_and_rose.price": null,
        "prop.Y29yZT1TaWRlK3N0b25lcytxdWFudGl0eSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "etsy.ivy_and_rose.is_supply": false,
        "prop.Y29yZT1HcmFtK3dlaWdodCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "15.6 grams",
        "prop.Y29yZT1DZW50ZXIrbWF4K2xlbmd0aCtmaXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "ebay.ivy_and_rose.condition": "NEW",
        "prop.Y29yZT1TZWNvbmRhcnkrc3RvbmUmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1TaWRlK3N0b25lK2NhcmF0K3dlaWdodCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "1.00ctw",
        "prop.Y29yZT1SaW5nK3NpemUmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1NZXRhbCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "10k Yellow Gold",
        "prop.Y29yZT1UaGVtZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1BcHByb3hpbWF0ZSt0aW1lK3BlcmlvZCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "New",
        "prop.Y29yZT1NYWluK3N0b25lK3NoYXBlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1DZW50ZXIrbWluK2xlbmd0aCtmaXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "ebay.ivy_and_rose.secondary_category_id": null,
        "prop.Y29yZT1NYXgrc2l6ZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "10",
        "prop.Y29yZT1GYW5jeStkaWFtb25kK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "etsy.ivy_and_rose.shop_section_id": "17865866",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY2FyYXQrd2VpZ2h0JmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1DdXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1NYXRlcmlhbCttdWx0aSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "10k Yellow Gold",
        "prop.Y29yZT1DZW50ZXIrbWF4K3dpZHRoK2ZpdCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "ebay.ivy_and_rose.sub_title": null,
        "product.additional_styles": [],
        "product.price": "50",
        "prop.Y29yZT1NZXRhbCtwdXJpdHkmZWJheT0mZXRzeT0mc2hvcGlmeT0": "10k",
        "product.title": "1ct NATURAL DIAMOND BANGLE BRACELET 10k YELLOW GOLD 15.6gm CUFF ESTATE JEWELRY",
        "prop.Y29yZT1UcmVhdG1lbnQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Not Treated, Not Enhanced",
        "prop.Y29yZT1NYWluK3N0b25lJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1TdHlsZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1PY2Nhc2lvbiZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "shopify.ivy_and_rose.product_type": null,
        "ebay.ivy_and_rose.price": "50",
        "product.description": "Why we love it: \n\r\nThis diamond and 10k yellow gold bracelet has a substantial and luxurious look with twisting ribbons of gold and glittering diamonds.\n\r\n\n\r\nHighlights:\n\r\n- 1 carat of vibrant diamonds\n\r\n- Bright and lively with grades of I-J SI2-SI3\n\r\n- Hinged bangle design\n\r\n- Solid 10k yellow gold\n\r\n\n\r\nDimensions:\n\r\n10.9mm in width.",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "etsy.ivy_and_rose.category_id": "1193",
        "shopify.ivy_and_rose.vendor": null,
        "product.cost": "10",
        "prop.Y29yZT1TaXphYmxlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1NaW4rc2l6ZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "4",
        "prop.Y29yZT1NYWluK3N0b25lK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1JdGVtK3R5cGUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Bracelet (All Other)",
        "prop.Y29yZT1FcmEmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Modern",
        "product.internal_title": null,
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY29sb3ImZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "shopify.ilya_fine_jewelry.price": null,
        "prop.Y29yZT1Db2xsZWN0aW9uJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "product.notes": "10.9mm width (Bright white diamonds)",
        "shopify.ilya_fine_jewelry.title": null,
        "product.stock_number": null,
        "etsy.ivy_and_rose.when_made": "2010_2019",
        "prop.Y29yZT1DYW4rYmUrcGVyc29uYWxpemVkJmViYXk9JmV0c3k9JnNob3BpZnk9": "Yes",
        "product.location": "some",
        "shopify.ilya_fine_jewelry.seo_title": null,
        "prop.Y29yZT1OYXR1cmFsJTJGbGFiLWNyZWF0ZWQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Natural",
        "ebay.ivy_and_rose.store_category2_id": null,
        "shopify.ilya_fine_jewelry.vendor": null,
        "shopify.ivy_and_rose.seo_title": null,
        "prop.Y29yZT1HZW1zdG9uZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "Diamond",
        "prop.Y29yZT1TaWRlK3N0b25lJmViYXk9JmV0c3k9JnNob3BpZnk9": "Diamond",
        "shopify.ilya_fine_jewelry.collection_ids": [],
        "shopify.ilya_fine_jewelry.tags": [],
        "prop.Y29yZT1TaWRlK3N0b25lK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": "I-J",
        "ebay.ivy_and_rose.category_id": "261988",
        "prop.Y29yZT1NYWRlK3RvK29yZGVyJmViYXk9JmV0c3k9JnNob3BpZnk9": "No",
        "product.availability": 1,
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrc2hhcGUmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY2xhcml0eSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1TaWRlK3N0b25lK2NsYXJpdHkmZWJheT0mZXRzeT0mc2hvcGlmeT0": "SI2-SI3"
      },
      "id": "9df6d09b-1865-42cc-a797-0152a28a73a7",
      "sku": "20317"
    },
    {
      "data": {
        "prop.Y29yZT1Ub3RhbCtjYXJhdCt3ZWlnaHQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1SZWNpcGllbnQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Women",
        "shopify.ivy_and_rose.collection_ids": [],
        "shopify.ivy_and_rose.tags": [],
        "prop.Y29yZT1TZXR0aW5nK3N0eWxlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1EaWFtb25kK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "shopify.ilya_fine_jewelry.product_type": null,
        "prop.Y29yZT1DbGFyaXR5JmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1TaWRlK3N0b25lK3NoYXBlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "etsy.ivy_and_rose.who_made": "i_did",
        "prop.Y29yZT1TaXplJmViYXk9JmV0c3k9JnNob3BpZnk9": "19\"",
        "prop.Y29yZT1DZW50ZXIrbWluK3dpZHRoK2ZpdCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "etsy.ivy_and_rose.price": "50",
        "prop.Y29yZT1DZXJ0aWZpY2F0aW9uJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "etsy.ivy_and_rose.title": "Solid 10K Yellow Gold Wheat Chain Necklace 19 Inch Italian Dainty Classic",
        "ebay.ivy_and_rose.store_category_id": "5979473",
        "product.main_style": "Necklace",
        "product.sku": "19639",
        "shopify.ivy_and_rose.title": null,
        "shopify.ivy_and_rose.price": null,
        "prop.Y29yZT1TaWRlK3N0b25lcytxdWFudGl0eSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "etsy.ivy_and_rose.is_supply": false,
        "prop.Y29yZT1HcmFtK3dlaWdodCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "2.9 grams",
        "prop.Y29yZT1DZW50ZXIrbWF4K2xlbmd0aCtmaXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "ebay.ivy_and_rose.condition": "NEW",
        "prop.Y29yZT1TZWNvbmRhcnkrc3RvbmUmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1TaWRlK3N0b25lK2NhcmF0K3dlaWdodCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1SaW5nK3NpemUmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1NZXRhbCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "10k Yellow Gold",
        "prop.Y29yZT1UaGVtZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1BcHByb3hpbWF0ZSt0aW1lK3BlcmlvZCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "New",
        "prop.Y29yZT1NYWluK3N0b25lK3NoYXBlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1DZW50ZXIrbWluK2xlbmd0aCtmaXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "ebay.ivy_and_rose.secondary_category_id": "261999",
        "prop.Y29yZT1NYXgrc2l6ZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "10",
        "prop.Y29yZT1GYW5jeStkaWFtb25kK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "etsy.ivy_and_rose.shop_section_id": "17869125",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY2FyYXQrd2VpZ2h0JmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1DdXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1NYXRlcmlhbCttdWx0aSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "10k Yellow Gold",
        "prop.Y29yZT1DZW50ZXIrbWF4K3dpZHRoK2ZpdCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "ebay.ivy_and_rose.sub_title": null,
        "product.additional_styles": [
          "Chain"
        ],
        "product.price": "50",
        "prop.Y29yZT1NZXRhbCtwdXJpdHkmZWJheT0mZXRzeT0mc2hvcGlmeT0": "10k",
        "product.title": "SOLID 10k YELLOW GOLD WHEAT CHAIN NECKLACE 19 INCH ITALIAN DAINTY CLASSIC",
        "prop.Y29yZT1UcmVhdG1lbnQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1NYWluK3N0b25lJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1TdHlsZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1PY2Nhc2lvbiZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "shopify.ivy_and_rose.product_type": null,
        "ebay.ivy_and_rose.price": "50",
        "product.description": "Why we love it: \n\r\nThis 10k yellow gold wheat chain has a sturdy construction and a slinky look.\n\r\n\n\r\nHighlights:\n\r\n- Solid 10k yellow gold\n\r\n- Adjustable length\n\r\n- Italian\n\r\n\n\r\nDimensions:\n\r\n19 inches in length. 1mm width.",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "etsy.ivy_and_rose.category_id": "1196",
        "shopify.ivy_and_rose.vendor": null,
        "product.cost": "10",
        "prop.Y29yZT1TaXphYmxlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1NaW4rc2l6ZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "4",
        "prop.Y29yZT1NYWluK3N0b25lK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1JdGVtK3R5cGUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Bracelet - Chain (No Stones)",
        "prop.Y29yZT1FcmEmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Modern",
        "product.internal_title": null,
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY29sb3ImZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "shopify.ilya_fine_jewelry.price": null,
        "prop.Y29yZT1Db2xsZWN0aW9uJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "product.notes": "1.0mm width, (Easily self adjustable lenght, nice thickness, well made! made in Italy)",
        "shopify.ilya_fine_jewelry.title": null,
        "product.stock_number": null,
        "etsy.ivy_and_rose.when_made": "2010_2019",
        "prop.Y29yZT1DYW4rYmUrcGVyc29uYWxpemVkJmViYXk9JmV0c3k9JnNob3BpZnk9": "Yes",
        "product.location": "some",
        "shopify.ilya_fine_jewelry.seo_title": null,
        "prop.Y29yZT1OYXR1cmFsJTJGbGFiLWNyZWF0ZWQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "ebay.ivy_and_rose.store_category2_id": "14736770",
        "shopify.ilya_fine_jewelry.vendor": null,
        "shopify.ivy_and_rose.seo_title": null,
        "prop.Y29yZT1HZW1zdG9uZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1TaWRlK3N0b25lJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "shopify.ilya_fine_jewelry.collection_ids": [],
        "shopify.ilya_fine_jewelry.tags": [],
        "prop.Y29yZT1TaWRlK3N0b25lK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "ebay.ivy_and_rose.category_id": "261988",
        "prop.Y29yZT1NYWRlK3RvK29yZGVyJmViYXk9JmV0c3k9JnNob3BpZnk9": "No",
        "product.availability": 1,
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrc2hhcGUmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY2xhcml0eSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1TaWRlK3N0b25lK2NsYXJpdHkmZWJheT0mZXRzeT0mc2hvcGlmeT0": null
      },
      "id": "981a46dc-57f0-4cf8-869e-593df8561437",
      "sku": "19639"
    },
    {
      "data": {
        "prop.Y29yZT1Ub3RhbCtjYXJhdCt3ZWlnaHQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1SZWNpcGllbnQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Women",
        "shopify.ivy_and_rose.collection_ids": [],
        "shopify.ivy_and_rose.tags": [],
        "prop.Y29yZT1TZXR0aW5nK3N0eWxlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1EaWFtb25kK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "shopify.ilya_fine_jewelry.product_type": null,
        "prop.Y29yZT1DbGFyaXR5JmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1TaWRlK3N0b25lK3NoYXBlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "etsy.ivy_and_rose.who_made": "i_did",
        "prop.Y29yZT1TaXplJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1DZW50ZXIrbWluK3dpZHRoK2ZpdCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "etsy.ivy_and_rose.price": "50",
        "prop.Y29yZT1DZXJ0aWZpY2F0aW9uJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "etsy.ivy_and_rose.title": "14K Yellow Gold Small Hoop Earrings Minimalist Simple Classic Huggie Everyday",
        "ebay.ivy_and_rose.store_category_id": "14737505",
        "product.main_style": "Hoop &amp; Huggie",
        "product.sku": "19625",
        "shopify.ivy_and_rose.title": null,
        "shopify.ivy_and_rose.price": null,
        "prop.Y29yZT1TaWRlK3N0b25lcytxdWFudGl0eSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "etsy.ivy_and_rose.is_supply": false,
        "prop.Y29yZT1HcmFtK3dlaWdodCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "0.8 grams",
        "prop.Y29yZT1DZW50ZXIrbWF4K2xlbmd0aCtmaXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "ebay.ivy_and_rose.condition": "NEW",
        "prop.Y29yZT1TZWNvbmRhcnkrc3RvbmUmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1TaWRlK3N0b25lK2NhcmF0K3dlaWdodCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1SaW5nK3NpemUmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1NZXRhbCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "14k Yellow Gold",
        "prop.Y29yZT1UaGVtZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1BcHByb3hpbWF0ZSt0aW1lK3BlcmlvZCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "New",
        "prop.Y29yZT1NYWluK3N0b25lK3NoYXBlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1DZW50ZXIrbWluK2xlbmd0aCtmaXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "ebay.ivy_and_rose.secondary_category_id": "262008",
        "prop.Y29yZT1NYXgrc2l6ZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "10",
        "prop.Y29yZT1GYW5jeStkaWFtb25kK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "etsy.ivy_and_rose.shop_section_id": "17821498",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY2FyYXQrd2VpZ2h0JmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1DdXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1NYXRlcmlhbCttdWx0aSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "14k Yellow Gold",
        "prop.Y29yZT1DZW50ZXIrbWF4K3dpZHRoK2ZpdCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "ebay.ivy_and_rose.sub_title": null,
        "product.additional_styles": [],
        "product.price": "50",
        "prop.Y29yZT1NZXRhbCtwdXJpdHkmZWJheT0mZXRzeT0mc2hvcGlmeT0": "14k",
        "product.title": "14K YELLOW GOLD SMALL HOOP EARRINGS MINIMALIST SIMPLE CLASSIC HUGGIE EVERYDAY",
        "prop.Y29yZT1UcmVhdG1lbnQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1NYWluK3N0b25lJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1TdHlsZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1PY2Nhc2lvbiZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "shopify.ivy_and_rose.product_type": null,
        "ebay.ivy_and_rose.price": "50",
        "product.description": "Why we love it: \n\r\nThe perfect everyday piece! These small 14k yellow gold hoops are the perfect minimalist touch that you can throw on with any outfit. \n\r\n\n\r\nHighlights:\n\r\n- 14k yellow gold\n\r\n- Simple and classic\n\r\n\n\r\nDimensions:\n\r\n17.1mm from top to bottom. 1.6mm in width",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "etsy.ivy_and_rose.category_id": "1208",
        "shopify.ivy_and_rose.vendor": null,
        "product.cost": "10",
        "prop.Y29yZT1TaXphYmxlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1NaW4rc2l6ZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "4",
        "prop.Y29yZT1NYWluK3N0b25lK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1JdGVtK3R5cGUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Drop Earrings - Hoop (New)",
        "prop.Y29yZT1FcmEmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Modern",
        "product.internal_title": null,
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY29sb3ImZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "shopify.ilya_fine_jewelry.price": null,
        "prop.Y29yZT1Db2xsZWN0aW9uJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "product.notes": "17.1 x 1.6",
        "shopify.ilya_fine_jewelry.title": null,
        "product.stock_number": null,
        "etsy.ivy_and_rose.when_made": "2010_2019",
        "prop.Y29yZT1DYW4rYmUrcGVyc29uYWxpemVkJmViYXk9JmV0c3k9JnNob3BpZnk9": "Yes",
        "product.location": "some",
        "shopify.ilya_fine_jewelry.seo_title": null,
        "prop.Y29yZT1OYXR1cmFsJTJGbGFiLWNyZWF0ZWQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "ebay.ivy_and_rose.store_category2_id": "14736887",
        "shopify.ilya_fine_jewelry.vendor": null,
        "shopify.ivy_and_rose.seo_title": null,
        "prop.Y29yZT1HZW1zdG9uZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1TaWRlK3N0b25lJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "shopify.ilya_fine_jewelry.collection_ids": [],
        "shopify.ilya_fine_jewelry.tags": [],
        "prop.Y29yZT1TaWRlK3N0b25lK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "ebay.ivy_and_rose.category_id": "261990",
        "prop.Y29yZT1NYWRlK3RvK29yZGVyJmViYXk9JmV0c3k9JnNob3BpZnk9": "No",
        "product.availability": 1,
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrc2hhcGUmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY2xhcml0eSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1TaWRlK3N0b25lK2NsYXJpdHkmZWJheT0mZXRzeT0mc2hvcGlmeT0": null
      },
      "id": "56130d5f-b533-4439-8eaa-1b8756cd497a",
      "sku": "19625"
    },
    {
      "data": {
        "prop.Y29yZT1Ub3RhbCtjYXJhdCt3ZWlnaHQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1SZWNpcGllbnQmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Women",
        "shopify.ivy_and_rose.collection_ids": [],
        "shopify.ivy_and_rose.tags": [],
        "prop.Y29yZT1TZXR0aW5nK3N0eWxlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1EaWFtb25kK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "shopify.ilya_fine_jewelry.product_type": null,
        "prop.Y29yZT1DbGFyaXR5JmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1TaWRlK3N0b25lK3NoYXBlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "etsy.ivy_and_rose.who_made": "i_did",
        "prop.Y29yZT1TaXplJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1DZW50ZXIrbWluK3dpZHRoK2ZpdCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "etsy.ivy_and_rose.price": "50",
        "prop.Y29yZT1DZXJ0aWZpY2F0aW9uJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "etsy.ivy_and_rose.title": "14K Yellow Gold Oval Hoop Earrings Twist Simple Classic Everyday Big Lightweight",
        "ebay.ivy_and_rose.store_category_id": "14737505",
        "product.main_style": "Hoop &amp; Huggie",
        "product.sku": "19621",
        "shopify.ivy_and_rose.title": null,
        "shopify.ivy_and_rose.price": null,
        "prop.Y29yZT1TaWRlK3N0b25lcytxdWFudGl0eSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "etsy.ivy_and_rose.is_supply": false,
        "prop.Y29yZT1HcmFtK3dlaWdodCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "2.9 grams",
        "prop.Y29yZT1DZW50ZXIrbWF4K2xlbmd0aCtmaXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "ebay.ivy_and_rose.condition": "NEW",
        "prop.Y29yZT1TZWNvbmRhcnkrc3RvbmUmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1TaWRlK3N0b25lK2NhcmF0K3dlaWdodCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1SaW5nK3NpemUmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1NZXRhbCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "14k Yellow Gold",
        "prop.Y29yZT1UaGVtZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1BcHByb3hpbWF0ZSt0aW1lK3BlcmlvZCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "New",
        "prop.Y29yZT1NYWluK3N0b25lK3NoYXBlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1DZW50ZXIrbWluK2xlbmd0aCtmaXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "ebay.ivy_and_rose.secondary_category_id": "262008",
        "prop.Y29yZT1NYXgrc2l6ZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "10",
        "prop.Y29yZT1GYW5jeStkaWFtb25kK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "etsy.ivy_and_rose.shop_section_id": "17821498",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY2FyYXQrd2VpZ2h0JmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1DdXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1NYXRlcmlhbCttdWx0aSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "14k Yellow Gold",
        "prop.Y29yZT1DZW50ZXIrbWF4K3dpZHRoK2ZpdCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "ebay.ivy_and_rose.sub_title": null,
        "product.additional_styles": [],
        "product.price": "50",
        "prop.Y29yZT1NZXRhbCtwdXJpdHkmZWJheT0mZXRzeT0mc2hvcGlmeT0": "14k",
        "product.title": "14K YELLOW GOLD OVAL HOOP EARRINGS TWIST SIMPLE CLASSIC EVERYDAY BIG LIGHTWEIGHT",
        "prop.Y29yZT1UcmVhdG1lbnQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1NYWluK3N0b25lJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1TdHlsZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1PY2Nhc2lvbiZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "shopify.ivy_and_rose.product_type": null,
        "ebay.ivy_and_rose.price": "50",
        "product.description": "Why we love it: \n\r\nThese elegant 14k yellow gold hoops have a twisted texture for a unique touch while maintaining a classic aesthetic!\n\r\n\n\r\nHighlights:\n\r\n- 14k yellow gold\n\r\n- Large look, but lightweight for easy wear\n\r\n\n\r\nDimensions:\n\r\n30.6mm from top to bottom. 3.8mm in width",
        "prop.Y29yZT1DZW50ZXIrc3RvbmUmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "etsy.ivy_and_rose.category_id": "1208",
        "shopify.ivy_and_rose.vendor": null,
        "product.cost": "10",
        "prop.Y29yZT1TaXphYmxlJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1NaW4rc2l6ZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": "4",
        "prop.Y29yZT1NYWluK3N0b25lK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "prop.Y29yZT1JdGVtK3R5cGUmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Drop Earrings - Hoop (New)",
        "prop.Y29yZT1FcmEmZWJheT0mZXRzeT0mc2hvcGlmeT0": "Modern",
        "product.internal_title": null,
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY29sb3ImZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "shopify.ilya_fine_jewelry.price": null,
        "prop.Y29yZT1Db2xsZWN0aW9uJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "product.notes": "30.6 x 3.8 (Big size, lightweight)",
        "shopify.ilya_fine_jewelry.title": null,
        "product.stock_number": null,
        "etsy.ivy_and_rose.when_made": "2010_2019",
        "prop.Y29yZT1DYW4rYmUrcGVyc29uYWxpemVkJmViYXk9JmV0c3k9JnNob3BpZnk9": "Yes",
        "product.location": "some",
        "shopify.ilya_fine_jewelry.seo_title": null,
        "prop.Y29yZT1OYXR1cmFsJTJGbGFiLWNyZWF0ZWQmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "ebay.ivy_and_rose.store_category2_id": "14736887",
        "shopify.ilya_fine_jewelry.vendor": null,
        "shopify.ivy_and_rose.seo_title": null,
        "prop.Y29yZT1HZW1zdG9uZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1TaWRlK3N0b25lJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "shopify.ilya_fine_jewelry.collection_ids": [],
        "shopify.ilya_fine_jewelry.tags": [],
        "prop.Y29yZT1TaWRlK3N0b25lK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": null,
        "ebay.ivy_and_rose.category_id": "261990",
        "prop.Y29yZT1NYWRlK3RvK29yZGVyJmViYXk9JmV0c3k9JnNob3BpZnk9": "No",
        "product.availability": 1,
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrc2hhcGUmZWJheT0mZXRzeT0mc2hvcGlmeT0": null,
        "prop.Y29yZT1DZW50ZXIrc3RvbmUrY2xhcml0eSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": null,
        "prop.Y29yZT1TaWRlK3N0b25lK2NsYXJpdHkmZWJheT0mZXRzeT0mc2hvcGlmeT0": null
      },
      "id": "242b99df-4a3e-4433-b302-066a89061015",
      "sku": "19621"
    }
  ]

  const fields = {
    "prop.Y29yZT1Ub3RhbCtjYXJhdCt3ZWlnaHQmZWJheT0mZXRzeT0mc2hvcGlmeT0": {
      "id": "prop.Y29yZT1Ub3RhbCtjYXJhdCt3ZWlnaHQmZWJheT0mZXRzeT0mc2hvcGlmeT0",
      "name": "Property -> Total carat weight",
      "options": [],
      "params": {
        "max_length": 65,
        "max_values": 1
      },
      "type": "string"
    },
    "prop.Y29yZT1SZWNpcGllbnQmZWJheT0mZXRzeT0mc2hvcGlmeT0": {
      "id": "prop.Y29yZT1SZWNpcGllbnQmZWJheT0mZXRzeT0mc2hvcGlmeT0",
      "name": "Property -> Recipient",
      "options": [],
      "params": {
        "max_length": 65,
        "max_values": 1
      },
      "type": "string"
    },
    "shopify.ivy_and_rose.collection_ids": {
      "id": "shopify.ivy_and_rose.collection_ids",
      "name": "Shopify -> Collections [Ivy & Rose]",
      "options": {
        "267204886665": "Home page",
        "270679998601": "Setting and Finishing",
        "278998057097": "Lab Created Diamond Flash Sale",
        "281466994825": "Gift Guide: Original Vintage",
        "281664716937": "Sapphire Solitaire Pendants",
        "281669795977": "0.20ct Diamond Solitaire Pendants",
        "284094234761": "Gemstone Stacking Bands"
      },
      "params": {
        "is_select": true,
        "max_values": 10
      },
      "type": "string"
    },
    "shopify.ivy_and_rose.tags": {
      "id": "shopify.ivy_and_rose.tags",
      "name": "Shopify -> Tags [Ivy & Rose]",
      "options": [
        "tag"
      ],
      "params": {
        "max_values": 10
      },
      "type": "string"
    },
    "prop.Y29yZT1TZXR0aW5nK3N0eWxlJmViYXk9JmV0c3k9JnNob3BpZnk9": {
      "id": "prop.Y29yZT1TZXR0aW5nK3N0eWxlJmViYXk9JmV0c3k9JnNob3BpZnk9",
      "name": "Property -> Setting style",
      "options": [],
      "params": {
        "max_length": 65,
        "max_values": 1
      },
      "type": "string"
    },
    "prop.Y29yZT1EaWFtb25kK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": {
      "id": "prop.Y29yZT1EaWFtb25kK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9",
      "name": "Property -> Diamond color",
      "options": [],
      "params": {
        "max_length": 65,
        "max_values": 1
      },
      "type": "string"
    },
    "shopify.ilya_fine_jewelry.product_type": {
      "id": "shopify.ilya_fine_jewelry.product_type",
      "name": "Shopify -> Product type [Ilya Fine Jewelry]",
      "options": [
        "Ring",
        "Earring",
        "Loose Stone",
        "Necklace",
        "Loose Studs",
        "virtual"
      ],
      "params": {},
      "type": "string"
    },
    "prop.Y29yZT1DbGFyaXR5JmViYXk9JmV0c3k9JnNob3BpZnk9": {
      "id": "prop.Y29yZT1DbGFyaXR5JmViYXk9JmV0c3k9JnNob3BpZnk9",
      "name": "Property -> Clarity",
      "options": [],
      "params": {
        "max_length": 65,
        "max_values": 1
      },
      "type": "string"
    },
    "prop.Y29yZT1TaWRlK3N0b25lK3NoYXBlJmViYXk9JmV0c3k9JnNob3BpZnk9": {
      "id": "prop.Y29yZT1TaWRlK3N0b25lK3NoYXBlJmViYXk9JmV0c3k9JnNob3BpZnk9",
      "name": "Property -> Side stone shape",
      "options": [],
      "params": {
        "max_length": 65,
        "max_values": 1
      },
      "type": "string"
    },
    "etsy.ivy_and_rose.who_made": {
      "id": "etsy.ivy_and_rose.who_made",
      "name": "Etsy -> Who made",
      "options": {
        "collective": "Collective",
        "i_did": "I did",
        "someone_else": "Someone else"
      },
      "params": {
        "is_select": true,
        "required": true
      },
      "type": "string"
    },
    "prop.Y29yZT1TaXplJmViYXk9JmV0c3k9JnNob3BpZnk9": {
      "id": "prop.Y29yZT1TaXplJmViYXk9JmV0c3k9JnNob3BpZnk9",
      "name": "Property -> Size",
      "options": [],
      "params": {
        "max_length": 65,
        "max_values": 1
      },
      "type": "string"
    },
    "etsy.ivy_and_rose.price": {
      "id": "etsy.ivy_and_rose.price",
      "name": "Etsy -> Price",
      "params": {
        "prefix": "$",
        "min": 0,
        "step": 0.01
      },
      "type": "number"
    },
    "prop.Y29yZT1DZXJ0aWZpY2F0aW9uJmViYXk9JmV0c3k9JnNob3BpZnk9": {
      "id": "prop.Y29yZT1DZXJ0aWZpY2F0aW9uJmViYXk9JmV0c3k9JnNob3BpZnk9",
      "name": "Property -> Certification",
      "options": [],
      "params": {
        "max_length": 65,
        "max_values": 1
      },
      "type": "string"
    },
    "etsy.ivy_and_rose.title": {
      "id": "etsy.ivy_and_rose.title",
      "name": "Etsy -> Title",
      "params": {
        "max_length": 140
      },
      "type": "string"
    },
    "ebay.ivy_and_rose.store_category_id": {
      "id": "ebay.ivy_and_rose.store_category_id",
      "name": "Ebay -> Store category",
      "options": {
        "1": "Other",
        "5809673": "Necklaces/Pendants",
        "5979469": "Earrings",
        "5979473": "Bracelets",
        "6106058": "Gemstone Rings",
        "8154825": "Men's Rings",
        "13991579": "Watches",
        "14736767": "Ladies Diamond Rings",
        "14736768": "Vintage & Antique",
        "14736769": "Loose Diamonds",
        "14736770": "Chains",
        "14736792": "Ladies Diamond Rings/Engagement Rings",
        "14736870": "Ladies Diamond Rings/Diamond Fashion Rings",
        "14736871": "Ladies Diamond Rings/Settings/Semi-Mounts",
        "14736872": "Ladies Diamond Rings/3 Stone Diamond Rings",
        "14736886": "Vintage & Antique/Vintage/Antique Rings",
        "14736887": "Vintage & Antique/Vintage/Antique Earrings",
        "14736888": "Vintage & Antique/Vintage/Antique Bracelets",
        "14736889": "Vintage & Antique/Other Vintage/Antique Jewelry",
        "14737504": "Earrings/Stud Earrings",
        "14737505": "Earrings/Exquisite Earrings",
        "14843984": "Ladies Diamond Rings/Anniversary/Wedding Bands"
      },
      "params": {
        "is_select": true
      },
      "type": "string"
    },
    "product.main_style": {
      "id": "product.main_style",
      "name": "Main style",
      "params": {
        "max_length": 255
      },
      "type": "string"
    },
    "product.sku": {
      "id": "product.sku",
      "name": "Sku",
      "params": {
        "max_length": 255,
        "required": true
      },
      "type": "string"
    },
    "shopify.ivy_and_rose.title": {
      "id": "shopify.ivy_and_rose.title",
      "name": "Shopify -> Title [Ivy & Rose]",
      "params": {
        "max_length": 250
      },
      "type": "string"
    },
    "shopify.ivy_and_rose.price": {
      "id": "shopify.ivy_and_rose.price",
      "name": "Shopify -> Price [Ivy & Rose]",
      "params": {
        "prefix": "$",
        "step": 0.01,
        "min": 0
      },
      "type": "number"
    },
    "prop.Y29yZT1TaWRlK3N0b25lcytxdWFudGl0eSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": {
      "id": "prop.Y29yZT1TaWRlK3N0b25lcytxdWFudGl0eSZlYmF5PSZldHN5PSZzaG9waWZ5PQ",
      "name": "Property -> Side stones quantity",
      "options": [],
      "params": {
        "max_length": 65,
        "max_values": 1
      },
      "type": "string"
    },
    "etsy.ivy_and_rose.is_supply": {
      "id": "etsy.ivy_and_rose.is_supply",
      "name": "Etsy -> Is supply",
      "options": {
        "false": "No",
        "true": "Yes"
      },
      "params": {
        "is_select": true
      },
      "type": "string"
    },
    "prop.Y29yZT1HcmFtK3dlaWdodCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": {
      "id": "prop.Y29yZT1HcmFtK3dlaWdodCZlYmF5PSZldHN5PSZzaG9waWZ5PQ",
      "name": "Property -> Gram weight",
      "options": [],
      "params": {
        "max_length": 65,
        "max_values": 1
      },
      "type": "string"
    },
    "ebay.ivy_and_rose.condition": {
      "id": "ebay.ivy_and_rose.condition",
      "name": "Ebay -> Condition",
      "options": {
        "CERTIFIED_REFURBISHED": "Certified refurbished",
        "EXCELLENT_REFURBISHED": "Excellent refurbished",
        "FOR_PARTS_OR_NOT_WORKING": "For parts or not working",
        "GOOD_REFURBISHED": "Good refurbished",
        "LIKE_NEW": "Like new",
        "MANUFACTURER_REFURBISHED": "Manufacturer refurbished",
        "NEW": "New",
        "NEW_OTHER": "New other",
        "NEW_WITH_DEFECTS": "New with defects",
        "SELLER_REFURBISHED": "Seller refurbished",
        "USED_ACCEPTABLE": "Used acceptable",
        "USED_EXCELLENT": "Used excellent",
        "USED_GOOD": "Used good",
        "USED_VERY_GOOD": "Used very good",
        "VERY_GOOD_REFURBISHED": "Very good refurbished"
      },
      "params": {
        "is_select": true
      },
      "type": "string"
    },
    "prop.Y29yZT1TZWNvbmRhcnkrc3RvbmUmZWJheT0mZXRzeT0mc2hvcGlmeT0": {
      "id": "prop.Y29yZT1TZWNvbmRhcnkrc3RvbmUmZWJheT0mZXRzeT0mc2hvcGlmeT0",
      "name": "Property -> Secondary stone",
      "options": [],
      "params": {
        "max_length": 65,
        "max_values": 1
      },
      "type": "string"
    },
    "prop.Y29yZT1TaWRlK3N0b25lK2NhcmF0K3dlaWdodCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": {
      "id": "prop.Y29yZT1TaWRlK3N0b25lK2NhcmF0K3dlaWdodCZlYmF5PSZldHN5PSZzaG9waWZ5PQ",
      "name": "Property -> Side stone carat weight",
      "options": [],
      "params": {
        "max_length": 65,
        "max_values": 1
      },
      "type": "string"
    },
    "prop.Y29yZT1SaW5nK3NpemUmZWJheT0mZXRzeT0mc2hvcGlmeT0": {
      "id": "prop.Y29yZT1SaW5nK3NpemUmZWJheT0mZXRzeT0mc2hvcGlmeT0",
      "name": "Property -> Ring size",
      "options": [
        "0.25",
        "0.5",
        "0.75",
        "1",
        "1.25",
        "1.5",
        "1.75",
        "2",
        "2.25",
        "2.5",
        "2.75",
        "3",
        "3.25",
        "3.5",
        "3.75",
        "4",
        "4.25",
        "4.5",
        "4.75",
        "5",
        "5.25",
        "5.5",
        "5.75",
        "6",
        "6.25",
        "6.5",
        "6.75",
        "7",
        "7.25",
        "7.5",
        "7.75",
        "8",
        "8.25",
        "8.5",
        "8.75",
        "9",
        "9.25",
        "9.5",
        "9.75",
        "10",
        "10.25",
        "10.5",
        "10.75",
        "11",
        "11.25",
        "11.5",
        "11.75",
        "12",
        "12.25",
        "12.5",
        "12.75",
        "13",
        "13.25",
        "13.5",
        "13.75",
        "14",
        "14.25",
        "14.5",
        "14.75",
        "15",
        "15.25",
        "15.5",
        "15.75",
        "16"
      ],
      "params": {
        "max_length": 65,
        "max_values": 1
      },
      "type": "string"
    },
    "prop.Y29yZT1NZXRhbCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": {
      "id": "prop.Y29yZT1NZXRhbCZlYmF5PSZldHN5PSZzaG9waWZ5PQ",
      "name": "Property -> Metal",
      "options": [],
      "params": {
        "max_length": 65,
        "max_values": 1
      },
      "type": "string"
    },
    "prop.Y29yZT1UaGVtZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": {
      "id": "prop.Y29yZT1UaGVtZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ",
      "name": "Property -> Theme",
      "options": [],
      "params": {
        "max_length": 65,
        "max_values": 1
      },
      "type": "string"
    },
    "prop.Y29yZT1BcHByb3hpbWF0ZSt0aW1lK3BlcmlvZCZlYmF5PSZldHN5PSZzaG9waWZ5PQ": {
      "id": "prop.Y29yZT1BcHByb3hpbWF0ZSt0aW1lK3BlcmlvZCZlYmF5PSZldHN5PSZzaG9waWZ5PQ",
      "name": "Property -> Approximate time period",
      "options": [
        "Vintage Inspired",
        "Modern",
        "Original Vintage"
      ],
      "params": {
        "max_length": 65,
        "max_values": 1
      },
      "type": "string"
    },
    "prop.Y29yZT1NYWluK3N0b25lK3NoYXBlJmViYXk9JmV0c3k9JnNob3BpZnk9": {
      "id": "prop.Y29yZT1NYWluK3N0b25lK3NoYXBlJmViYXk9JmV0c3k9JnNob3BpZnk9",
      "name": "Property -> Main stone shape",
      "options": [],
      "params": {
        "max_length": 65,
        "max_values": 1
      },
      "type": "string"
    },
    "ebay.ivy_and_rose.secondary_category_id": {
      "id": "ebay.ivy_and_rose.secondary_category_id",
      "name": "Ebay -> Secondary category",
      "options": {
        "494": "Jewelry & Watches -> Jewelry Care, Design & Repair -> Other Jewelry Care, Design & Repair",
        "10292": "Jewelry & Watches -> Men's Jewelry -> Bolo Ties",
        "67681": "Jewelry & Watches -> Fashion Jewelry -> Rings",
        "110655": "Jewelry & Watches -> Handcrafted & Artisan Jewelry -> Necklaces & Pendants",
        "137839": "Jewelry & Watches -> Men's Jewelry -> Necklaces & Pendants",
        "137856": "Jewelry & Watches -> Men's Jewelry -> Rings",
        "166734": "Jewelry & Watches -> Vintage & Antique Jewelry -> Other Vintage & Antique Jewelry",
        "261975": "Jewelry & Watches -> Engagement & Wedding -> Engagement Rings",
        "261976": "Jewelry & Watches -> Engagement & Wedding -> Engagement & Wedding Ring Sets",
        "261977": "Jewelry & Watches -> Engagement & Wedding -> Wedding & Anniversary Bands",
        "261984": "Jewelry & Watches -> Ethnic, Regional & Tribal -> Necklaces & Pendants",
        "261987": "Jewelry & Watches -> Fashion Jewelry -> Bracelets & Charms",
        "261988": "Jewelry & Watches -> Fine Jewelry -> Bracelets & Charms",
        "261990": "Jewelry & Watches -> Fine Jewelry -> Earrings",
        "261992": "Jewelry & Watches -> Fine Jewelry -> Jewelry Sets",
        "261993": "Jewelry & Watches -> Fine Jewelry -> Necklaces & Pendants",
        "261994": "Jewelry & Watches -> Fine Jewelry -> Rings",
        "261999": "Jewelry & Watches -> Men's Jewelry -> Bracelets & Charms",
        "262003": "Jewelry & Watches -> Vintage & Antique Jewelry -> Bracelets & Charms",
        "262004": "Jewelry & Watches -> Vintage & Antique Jewelry -> Brooches & Pins",
        "262005": "Jewelry & Watches -> Vintage & Antique Jewelry -> Cameos",
        "262006": "Jewelry & Watches -> Vintage & Antique Jewelry -> Cufflinks",
        "262008": "Jewelry & Watches -> Vintage & Antique Jewelry -> Earrings",
        "262013": "Jewelry & Watches -> Vintage & Antique Jewelry -> Necklaces & Pendants",
        "262014": "Jewelry & Watches -> Vintage & Antique Jewelry -> Rings",
        "262019": "Jewelry & Watches -> Jewelry Care, Design & Repair -> Jewelry Settings",
        "262020": "Jewelry & Watches -> Jewelry Care, Design & Repair -> Jewelry Tools & Workbenches",
        "262026": "Jewelry & Watches -> Loose Diamonds & Gemstones -> Loose Diamonds",
        "262027": "Jewelry & Watches -> Loose Diamonds & Gemstones -> Loose Gemstones"
      },
      "params": {
        "is_select": true
      },
      "type": "string"
    },
    "prop.Y29yZT1NYXgrc2l6ZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": {
      "id": "prop.Y29yZT1NYXgrc2l6ZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ",
      "name": "Property -> Max size",
      "options": [],
      "params": {
        "max_length": 65,
        "max_values": 1
      },
      "type": "string"
    },
    "prop.Y29yZT1GYW5jeStkaWFtb25kK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": {
      "id": "prop.Y29yZT1GYW5jeStkaWFtb25kK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9",
      "name": "Property -> Fancy diamond color",
      "options": [],
      "params": {
        "max_length": 65,
        "max_values": 1
      },
      "type": "string"
    },
    "etsy.ivy_and_rose.shop_section_id": {
      "id": "etsy.ivy_and_rose.shop_section_id",
      "name": "Etsy -> Shop section",
      "options": {
        "17500487": "Engagement/Wedding ",
        "17746504": "Art Deco 1920's-1940's",
        "17821498": "Earrings ",
        "17821976": "Art Nouveau 1900-1920's",
        "17822028": "Bands ",
        "17825239": "Victorian 1880's-1890's",
        "17825259": "I&R Designed Settings",
        "17865866": "Bracelets ",
        "17869125": "Pendants/Necklaces ",
        "18036037": "Loose Diamonds",
        "20281911": "I&R Originals",
        "21991708": "One of a Kind",
        "22003527": "Loose Gemstones"
      },
      "params": {
        "is_select": true
      },
      "type": "string"
    },
    "prop.Y29yZT1DZW50ZXIrc3RvbmUrY2FyYXQrd2VpZ2h0JmViYXk9JmV0c3k9JnNob3BpZnk9": {
      "id": "prop.Y29yZT1DZW50ZXIrc3RvbmUrY2FyYXQrd2VpZ2h0JmViYXk9JmV0c3k9JnNob3BpZnk9",
      "name": "Property -> Center stone carat weight",
      "options": [],
      "params": {
        "max_length": 65,
        "max_values": 1
      },
      "type": "string"
    },
    "prop.Y29yZT1DdXQmZWJheT0mZXRzeT0mc2hvcGlmeT0": {
      "id": "prop.Y29yZT1DdXQmZWJheT0mZXRzeT0mc2hvcGlmeT0",
      "name": "Property -> Cut",
      "options": [],
      "params": {
        "max_length": 65,
        "max_values": 1
      },
      "type": "string"
    },
    "prop.Y29yZT1NYXRlcmlhbCttdWx0aSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": {
      "id": "prop.Y29yZT1NYXRlcmlhbCttdWx0aSZlYmF5PSZldHN5PSZzaG9waWZ5PQ",
      "name": "Property -> Material",
      "options": [],
      "params": {
        "max_length": 65,
        "max_values": 1
      },
      "type": "string"
    },
    "ebay.ivy_and_rose.sub_title": {
      "id": "ebay.ivy_and_rose.sub_title",
      "name": "Ebay -> Sub title",
      "params": {
        "max_length": 55
      },
      "type": "string"
    },
    "product.additional_styles": {
      "id": "product.additional_styles",
      "name": "Additional styles",
      "params": {
        "max_length": 255,
        "max_values": 10
      },
      "type": "string"
    },
    "product.price": {
      "id": "product.price",
      "name": "Price",
      "params": {
        "prefix": "$",
        "min": 0,
        "step": 0.01,
        "required": true
      },
      "type": "number"
    },
    "prop.Y29yZT1NZXRhbCtwdXJpdHkmZWJheT0mZXRzeT0mc2hvcGlmeT0": {
      "id": "prop.Y29yZT1NZXRhbCtwdXJpdHkmZWJheT0mZXRzeT0mc2hvcGlmeT0",
      "name": "Property -> Metal purity",
      "options": [],
      "params": {
        "max_length": 65,
        "max_values": 1
      },
      "type": "string"
    },
    "product.title": {
      "id": "product.title",
      "name": "Title",
      "params": {
        "max_length": 80,
        "required": true
      },
      "type": "string"
    },
    "prop.Y29yZT1UcmVhdG1lbnQmZWJheT0mZXRzeT0mc2hvcGlmeT0": {
      "id": "prop.Y29yZT1UcmVhdG1lbnQmZWJheT0mZXRzeT0mc2hvcGlmeT0",
      "name": "Property -> Treatment",
      "options": [],
      "params": {
        "max_length": 65,
        "max_values": 1
      },
      "type": "string"
    },
    "prop.Y29yZT1NYWluK3N0b25lJmViYXk9JmV0c3k9JnNob3BpZnk9": {
      "id": "prop.Y29yZT1NYWluK3N0b25lJmViYXk9JmV0c3k9JnNob3BpZnk9",
      "name": "Property -> Main stone",
      "options": [],
      "params": {
        "max_length": 65,
        "max_values": 1
      },
      "type": "string"
    },
    "prop.Y29yZT1TdHlsZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": {
      "id": "prop.Y29yZT1TdHlsZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ",
      "name": "Property -> Style",
      "options": [],
      "params": {
        "max_length": 65,
        "max_values": 1
      },
      "type": "string"
    },
    "prop.Y29yZT1PY2Nhc2lvbiZlYmF5PSZldHN5PSZzaG9waWZ5PQ": {
      "id": "prop.Y29yZT1PY2Nhc2lvbiZlYmF5PSZldHN5PSZzaG9waWZ5PQ",
      "name": "Property -> Occasion",
      "options": [],
      "params": {
        "max_length": 65,
        "max_values": 1
      },
      "type": "string"
    },
    "shopify.ivy_and_rose.product_type": {
      "id": "shopify.ivy_and_rose.product_type",
      "name": "Shopify -> Product type [Ivy & Rose]",
      "options": [
        "Ring",
        "Earring",
        "Loose Stone",
        "Necklace",
        "Loose Studs",
        "virtual"
      ],
      "params": {},
      "type": "string"
    },
    "ebay.ivy_and_rose.price": {
      "id": "ebay.ivy_and_rose.price",
      "name": "Ebay -> Price",
      "params": {
        "prefix": "$",
        "min": 0,
        "step": 0.01
      },
      "type": "number"
    },
    "product.description": {
      "id": "product.description",
      "name": "Description",
      "params": {
        "max_length": 800,
        "required": true
      },
      "type": "string"
    },
    "prop.Y29yZT1DZW50ZXIrc3RvbmUmZWJheT0mZXRzeT0mc2hvcGlmeT0": {
      "id": "prop.Y29yZT1DZW50ZXIrc3RvbmUmZWJheT0mZXRzeT0mc2hvcGlmeT0",
      "name": "Property -> Center stone",
      "options": [],
      "params": {
        "max_length": 65,
        "max_values": 1
      },
      "type": "string"
    },
    "etsy.ivy_and_rose.category_id": {
      "id": "etsy.ivy_and_rose.category_id",
      "name": "Etsy -> Category",
      "options": {
        "1": "Accessories",
        "2": "Accessories -> Belts & Suspenders",
        "1179": "Jewelry",
        "1193": "Jewelry -> Bracelets",
        "1194": "Jewelry -> Bracelets -> Bangles",
        "1196": "Jewelry -> Bracelets -> Chain & Link Bracelets",
        "1197": "Jewelry -> Bracelets -> Charm Bracelets",
        "1200": "Jewelry -> Bracelets -> Woven & Braided Bracelets",
        "1201": "Jewelry -> Brooches, Pins & Clips -> Brooches",
        "1203": "Jewelry -> Earrings",
        "1206": "Jewelry -> Earrings -> Cluster Earrings",
        "1208": "Jewelry -> Earrings -> Dangle & Drop Earrings",
        "1212": "Jewelry -> Earrings -> Hoop Earrings",
        "1214": "Jewelry -> Earrings -> Stud Earrings",
        "1217": "Jewelry -> Necklaces",
        "1220": "Jewelry -> Necklaces -> Cameo Necklaces",
        "1221": "Jewelry -> Necklaces -> Chains",
        "1225": "Jewelry -> Necklaces -> Lariat & Y Necklaces",
        "1227": "Jewelry -> Necklaces -> Monogram & Name Necklaces",
        "1229": "Jewelry -> Necklaces -> Pendant Necklaces",
        "1231": "Jewelry -> Rings",
        "1232": "Jewelry -> Rings -> Bands",
        "1233": "Jewelry -> Rings -> Signet Rings -> Fraternal & Class Rings",
        "1236": "Jewelry -> Rings -> Multi-Stone Rings",
        "1238": "Jewelry -> Rings -> Solitaire Rings",
        "1239": "Jewelry -> Rings -> Stackable Rings",
        "1240": "Jewelry -> Rings -> Statement Rings",
        "1241": "Jewelry -> Rings -> Wedding & Engagement",
        "1243": "Jewelry -> Rings -> Wedding & Engagement -> Bridal Sets",
        "1244": "Jewelry -> Rings -> Wedding & Engagement -> Claddagh Rings",
        "1245": "Jewelry -> Rings -> Wedding & Engagement -> Engagement Rings",
        "1247": "Jewelry -> Rings -> Wedding & Engagement -> Wedding Bands",
        "1684": "Weddings -> Jewelry -> Earrings",
        "1685": "Weddings -> Jewelry -> Jewelry Sets",
        "2803": "Accessories -> Hats & Caps -> Helmets -> Sports Helmets -> Baseball Helmets",
        "6648": "Craft Supplies & Tools -> Beads, Gems & Cabochons -> Gemstones"
      },
      "params": {
        "is_select": true,
        "required": true
      },
      "type": "string"
    },
    "shopify.ivy_and_rose.vendor": {
      "id": "shopify.ivy_and_rose.vendor",
      "name": "Shopify -> Vendor [Ivy & Rose]",
      "options": [
        "Ivy & Rose",
        "Ilya Fine Jewelry"
      ],
      "params": {},
      "type": "string"
    },
    "product.cost": {
      "id": "product.cost",
      "name": "Cost",
      "params": {
        "prefix": "$",
        "min": 0,
        "step": 0.01
      },
      "type": "number"
    },
    "prop.Y29yZT1TaXphYmxlJmViYXk9JmV0c3k9JnNob3BpZnk9": {
      "id": "prop.Y29yZT1TaXphYmxlJmViYXk9JmV0c3k9JnNob3BpZnk9",
      "name": "Property -> Sizable",
      "options": [],
      "params": {
        "max_length": 65,
        "max_values": 1
      },
      "type": "string"
    },
    "prop.Y29yZT1NaW4rc2l6ZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": {
      "id": "prop.Y29yZT1NaW4rc2l6ZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ",
      "name": "Property -> Min size",
      "options": [],
      "params": {
        "max_length": 65,
        "max_values": 1
      },
      "type": "string"
    },
    "prop.Y29yZT1NYWluK3N0b25lK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": {
      "id": "prop.Y29yZT1NYWluK3N0b25lK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9",
      "name": "Property -> Main stone color",
      "options": [],
      "params": {
        "max_length": 65,
        "max_values": 1
      },
      "type": "string"
    },
    "prop.Y29yZT1JdGVtK3R5cGUmZWJheT0mZXRzeT0mc2hvcGlmeT0": {
      "id": "prop.Y29yZT1JdGVtK3R5cGUmZWJheT0mZXRzeT0mc2hvcGlmeT0",
      "name": "Property -> Item type",
      "options": [],
      "params": {
        "max_length": 65,
        "max_values": 1
      },
      "type": "string"
    },
    "prop.Y29yZT1FcmEmZWJheT0mZXRzeT0mc2hvcGlmeT0": {
      "id": "prop.Y29yZT1FcmEmZWJheT0mZXRzeT0mc2hvcGlmeT0",
      "name": "Property -> Era",
      "options": [],
      "params": {
        "max_length": 65,
        "max_values": 1
      },
      "type": "string"
    },
    "product.internal_title": {
      "id": "product.internal_title",
      "name": "Searchable tag",
      "params": {
        "max_length": 255
      },
      "type": "string"
    },
    "prop.Y29yZT1DZW50ZXIrc3RvbmUrY29sb3ImZWJheT0mZXRzeT0mc2hvcGlmeT0": {
      "id": "prop.Y29yZT1DZW50ZXIrc3RvbmUrY29sb3ImZWJheT0mZXRzeT0mc2hvcGlmeT0",
      "name": "Property -> Center stone color",
      "options": [],
      "params": {
        "max_length": 65,
        "max_values": 1
      },
      "type": "string"
    },
    "shopify.ilya_fine_jewelry.price": {
      "id": "shopify.ilya_fine_jewelry.price",
      "name": "Shopify -> Price [Ilya Fine Jewelry]",
      "params": {
        "prefix": "$",
        "min": 0,
        "step": 0.01
      },
      "type": "number"
    },
    "prop.Y29yZT1Db2xsZWN0aW9uJmViYXk9JmV0c3k9JnNob3BpZnk9": {
      "id": "prop.Y29yZT1Db2xsZWN0aW9uJmViYXk9JmV0c3k9JnNob3BpZnk9",
      "name": "Property -> Collection",
      "options": [],
      "params": {
        "max_length": 65,
        "max_values": 1
      },
      "type": "string"
    },
    "product.notes": {
      "id": "product.notes",
      "name": "Notes",
      "params": {
        "max_length": 800
      },
      "type": "string"
    },
    "shopify.ilya_fine_jewelry.title": {
      "id": "shopify.ilya_fine_jewelry.title",
      "name": "Shopify -> Title [Ilya Fine Jewelry]",
      "params": {
        "max_length": 250
      },
      "type": "string"
    },
    "product.stock_number": {
      "id": "product.stock_number",
      "name": "Stock number",
      "params": {
        "max_length": 255
      },
      "type": "string"
    },
    "etsy.ivy_and_rose.when_made": {
      "id": "etsy.ivy_and_rose.when_made",
      "name": "Etsy -> When made",
      "options": {
        "1700s": "1700s",
        "1800s": "1800s",
        "1900s": "1900s",
        "1910s": "1910s",
        "1920s": "1920s",
        "1930s": "1930s",
        "1940s": "1940s",
        "1950s": "1950s",
        "1960s": "1960s",
        "1970s": "1970s",
        "1980s": "1980s",
        "1990s": "1990s",
        "2000_2004": "2000 2004",
        "2005_2009": "2005 2009",
        "2010_2019": "2010 2019",
        "2020_2024": "2020 2024",
        "before_1700": "Before 1700",
        "before_2005": "Before 2005",
        "made_to_order": "Made to order"
      },
      "params": {
        "is_select": true,
        "required": true
      },
      "type": "string"
    },
    "prop.Y29yZT1DYW4rYmUrcGVyc29uYWxpemVkJmViYXk9JmV0c3k9JnNob3BpZnk9": {
      "id": "prop.Y29yZT1DYW4rYmUrcGVyc29uYWxpemVkJmViYXk9JmV0c3k9JnNob3BpZnk9",
      "name": "Property -> Can be personalized",
      "options": [
        "Yes",
        "No"
      ],
      "params": {
        "max_length": 65,
        "max_values": 1
      },
      "type": "string"
    },
    "product.location": {
      "id": "product.location",
      "name": "Location",
      "params": {
        "readonly": true
      },
      "type": "string"
    },
    "shopify.ilya_fine_jewelry.seo_title": {
      "id": "shopify.ilya_fine_jewelry.seo_title",
      "name": "Shopify -> SEO title [Ilya Fine Jewelry]",
      "params": {
        "max_length": 60
      },
      "type": "string"
    },
    "prop.Y29yZT1OYXR1cmFsJTJGbGFiLWNyZWF0ZWQmZWJheT0mZXRzeT0mc2hvcGlmeT0": {
      "id": "prop.Y29yZT1OYXR1cmFsJTJGbGFiLWNyZWF0ZWQmZWJheT0mZXRzeT0mc2hvcGlmeT0",
      "name": "Property -> Natural / lab-created",
      "options": [
        "Lab-Created",
        "Natural"
      ],
      "params": {
        "max_length": 65,
        "max_values": 1
      },
      "type": "string"
    },
    "ebay.ivy_and_rose.store_category2_id": {
      "id": "ebay.ivy_and_rose.store_category2_id",
      "name": "Ebay -> Store category 2",
      "options": {
        "1": "Other",
        "5809673": "Necklaces/Pendants",
        "5979469": "Earrings",
        "5979473": "Bracelets",
        "6106058": "Gemstone Rings",
        "8154825": "Men's Rings",
        "13991579": "Watches",
        "14736767": "Ladies Diamond Rings",
        "14736768": "Vintage & Antique",
        "14736769": "Loose Diamonds",
        "14736770": "Chains",
        "14736792": "Ladies Diamond Rings/Engagement Rings",
        "14736870": "Ladies Diamond Rings/Diamond Fashion Rings",
        "14736871": "Ladies Diamond Rings/Settings/Semi-Mounts",
        "14736872": "Ladies Diamond Rings/3 Stone Diamond Rings",
        "14736886": "Vintage & Antique/Vintage/Antique Rings",
        "14736887": "Vintage & Antique/Vintage/Antique Earrings",
        "14736888": "Vintage & Antique/Vintage/Antique Bracelets",
        "14736889": "Vintage & Antique/Other Vintage/Antique Jewelry",
        "14737504": "Earrings/Stud Earrings",
        "14737505": "Earrings/Exquisite Earrings",
        "14843984": "Ladies Diamond Rings/Anniversary/Wedding Bands"
      },
      "params": {
        "is_select": true
      },
      "type": "string"
    },
    "shopify.ilya_fine_jewelry.vendor": {
      "id": "shopify.ilya_fine_jewelry.vendor",
      "name": "Shopify -> Vendor [Ilya Fine Jewelry]",
      "options": [
        "Ivy & Rose",
        "Ilya Fine Jewelry"
      ],
      "params": {},
      "type": "string"
    },
    "shopify.ivy_and_rose.seo_title": {
      "id": "shopify.ivy_and_rose.seo_title",
      "name": "Shopify -> SEO title [Ivy & Rose]",
      "params": {
        "max_length": 60
      },
      "type": "string"
    },
    "prop.Y29yZT1HZW1zdG9uZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": {
      "id": "prop.Y29yZT1HZW1zdG9uZSZlYmF5PSZldHN5PSZzaG9waWZ5PQ",
      "name": "Property -> Gemstone",
      "options": [],
      "params": {
        "max_length": 65,
        "max_values": 1
      },
      "type": "string"
    },
    "prop.Y29yZT1TaWRlK3N0b25lJmViYXk9JmV0c3k9JnNob3BpZnk9": {
      "id": "prop.Y29yZT1TaWRlK3N0b25lJmViYXk9JmV0c3k9JnNob3BpZnk9",
      "name": "Property -> Side stone",
      "options": [],
      "params": {
        "max_length": 65,
        "max_values": 1
      },
      "type": "string"
    },
    "shopify.ilya_fine_jewelry.collection_ids": {
      "id": "shopify.ilya_fine_jewelry.collection_ids",
      "name": "Shopify -> Collections [Ilya Fine Jewelry]",
      "options": {
        "275900301493": "Home page",
        "278661922997": "test",
        "278832808117": "new",
        "341388591285": "Our Recommendations"
      },
      "params": {
        "is_select": true,
        "max_values": 10
      },
      "type": "string"
    },
    "shopify.ilya_fine_jewelry.tags": {
      "id": "shopify.ilya_fine_jewelry.tags",
      "name": "Shopify -> Tags [Ilya Fine Jewelry]",
      "options": [
        "tag"
      ],
      "params": {
        "max_values": 10
      },
      "type": "string"
    },
    "prop.Y29yZT1TaWRlK3N0b25lK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9": {
      "id": "prop.Y29yZT1TaWRlK3N0b25lK2NvbG9yJmViYXk9JmV0c3k9JnNob3BpZnk9",
      "name": "Property -> Side stone color",
      "options": [],
      "params": {
        "max_length": 65,
        "max_values": 1
      },
      "type": "string"
    },
    "ebay.ivy_and_rose.category_id": {
      "id": "ebay.ivy_and_rose.category_id",
      "name": "Ebay -> Category",
      "options": {
        "494": "Jewelry & Watches -> Jewelry Care, Design & Repair -> Other Jewelry Care, Design & Repair",
        "10292": "Jewelry & Watches -> Men's Jewelry -> Bolo Ties",
        "67681": "Jewelry & Watches -> Fashion Jewelry -> Rings",
        "110655": "Jewelry & Watches -> Handcrafted & Artisan Jewelry -> Necklaces & Pendants",
        "137839": "Jewelry & Watches -> Men's Jewelry -> Necklaces & Pendants",
        "137856": "Jewelry & Watches -> Men's Jewelry -> Rings",
        "166734": "Jewelry & Watches -> Vintage & Antique Jewelry -> Other Vintage & Antique Jewelry",
        "261975": "Jewelry & Watches -> Engagement & Wedding -> Engagement Rings",
        "261976": "Jewelry & Watches -> Engagement & Wedding -> Engagement & Wedding Ring Sets",
        "261977": "Jewelry & Watches -> Engagement & Wedding -> Wedding & Anniversary Bands",
        "261984": "Jewelry & Watches -> Ethnic, Regional & Tribal -> Necklaces & Pendants",
        "261987": "Jewelry & Watches -> Fashion Jewelry -> Bracelets & Charms",
        "261988": "Jewelry & Watches -> Fine Jewelry -> Bracelets & Charms",
        "261990": "Jewelry & Watches -> Fine Jewelry -> Earrings",
        "261992": "Jewelry & Watches -> Fine Jewelry -> Jewelry Sets",
        "261993": "Jewelry & Watches -> Fine Jewelry -> Necklaces & Pendants",
        "261994": "Jewelry & Watches -> Fine Jewelry -> Rings",
        "261999": "Jewelry & Watches -> Men's Jewelry -> Bracelets & Charms",
        "262003": "Jewelry & Watches -> Vintage & Antique Jewelry -> Bracelets & Charms",
        "262004": "Jewelry & Watches -> Vintage & Antique Jewelry -> Brooches & Pins",
        "262005": "Jewelry & Watches -> Vintage & Antique Jewelry -> Cameos",
        "262006": "Jewelry & Watches -> Vintage & Antique Jewelry -> Cufflinks",
        "262008": "Jewelry & Watches -> Vintage & Antique Jewelry -> Earrings",
        "262013": "Jewelry & Watches -> Vintage & Antique Jewelry -> Necklaces & Pendants",
        "262014": "Jewelry & Watches -> Vintage & Antique Jewelry -> Rings",
        "262019": "Jewelry & Watches -> Jewelry Care, Design & Repair -> Jewelry Settings",
        "262020": "Jewelry & Watches -> Jewelry Care, Design & Repair -> Jewelry Tools & Workbenches",
        "262026": "Jewelry & Watches -> Loose Diamonds & Gemstones -> Loose Diamonds",
        "262027": "Jewelry & Watches -> Loose Diamonds & Gemstones -> Loose Gemstones"
      },
      "params": {
        "is_select": true
      },
      "type": "string"
    },
    "prop.Y29yZT1NYWRlK3RvK29yZGVyJmViYXk9JmV0c3k9JnNob3BpZnk9": {
      "id": "prop.Y29yZT1NYWRlK3RvK29yZGVyJmViYXk9JmV0c3k9JnNob3BpZnk9",
      "name": "Property -> Made to order",
      "options": [
        "Yes",
        "No"
      ],
      "params": {
        "is_select": true,
        "max_length": 65,
        "max_values": 1
      },
      "type": "string"
    },
    "product.availability": {
      "id": "product.availability",
      "name": "Availability",
      "params": {
        "min": 0,
        "readonly": true,
        "required": true
      },
      "type": "number"
    },
    "prop.Y29yZT1DZW50ZXIrc3RvbmUrc2hhcGUmZWJheT0mZXRzeT0mc2hvcGlmeT0": {
      "id": "prop.Y29yZT1DZW50ZXIrc3RvbmUrc2hhcGUmZWJheT0mZXRzeT0mc2hvcGlmeT0",
      "name": "Property -> Center stone shape",
      "options": [],
      "params": {
        "max_length": 65,
        "max_values": 1
      },
      "type": "string"
    },
    "prop.Y29yZT1DZW50ZXIrc3RvbmUrY2xhcml0eSZlYmF5PSZldHN5PSZzaG9waWZ5PQ": {
      "id": "prop.Y29yZT1DZW50ZXIrc3RvbmUrY2xhcml0eSZlYmF5PSZldHN5PSZzaG9waWZ5PQ",
      "name": "Property -> Center stone clarity",
      "options": [],
      "params": {
        "max_length": 65,
        "max_values": 1
      },
      "type": "string"
    },
    "prop.Y29yZT1TaWRlK3N0b25lK2NsYXJpdHkmZWJheT0mZXRzeT0mc2hvcGlmeT0": {
      "id": "prop.Y29yZT1TaWRlK3N0b25lK2NsYXJpdHkmZWJheT0mZXRzeT0mc2hvcGlmeT0",
      "name": "Property -> Side stone clarity",
      "options": [],
      "params": {
        "max_length": 65,
        "max_values": 1
      },
      "type": "string"
    }
  }
</script>

<ProductTable
  {products}
  {fields}
  dirty={setStore()}
  errors={mapStore()}
  drafts={mapStore()}
  loading={false}
  sending={false}
  on:update={console.log}
  on:create={console.log}
  on:exit={console.log}
/>

<!--
<SelectPage />
-->
