import { writable } from "svelte/store"
import persist from "./persist.js"

const notify = (eventType, payload) => {
  const event = new CustomEvent(eventType, {
    detail: payload || Date.now(),
    bubbles: true,
    composed: true
  })

  window.dispatchEvent(event)
}

const RE = /\[(.*?)\]/

const parseQuery = (search) => {
  let result = {}

  const params = new URLSearchParams(search)

  params.forEach((value, key) => {
    if (key && key.endsWith("]")) {
      const [objKey, subKey] = key.split("[")
      const obj = result[objKey] || {}

      obj[subKey.slice(0, -1)] = value
      result[objKey] = obj
    } else {
      const current = result[key] 
      switch (true) {
        case current === undefined: return result[key] = value
        case Array.isArray(current): return result[key] = [...current, value]
        default: return result[key] = [current, value]
      }
    }
  })

  return result
}

const parseURL = () => {
  const {
    pathname,
    search,
    hash
  } = window.location

  const [section, id, action] = pathname.split("/").slice(1)
  const query = parseQuery(search)

  const mode = !id ? "index"
    : id === "new" ? "new"
      : action === "edit"
        ? "edit" : "view"

  notify("parsedURL")

  return {
    id,
    mode,
    query,
    action,
    section,
    queryString: search,
    hash: hash.replace(/#/g, ""),
    visible: !document.hidden
  }
}

const { subscribe, set, update } = writable(parseURL())

const updateVisibility = (e) => {

  update(state => {
    state.visible = !document.hidden

    return state
  })
}

const performURL = () => {
  set(parseURL())
}

const url = (...args) => {
  const { section, id, action } = parseURL()
  let where = [section, id, action].filter(v => v)

  switch (args.length) {
    case 0:
      where = where.slice(0, -1)
      break
    case 1:
      where.push(args[0])
      break
    default:
      where = where.slice(0, -args.length)
      where.push(...args)
  }

  return `/${where.join("/")}`.replace(/\/\//g, "/")
}

const up = (lvl) => {
  const { section, id, action } = parseURL()
  const where = [section, id, action].filter(v => v)
  const url = "/" + where.slice(0, lvl || -1).join("/")

  history.pushState(where, "", url)

  toTop()
  performURL()
}

const go = (...args) => {
  const canBack = !args.length &&
    history.length &&
    history.state &&
    history.state.length === 1

  if (canBack) {
    history.back()
  } else {
    history.pushState(args, "", url(...args))

    toTop()
    performURL()
  }
}

const query = (params) => {
  const query = new URLSearchParams(location.search)

  Object.entries(params).forEach(([key, value]) => {
    switch (true) {
      case [null, "null", "undefined"].includes(value):
        query.delete(key)
        break

      case Array.isArray(value):
        query.delete(key)
        if (value.length) value.forEach(v => query.append(key, v))
        break

      case typeof value === 'object': 
        Object.entries(value).forEach(entry => {
          const deepKey = `${key}[${entry[0]}]`

          if (entry[1] !== null) {
            query.set(deepKey, entry[1])
          } else {
            query.delete(deepKey)
          }
        })
        break

      default:
        query.set(key, value)
    }
  })

  const queryString = query.toString() ? "?" + query.toString() : ""
  history.pushState(null, "", location.pathname + queryString)

  performURL()
}

const hash = (hash) => window.location.hash = hash

const toTop = (top) => {
  const current =
    document.body.scrollTop || document.documentElement.scrollTop

  if (!current || current < (top || 0)) return

  window.scrollTo({
    top: top || 0,
    behavior: "smooth"
  })
}

export const backLink = persist("backLink", {
  empty: {
    href: null,
    scroll: null,
    name: null
  }
})

backLink.restore = () => backLink.update(state => {
  const { href, scroll, empty } = state

  if (!href) return { empty, ...empty }
  if (href !== location.href) return state

  setTimeout(() => {
    window.scrollTo({
      top: scroll,
      behavior: "smooth"
    })
  }, 100)

  return { empty, ...empty }
})

backLink.save = (e, saveOnly) => {
  if (!e || !e.target.href) return

  backLink.update(state => ({
    scroll: window.pageYOffset,
    name: e.deepGet(["target", "dataset", "name"], "back"),
    href: location.href,
    empty: state.empty
  }))

  if (!saveOnly) {
    e.preventDefault()
    location.href = e.target.href
  }
}

window.addEventListener("popstate", performURL)
window.addEventListener("hashchange", performURL)
window.addEventListener("visibilitychange", updateVisibility)

export default {
  performURL,
  subscribe,
  notify,
  toTop,
  query,
  hash,
  url,
  go,
  up
}
