<script context="module">
  import { writable } from "svelte/store"

  let store = writable({})
  let i = 0

  const close = (tid) => store.update(toasts => {
    delete toasts[tid]

    return toasts
  })

  const alert = async (toast, status, timeout = 300) => {
    const tid = i++

    try {
      if (typeof toast.json === "function") toast = await toast.json()
    } catch (e) {
      console.error(e)
    }

    if (typeof status === "number") {
      switch (true) {
        case status < 300 && status >= 200: status = "ok"; break
        case status >= 400: status = "error"; break
      }
    }

    store.update(toasts => {
      toasts[tid] = {
        toast: toast,
        status: status
      }

      return toasts
    })

    if (timeout) setTimeout(() => close(tid), timeout * 1000)

    return toast
  }

  export const toasts = {
    alert,
    success: (toast, timeout) => alert(toast, "ok", timeout),
    error: (toast, timeout) => alert(toast, "error", timeout),
    info: (toast, timeout) => alert(toast, "info", timeout)
  }
</script>

<script>
  export let timeout = 30
  export let flash = {}

  import Toast from "./common/toast.svelte"

  Object.entries(flash).forEach(tuple => {
    const [status, message] = tuple

    alert(message, status, timeout)
  })
</script>


{#if Object.keys($store).length}
  <figure id="toasts">
    <div>
      {#each Object.entries($store) as [tid, toast] (tid)}
        <Toast
          {tid}
          {...toast}
          on:close={() => close(tid)}
        />
      {/each}
    </div>
  </figure>
{/if}

<style>
  figure {
    position: fixed;
    right: 0;
    top: 60px;
    bottom: 0;
    width: 50vw;
    max-width: 600px;
    min-width: 400px;
    overflow-x: hidden;
    overflow-y: auto;
    margin: 0;
    z-index: 999999;
    pointer-events: none;
  }
  div {
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 5px;
  }
</style>
