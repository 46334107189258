<script>
  import { Title, Button } from "hui"
  import { IconSquare, IconCheckSquare, IconAlertTriangle } from "hui"

  export let rowIdx
  export let row
  export let mode
  export let rowsChecked
  export let errors
  export let dirty
  export let rowsMissingRequired

  $: id = row.id
</script>

<Title
  size={{ width: "100%" }}
  theme="small"
  idx="product_sku"
>
  {#if rowsMissingRequired.has(id)}
    <IconAlertTriangle stroke="red" />
  {:else if mode === "changes"}
    <Button
      theme="flat small"
      name="Check row to submit"
      on:click={() => rowsChecked.toggle(id)}
    >
      {#if $rowsChecked.has(id)}
        <IconCheckSquare />
      {:else}
        <IconSquare />
      {/if}
    </Button>
  {:else}
    {rowIdx + 1}.
  {/if}
  {row.sku}
  {#if row.new}
    <sup>new</sup>
  {/if}
  {#if row.draft}
    <sup>draft</sup>
  {/if}
  {#if $errors.has(row.sku)}
    <sup>error</sup>
  {/if}
  {#if $dirty.has(id)}
    <sup>*</sup>
  {/if}
</Title>

<style>
  sup {
    line-height: 1;
  }
</style>
