const parse = () => {
  const result = {}

  const normalize = txt =>
    decodeURI(txt.trim())

  document.cookie.split(";").forEach(c => {
    const [key, value] = c.split("=")

    if (!value) return

    result[normalize(key)] = JSON.parse(normalize(value))
  })

  return result
}

export default {
  parse
}
