<script>
  import { Swapper, Title, Button, fetchParentByTag } from "hui"
  import { IconChevronRight, IconChevronLeft } from "hui"
  import { createEventDispatcher } from "svelte"
  const dispatch = createEventDispatcher()

  export let columns
  export let col
  export let colIdx
  export let mode
  export let fields
  export let colsWidthMap
  export let colsActiveMap
  export let colsSettingsInactive

  $: colName = fields[col]?.name
  $: colWidth = +$colsWidthMap[col]
  $: isActive = colsActiveMap[col]

  const applyColsOrder = (cols) => dispatch("applyColsOrder", cols)

  const mvCol = (dir) => {
    const cols = [...columns]
    const [col] = cols.splice(colIdx, 1)

    let newIxd = dir === "right" ? colIdx + 1 : colIdx - 1

    cols.splice(newIxd, 0, col)

    applyColsOrder(cols)
  }

  const changeWidth = (e, dir) => {
    let current = colWidth

    if (!current) {
      const th = fetchParentByTag(e.target.parentElement, "TH")
      current = th.offsetWidth
    }

    const diff = (dir === "-" ? -current : current) * 0.1

    $colsWidthMap[col] = current + diff
  }
</script>

{#if mode === "columns"}
  <Swapper
    idx={colIdx}
    list={columns}
    on:swap={(e) => applyColsOrder(e.detail)}
  >
    <Title theme="small center">
      {colName}
    </Title>
    <Button
      theme="small"
      text="<"
      disabled={!colIdx}
      name="{colName} move left"
      on:click={() => mvCol("left")}
    >
      <IconChevronLeft />
    </Button>
    <Button
      theme="small"
      text="-"
      disabled={colWidth <= 250}
      on:click={(e) => changeWidth(e, "-")}
    />
    <Button
      theme="small"
      active={isActive}
      text={isActive ? "On" : "Off"}
      name="{colName} switch {!isActive ? "on" : "off"}"
      on:click={() => $colsSettingsInactive[col] = !!isActive}
    />
    <Button
      theme="small"
      text="+"
      disabled={colWidth >= window.innerWidth / 2}
      on:click={(e) => changeWidth(e, "+")}
    />
    <Button
      theme="small"
      text=">"
      name="{colName} move right"
      disabled={colIdx >= columns.length - 1}
      on:click={() => mvCol("right")}
    >
      <IconChevronRight />
    </Button>
  </Swapper>
{:else}
  <Title theme="small">
    {colName}
  </Title>
{/if}
