<script>
  import { Select, NumberInput, MultiInput, TextInput, Textarea, Button, MultiSelect } from "hui"
  import { getIn, putIn, delIn, formatValue, isEqual, uniqueArray } from "hui"
  import { IconX, IconCheck, IconRotateLeft, IconAlertCircle } from "hui"
  import { isObject, isArray, checkEmpty, scrollIntoViewIfNeeded } from "hui"

  import { createEventDispatcher, tick } from "svelte"
  const dispatch = createEventDispatcher()

  export let changes
  export let row
  export let field
  export let dirty
  export let errors
  export let disabled
  export let cellFocus
  export let cellFocusSku
  export let cellFocusHistory
  export let rowIdx
  export let colIdx

  let tmpValue, inputNode;

  $: id = row?.id
  $: sku = row?.sku
  $: name = field?.name
  $: isDirty = $dirty.has(id)
  $: isError = $errors.has(sku)
  $: fieldId = id && field?.id
  $: isDraft = id && row?.draft
  $: type = id && field?.type
  $: options = id && field?.options
  $: params = id && field?.params
  $: maxValues = getIn(params, "max_values", 1)
  $: readonly = params?.readonly && !isDraft
  $: def = id && (field?.default ?? null)
  $: oldValueRaw = id && getIn(row, ["data", fieldId], def)
  $: oldValue = id && formatValue(oldValueRaw, type, def)
  $: changed = id && getIn($changes, id)?.hasOwnProperty(fieldId)
  $: newValue = changed ? getIn($changes, [id, fieldId], def) : def
  $: commitedValue = changed ? newValue : oldValue
  $: tmpChanged = tmpValue !== undefined && !isEqual(tmpValue, commitedValue)
  $: isMissed = params?.required && checkEmpty(commitedValue)

  $: visibleValue = id && !params?.is_select || !isObject(options) ? commitedValue
    : isArray(commitedValue) ? commitedValue.map(v => options[v])
    : options[commitedValue]

  $: formattedValue = checkEmpty(visibleValue) ? "-"
    : isArray(visibleValue) ? visibleValue.join(", ") : visibleValue

  $: isFocused = $cellFocus?.col === colIdx && $cellFocus?.row === rowIdx
  $: wasFocused = $cellFocusHistory?.col === colIdx && $cellFocusHistory?.row === rowIdx

  $: idx = `${rowIdx}|${colIdx}`

  const change = (e) => tmpValue = e.detail

  const reset = (resetFocus) => {
    tmpValue = undefined

    if (resetFocus) {
      cellFocusSku.set(null)
      cellFocus.set({ row: null, col: null })
    }
  }

  const commit = (resetFocus = true) => {
    if (tmpValue === undefined) return reset(resetFocus)

    if (params?.unique) tmpValue = uniqueArray(tmpValue)
    
    if (isEqual(tmpValue, oldValue)) {
      changes.update(c => delIn(c, [id, fieldId]))
    } else if (!isEqual(tmpValue, commitedValue)) {
      changes.update(c => putIn(c, [id, fieldId], tmpValue))
    }

    reset(resetFocus)
  }

  const resetChanges = async () => {
    await tick()

    if (!changed) return
    if (!isEqual(newValue, oldValue)) return
    
    changes.update(c => delIn(c, [id, fieldId]))
  }

  $: resetChanges(oldValueRaw)

  const restore = (e) => {
    e.stopPropagation()
    changes.update(c => delIn(c, [id, fieldId]))
    
    reset(false)
  }

  const edit = () => {
    cellFocus.set({ col: colIdx, row: rowIdx, noScroll: true })
  }

  $: {
    if (wasFocused && !isFocused) {
      tick().then(() => {
        commit(false)
        dispatch("blur")
      })
    } else if (isFocused && !wasFocused) {
      cellFocusSku.set(`${rowIdx + 1}. ${sku}`)
      if (!$cellFocus.noScroll) scrollIntoViewIfNeeded(inputNode)
    }
  }
</script>

{#if !isFocused || readonly || disabled || isDirty}
  <Button
    theme="flat round"
    disabled={disabled || isDirty}
    title="SKU: {sku}"
    name="{name} edit"
    {readonly}
    size={{
      width: "100%",
      maxWidth: "33vw"
    }}
    on:click={edit}
  >
    {#if changed}
      <sup>*</sup>
    {/if}
    {#if formattedValue !== "-"}
      {params?.prefix || ""}
      {formattedValue}
      {params?.suffix || ""}
    {:else if isMissed}
      <IconAlertCircle />
    {:else}
      -
    {/if}
  </Button>
{:else}
  {#if changed && !tmpChanged}
    <Button
      theme="round small flat"
      on:click={restore}
      margin={{ right: ".5rem" }}
      title="Restore initial value"
      name="{name} restore"
    >
      <IconRotateLeft />
    </Button>
  {:else}
    <Button
      theme="round small flat"
      margin={{ right: ".5rem" }}
      on:click={reset}
      title="Cancel editing"
      name="{name} reset"
    >
      <IconX />
    </Button>
  {/if}
  {#if params?.is_select && maxValues > 1}
    <MultiSelect
      selected={tmpChanged ? tmpValue : commitedValue}
      options={options}
      {maxValues}
      {idx}
      {name}
      {readonly}
      bind:inputNode
      on:blur
      on:focus
      on:select={change}
    />
  {:else if params?.is_select}
    <Select
      value={tmpChanged ? tmpValue : commitedValue}
      options={options}
      required={params?.required}
      {idx}
      {name}
      {readonly}
      bind:inputNode
      on:blur
      on:focus
      on:select={change}
    />
  {:else if type === "number"}
    <NumberInput
      placeholder="Enter"
      value={tmpChanged ? tmpValue : commitedValue}
      min={params?.min}
      max={params?.max}
      step={params?.step}
      prefix={params?.prefix}
      suffix={params?.suffix}
      {idx}
      {name}
      {readonly}
      bind:inputNode
      on:blur
      on:focus
      on:change={change}
    />
  {:else if maxValues > 1}
    <MultiInput
      placeholder="Enter"
      values={tmpChanged ? tmpValue : commitedValue}
      options={options}
      maxLength={params?.max_length}
      {maxValues}
      {idx}
      {name}
      {readonly}
      bind:inputNode
      on:blur
      on:focus
      on:change={change}
    />
  {:else if params?.max_length > 255}
    <Textarea
      placeholder="Enter"
      value={tmpChanged ? tmpValue : commitedValue}
      maxLength={params?.max_length}
      {idx}
      {name}
      {readonly}
      bind:inputNode
      on:blur
      on:focus
      on:change={change}
      newLineCtrlEnter
    />
  {:else}
    <TextInput
      placeholder="Enter"
      value={tmpChanged ? tmpValue : commitedValue}
      maxLength={params?.max_length}
      {idx}
      {name}
      {readonly}
      bind:inputNode
      on:blur
      on:focus
      prefix={params?.prefix}
      suffix={params?.suffix}
      options={options}
      on:change={change}
    />
  {/if}
  {#if changed && !tmpChanged}
    <Button
      theme="round small flat"
      margin={{ left: ".5rem" }}
      on:click={reset}
      title="Cancel editing"
      name="{name} reset"
    >
      <IconX />
    </Button>
  {:else}
    <Button
      text="✓"
      theme="round small flat"
      margin={{ left: ".5rem" }}
      on:click={commit}
      title="Confirm changes"
      name="{name} commit"
    >
      <IconCheck />
    </Button>
  {/if}
{/if}
