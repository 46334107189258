import { writable, derived, get } from "svelte/store"
import { backLink } from "./router.js"
import cookie from "./cookie.js"
import socket from "./socket.js"

const { subscribe, update } = writable({})
  
const logout = async (to, csrf, method) => {
  localStorage.clear()

  backLink.save({
    target: { href: to }
  }, true)

  const res = (to && csrf && method) ? await fetch(to, {
    headers: {
      "content-type": "application/x-www-form-urlencoded",
      "x-from-js": "json",
      "x-csrf-token": csrf
    },
    credentials: "include",
    method: "POST",
    mode: "cors",
    body: `_method=${method}`
  }).catch(() => ({})) : {}

  location.href = res.redirected ? res.url : "/"
}

const fetchUser = (user = {}) => {
  const {
    user_id,
    user_role,
    user_name,
    company_name,
    company_features
  } = cookie.parse()

  const event = new Event("usersUpdated")
  window.dispatchEvent(event)

  if (user.id && user.id !== user_id) return

  const role = user.role || user_role

  const rules = {
    isManager: ["admin", "manager"].includes(role),
    company_features: company_features || [],
    isAdmin: role === "admin"
  }

  update(store => Object.assign(store, {
    id: user.id || user_id,
    name: user_name,
    company_name,
    role
  }, rules))
}

fetchUser()

const apply = (res, cb) => {
  if (typeof cb !== "function") return

  cb(res)
}

export const saveSettings = (target, data, csrf, okCb, errCb) => {
  fetch(`/settings/user/${target}`, {
    headers: {
      "content-type": "application/json",
      "x-csrf-token": csrf
    },
    body: JSON.stringify({ data }),
    credentials: "include",
    method: "PUT",
    mode: "cors"
  })
  .then(resp => resp.json().then(r => apply(r, okCb)))
  .catch(e => apply(e, errCb))
}

const fetchSettings = async (target) => {
  const resp = await fetch(`/settings/user/${target}`, {
    credentials: "include",
    mode: "cors"
  })

  if (!resp.ok) return null

  return await resp.json().catch(() => null)
}

export const settings = (target, def) => {
  const { user_id } = cookie.parse()
  const channel = "user_settings"
  const serverData = writable(def ?? null)

  const tmpData = writable(undefined, () => {
    const refs = socket.subscribe(channel, [target], fetch)
    return () => socket.unsubscribe(channel, [target], refs)
  })

  const fetch = (user) => {
    if (user.id !== user_id) return 

    fetchSettings(target).then(serverData.set)
  }

  fetch({ id: user_id })

  const store = derived([serverData, tmpData], ([$srv, $tmp], set) => {
    const changed = $tmp !== undefined 
    const data = (changed ? $tmp : $srv) ?? def ?? null

    set({ changed, data, _target: target })
  }, { changed: false, data: def ?? null, _target: target })

  const reset = () => tmpData.set(undefined)

  const save = (csrf, okCb, errCb) => {
    const data = get(tmpData)
    if (data === undefined) return

    const cb = (msg) => {
      apply(msg, okCb)
      reset()
    }

    saveSettings(target, data, csrf, cb, errCb)
  }

  const update = (cb) => {
    const value = cb(get(store).data)
    tmpData.set(value)
  }

  /*
  * This for $store.key = "value"
  */
  const set = (value) => {
    if (value == null) return tmpData.set(value)
    if (typeof value !== "object") return tmpData.set(value)
    if (!value.hasOwnProperty("_target")) return tmpData.set(value) 
    const keys = new Set(Object.keys(value))
    const diff = keys.difference(new Set(["changed", "data", "_target"]))
    if (diff.size !== 1) return tmpData.set(value) 
    const [key] = diff.values()

    tmpData.update(tmp => {
      return { ...(tmp || get(store).data), [key]: value[key] }
    })
  }

  return {
    subscribe: store.subscribe,
    update,
    reset,
    save,
    set
  }
}

socket.subscribe("users", ["updated"], fetchUser)

export default {
  subscribe,
  logout
}
