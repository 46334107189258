<script>
  import { Flex, Button, Strong, TextInput, arrayToMap, Badge } from "hui"
  import { IconChevronLeft, IconChevronRight, IconX, IconSettings } from "hui"
  import { toasts } from "../../toasts.svelte"

  import { createEventDispatcher, getContext, tick, onDestroy } from "svelte"
  const dispatch = createEventDispatcher()
  const csrf = getContext("csrf")

  export let mode
  export let columns
  export let drafts
  export let loading
  export let sending
  export let tableNode
  export let rowsChagengedCount
  export let changesMode
  export let columnsMode
  export let columnsListMode
  export let rowsToUpdate
  export let rowsToCreate
  export let rowsChecked
  export let currentRows
  export let rowsPageCount
  export let rowsPage
  export let colsFilter
  export let colsActiveCount
  export let colsCount
  export let colsSettingsInactive
  export let colsSettingsOrder
  export let rowsMissingRequired
  export let requiredCols
  export let cellFocusSku

  let addDraftMode = false
  let newDraftSku = null

  const exit = () => dispatch("exit")
  const update = () => dispatch("update")
  const create = () => dispatch("create")
  const colsSort = () => dispatch("colsSort")

  const colsBulkSwitch = (stateOrFun) =>
    $colsSettingsInactive = arrayToMap(columns, stateOrFun)

  $: settingsChanged = $colsSettingsInactive.changed || $colsSettingsOrder.changed

  const settingStores = [colsSettingsInactive, colsSettingsOrder]

  const saveSettings = () => settingStores.forEach(store => {
    store.save(csrf, toasts.success, toasts.error)
  })

  const resetSettings = () => settingStores.forEach(store => store.reset())

  const scroll = async (dir = "up") => {
    if (!tableNode) return
    await tick()

    tableNode.scrollTop = (dir === "up" ? 0 : tableNode.scrollHeight)
  }

  const changePage = async (dir) => {
    const page = dir == "next" ? rowsPage + 1 : rowsPage - 1

    if (page < 0 || page >= rowsPageCount) return

    rowsPage = page
    scroll("up")
  }

  $: validRowIds = currentRows.reduce((acc, r) =>
    rowsMissingRequired.has(r.id) ? acc : [...acc, r.id], [])

  $: rowsToSendCount = rowsToCreate.length + rowsToUpdate.length

  $: nextSkuInt = [...$drafts.values()].reduce((result, draft) => {
    const int = +draft?.sku
    return isNaN(int) || result > int ? result : int
  }, null)

  $: nextSku = nextSkuInt == null ? "" : `(${nextSkuInt + 1})`

  const fetchParams = (body) => ({
    credentials: "include",
    mode: "cors",
    method: "POST",
    body: JSON.stringify(body),
    headers: {
      "x-csrf-token": csrf,
      "content-type": "application/json",
      "accept": "application/json"
    }
  })

  const cancelSkuReserve = () => {
    const skus = [...$drafts.values()].map(d => d.sku)
    const params = fetchParams({ skus })

    fetch("/products/cancel_sku_reserve", params)
  }

  const checkDraftSku = () => {
    if (!newDraftSku) return

    const params = fetchParams({ sku: newDraftSku })

    fetch("/products/check_sku", params)
    .try()
    .match({
      Left: toasts.error,
      Right: resp => resp.json().try().match({
        Left: toasts.error,
        Right: result => {
          if (result.message) toasts.error(result.message)
          if (result.ack === "ok") addDraft(result.product_id)
        }
      })
    })
  }

  const addDraft = async (id) => {
    drafts.set(id, {
      id: id,
      sku: newDraftSku,
      data: {},
      draft: true,
      new: true
    })

    await tick()
    newDraftSku = null
    addDraftMode = false
    rowsPage = rowsPageCount - 1
    scroll("down")
  }

  onDestroy(cancelSkuReserve)
</script>

<Flex
  alignItems="center"
  justifyContent="center"
  columnGap=".3rem"
  bg="#eee"
  padding={{
    top: ".3rem",
    bottom: ".3rem",
    right: "1rem",
    left: "1rem"
  }}
>
  {#if $cellFocusSku}
    <Badge
      theme="small"
      name="Current row sku"
    >
      {$cellFocusSku}
    </Badge>
  {/if}
  <div>
    &nbsp;
  </div>
  <Button
    theme="medium"
    name="Add draft"
    title="Add draft"
    hidden={mode !== "normal"}
    text={addDraftMode ? "CANCEL" : "ADD"}
    on:click={() => addDraftMode = !addDraftMode}
  />
  {#if addDraftMode}
    <TextInput
      theme="medium"
      name="New draft sku"
      bind:value={newDraftSku}
      placeholder="NEW PRODUCT SKU {nextSku}"
      on:enter={checkDraftSku}
      size={{ width: "12rem", minWidth: "12rem" }}
    />
    <Button
      theme="medium"
      on:click={checkDraftSku}
      disabled={!newDraftSku}
      title="Submit draft"
      name="Submit draft"
      text="ADD"
    />
  {:else}
    <Button
      theme="medium"
      name="Changes mode"
      title="Review changes"
      active={mode === "changes"}
      disabled={loading || sending}
      hidden={mode === "columns" || !rowsChagengedCount}
      on:click={() => changesMode = mode !== "changes"}
    >
      {#if mode === "changes"}
        EXIT
      {:else}
        REVIEW CHANGES
        <sup>{rowsChagengedCount}</sup>
      {/if}
    </Button>
    {#if mode === "changes"}
      <Button
        theme="medium"
        name="Create checked"
        title="Create checked"
        on:click={create}
        disabled={sending || loading || !rowsToCreate.length}
      >
        {#if sending || loading}
          PROCESSING
        {:else}
          CREATE
        {/if}
        <sup>{rowsToCreate.length || 0}</sup>
      </Button>
      <Button
        theme="medium"
        name="Update checked"
        title="Update checked"
        on:click={update}
        disabled={sending || loading || !rowsToUpdate.length}
      >
        {#if sending || loading}
          PROCESSING
        {:else}
          UPDATE
        {/if}
        <sup>{rowsToUpdate.length || 0}</sup>
      </Button>
      <Button
        theme="medium"
        name="Check all rows"
        title="Check all rows"
        disabled={rowsToSendCount >= validRowIds.length}
        on:click={() => rowsChecked.replace(validRowIds)}
      >
        ALL
      </Button>
      <Button
        theme="medium"
        name="Reset checked rows"
        title="Reset checked rows"
        disabled={!rowsToSendCount}
        on:click={() => rowsChecked.clear()}
      >
        CLEAR
      </Button>
    {/if}
    {#if mode === "normal" && rowsPageCount > 1}
      <Button
        theme="medium"
        text="<"
        title="Previous page"
        disabled={!rowsPage}
        on:click={() => changePage("prev")}
      >
        <IconChevronLeft />
      </Button>
      <Strong>
        {rowsPage + 1}/{rowsPageCount}
      </Strong>
      <Button
        theme="medium"
        title="Next page"
        disabled={rowsPage + 1 === rowsPageCount}
        on:click={() => changePage("next")}
      >
        <IconChevronRight />
      </Button>
    {/if}
    <TextInput
      theme="medium"
      hidden={mode === "changes" || columnsListMode}
      bind:value={colsFilter}
      placeholder="COLUMN"
      size={{ width: "6rem", minWidth: "6rem" }}
    />
    {#if mode === "columns"}
      <Button
        theme="medium"
        title="Column list"
        name="Columns list mode"
        text="LIST"
        active={columnsListMode}
        on:click={() => columnsListMode = !columnsListMode}
      />
      <Button
        theme="medium"
        on:click={colsSort}
        title="Sort columns"
        name="Sort columns az"
        text="A > Z"
      />
      <Button
        theme="medium"
        text="OFF"
        name="Switch off all columns"
        title="Switch off all columns"
        disabled={!colsActiveCount}
        on:click={() => colsBulkSwitch(!false)}
      />
      <Strong>
        {colsActiveCount}
        /
        {colsCount}
      </Strong>
      <Button
        theme="medium"
        text="ON"
        name="Switch on all columns"
        title="Switch on all columns"
        disabled={colsActiveCount === colsCount}
        on:click={() => colsBulkSwitch(!true)}
      />
      <Button
        theme="medium"
        text="REQUIRED"
        title="Switch on all required columns"
        name="Switch on all required columns"
        on:click={() => colsBulkSwitch(c => !requiredCols.has(c))}
      />
      {#if settingsChanged}
        <Button
          theme="medium"
          text="SAVE"
          title="Save column settings"
          name="Save column settings"
          on:click={saveSettings}
        />
        <Button
          theme="medium"
          text="RESET"
          title="Reset column settings"
          name="Reset column settings"
          on:click={resetSettings}
        />
      {/if}
    {/if}
    <Button
      theme="medium"
      active={mode === "columns"}
      hidden={mode === "changes"}
      title="Column settings"
      name="Columns mode"
      on:click={() => columnsMode = mode !== "columns"}
    >
      {#if mode === "columns"}
        <IconX />
      {:else}
        COLUMNS
        {#if settingsChanged}
          <sup>*</sup>
        {/if}
      {/if}
    </Button>
    {#if mode === "normal"}
      <Button
        theme="medium"
        text="QUIT"
        title="Quit editor"
        name="Quit"
        on:click={exit}
      />
    {/if}
  {/if}
</Flex>

<style>
  sup {
    line-height: 1;
  }
  div {
    flex-grow: 1;
  }
</style>
