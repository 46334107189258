<script>
  export let cellFocus
  export let cellFocusSku
  export let cellInputFocus
  export let colsVisibleCount
  export let currentRowsCount

  $: rowIdx = $cellFocus.row
  $: colIdx = $cellFocus.col
  $: isFocusedCell = rowIdx != null && colIdx != null

  const moveCellUp = () => {
    if (!rowIdx) return

    cellFocus.set({ row: rowIdx - 1, col: colIdx })
  }

  const moveCellDown = () => {
    if (rowIdx >= currentRowsCount - 1) return

    cellFocus.set({ row: rowIdx + 1, col: colIdx })
  }

  const moveCellLeft = () => {
    if (!colIdx) return

    cellFocus.set({ col: colIdx - 1, row: rowIdx })
  }

  const moveCellRight = () => {
    if (colIdx >= colsVisibleCount - 1) return

    cellFocus.set({ col: colIdx + 1, row: rowIdx })
  }

  const stopEvent = (e) => {
    e.preventDefault()
    e.stopPropagation()
  }

  const moveCell = (e, dir) => {
    stopEvent(e)

    switch (dir) {
      case "Up": return moveCellUp()
      case "Down": return moveCellDown()
      case "Left": return moveCellLeft()
      case "Right": return moveCellRight()
    }
  }

  const focusInput = (e) => {
    stopEvent(e)

    const selector = `[data-hui-idx='${rowIdx}|${colIdx}'] [data-hui-input]`

    document.querySelector(selector)?.focus()
  }

  const resetFocus = () => {
    cellFocusSku.set(null)
    cellFocus.set({ row: null, col: null })
  }

  const keydown = (e) => {
    if (!isFocusedCell || e.altKey || e.metaKey) return

    const isFocusedInput = $cellInputFocus

    if (e.ctrlKey && e.code.startsWith("Arrow")) {
      moveCell(e, e.code.replace("Arrow", ""))
    } else if (!e.ctrlKey && e.code === "Enter") {
      if (!isFocusedInput) focusInput(e)
      else moveCell(e, e.shiftKey ? "Up" : "Down")
    } else if (!e.ctrlKey && e.code === "Tab") {
      moveCell(e, e.shiftKey ? "Left" : "Right")
    } else if (!isFocusedInput && ["Space", "F2"].includes(e.code)) {
      focusInput(e)
    } else if ([e.code, e.key].includes("Escape")) {
      resetFocus()
    }
  }

  $: resetFocus(colsVisibleCount, currentRowsCount)
</script>

<svelte:window on:keydown={keydown} />
