<script>
  import { Title, Button, Scroll, Table, Swapper } from "hui"
  import { IconChevronLeft, IconChevronRight, IconMove } from "hui"
  import { createEventDispatcher } from "svelte"

  export let columns
  export let fields
  export let colsActiveMap
  export let colsSettingsInactive

  const dispatch = createEventDispatcher()
  const applyColsOrder = (e) => dispatch("applyColsOrder", e.detail)
</script>

<Scroll x>
  <Table margin={{ left: "auto", right: "auto" }}>
    <tr>
      <td />
      <td>
        <Title
          theme="center small"
          size={{ minWidth: "20rem" }}
        >
          Inactive
        </Title>
      </td>
      <td>
        <Title
          theme="center small"
          size={{ minWidth: "20rem" }}
        >
          Active
        </Title>
      </td>
      <td />
    </tr>
    {#each columns as col, colIdx}
      {@const colName = fields[col]?.name}
      {@const isActive = colsActiveMap[col]}
      <tr>
        <td>
          <Button
            text="Off"
            theme="small"
            disabled={!isActive}
            name="{colName} switch off"
            on:click={() => $colsSettingsInactive[col] = true}
          >
            <IconChevronLeft />
          </Button>
        </td>
        {#if isActive}
          <td></td>
        {/if}
        <td>
          <Swapper
            idx={colIdx}
            list={columns}
            theme="vertical"
            on:swap={applyColsOrder}
            flex={{
              justifyContent: "space-between",
              alignItems: "center"
            }}
          >
            <Title>
              {colName}
            </Title>
            <Button
              name="Column move"
              theme="flat small"
            >
              <IconMove />
            </Button>
          </Swapper>
        </td>
        {#if !isActive}
          <td></td>
        {/if}
        <td>
          <Button
            text="On"
            theme="small"
            disabled={isActive}
            name="{colName} switch on"
            on:click={() => $colsSettingsInactive[col] = false}
          >
            <IconChevronRight />
          </Button>
        </td>
      </tr>
    {/each}
  </Table>
</Scroll>

<style>
  td :global(button[name='Column move']) {
    opacity: 0;
  }
  td:hover :global(button[name='Column move']) {
    opacity: 1;
  }
</style>
