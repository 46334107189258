<script>
  import { DataTable, Title, Strong, Button, Grid, Overlay} from "hui"
  import { getIn, sortBy, checkEmpty, checkNotEmpty, setStore } from "hui"
  import { buildFuzzyRegex, arrayToMap, mapStore } from "hui"

  import KeyboardNavigation from "./keyboard_navigation.svelte"
  import ColumnChooser from "./column_chooser.svelte"
  import ProductField from "./product_field.svelte"
  import TableHeader from "./table_header.svelte"
  import ActionBar from "./action_bar.svelte"
  import FirstCell from "./first_cell.svelte"

  import persist from "../../stores/persist.js"
  import { settings } from "../../stores/user.js"
  import { tick, createEventDispatcher, getContext } from "svelte"
  import { writable, readable } from "svelte/store"

  const dispatch = createEventDispatcher()
  const csrf = getContext("csrf")

  export let products
  export let drafts
  export let fields
  export let dirty
  export let errors
  export let loading
  export let sending

  const changes = writable({})
  const rowsChecked = setStore()
  const cellFocus = writable({ col: null, row: null })
  const cellInputFocus = writable(false)
  const cellFocusSku = writable(null)

  let columnsListMode, columnsMode, changesMode, colsFilter, tableNode, tableWidth;

  const perPage = 25
  let rowsPage = 0

  $: rows = products.concat([...$drafts.values()])
  $: rowsFrom = +rowsPage * perPage
  $: rowsTo = Math.min(rowsFrom + perPage, rows.length)
  $: rowsPageCount = Math.ceil(rows.length / perPage)

  const cellFocusHistory = readable($cellFocus, set => {
    let current = { ...$cellFocus }

    return cellFocus.subscribe(newFocus => {
      set(current)
      current = { ...newFocus }
    })
  })

  const colsWidthMap = persist("bulk_editor_column_width", {})
  const colsSettingsInactive = settings("bulk_editor_column_inactive", {})
  const colsSettingsOrder = settings("bulk_editor_column_order", Object.keys(fields))

  const colSorter = (colA, colB, map) => {
    const idxA = map[colA] ?? -1
    const idxB = map[colB] ?? -1

    return idxA - idxB
  }

  $: colsOrderMap = $colsSettingsOrder?.data
  $: columns = Object.keys(fields).sort((a, b) => colSorter(a, b, colsOrderMap))

  $: colsInactiveMap = $colsSettingsInactive?.data
  $: activeColumns = columns.filter(c => !colsInactiveMap[c])
  $: colsActiveMap = arrayToMap(activeColumns, true)

  $: mode = changesMode ? "changes" : columnsMode ? "columns" : "normal"

  $: colsFilterRe = (colsFilter || "").length > 1
    ? buildFuzzyRegex(colsFilter) : null

  $: colsFilterMap = !colsFilterRe ? [] : arrayToMap(columns, c =>
    !getIn(fields, [c, "name"], "").match(colsFilterRe))

  $: colsVisibleCount = activeColumns.filter(c => !colsFilterMap[c]).length

  const changedColsReducer = (acc, changes) => {
    Object.keys(changes).forEach(col => acc[col] = true)

    return acc
  }

  $: colsChangedMap = mode !== "changes" ? {}
    : Object.values($changes).reduce(changedColsReducer, {})

  $: colsChangedCount = Object.keys(colsChangedMap).length

  $: colsActive = mode === "changes" ? colsChangedMap : colsActiveMap

  $: requiredCols = new Set(Object.values(fields).reduce((acc, field) => {
    return field?.params?.required ? [...acc, field.id] : acc
  }, []))

  const missingRequiredReducer = (acc, id, changes, required, drafts) => {
    const missed = drafts.has(id) ? [...required.values()].some(col => checkEmpty(changes[col]))
      : Object.entries(changes).some(([key, val]) => required.has(key) && checkEmpty(val))

    return missed ? [...acc, id] : acc
  }

  $: rowsMissingRequired = new Set(Object.entries($changes).reduce((acc, [id, changes]) =>
    missingRequiredReducer(acc, id, changes, requiredCols, $drafts), []))

  $: currentRows = mode === "columns" ? rows.slice(0, 1)
    : mode === "normal" ? rows.slice(rowsFrom, rowsTo)
    : rows.filter(p => checkNotEmpty($changes[p.id]))

  $: rowsChagengedCount = Object.values($changes).reduce((acc, changes) =>
    checkNotEmpty(changes) ? acc + 1 : acc, 0)

  $: {
    if (!rowsChagengedCount) {
      changesMode = false
      rowsChecked.clear()
    }
  }

  const rowsReducer = (acc, r, changes, type) => {
    if (!rowsChecked.has(r.id)) return acc
    if (type === "update" && $drafts.has(r.id)) return acc
    if (type === "create" && !$drafts.has(r.id)) return acc

    acc.push({
      id: r.id,
      sku: r.sku,
      changes: changes[r.id],
      new: type === "create"
    })

    return acc
  }

  $: rowsToUpdate = mode !== "changes" || !$rowsChecked.size ? []
    : currentRows.reduce((acc, r) => rowsReducer(acc, r, $changes, "update"), [])

  $: rowsToCreate = mode !== "changes" || !$rowsChecked.size ? []
    : currentRows.reduce((acc, r) => rowsReducer(acc, r, $changes, "create"), [])

  const applyColsOrder = (columns) =>
    $colsSettingsOrder = arrayToMap(columns, (_, i) => i)

  const colsSort = () => applyColsOrder(sortBy(columns, c =>
    getIn(fields, [c, "name"], "")))

  const calcTableWidth = async (tableNode) => {
    await tick()
    tableWidth = getIn(tableNode, "offsetWidth") || 0
  }

  $: calcTableWidth(
    tableNode,
    colsActiveMap,
    $colsWidthMap,
    colsFilterMap,
    colsChangedCount,
    mode
  )

  $: tableGrid = tableWidth > window.innerWidth ? null : {
    templateColumns: `repeat(${columns.length + 1}, auto)`
  }

  const update = () => dispatch("update", rowsToUpdate)
  const create = () => dispatch("create", rowsToCreate)
  const exit = () => dispatch("exit")
</script>

<Overlay
  fullscreen
  z="5"
  bg="white"
  flex={{
    direction: "column",
    justifyContent: "space-between",
    alignItems: "stretch"
  }}
>
  {#if mode === "columns" && columnsListMode}
    <ColumnChooser
      {columns}
      {fields}
      {colsActiveMap}
      {colsSettingsInactive}
      on:applyColsOrder={(e) => applyColsOrder(e.detail)}
    />
  {:else}
    <DataTable
      scrollX
      scrollY="hidden"
      cols={columns}
      rows={currentRows}
      size={{ maxWidth: "100%" }}
      {colsActive}
      colsFilter={colsFilterMap}
      colsWidth={$colsWidthMap}
      showInactiveCols={mode === "columns"}
      showFilteredCols={mode === "changes"}
      grid={tableGrid}
      bind:tbodyNode={tableNode}
      rowsIdxKey="id"
    >
      <TableHeader
        slot="th"
        let:col
        let:colIdx
        {col}
        {colIdx}
        {columns}
        {mode}
        {fields}
        {colsWidthMap}
        {colsActiveMap}
        {colsSettingsInactive}
        on:applyColsOrder={(e) => applyColsOrder(e.detail)}
      />
      <ProductField
        slot="td"
        let:col
        let:row
        let:colIdx
        let:rowIdx
        field={fields[col]}
        disabled={loading || sending}
        on:blur={() => $cellInputFocus = false}
        on:focus={() => $cellInputFocus = true}
        {row}
        {cellFocus}
        {cellFocusSku}
        {cellFocusHistory}
        {changes}
        {errors}
        {dirty}
        {colIdx}
        {rowIdx}
      />
      <FirstCell
        slot="tdFirst"
        let:rowIdx
        let:row
        {rowIdx}
        {row}
        {mode}
        {rowsChecked}
        {errors}
        {dirty}
        {rowsMissingRequired}
      />
      <Strong slot="thFirst">
        &nbsp;
      </Strong>
      <Strong slot="rowsEmptyTd">
        No products to edit, click <b>ADD</b> to create
      </Strong>
    </DataTable>
  {/if}
  <ActionBar
    {mode}
    {columns}
    {drafts}
    {loading}
    {sending}
    {tableNode}
    {rowsChagengedCount}
    {rowsToCreate}
    {rowsToUpdate}
    {currentRows}
    {rowsPageCount}
    {rowsChecked}
    {colsSettingsInactive}
    {colsSettingsOrder}
    {rowsMissingRequired}
    {requiredCols}
    {cellFocusSku}
    colsActiveCount={activeColumns.length}
    colsCount={columns.length}
    bind:colsFilter
    bind:rowsPage
    bind:changesMode
    bind:columnsMode
    bind:columnsListMode
    on:exit={exit}
    on:update={update}
    on:create={create}
    on:colsSort={colsSort}
  />
</Overlay>

<KeyboardNavigation
  {cellFocus}
  {cellFocusSku}
  {colsVisibleCount}
  {cellInputFocus}
  currentRowsCount={currentRows.length}
/>
